import type { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import type { IOption } from "store/slices/topicPractice";

interface Props {
  qIndex: number;
  options: IOption[];
  onClick: (qIndex: number, option: IOption) => void;
  selected: Array<IOption | null | undefined>;
}

const Option: FC<Props> = ({ qIndex, options, selected, onClick }) => {
  const isChecked = (qIndex: number, option: string | null | undefined) => {
    if (typeof option !== "string") return false;

    const answer = selected[qIndex];
    if (answer === null || answer === undefined) return false;

    return answer.value === option;
  };

  return (
    <div className="button-group">
      <ButtonGroup className={"btn-group-select btn-shadow"}>
        {options.map((option, oIndex) => (
          <ToggleButton
            key={oIndex}
            id={`radio-${qIndex}-${oIndex}`}
            type="radio"
            variant="select"
            value={option.value ?? ""}
            checked={isChecked(qIndex, option.value)}
            onChange={() => {
              onClick(qIndex, option);
            }}
          >
            {option.value}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Option;
