import React from "react";
import { Form, InputGroup, type InputGroupProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props extends InputGroupProps {
  accept?: string;
  label?: string;
}

const FileInput: React.FC<Props> = ({ accept, className, id = "fileUpload", label, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <InputGroup className={`file-input ${className ?? ""}`} id={id} {...restProps}>
      <label className="input-group-text" htmlFor={`${id}-input`}>
        {label ?? t("fileInput.label")}
      </label>
      <Form.Control accept={accept} id={`${id}-input`} type="file" />
    </InputGroup>
  );
};
export default FileInput;
