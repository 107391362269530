import type { FC } from "react";
import {
  EnvelopeStackIconAlmostFull,
  EnvelopeStackIconEmpty,
  EnvelopeStackIconFull,
  EnvelopeStackIconHalf,
  EnvelopeStackIconQuarter,
  EnvelopeStackIconThreeQuarters,
} from "./variant";

export type TEnvelopeType = "red" | "orange" | "green";

interface Props {
  type?: TEnvelopeType;
  percentage?: number;
}

const EnvelopeStackIcon: FC<Props> = ({ type, percentage = 0 }) => {
  let backgroundColor, foregroundColor;

  switch (type) {
    case "green":
      backgroundColor = "#27AE60";
      foregroundColor = backgroundColor;
      break;
    case "orange":
      backgroundColor = "#F2994A";
      foregroundColor = backgroundColor;
      break;
    case "red":
      backgroundColor = "#EB5757";
      foregroundColor = backgroundColor;
      break;
    default:
      backgroundColor = "#2296B9";
      foregroundColor = "#17A4C2";
  }

  if (percentage === 0) {
    return <EnvelopeStackIconEmpty foregroundColor={foregroundColor} backgroundColor={backgroundColor} />;
  } else if (percentage <= 0.25) {
    return <EnvelopeStackIconQuarter foregroundColor={foregroundColor} backgroundColor={backgroundColor} />;
  } else if (percentage <= 0.5) {
    return <EnvelopeStackIconHalf foregroundColor={foregroundColor} backgroundColor={backgroundColor} />;
  } else if (percentage <= 0.75) {
    return <EnvelopeStackIconThreeQuarters foregroundColor={foregroundColor} backgroundColor={backgroundColor} />;
  } else if (percentage < 1) {
    return <EnvelopeStackIconAlmostFull foregroundColor={foregroundColor} backgroundColor={backgroundColor} />;
  } else {
    return <EnvelopeStackIconFull foregroundColor={foregroundColor} backgroundColor={backgroundColor} />;
  }
};

export default EnvelopeStackIcon;
