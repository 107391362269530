import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

import {
  BlackEnvelopeBox,
  Loading,
  OutageBox,
  PracticeInMhdBox,
  WelcomeMessage,
  WelcomeNewUserModal,
} from "components";
import { CheckWelcomeBadges, DashboardCalendarOverview, DashboardEnvelopesSummary, NewestBadges } from "features";

import { useAppSelector } from "store/hooks";
import { selectUserWelcomed, getTimetableCreateDate } from "store/slices/user";

import { useGetDashboardDataQuery } from "api/generated";

import { formatDate, getPrevMonday } from "utils/calendarDateFormatting";
import { getNearestSunday } from "utils/validateWeekRange";
import { isMobile } from "utils/isMobile";
import { processApiError } from "utils/processApiError";

const Dashboard = () => {
  const monday = formatDate(getPrevMonday(new Date()));
  const sunday = formatDate(getNearestSunday(new Date()));

  const userWelcomed = useAppSelector(selectUserWelcomed);
  const timetableCreatedDate = useAppSelector(getTimetableCreateDate);

  const timetableDateFrom =
    timetableCreatedDate === null || monday > timetableCreatedDate ? monday : timetableCreatedDate;

  const {
    data,
    isLoading,
    error: dashboardApiError,
    refetch: updateData,
  } = useGetDashboardDataQuery({ timetableFrom: timetableDateFrom, timetableTo: sunday });

  useEffect(() => {
    updateData().catch(console.error);
  }, []);

  useEffect(() => {
    if (dashboardApiError === undefined) return;

    processApiError(dashboardApiError);
  }, [dashboardApiError]);

  return (
    <>
      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col>
                <OutageBox data={data?.displayOutages} />
                <WelcomeMessage />
                <DashboardCalendarOverview data={data} />
                <Row className={"g-24px"}>
                  {isMobile() && (
                    <Col md={6}>
                      <PracticeInMhdBox />
                    </Col>
                  )}

                  <Col md={isMobile() ? 6 : 12}>
                    <BlackEnvelopeBox data={data?.blindSpotTopics} />
                  </Col>
                </Row>
                <DashboardEnvelopesSummary data={data?.envelopesSummary} />
                <NewestBadges data={data?.lastAchievements} />
              </Col>
            </Row>

            <CheckWelcomeBadges data={data?.checkAchievements} />
            {!userWelcomed ? <WelcomeNewUserModal /> : null}
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
