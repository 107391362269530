import * as React from "react";

function IconArrowForward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.25 3.5 9.75 8l-4.5 4.5"
      />
    </svg>
  );
}

export default IconArrowForward;
