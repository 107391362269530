import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  current: number;
  total: number;
  onNavigation: (page: number) => void;
}

const Pagination = ({ className, current, total, onNavigation }: Props) => {
  const { t } = useTranslation(["common"]);

  const handleNew = (page: number) => {
    onNavigation(page);
  };

  const handleNext = () => {
    if (current < total) {
      onNavigation(current + 1);
    }
  };

  /**
   * Prevent unnecesarry renders of 'pagination__ellipsis'
   * - if left out and multiple 'pagination__ellipsis' are rendered it won't break the style and only one is displayed
   */
  let ellipsisRendered = false;

  return (
    <div className={["pagination", className].join(" ").trim()}>
      {Array(total)
        .fill(0)
        .map((_, index) => {
          const isCurrent = current === index + 1;

          if (
            index === 0 || // First item
            index === total - 1 || // Last item
            index + 1 === current - 1 || // Previous item
            index + 1 === current || // Current item
            index + 1 === current + 1 // Next item
          ) {
            ellipsisRendered = false;

            return (
              <Button
                variant="outline-secondary"
                className={isCurrent ? "active" : ""}
                key={index}
                onClick={() => {
                  if (!isCurrent) {
                    handleNew(index + 1);
                  }
                }}
              >
                {index + 1}
              </Button>
            );
          }

          if (ellipsisRendered) {
            return null;
          } else {
            ellipsisRendered = true;

            return (
              <div className={"pagination__ellipsis"} key={index}>
                ...
              </div>
            );
          }
        })}

      {current < total ? (
        <Button variant="outline-secondary" className={"ps-20px pe-16px"} onClick={handleNext}>
          {t("pagination.next")}
        </Button>
      ) : null}
    </div>
  );
};

export default Pagination;
