import { useEffect } from "react";
import type { FC } from "react";

import { ExerciseSelfEvaluation } from "features";

import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { startAssignmentTimer, resetAssignmentTimer } from "store/slices/timer";
import { setTips, getAssignmentResult } from "store/slices/exercise";

import { useSaveExerciseSelfEvaluation } from "utils/hooks/useSaveExerciseSelfEvaluation";

/* rL4n9VXYXv2RntyoGQlj */

interface Props {
  data: UserAssignmentForExerciseFragment;
  userAssignmentId: string;
  evaluateAnswer: boolean;
  onNextClick: () => void;
}

const Rysovani: FC<Props> = ({ data, userAssignmentId, evaluateAnswer, onNextClick }) => {
  const dispatch = useAppDispatch();
  const saveExerciseSelfEvaluationAnswer = useSaveExerciseSelfEvaluation();

  const assignmentResults = useAppSelector(getAssignmentResult<string>(userAssignmentId));

  useEffect(() => {
    dispatch(resetAssignmentTimer());
    dispatch(setTips({ userAssignmentId, tips: [false] }));
    dispatch(startAssignmentTimer());

    return () => {
      dispatch(resetAssignmentTimer());
    };
  }, []);

  const handleNextClick = () => {
    onNextClick();
  };

  return (
    <ExerciseSelfEvaluation
      userAssignment={data}
      onNextClick={handleNextClick}
      results={assignmentResults}
      saveAnswer={saveExerciseSelfEvaluationAnswer}
      userAssignmentId={userAssignmentId}
      evaluateAnswer={evaluateAnswer}
    />
  );
};

export default Rysovani;
