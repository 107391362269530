import type { FC } from "react";
import { Alert, Button } from "react-bootstrap";

import { ReactComponent as PrinterIcon } from "images/icons/printer-blue.svg";

import { generateImagePDF } from "utils/generateImagePDF";

interface Props {
  printAttachmentURL: string;
  pdfName?: string;
}

const PrintAttachmentBox: FC<Props> = ({ printAttachmentURL, pdfName }) => {
  const handleButtonClick = () => {
    generateImagePDF([printAttachmentURL], pdfName).catch(console.error);
  };

  return (
    <Alert variant="info">
      <div>
        {/* TODO: translate */}
        <strong>Řeš na papír</strong>
      </div>
      <Button variant="link" className="p-0" onClick={handleButtonClick}>
        <PrinterIcon /> Stáhni a vytiskni si arch
      </Button>
    </Alert>
  );
};

export default PrintAttachmentBox;
