import { useState, useEffect } from "react";

import { getFirebaseImageURL } from "utils/getFirebaseImageURL";

export const useImageUrlResolver = (imagePath: string | null | undefined) => {
  const [imageUrl, setImagePath] = useState<string | null>(null);

  useEffect(() => {
    if (typeof imagePath === "string" && imagePath.length > 0) {
      getFirebaseImageURL(imagePath)
        .then(url => {
          if (url === undefined) return;
          setImagePath(url);
        })
        .catch(console.error);
    }
  }, []);

  return { imageUrl };
};
