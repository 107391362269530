import { useEffect } from "react";
import type { FC } from "react";
import { Button } from "react-bootstrap";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { getTopicAssignments, setTopicAssignments } from "store/slices/assignmentNavigation";

import { useLazyGetAssignmentsInEnvelopeQuery } from "api/generated";

import { translateEnvelopeId } from "utils/translateEnvelopeId";
import { processApiError } from "utils/processApiError";

import { ReactComponent as IconArrowLeft } from "images/icons/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "images/icons/arrow-right.svg";

interface Props {
  assignmentId: string;
  combinedId: string;
  envelopeId: string;
  onNavigate: (assignmentId: string) => void;
}

const EnvelopeAssignmentsNavigation: FC<Props> = ({ assignmentId, envelopeId, combinedId, onNavigate }) => {
  const assignmentNavigation = useAppSelector(getTopicAssignments(`${combinedId}-${envelopeId}`));
  const dispatch = useAppDispatch();

  const [getAssignmentsList, { error: apiLazyGetListError }] = useLazyGetAssignmentsInEnvelopeQuery();

  useEffect(() => {
    if (apiLazyGetListError === undefined) return;

    processApiError(apiLazyGetListError);
  }, [apiLazyGetListError]);

  useEffect(() => {
    if (assignmentNavigation === undefined) {
      getAssignmentsList({
        combinedId,
        envelopeId: translateEnvelopeId(envelopeId),
      })
        .then(response => {
          const idList = response.data?.userAssignments.items.map(assignment => assignment.id) ?? [];
          dispatch(setTopicAssignments({ topicId: `${combinedId}-${envelopeId}`, assignments: idList }));
        })
        .catch(error => {
          console.error(error);
          dispatch(setTopicAssignments({ topicId: `${combinedId}-${envelopeId}`, assignments: [] }));
        });
    }
  }, [assignmentNavigation]);

  const navigatePrevious = () => {
    if (assignmentNavigation === undefined || assignmentNavigation.length <= 1) return;

    const index = assignmentNavigation.indexOf(assignmentId);

    if (index === -1) return;
    if (index === 0) {
      onNavigate(assignmentNavigation[assignmentNavigation.length - 1]);
      return;
    }

    onNavigate(assignmentNavigation[index - 1]);
  };

  const navigateNext = () => {
    if (assignmentNavigation === undefined || assignmentNavigation.length <= 1) return;

    const index = assignmentNavigation.indexOf(assignmentId);

    if (index === -1) return;
    if (index >= assignmentNavigation.length - 1) {
      onNavigate(assignmentNavigation[0]);
      return;
    }

    onNavigate(assignmentNavigation[index + 1]);
  };

  if (assignmentNavigation === undefined) return null;

  return (
    <div className="assignment-navigation">
      <Button variant={"select"} className={"ms-4px btn-shadow"} onClick={navigatePrevious}>
        <IconArrowLeft />
      </Button>
      <Button variant={"select"} className={"ms-4px btn-shadow"} onClick={navigateNext}>
        <IconArrowRight />
      </Button>
    </div>
  );
};

export default EnvelopeAssignmentsNavigation;
