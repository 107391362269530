// noinspection InvisibleCharacter

import { Alert, ButtonGroup, Col, Form, Row, Table, ToggleButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ExerciseBar } from "../../../../features";

import { ReactComponent as Iconcheck } from "images/icons/check.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Exercise04 = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Nechápu to", value: "1" },
    { name: "Nic moc, ale tuším", value: "2" },
    { name: "Tohle mi jde", value: "3" },
    { name: "Je to má superschopnost", value: "4" },
  ];

  return (
    <div className="exercise">
      <div className="exercise__header">
        <ExerciseBar
          totalTime={600000}
          isTimerStopped={true}
          userAssignmentId={"test"}
          isProgressBarVisible={true}
          onQuit={() => {}}
        />

        <div className="progress-dots">
          <div className="dot current" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>

      <div className="exercise__body">
        <p className="py-20px">
          Ve škole, která má v každém ročníku dvě třídy (A,B), proběhla soutěž ve sběru papíru. V tabulkách jsou uvedeny
          některé údaje z této soutěže.
        </p>

        <Row className={"gx-2"}>
          <Col xs={7}>
            <Table bordered>
              <thead>
                <tr>
                  <th colSpan={3}>První ročník</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.A</td>
                  <td>1.B</td>
                  <td>celkem</td>
                </tr>
                <tr>
                  <td>600 kg</td>
                  <td>600 kg</td>
                  <td>1 200 kg</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={5}>
            <Table bordered>
              <thead>
                <tr>
                  <th colSpan={2}>Druhý ročník</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.A</td>
                  <td>2.B</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </Table>
          </Col>

          <p className="mb-20px">
            <strong>Přiřaďte ke každé úloze (15.1 - 15.3) odpovídající výsledek (A-F).</strong>
          </p>
        </Row>
        <div>
          <div className={"question"}>
            <p>Třída 2.A nasbírala o 25% méně papíru než třída 1.A.</p>

            <Form.Group>
              <Form.Label>Kolik kg papíru nasbírala třída 2.A?</Form.Label>
              <Alert variant={"success"}>
                <Row>
                  <Col xs={"auto"} className={"my-auto"}>
                    450 kg
                    <Iconcheck className={"text-success ms-4px"} />
                  </Col>
                  <Col xs={"auto"} className={"ms-auto my-auto"}>
                    <ToggleButton
                      value={1}
                      variant={"select"}
                      className={"btn-shadow btn-emoji"}
                      id="toggle-check"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        setChecked(e.currentTarget.checked);
                      }}
                    >
                      {t("btn.guessed", { ns: "exercise" })}
                    </ToggleButton>
                    <Button variant={"select"} className={"btn-shadow btn-smaller ms-4px"}>
                      Rozbor
                    </Button>
                  </Col>
                </Row>
              </Alert>
            </Form.Group>
          </div>

          <div className={"question"}>
            <p>Třída 1. B nasbírala o 20% více papíru než třída 2.B.</p>
            <Form.Group>
              <Form.Label>Kolik kg papíru nasbírala třída 2.B?</Form.Label>
              <Alert variant={"success"}>
                <Row>
                  <Col xs={"auto"} className={"my-auto"}>
                    500 kg
                    <Iconcheck className={"text-success ms-4px"} />
                  </Col>
                  <Col xs={"auto"} className={"ms-auto my-auto"}>
                    <ToggleButton
                      value={1}
                      variant={"select"}
                      className={"btn-shadow btn-emoji"}
                      id="toggle-check"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        setChecked(e.currentTarget.checked);
                      }}
                    >
                      {t("btn.guessed", { ns: "exercise" })}
                    </ToggleButton>
                    <Button variant={"select"} className={"btn-shadow btn-smaller ms-4px"}>
                      Rozbor
                    </Button>
                  </Col>
                </Row>
              </Alert>
            </Form.Group>
          </div>

          <div className={"question"}>
            <p>Ze všech žáků prvního ročníku nasbíraly dívky o 50% více papíru než chlapci.</p>
            <Form.Group>
              <Form.Label>Kolik kg papíru nasbírala třída 2.B?</Form.Label>
              <Alert variant={"warning"}>
                <Row>
                  <Col xs={"auto"} className={"my-auto"}>
                    480 kg
                    <Iconcheck className={"text-warning ms-4px"} />
                  </Col>
                  <Col xs={"auto"} className={"ms-auto my-auto"}>
                    <ToggleButton
                      value={1}
                      variant={"select"}
                      className={"btn-shadow btn-emoji"}
                      id="toggle-check"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        setChecked(e.currentTarget.checked);
                      }}
                    >
                      {t("btn.guessed", { ns: "exercise" })}
                    </ToggleButton>
                    <Button variant={"select"} className={"btn-shadow btn-smaller ms-4px"}>
                      Rozbor
                    </Button>
                  </Col>
                </Row>{" "}
              </Alert>
            </Form.Group>
          </div>
        </div>
      </div>

      <div className="exercise__footer exercise__footer--emphasized">
        <div className="mw-sm">
          <p className="text-center">
            <strong>Jak ti tento příklad šel?</strong>
          </p>
          <ButtonGroup className={"btn-group-select btn-shadow mb-12px"}>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="select"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={e => {
                  setRadioValue(e.currentTarget.value);
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <Button className={"w-100 text-uppercase"}>{t("continue")}</Button>
        </div>
      </div>
    </div>
  );
};

export default Exercise04;
