import { useEffect, useState } from "react";
import type { MouseEvent } from "react";
import { Alert, Button, Breadcrumb, Container, Nav, NavItem } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useGetAllTimetableGenericsQuery, useResetOnboardingMutation } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setToken } from "store/slices/token";
import { resetOnboarding, selectOnboardingStatus } from "store/slices/user";

import { saveToTimedLocalStorage } from "utils/localStorage";
import { processApiError } from "utils/processApiError";

import { ACCESS_TOKEN_PARAM } from "const";

import IconSettings from "../../components/Icons/IconSettings/IconSettings";

const Dev = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const [resetOnboardingMutation, { error: apiMutationError }] = useResetOnboardingMutation();
  const { data: timetableData, error: apiError } = useGetAllTimetableGenericsQuery();

  const [resetDone, setReset] = useState(false);
  const onboardingStatus = useAppSelector(selectOnboardingStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const handleOnboardingRedirect = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (onboardingStatus.finished) {
      navigate("/");
    } else if (
      !resetDone &&
      timetableData?.timetableGenericAll !== undefined &&
      timetableData.timetableGenericAll.length > 0
    ) {
      navigate("/onboarding/priklad-uvod");
    } else if (typeof onboardingStatus.phoneNumber === "string" && onboardingStatus.phoneNumber.length > 0) {
      navigate("/onboarding/rozvrh");
    } else if (onboardingStatus.introduced) {
      navigate("/onboarding/preference");
    } else {
      navigate("/onboarding/uvod/1");
    }
  };

  const handleLogout = () => {
    saveToTimedLocalStorage(ACCESS_TOKEN_PARAM);
    dispatch(setToken(null));
  };

  const handleOnboardingReset = () => {
    resetOnboardingMutation()
      .then(() => {
        dispatch(resetOnboarding());
        setReset(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-sm"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/"}>
                <IconArrowBack />
                {t("back")}
              </Link>
            </li>
          </Breadcrumb>
          <h1 className={"mb-0 d-flex align-items-center"}>
            <span className="text-muted me-8px d-inline-flex align-items-center">
              <IconSettings />
            </span>
            Dev
          </h1>
        </Container>
      </div>

      <Container className={"container-mw-sm page-container pt-25px pt-sm-45px"}>
        <h4>Dev templates</h4>
        <Nav className={"nav-menu"}>
          <NavItem>
            <LinkContainer to="/onboarding/uvod/1" onClick={handleOnboardingRedirect}>
              <Nav.Link className={"nav-link--with-arrow"}>Onboarding</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/vysledky">
              <Nav.Link className={"nav-link--with-arrow"}>Onboarding - vysledky</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni">
              <Nav.Link className={"nav-link--with-arrow"}>Cviceni</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/obalky">
              <Nav.Link className={"nav-link--with-arrow"}>Obalky a ulohy</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/dashboard">
              <Nav.Link className={"nav-link--with-arrow"}>Dashboard</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/napoveda">
              <Nav.Link className={"nav-link--with-arrow"}>Nápověda</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/moje-vitezstvi">
              <Nav.Link className={"nav-link--with-arrow"}>Mojě vítězství</Nav.Link>
            </LinkContainer>
          </NavItem>
        </Nav>

        <div className="mt-40px">
          <h4>Dev Links</h4>
          <div className="d-grid gap-1">
            <Button variant={"outline-danger"} className={"text-start"} onClick={handleLogout}>
              Google Auth Logout
            </Button>
            <Button
              variant={"outline-secondary"}
              className={"text-start"}
              onClick={handleOnboardingReset}
              disabled={!!resetDone}
            >
              Reset onboarding
            </Button>
          </div>
        </div>

        <div className="d-grid mt-40px">
          {resetDone ? (
            <Alert className={"mt-4"} variant={"success"}>
              <p>Onboarding reset done.</p>
              {timetableData?.timetableGenericAll !== undefined ? (
                <p>
                  <strong style={{ color: "red" }}>Timetables can be deleted only manually in database!</strong>
                  <br />
                  This time you will start onboarding from the start, but next time when you try to start the onboarding
                  once again, you will be redirected on the next screen after timetable setup.
                </p>
              ) : null}
            </Alert>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default Dev;
