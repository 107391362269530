import { useState } from "react";
import type { FC } from "react";
import Button, { type ButtonProps } from "react-bootstrap/Button";

import { BookmarkedModal } from "components/Modal/Bookmarked";

import { ReactComponent as IconBookmark } from "images/icons/bookmark.svg";

import { useBookmarks } from "utils/hooks/useBookmarks";
import type { NoStringIndexType } from "utils/types/NoStringIndexType";

interface Props extends Omit<NoStringIndexType<ButtonProps>, "onClick"> {
  userAssignmentId: string;
}

const BookmarkIcon: FC<Props> = ({ className, userAssignmentId, ...restProps }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { toggleBookmark, isLoadingData, isBookmarked } = useBookmarks(userAssignmentId);

  const handleButtonClick = () => {
    if (isLoadingData) return;

    toggleBookmark();
    if (!isBookmarked) {
      setModalVisible(true);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Button
        size={"sm"}
        variant={"navigation"}
        onClick={handleButtonClick}
        id="bookmark"
        className={`p-0 ${className ?? ""}`}
        {...restProps}
      >
        <IconBookmark fill={isBookmarked ? "black" : "none"} />
      </Button>
      {modalVisible ? <BookmarkedModal isLoading={isLoadingData} onClose={handleModalClose} /> : null}
    </>
  );
};

export default BookmarkIcon;
