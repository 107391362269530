import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";

import AnalysisLayout from "./_AnalysisLayout";
import SelfEvaluation from "./_SelfEvaluation";

import { AnswerPractice } from "components";

import { useLazyGetAssignmentAnswersQuery } from "api/generated";
import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { selectSimpleAnswer } from "store/slices/answer";

import { getSolutionVariant } from "utils/getSolutionVariant";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { processApiError } from "utils/processApiError";

/* jchWUrJ67yoyztmi6ad7 */

interface Props {
  data: UserAssignmentForExerciseFragment;
}

const ViceMoznostiNahore: FC<Props> = ({ data }) => {
  const assignment = data.assignment;
  if (assignment === null || assignment === undefined) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const selected = useAppSelector(selectSimpleAnswer);

  const [trigger, { data: answerData, isLoading, error: apiLazyError }] = useLazyGetAssignmentAnswersQuery();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);
  const [answers, setAnswers] = useState<Array<Array<string | null>> | undefined>(undefined);

  useEffect(() => {
    trigger({ assignmentId: assignment.id }).catch(error => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  useEffect(() => {
    const responseAnswers = answerData?.assignments.map(
      answerAssignment =>
        answerAssignment.assignment?.questions.map(question => {
          if (assignment.assignment?.isAnswerCaseSensitive === true) return question.correctAnswers;
          return question.correctAnswers
            .filter((answer): answer is string => answer !== null)
            .map(answer => answer.toLocaleLowerCase());
        }),
    );

    if (responseAnswers === undefined || responseAnswers[0] === undefined || responseAnswers[0].length === 0) {
      return;
    }

    setAnswers(responseAnswers[0]);
  }, [answerData]);

  if (isSelfEvaluation) {
    return <SelfEvaluation userAssignment={data} />;
  }

  return (
    <AnalysisLayout>
      <>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        {assignment.assignment?.questions.map((question, index) => {
          const type = getSolutionVariant(
            selected[index],
            answers,
            index,
            assignment.assignment?.isAnswerCaseSensitive ?? false,
          );

          return (
            <div key={index} className={"question mt-20px"}>
              <strong>
                <Latex>{question.heading ?? ""}</Latex>
              </strong>

              {isLoading ? null : (
                <AnswerPractice assignment={assignment} type={type} index={index} userAssignmentId={data.id}>
                  {selected[index]}
                </AnswerPractice>
              )}
            </div>
          );
        })}

        <div className="question mt-20px">
          <p
            dangerouslySetInnerHTML={{
              __html: assignment.assignment?.questionsText ?? "",
            }}
          ></p>
        </div>
      </>
    </AnalysisLayout>
  );
};

export default ViceMoznostiNahore;
