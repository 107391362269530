import { Button, type ButtonProps } from "react-bootstrap";
import type { NoStringIndexType } from "utils/types/NoStringIndexType";

import { ReactComponent as SoundOnIcon } from "images/icons/20-sound-on.svg";
import { ReactComponent as SoundOffIcon } from "images/icons/20-sound-off.svg";
import { useClassroomAmbienceContext } from "./context";

type Props = Omit<NoStringIndexType<ButtonProps>, "onClick">;

const ClassroomAmbienceButton: React.FC<Props> = ({ className, size = "sm", variant = "navigation", ...restProps }) => {
  const { isSoundOn, onToggleSound } = useClassroomAmbienceContext();

  return (
    <Button size={size} variant={variant} onClick={onToggleSound} className={`p-0 ${className ?? ""}`} {...restProps}>
      {isSoundOn ? <SoundOffIcon /> : <SoundOnIcon />}
    </Button>
  );
};

export default ClassroomAmbienceButton;
