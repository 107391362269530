import type { FC } from "react";
import { Badge, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import { ResultBoxPractice } from "components";

import type { IAssignmentResult } from "store/slices/topicPractice";
import { useTranslation } from "react-i18next";

interface IResultObj {
  result: IAssignmentResult;
  index: number;
}

interface Props {
  results: Record<string, IAssignmentResult>;
}

const Results: FC<Props> = ({ results }) => {
  const correctAssignments: IResultObj[] = [];
  const incorrectAssignments: IResultObj[] = [];

  const { t } = useTranslation(["exercise"]);

  Object.values(results).forEach((result, index) => {
    const obj = {
      result,
      index,
    };

    if (result.status === "not_taken") return;

    result.status === "correct" ? correctAssignments.push(obj) : incorrectAssignments.push(obj);
  });

  return (
    <>
      <div className={"page-header page-header--has-tabs page-header--no-tabs-desktop mb-sm-8px"}>
        <Container className={"container-mw-md"}>
          <h1>{t("header.checkYourResults")}</h1>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Tab.Container defaultActiveKey={incorrectAssignments.length > 0 ? "first" : "second"}>
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="first">
                {t("tab.wrongAnswers")}{" "}
                <Badge bg={incorrectAssignments.length > 0 ? "danger" : ""} className={"badge-circular"}>
                  {incorrectAssignments.length}
                </Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                {t("tab.correctAnswers")}{" "}
                <Badge bg={correctAssignments.length === 0 ? "" : "success"} className={"badge-circular"}>
                  {correctAssignments.length}
                </Badge>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first" className={incorrectAssignments.length === 0 ? "pt-20px pb-40px" : ""}>
              {incorrectAssignments.length === 0 ? (
                <p className="text-center mx-5">{t("evaluation.allCorrect", { ns: "exercise" })}</p>
              ) : (
                <Nav className={"nav-menu nav-menu--results"}>
                  {incorrectAssignments.map(obj => (
                    <ResultBoxPractice
                      key={obj.result.assignment.id}
                      result={obj.result}
                      index={obj.index}
                      type={obj.result.status}
                    />
                  ))}
                </Nav>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="second" className={correctAssignments.length === 0 ? "pt-20px pb-40px" : ""}>
              {correctAssignments.length === 0 ? (
                <p className="text-center mx-5">{t("evaluation.allWrong", { ns: "exercise" })}</p>
              ) : (
                <Nav className={"nav-menu nav-menu--results"}>
                  {correctAssignments.map(obj => (
                    <ResultBoxPractice
                      key={obj.result.assignment.id}
                      result={obj.result}
                      index={obj.index}
                      type={obj.result.status}
                    />
                  ))}
                </Nav>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <hr className={"thin yellow my-sm-4"} />

        <Row className={"pb-20px"}>
          <Col className="text-center pb-32px">
            <LinkContainer to={"/"}>
              <Button className="btn-mobile-width">{t("btn.closeResults")}</Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Results;
