import Button from "react-bootstrap/Button";
import Modal, { type ModalProps } from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import type { NoStringIndexType } from "utils/types/NoStringIndexType";
import TeacherEvaluationUploadForm from "./TeacherEvaluationUploadForm";
import { useState } from "react";
import { useToken } from "./useToken";

interface Props extends NoStringIndexType<ModalProps> {
  onSubmit: () => void;
  userAssignmentId: string;
}

const TeacherEvaluationUploadModal: React.FC<Props> = ({
  className,
  onHide,
  onSubmit,
  show,
  size = "lg",
  userAssignmentId,
  ...restProps
}) => {
  const { t } = useTranslation("teacherEvaluation");
  const token = useToken();
  const [isUploaded, setIsUploaded] = useState(false);

  const handleSuccess = () => {
    setIsUploaded(true);
  };

  if (token === null) return null;

  return (
    <Modal show={show} size={size} className={`modal--exercise ${className ?? ""}`} onHide={onHide} {...restProps}>
      <Modal.Header closeButton />
      <Modal.Body className="pb-60px pt-20px px-80px">
        <div className={"qr-block mb-20px"}>
          <TeacherEvaluationUploadForm onSuccess={handleSuccess} token={token} userAssignmentId={userAssignmentId} />
        </div>

        <div className="d-grid gap-8px">
          <Button variant="primary" className={"w-100 text-uppercase"} disabled={!isUploaded} onClick={onSubmit}>
            {t("buttonPhotoConfirm")}
          </Button>
          <Button variant="outline-secondary" className={"w-100 text-uppercase"} onClick={onHide}>
            {t("close")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TeacherEvaluationUploadModal;
