import { formatDate, getPrevMonday } from "./calendarDateFormatting";

const stringToDate = (dateStr: string | null) => {
  if (dateStr === null) return null;

  const dateTime = Date.parse(dateStr);
  if (isNaN(dateTime)) return null;

  return new Date(dateTime);
};

const isMonday = (date: Date) => {
  return date.getDay() === 1;
};

export const getNextSunday = (date: Date) => {
  const nextSundayOfGivenDate = new Date(date.getTime());
  nextSundayOfGivenDate.setDate(nextSundayOfGivenDate.getDate() + 7 - (nextSundayOfGivenDate.getDay() % 7));

  return nextSundayOfGivenDate;
};

export const getNearestSunday = (date: Date) => {
  if (date.getDay() === 0) return date;

  const nextSundayOfGivenDate = new Date(date.getTime());
  nextSundayOfGivenDate.setDate(nextSundayOfGivenDate.getDate() + 7 - (nextSundayOfGivenDate.getDay() % 7));

  return nextSundayOfGivenDate;
};

export const validateMonday = (dateString: string | null) => {
  const from = stringToDate(dateString);

  if (from !== null && isMonday(from) && !isDateInPastWeek(from)) {
    return {
      from,
    };
  }

  return {
    from: null,
  };
};

export const isDateInPastWeek = (date: Date) => {
  const formattedClickedDate = formatDate(date);
  const formattedPrevMonday = formatDate(getPrevMonday(new Date()));

  return formattedClickedDate < formattedPrevMonday;
};
