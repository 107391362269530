import type { FC } from "react";

import { ReactComponent as StopwatchBg } from "images/icons/stopwatch.svg";
import { useTranslation } from "react-i18next";

interface Props {
  minutes: number;
  className?: string;
}

const Stopwatch: FC<Props> = ({ minutes, className }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className={`stopwatch ${className ?? ""}`}>
      <div className="stopwatch__time">
        <time>{minutes}</time>
        <span>{t("minuteShort")}</span>
      </div>

      <StopwatchBg />
    </div>
  );
};

export default Stopwatch;
