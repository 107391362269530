import type { CheckAnswersMutation, UserAssignmentForExerciseFragment } from "api/generated";
import { gtmTaskEvent } from "./gtmEvents";

export const processAnswerResponse = (
  response:
    | {
        data: CheckAnswersMutation;
      }
    | {
        error: unknown;
      },
  assignment: UserAssignmentForExerciseFragment,
) => {
  if ("data" in response) {
    gtmTaskEvent("task-result", {
      id: assignment?.id,
      subject: assignment.subject?.appName,
      score: `${response.data.answer.scoreReal}/${
        assignment.scoreMax !== undefined && assignment.scoreMax !== null
          ? assignment.scoreMax
          : response.data.answer.scoreReal
      }`,
    });

    return;
  }

  console.error(response.error);
};
