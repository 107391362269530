import { useEffect } from "react";
import type { FC } from "react";
import { Button } from "react-bootstrap";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { getTopicAssignments, setTopicAssignments } from "store/slices/assignmentNavigation";

import { useLazyGetAssignmentsInTopicQuery } from "api/generated";

import { processApiError } from "utils/processApiError";

import { ReactComponent as IconArrowLeft } from "images/icons/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "images/icons/arrow-right.svg";

interface Props {
  assignmentId: string;
  combinedId: string;
  onNavigate: (assignmentId: string) => void;
}

const TopicAssignmentsNavigation: FC<Props> = ({ assignmentId, combinedId, onNavigate }) => {
  const assignmentNavigation = useAppSelector(getTopicAssignments(combinedId));
  const dispatch = useAppDispatch();

  const [getAssignmentsData, { error: apiLazyError }] = useLazyGetAssignmentsInTopicQuery();

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
    dispatch(setTopicAssignments({ topicId: `${combinedId}`, assignments: [] }));
  }, [apiLazyError]);

  useEffect(() => {
    if (assignmentNavigation === undefined) {
      getAssignmentsData({ combinedId })
        .then(response => {
          const assignments = response.data?.userAssignments.items.map(assignment => assignment.id) ?? [];
          dispatch(setTopicAssignments({ topicId: `${combinedId}`, assignments }));
        })
        .catch(error => {
          console.error(error);
          dispatch(setTopicAssignments({ topicId: `${combinedId}`, assignments: [] }));
        });
    }
  }, [assignmentNavigation]);

  const navigatePrevious = () => {
    if (assignmentNavigation === undefined || assignmentNavigation.length <= 1) return;

    const index = assignmentNavigation.indexOf(assignmentId);

    if (index === -1) return;
    if (index === 0) {
      onNavigate(assignmentNavigation[assignmentNavigation.length - 1]);
      return;
    }

    onNavigate(assignmentNavigation[index - 1]);
  };

  const navigateNext = () => {
    if (assignmentNavigation === undefined || assignmentNavigation.length <= 1) return;

    const index = assignmentNavigation.indexOf(assignmentId);

    if (index === -1) return;
    if (index >= assignmentNavigation.length - 1) {
      onNavigate(assignmentNavigation[0]);
      return;
    }

    onNavigate(assignmentNavigation[index + 1]);
  };

  return (
    <div className="assignment-navigation">
      <Button variant={"select"} className={"ms-4px btn-shadow"} onClick={navigatePrevious}>
        <IconArrowLeft />
      </Button>
      <Button variant={"select"} className={"ms-4px btn-shadow"} onClick={navigateNext}>
        <IconArrowRight />
      </Button>
    </div>
  );
};

export default TopicAssignmentsNavigation;
