import { useTranslation } from "react-i18next";
import { Breadcrumb, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconArrowBack from "../../../../components/Icons/IconArrowBack/IconArrowBack";
import { ReactComponent as IconBookmark } from "images/icons/bookmark.svg";
import Button from "react-bootstrap/Button";

const Envelope01 = () => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/"}>
                <IconArrowBack />
                {t("goHome")}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-25px"}>{t("pageTitle.tasksForExercise", { ns: "envelope" })}</h1>

          <Row className={"row--border-inside row--border-inside-sm-col mb-25px gx-4"}>
            <Col xs={12} sm={6} className={"col"}>
              <div className="page-header__info-block">
                <Image src={"/images/envelope-waiting.svg"} />
                <p>
                  20 testových sad čeká, až je absolvuješ v rámci testů nanečisto. Poté se Ti zpřístupní i zde v
                  aplikaci. <a href={"#"}>Jak to funguje?</a>
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} className={"col"}>
              <div className="page-header__info-block">
                <Image src={"/images/envelope-buy.svg"} />
                <p>
                  10 dalších testů si <a href={"#"}>můžeš ještě dokoupit</a>
                </p>
              </div>
            </Col>
          </Row>

          <Button variant="tertiary" className="btn-shadow btn-arrow">
            <IconBookmark className={"icon me-2"} />
            Uložené úlohy (38)
          </Button>

          <hr className={"my-25px"} />

          <Form>
            <Form.Control className={"form-search"} placeholder={"Hledej v textu úlohy..."} />
          </Form>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}></Container>
    </>
  );
};

export default Envelope01;
