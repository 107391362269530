import { createContext, type MouseEventHandler, useContext } from "react";

interface ClassroomAmbienceContextProps {
  isSoundOn: boolean;
  onToggleSound: MouseEventHandler<HTMLButtonElement>;
}

export const ClassroomAmbienceContext = createContext<ClassroomAmbienceContextProps | null>(null);

export const useClassroomAmbienceContext = (): ClassroomAmbienceContextProps => {
  const context = useContext(ClassroomAmbienceContext);
  if (context === null) {
    throw new Error("useClassroomAmbienceContext must be used within a ClassroomAmbienceProvider");
  }
  return context;
};
