import * as React from "react";

const IconBook = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.34863 15.218V4.65062C1.7797 4.34529 3.06929 3.50496 4.77918 3.39001C6.48906 3.27506 8.32947 4.14173 9.03593 4.48898C9.44903 4.18963 10.771 3.39001 12.7539 3.39001C14.7367 3.39001 16.1784 4.29739 16.6514 4.65062V15.218C16.6514 15.9544 16.2922 15.7928 16.0587 15.5772C15.8252 15.3617 14.7475 14.69 12.6102 14.69C10.9003 14.69 9.56279 15.4934 8.94613 15.7928C8.16183 15.4934 6.62557 14.69 5.17432 14.69C3.36026 14.69 2.06707 15.3976 1.76173 15.5772C1.4564 15.7568 1.34863 15.685 1.34863 15.218Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M9.00003 6.43601V13.3918" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default IconBook;
