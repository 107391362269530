import type { FC } from "react";
import { Form } from "react-bootstrap";

import type { IOption } from "store/slices/topicPractice";

import { ABCDOptionText } from "utils/formatTextForAnalysis";

interface Props {
  qIndex: number;
  options: IOption[];
  value: IOption | null | undefined;
  onClick: (qIndex: number, option: IOption) => void;
}

const ABCDOption: FC<Props> = ({ qIndex, options, value, onClick }) => {
  return (
    <Form className="mt-20px question question--select" {...(qIndex === 0 ? { id: "question" } : {})}>
      {options.map((option, oIndex) => {
        const key = `question-${qIndex}-${oIndex}`;

        return (
          <Form.Check type="radio" key={key}>
            <Form.Check.Input
              type="radio"
              name={"radioGroup"}
              onClick={() => {
                onClick(qIndex, option);
              }}
              checked={value !== undefined && value !== null && option.value === value.value}
              id={key}
            />
            <Form.Check.Label htmlFor={key}>{ABCDOptionText(option)}</Form.Check.Label>
          </Form.Check>
        );
      })}
    </Form>
  );
};

export default ABCDOption;
