function Strawberry() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5443_3175)">
        <path
          d="M2.14608 7.45676C2.6525 5.05158 4.61867 4.12535 5.59359 3.66855C5.94449 3.35323 7.30315 2.94905 9.93057 3.85497C13.2148 4.98737 14.4203 6.42129 14.8422 7.97707C15.2642 9.53285 14.4763 11.9696 13.4174 13.4462C12.1533 15.2091 11.3361 15.8301 9.59368 16.465C7.85123 17.1 5.65559 16.9581 4.23508 15.1368C3.45961 14.1426 1.43425 10.8376 2.14608 7.45676Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M7.29697 2.12213C7.78665 2.05285 9.34285 3.02667 9.59003 3.36008C9.78777 3.62681 10.1481 3.74706 10.3036 3.77384C10.7937 3.61937 11.9279 3.33833 12.544 3.44999C13.3143 3.58956 13.091 4.10867 12.7997 4.44265C12.5665 4.70984 12.1043 4.7352 11.9024 4.71448C12.2469 4.74741 13.1005 4.93978 13.7591 5.44582C14.5823 6.07838 14.1965 6.57628 13.0683 6.14034C12.1656 5.7916 11.1218 5.4926 10.7127 5.3867C10.3659 5.73916 9.59615 6.43926 9.29171 6.42004C8.91116 6.39602 8.10356 5.07729 8.01338 4.7859C7.92321 4.4945 7.19985 4.55678 5.72065 4.04908C4.24144 3.54138 5.24126 3.26369 5.92664 3.28031C6.61201 3.29694 7.19106 3.39642 7.17915 3.31528C7.16724 3.23415 7.00838 2.44573 7.29697 2.12213Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M9.38616 4.93706C9.54243 3.85152 10.2318 1.54254 11.7391 0.990884"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path d="M11.5466 9.47412L10.9635 11.3251" stroke="currentColor" strokeWidth="2" />
        <path d="M7.82312 11.5645L7.25566 13.4561" stroke="currentColor" strokeWidth="2" />
        <path d="M6.12555 7.62793L5.5382 9.7947" stroke="currentColor" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_5443_3175">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Strawberry;
