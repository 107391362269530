import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import Layout from "./_Layout";

import { ViceMoznostiDoleOption } from "components";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectSimpleAnswer, setSimpleAnswer } from "store/slices/answer";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* Vg88XS8doR7IqSBg5PuQ */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const ViceMoznostiDole: FC<Props> = ({ data, onSubmit }) => {
  const questionsCount = data.assignment?.assignment?.questions.length;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const selected = useAppSelector(selectSimpleAnswer);
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const handleChange = (index: number, value: string) => {
    const newAnswers = selected.length === 0 ? new Array(questionsCount).fill("") : [...selected];
    newAnswers[index] = value;

    dispatch(setSimpleAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return selected.length > 0 && selected.every(answer => answer.length > 0);
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()}>
      <>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        <div className={"mt-20px"}>
          {data.assignment?.assignment?.questions.map((question, index) => {
            return (
              <div key={index} className="question">
                <Latex>{question.heading ?? ""}</Latex>

                <ViceMoznostiDoleOption
                  index={index}
                  placeholder={question.placeholder ?? ""}
                  options={question.options}
                  isLast={index + 1 === data.assignment?.assignment?.questions.length}
                  onChange={handleChange}
                />
              </div>
            );
          })}
        </div>
      </>
    </Layout>
  );
};

export default ViceMoznostiDole;
