import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";

import { UserLoginDocument } from "api/generated";
import type { UserLoginMutation, UserLoginMutationVariables } from "api/generated";

import type { RootState } from "store/store";

const PARENT_ENDPOINTS = ["getParentRewards", "updateParentRewards"];
const TEACHER_EVALUATION_ENDPOINTS = ["solutionUploadUrl", "solutionUploadConfirm"];

const getToken = (state: RootState) => {
  const token = state.token.token;

  if (token === null) {
    window.location.reload();
    throw new Error("Not authenticated");
  }

  return token;
};

const getParentToken = (state: RootState) => {
  const parentToken = state.token.parentToken;

  if (parentToken === null) {
    window.location.replace("/");
    throw new Error("Parent token not set!");
  }

  return parentToken;
};

const getTeacherEvaluationToken = (state: RootState) => {
  const teacherEvaluationToken = state.token.teacherEvaluationToken ?? state.token.token;

  if (teacherEvaluationToken === null) {
    window.location.replace("/");
    throw new Error("Teacher evaluation token not set!");
  }

  return teacherEvaluationToken;
};

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT === undefined ? "" : process.env.REACT_APP_GRAPHQL_ENDPOINT,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const state = getState() as RootState;

      switch (true) {
        case endpoint === "login":
          break;
        case PARENT_ENDPOINTS.includes(endpoint):
          headers.set("Parent-Token", getParentToken(state));
          break;
        case TEACHER_EVALUATION_ENDPOINTS.includes(endpoint):
          headers.set("Auth-Token", getTeacherEvaluationToken(state));
          break;
        default:
          headers.set("Auth-Token", getToken(state));
      }

      return headers;
    },
  }),
  endpoints: build => ({
    login: build.mutation<UserLoginMutation, UserLoginMutationVariables>({
      query: variables => ({
        document: UserLoginDocument,
        variables,
      }),
    }),
  }),
});

export const { useLoginMutation } = api;
