import { getStorage, getDownloadURL, ref } from "firebase/storage";

export const getFirebaseImageURL = async (imageId: string) => {
  const storage = getStorage();
  const imageRef = ref(storage, imageId);

  try {
    const response = await getDownloadURL(imageRef);
    return response;
  } catch (error) {
    console.error(error);
  }
};
