import type { FC, ReactNode } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { ReactComponent as PrinterIcon } from "images/icons/printer-blue.svg";

interface Props {
  children: ReactNode;
  onSubmit?: () => void;
  isSubmitDisabled?: boolean;
  isSelfEvaluation?: boolean;
  printImage?: string;
}

const DetailLayout: FC<Props> = ({ children, onSubmit, isSubmitDisabled, isSelfEvaluation, printImage }) => {
  const { t } = useTranslation(["assignments", "common", "exercise"]);

  const handleSubmit = () => {
    if (onSubmit !== undefined) {
      onSubmit();
    }
  };

  return (
    <div className="exercise">
      <div className="exercise__body">{children}</div>
      {onSubmit === undefined ? null : (
        <div className="exercise__footer">
          <div className="mw-sm px-3">
            {typeof printImage === "string" && printImage.length > 0 ? (
              <Alert variant="info">
                <div>
                  <strong>{t("info.paperwork", { ns: "exercise" })}</strong>
                </div>
                <a href={printImage} download>
                  <PrinterIcon /> {t("btn.printPaper", { ns: "exercise" })}
                </a>
              </Alert>
            ) : null}

            <Button className={"w-100 text-uppercase"} onClick={handleSubmit} disabled={isSubmitDisabled}>
              {isSelfEvaluation === true
                ? t("detail.submitSelfEvaluation", { ns: "assignments" })
                : t("detail.submit", { ns: "assignments" })}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailLayout;
