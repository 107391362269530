import type { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  onSubmit?: () => void;
  isSubmitDisabled?: boolean;
}

const SolutionLayout: FC<Props> = ({ children }) => {
  return (
    <div className="exercise">
      <div className="exercise__body">{children}</div>
    </div>
  );
};

export default SolutionLayout;
