import { useEffect, useMemo, useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Loading } from "components";
import { useUpdatePhoneNumberMutation, useSendPhoneValidationCodeMutation } from "api/generated";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getPhoneValidationStatus,
  selectTempPhoneNumber,
  setPhoneValidationDateTimeLast,
  validatePhoneNumber,
} from "store/slices/user";
import { processApiError } from "utils/processApiError";
import { ValidationCodeInput } from "components/ValidationCodeInput";
import useGetTimeToResendCode from "./useGetTimeToResendCode";

interface Props {
  redirectBackUrl: string;
  redirectNextUrl: string;
}

const PhoneValidator: FC<Props> = ({ redirectBackUrl, redirectNextUrl }) => {
  const { t } = useTranslation(["common", "onboarding"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tempPhone = useAppSelector(selectTempPhoneNumber);
  const initialValidationStatus = useAppSelector(getPhoneValidationStatus);

  const [resendCode, { isLoading: isResending, error: apiResendMutationError }] = useUpdatePhoneNumberMutation();
  const [
    sendCode,
    { isLoading: isSending, error: apiSendMutationError, status: validationMutationStatus, data: codeSendResponse },
  ] = useSendPhoneValidationCodeMutation();

  const { remainingTime, isResendDisabled } = useGetTimeToResendCode();
  const [isResendModalShown, setResetModalVisibility] = useState(false);
  const [validationCode, setValidationCode] = useState<string | null>(null);
  const [isConfirmDisabled, setDisabledConfirm] = useState(true);

  const isLoadingMutation = useRef(false);

  useEffect(() => {
    if (apiResendMutationError === undefined) return;

    processApiError(apiResendMutationError);
  }, [apiResendMutationError]);

  useEffect(() => {
    if (apiSendMutationError === undefined) return;

    processApiError(apiSendMutationError);
  }, [apiSendMutationError]);

  useEffect(() => {
    if (initialValidationStatus === "accepted" && validationMutationStatus === QueryStatus.uninitialized) {
      navigate(redirectBackUrl);
    }
  }, [initialValidationStatus]);

  const handleResendPhone = () => {
    if (isLoadingMutation.current || typeof tempPhone !== "string") return;

    setResetModalVisibility(true);
    resendCode({ phoneNumber: tempPhone, smsEnabled: true })
      .then(response => {
        if ("data" in response) {
          const datetimeLast = response.data.updatePhone.datetimeLast;
          if (typeof datetimeLast === "string") {
            dispatch(setPhoneValidationDateTimeLast(datetimeLast));
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const sendValidationCode = () => {
    if (isConfirmDisabled || isLoadingMutation.current || validationCode === null) return;

    isLoadingMutation.current = true;
    setDisabledConfirm(true);

    sendCode({ code: validationCode })
      .then(response => {
        if ("data" in response) {
          const validationStatus = response.data.validatePhone.status;
          dispatch(validatePhoneNumber(validationStatus));

          if (validationStatus === "accepted") {
            navigate(redirectNextUrl);
          }
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        isLoadingMutation.current = false;
        setDisabledConfirm(false);
      });
  };

  const isResendCodeEnabled = useMemo(() => remainingTime <= 0 || !isResendDisabled, [remainingTime, isResendDisabled]);

  return (
    <>
      <div className="page__content justify-content-center">
        <Alert variant="danger" show={codeSendResponse?.validatePhone.status === "refused"} className="text-start">
          {t("invalidCode")}
        </Alert>

        <h1 className={"h2 mb-3"}>{t("phoneValidation.heading", { ns: "onboarding" })}</h1>
        <p>
          <span className="fw-bold">
            {t("on")} {tempPhone}
          </span>{" "}
          <Link to={redirectBackUrl}>{t("changeNumber")}</Link>
        </p>

        <Form>
          <ValidationCodeInput
            onCodeComplete={code => {
              if (code !== null) {
                setValidationCode(code);
                setDisabledConfirm(false);
              } else {
                setDisabledConfirm(true);
              }
            }}
            disabled={isSending || isResending}
            isNumericOnly
          />
        </Form>

        {isResendCodeEnabled ? (
          <Button variant={"link"} onClick={handleResendPhone} className="p-0 mt-3 mx-auto">
            {t("resendCode")}
          </Button>
        ) : (
          <div className="mt-3">
            {t("codeDoesNotCome")} {remainingTime}
          </div>
        )}
      </div>

      <div className={"page__footer"}>
        <Button
          className={"w-100 text-uppercase mt-auto"}
          onClick={sendValidationCode}
          disabled={isConfirmDisabled || isSending || isResending || validationCode === null}
        >
          {t("continue")}
        </Button>
      </div>

      <Modal
        show={isResendModalShown}
        onHide={() => {
          setResetModalVisibility(false);
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>{isResending ? <Loading /> : <p className={"text-center"}>Kód byl znovu odeslán</p>}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={"w-100 text-uppercase"}
            onClick={() => {
              setResetModalVisibility(false);
            }}
          >
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhoneValidator;
