import type { FC, ReactNode } from "react";
import { Alert } from "react-bootstrap";

interface Props {
  children: ReactNode;
  type: "success" | "warning" | "danger";
}

const AnswerSimple: FC<Props> = ({ children, type }) => {
  return (
    <Alert variant={type} className="answer-simple">
      <div className={"answer-simple"}>{children}</div>
    </Alert>
  );
};

export default AnswerSimple;
