import type { UserAssignmentForExerciseFragment, UserAssignmentDetailFragment } from "api/generated";

import type { IAssignmentResult as IAssignmentResultPractice } from "store/slices/topicPractice";
import type { IAssignmentResult as IAssignmentResultExercise, IOption } from "store/slices/exercise";

import {
  ABCDDetail,
  ABCDOddeleneDetail,
  AnoNeDetail,
  JednoslovneOdpovediDetail,
  ViceMoznostiDoleDetail,
  ViceMoznostiNahoreDetail,
  RovniceDetail,
  RysovaniDetail,
  RazeniDetail,
  ViceJednoslovnychOdpovediDetail,
  ViceslovneOdpovediDetail,
  ABCDExercise,
  ABCDOddeleneExercise,
  AnoNeExercise,
  JednoslovneOdpovediExercise,
  ViceMoznostiDoleExercise,
  ViceMoznostiNahoreExercise,
  RovniceExercise,
  RysovaniExercise,
  RazeniExercise,
  ViceJednoslovnychOdpovediExercise,
  ViceslovneOdpovediExercise,
  ABCDPractice,
  ABCDOddelenePractice,
  AnoNePractice,
  JednoslovneOdpovediPractice,
  ViceMoznostiDolePractice,
  ViceMoznostiNahorePractice,
  RovnicePractice,
  RysovaniPractice,
  RazeniPractice,
  ViceJednoslovnychOdpovediPractice,
  ViceslovneOdpovediPractice,
  ABCDModalExercise,
  ABCDOddeleneModalExercise,
  AnoNeModalExercise,
  JednoslovneOdpovediModalExercise,
  ViceMoznostiDoleModalExercise,
  ViceMoznostiNahoreModalExercise,
  RovniceModalExercise,
  RysovaniModalExercise,
  RazeniModalExercise,
  ViceJednoslovnychOdpovediModalExercise,
  ViceslovneOdpovediModalExercise,
  ABCDModalPractice,
  ABCDOddeleneModalPractice,
  AnoNeModalPractice,
  JednoslovneOdpovediModalPractice,
  ViceMoznostiDoleModalPractice,
  ViceMoznostiNahoreModalPractice,
  RovniceModalPractice,
  RysovaniModalPractice,
  RazeniModalPractice,
  ViceJednoslovnychOdpovediModalPractice,
  ViceslovneOdpovediModalPractice,
} from "features";

export const getAssignmentDetailByType = (
  type: string | null | undefined,
  assignment: UserAssignmentDetailFragment,
  onSubmit: () => void,
) => {
  switch (type) {
    case "abcd":
      return <ABCDDetail data={assignment} onSubmit={onSubmit} />;
    case "abcd_separated":
      return <ABCDOddeleneDetail data={assignment} onSubmit={onSubmit} />;
    case "yes_no":
      return <AnoNeDetail data={assignment} onSubmit={onSubmit} />;
    case "drawing":
      return <RysovaniDetail data={assignment} onSubmit={onSubmit} />;
    case "sorting":
      return <RazeniDetail data={assignment} onSubmit={onSubmit} />;
    case "multiple_words":
      return <ViceslovneOdpovediDetail data={assignment} onSubmit={onSubmit} />;
    case "individual_word":
      return <JednoslovneOdpovediDetail data={assignment} onSubmit={onSubmit} />;
    case "multiple_options_bottom":
      return <ViceMoznostiDoleDetail data={assignment} onSubmit={onSubmit} />;
    case "multiple_options_top":
      return <ViceMoznostiNahoreDetail data={assignment} onSubmit={onSubmit} />;
    case "equation":
      return <RovniceDetail data={assignment} onSubmit={onSubmit} />;
    case "multiple_individual_words":
      return <ViceJednoslovnychOdpovediDetail data={assignment} onSubmit={onSubmit} />;
    default:
      return null;
  }
};

export const getAssignmentPracticeByType = (
  type: string | null | undefined,
  assignment: UserAssignmentDetailFragment,
  onNextClick: () => void,
) => {
  switch (type) {
    case "abcd":
      return <ABCDPractice data={assignment} onNextClick={onNextClick} />;
    case "abcd_separated":
      return <ABCDOddelenePractice data={assignment} onNextClick={onNextClick} />;
    case "yes_no":
      return <AnoNePractice data={assignment} onNextClick={onNextClick} />;
    case "drawing":
      return <RysovaniPractice data={assignment} onNextClick={onNextClick} />;
    case "sorting":
      return <RazeniPractice data={assignment} onNextClick={onNextClick} />;
    case "multiple_words":
      return <ViceslovneOdpovediPractice data={assignment} onNextClick={onNextClick} />;
    case "individual_word":
      return <JednoslovneOdpovediPractice data={assignment} onNextClick={onNextClick} />;
    case "multiple_options_bottom":
      return <ViceMoznostiDolePractice data={assignment} onNextClick={onNextClick} />;
    case "multiple_options_top":
      return <ViceMoznostiNahorePractice data={assignment} onNextClick={onNextClick} />;
    case "equation":
      return <RovnicePractice data={assignment} onNextClick={onNextClick} />;
    case "multiple_individual_words":
      return <ViceJednoslovnychOdpovediPractice data={assignment} onNextClick={onNextClick} />;
    default:
      return null;
  }
};

export const getAssignmentExerciseByType = (
  type: string | null | undefined,
  assignment: UserAssignmentForExerciseFragment,
  userAssignmentId: string,
  onNextClick: () => void,
  evaluateAnswer: boolean = true,
) => {
  switch (type) {
    case "abcd":
      return (
        <ABCDExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "abcd_separated":
      return (
        <ABCDOddeleneExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "yes_no":
      return (
        <AnoNeExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "drawing":
      return (
        <RysovaniExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "sorting":
      return (
        <RazeniExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "multiple_words":
      return (
        <ViceslovneOdpovediExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "individual_word":
      return (
        <JednoslovneOdpovediExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "multiple_options_bottom":
      return (
        <ViceMoznostiDoleExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "multiple_options_top":
      return (
        <ViceMoznostiNahoreExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "equation":
      return (
        <RovniceExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    case "multiple_individual_words":
      return (
        <ViceJednoslovnychOdpovediExercise
          key={assignment.id}
          data={assignment}
          userAssignmentId={userAssignmentId}
          onNextClick={onNextClick}
          evaluateAnswer={evaluateAnswer}
        />
      );
    default:
      return null;
  }
};

export const getAssignmentModalExerciseByType = (
  type: string | null | undefined,
  assignment: UserAssignmentForExerciseFragment,
  result: IAssignmentResultExercise<IOption | string>,
  toggleModals: (index: number | null) => void,
) => {
  switch (type) {
    case "abcd":
      return <ABCDModalExercise assignmentDetail={assignment} assignmentResult={result} toggleModals={toggleModals} />;
    case "abcd_separated":
      return (
        <ABCDOddeleneModalExercise
          assignmentDetail={assignment}
          assignmentResult={result}
          toggleModals={toggleModals}
        />
      );
    case "yes_no":
      return <AnoNeModalExercise assignmentDetail={assignment} assignmentResult={result} toggleModals={toggleModals} />;
    case "drawing":
      return (
        <RysovaniModalExercise assignmentDetail={assignment} assignmentResult={result} toggleModals={toggleModals} />
      );
    case "sorting":
      return (
        <RazeniModalExercise assignmentDetail={assignment} assignmentResult={result} toggleModals={toggleModals} />
      );
    case "multiple_words":
      return (
        <ViceslovneOdpovediModalExercise
          assignmentDetail={assignment}
          assignmentResult={result}
          toggleModals={toggleModals}
        />
      );
    case "individual_word":
      return (
        <JednoslovneOdpovediModalExercise
          assignmentDetail={assignment}
          assignmentResult={result}
          toggleModals={toggleModals}
        />
      );
    case "multiple_options_bottom":
      return (
        <ViceMoznostiDoleModalExercise
          assignmentDetail={assignment}
          assignmentResult={result}
          toggleModals={toggleModals}
        />
      );
    case "multiple_options_top":
      return (
        <ViceMoznostiNahoreModalExercise
          assignmentDetail={assignment}
          assignmentResult={result}
          toggleModals={toggleModals}
        />
      );
    case "equation":
      return (
        <RovniceModalExercise assignmentDetail={assignment} assignmentResult={result} toggleModals={toggleModals} />
      );
    case "multiple_individual_words":
      return (
        <ViceJednoslovnychOdpovediModalExercise
          assignmentDetail={assignment}
          assignmentResult={result}
          toggleModals={toggleModals}
        />
      );
    default:
      return null;
  }
};

export const getAssignmentModalPracticeByType = (
  type: string | null | undefined,
  result: IAssignmentResultPractice,
  toggleModals: (index: number | null) => void,
) => {
  switch (type) {
    case "abcd":
      return <ABCDModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "abcd_separated":
      return <ABCDOddeleneModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "yes_no":
      return <AnoNeModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "drawing":
      return <RysovaniModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "sorting":
      return <RazeniModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "multiple_words":
      return <ViceslovneOdpovediModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "individual_word":
      return <JednoslovneOdpovediModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "multiple_options_bottom":
      return <ViceMoznostiDoleModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "multiple_options_top":
      return <ViceMoznostiNahoreModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "equation":
      return <RovniceModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    case "multiple_individual_words":
      return <ViceJednoslovnychOdpovediModalPractice assignmentResult={result} toggleModals={toggleModals} />;
    default:
      return null;
  }
};
