import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import { TopicPractice } from "features";

import { Loading } from "components";

import { useGetTopicAssignmentsListQuery } from "api/generated";
import type { UserAssignmentPracticeFragment } from "api/generated";

import { processApiError } from "utils/processApiError";

const MixedPractice = () => {
  const { topicId } = useParams();

  if (typeof topicId !== "string") return null;

  const { data, isLoading, error: apiError } = useGetTopicAssignmentsListQuery({ topicId });

  const [allAssignments, setAllAssignments] = useState<UserAssignmentPracticeFragment[] | null>(null);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const assignments = data.userAssignments.items.filter(assignment => assignment.assignment?.isActive);
    setAllAssignments(assignments);
  }, [data]);

  if (isLoading) {
    return (
      <Container className={"container-mw-sm layout--narrow"}>
        <div className={"fullpage bg-primary-subtle"}>
          <Loading />
        </div>
      </Container>
    );
  }

  if (allAssignments === null || allAssignments.length === 0) {
    return (
      <Container className={"container-mw-sm layout--narrow"}>
        <div className={"fullpage bg-primary-subtle"}>
          <p>Nepodařilo se načíst úlohy</p>
        </div>
      </Container>
    );
  }

  return <TopicPractice assignmentsList={allAssignments} title={data?.topics[0]?.name ?? ""} topicId={topicId} />;
};

export default MixedPractice;
