import { Trans, useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import { ReactComponent as IconPlus } from "images/icons/plus.svg";
import { ReactComponent as IconMinus } from "images/icons/minus.svg";
import { useState } from "react";
import { Stopwatch } from "../../../../components/Stopwatch";
import { Link } from "react-router-dom";

const COUNT_MIN = 1;
const COUNT_MAX = 10;

const Exercise02 = () => {
  const [count, setCount] = useState<number>(1);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    setCount(count - 1);
  };

  const { t } = useTranslation();

  return (
    <main className={"h-100 overflow-hidden d-flex flex-column justify-content-between justify-content-sm-center"}>
      <div className="page px-3">
        <div className="page__header">
          <LinkContainer to="/dev/cviceni/">
            <Button variant={"close"} />
          </LinkContainer>
        </div>

        <div className="page__content">
          <h1 className={"h2 mb-40px text-center"}>
            <Trans
              i18nKey="header.exerciseStart"
              ns="exercise"
              values={{ exerciseType: "rovnice" }}
              components={{
                highlight: <span className={"text-highlight"} />,
                br: <br />,
              }}
            />
          </h1>

          <p className="text-center mb-12px">
            <strong>{t("label.howManyExercises", { ns: "exercise" })}</strong>
          </p>

          <div className="increment-group mb-32px">
            <Button
              variant={"primary"}
              className={"btn-circular"}
              onClick={decrementCount}
              disabled={count === COUNT_MIN}
            >
              <IconMinus />
            </Button>
            <p className={"h2 mb-0"}>
              {count} {t("exercise", { ns: "exercise", count })}
            </p>
            <Button
              variant={"primary"}
              className={"btn-circular"}
              onClick={incrementCount}
              disabled={count === COUNT_MAX}
            >
              <IconPlus />
            </Button>
          </div>

          <p className="text-center mb-12px">
            <strong>{t("label.timeEstimate", { ns: "exercise" })}</strong>
          </p>

          <Stopwatch minutes={count * 3} className={"mb-32px"} />

          <Alert variant={"info"}>{t("info.getSupplies", { ns: "exercise" })}</Alert>

          <Alert variant={"danger"} className={"alert--icon-danger"}>
            <Trans
              i18nKey="warning.geometryExercise"
              ns="exercise"
              components={{
                lnk: <Link to={"#"} />,
                strong: <strong />,
              }}
            />
          </Alert>
        </div>

        <div className="page__footer">
          <Button type={"submit"} className={"w-100 text-uppercase"}>
            {t("btn.start", { ns: "exercise" })}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default Exercise02;
