import type { IOnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip";
import { Image } from "react-bootstrap";

import IconBookmark from "images/icons/bookmark.svg";

export const tooltipTexts: IOnboardingTooltip[] = [
  {
    key: "introduction",
    beforeTitleText: "Nezapomeň, že budeš vždy potřebovat psací potřeby a čisté A4 papíry.",
    title: "Skoč si pro ně a můžeme pokračovat.",
    description: <p>{"Pokud bude součástí také rýsování, vždy ti to na začátku řekneme."}</p>,
    buttonText: "Zobrazit další tip",
  },
  {
    key: "time",
    title: "Odpočet času",
    description: (
      <p>{"Zde uvidíš odpočet času od začátku procvičování. Pokud předpokládaný čas vyčerpáš, nic se neděje."}</p>
    ),
    buttonText: "Zobrazit další tip",
    elementId: "timer",
  },
  {
    key: "bookmark",
    title: "Označení otázek",
    description: (
      <p>
        {
          'Otázku si můžeš označit, ať se k ní můžeš kdykoliv vrátit. Všechny označené otázky nalezneš v sekci "Obálky a úlohy"'
        }
        <Image src={IconBookmark} />
      </p>
    ),
    buttonText: "Zobrazit další tip",
    elementId: "bookmark",
  },
  {
    key: "navigation",
    title: "Jednotlivé otázky",
    description: <p>{"Tady vidíš, kolik otázek Tě čeká a jakou otázku zrovna procvičuješ."}</p>,
    buttonText: "Zobrazit další tip",
    elementId: "exerciseNavigation",
  },
  {
    key: "answer",
    title: "Odpovědi",
    description: (
      <p>
        {
          "Některé otázky mají pouze jednu odpověď, některé více. Pokud jich je více, musíš vyplnit všechny odpovědi, do té doby nemůžeš vidět výsledky."
        }
      </p>
    ),
    buttonText: "Chápu, jdeme na to 🙂",
    elementId: "question",
    placement: "top",
  },
  {
    key: "tip",
    title: '"To jsem tipla"',
    description: (
      <p>
        {
          "Někdy se stává, že i když máš otázku dobř, nemusíš dané látce rozumět. A to my potřebujeme vědět :) U každé podotázky, kterou máš správně, budeš mít možnost zaškrtnout, že sis to pouze tipl/a. Není na tom vůbec nic špatného a nikomu to neřekneme :) Jen to musíme vědět, abychom ti pomohli dané látce do přijímaček porozumět."
        }
      </p>
    ),
    buttonText: "Chápu :)",
    elementId: "tip",
    placement: "top",
  },
  {
    key: "analysis",
    title: "Rozbor",
    description: (
      <p>
        {
          "U každé podotázky najdeš rozbor, kde se dozvíš postup správného řešení a také video vysvětlení, kde Ti učitel probíranou látku vysvětlí."
        }
      </p>
    ),
    buttonText: "Chápu :)",
    elementId: "analysis",
    placement: "top",
  },
];
