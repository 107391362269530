import type { FC } from "react";

interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
}

const Quarter: FC<Props> = ({ foregroundColor, backgroundColor }) => {
  return (
    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      {backgroundColor !== null ? (
        <path
          d="M1.55835 55.4289L2.18335 61.7574L8.66848 65.8202L25.7007 70.5861L42.2642 66.6015L58.0464 48.6317L68.9846 33.3182L67.6563 32.1463L61.3279 30.4275L53.9837 29.2555L39.4516 27.0679H36.0138L33.2012 28.5523L30.701 31.0525L27.4977 33.3182L22.4974 36.9122L1.55835 55.4289Z"
          fill={backgroundColor}
        />
      ) : (
        ""
      )}
      <path
        opacity="0.3"
        d="M1.55835 55.4289L2.18335 61.7574L8.66848 65.8202L25.7007 70.5861L42.2642 66.6015L58.0464 48.6317L68.9846 33.3182L67.6563 32.1463L61.3279 30.4275L53.9837 29.2555L39.4516 27.0679H36.0138L33.2012 28.5523L30.701 31.0525L27.4977 33.3182L22.4974 36.9122L1.55835 55.4289Z"
        fill="#34322E"
      />
      <path
        d="M1.28882 55.5357L18.2449 40.5333C20.4249 38.535 25.4607 34.1896 28.1638 32.7944C31.5428 31.0504 32.5238 26.6904 37.9737 27.0174C43.4237 27.3444 59.3375 30.0694 61.0815 30.3964C62.4767 30.658 65.9501 31.5954 67.5124 32.0314"
        stroke="#34322E"
      />
      <path d="M38.8191 28.4841C39.0025 29.3004 39.2592 31.5526 38.8191 34.0314" stroke="#34322E" />
      <path
        d="M48.7352 64.1188C49.7762 62.9872 57.9231 53.3467 58.9189 51.7626C59.6983 50.5227 64.6164 44.1625 67.1456 40.8309C67.2014 40.7573 67.2785 40.5542 66.9758 40.4917C64.4119 39.9617 59.391 38.8123 58.191 38.8123C56.691 38.8123 56.191 37.8123 52.691 37.8123H30.6909C26.5243 41.8123 16.8909 50.7123 11.6909 54.3123C6.49095 57.9123 4.04526 60.4229 3.38257 61.3318C3.30444 61.488 3.36104 61.5808 3.44642 61.6032C5.68884 62.1915 10.7813 63.4212 15.1065 64.1188C20.7188 65.024 32.9392 67.3775 34.4328 67.3775C35.9264 67.3775 37.8714 68.3123 39.191 68.3123H44.191C45.2772 68.3123 47.6942 65.2503 48.7352 64.1188Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M4.13107 61.2975C8.07068 59.7519 16.6667 56.3893 19.5339 55.3036C23.118 53.9465 34.4567 49.6877 35.1394 49.0091C35.8221 48.3306 34.6422 45.1976 34.6422 43.1054C34.6422 41.546 32.9405 39.4895 30.7644 37.9438"
        stroke="#78746D"
      />
      <path
        d="M32.312 46.7672C32.0161 46.9336 31.5131 47.3552 31.8681 47.7103"
        stroke="#78746D"
        strokeLinecap="round"
      />

      {foregroundColor !== null ? (
        <path
          d="M14.9558 58.8123L1.45581 55.8123L2.95581 65.8123C2.78914 66.4789 3.05581 67.8123 5.45581 67.8123C8.45581 67.8123 19.4558 71.3123 23.4558 72.3123C27.4558 73.3123 31.9558 72.8123 37.4558 73.3123C42.9558 73.8123 43.7058 73.3123 44.9558 72.3123C47.4558 70.3123 47.9558 68.3123 50.9558 64.8123C53.9558 61.3123 60.4558 52.8123 61.4558 50.8123C62.4558 48.8123 68.9558 43.3123 69.9558 42.3123C70.9558 41.3123 70.4558 37.3123 70.9558 35.3123C71.3558 33.7123 68.4558 32.3123 66.9558 31.8123C67.6225 32.1456 68.9558 32.9123 68.9558 33.3123C68.9558 33.8123 67.4558 34.3123 66.4558 35.3123C65.4558 36.3123 62.4558 40.3123 60.9558 42.3123C59.4558 44.3123 57.9558 45.8123 55.4558 48.3123C53.4558 50.3123 45.9558 59.4789 42.4558 63.8123C39.9558 63.3123 34.6558 62.2123 33.4558 61.8123C32.2558 61.4123 28.2891 61.1456 25.4558 60.8123C25.4558 62.3123 25.5558 64.7123 21.9558 64.3123C18.3558 63.9123 15.4558 59.9789 14.9558 58.8123Z"
          fill={foregroundColor}
        />
      ) : (
        ""
      )}

      <path
        d="M14.9558 58.8123L1.45581 55.8123L2.95581 65.8123C2.78914 66.4789 3.05581 67.8123 5.45581 67.8123C8.45581 67.8123 19.4558 71.3123 23.4558 72.3123C27.4558 73.3123 31.9558 72.8123 37.4558 73.3123C42.9558 73.8123 43.7058 73.3123 44.9558 72.3123C47.4558 70.3123 47.9558 68.3123 50.9558 64.8123C53.9558 61.3123 60.4558 52.8123 61.4558 50.8123C62.4558 48.8123 68.9558 43.3123 69.9558 42.3123C70.9558 41.3123 70.4558 37.3123 70.9558 35.3123C71.3558 33.7123 68.4558 32.3123 66.9558 31.8123C67.6225 32.1456 68.9558 32.9123 68.9558 33.3123C68.9558 33.8123 67.4558 34.3123 66.4558 35.3123C65.4558 36.3123 62.4558 40.3123 60.9558 42.3123C59.4558 44.3123 57.9558 45.8123 55.4558 48.3123C53.4558 50.3123 45.9558 59.4789 42.4558 63.8123C39.9558 63.3123 34.6558 62.2123 33.4558 61.8123C32.2558 61.4123 28.2891 61.1456 25.4558 60.8123C25.4558 62.3123 25.5558 64.7123 21.9558 64.3123C18.3558 63.9123 15.4558 59.9789 14.9558 58.8123Z"
        stroke="#34322E"
      />
      <path d="M42.5691 65.8301C42.7525 66.6463 43.0092 68.8986 42.5691 71.3773" stroke="#34322E" />
    </svg>
  );
};

export default Quarter;
