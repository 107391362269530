import { Alert } from "react-bootstrap";
import { Trans } from "react-i18next";

const NotExerciseInfo = () => {
  return (
    <Alert variant="secondary">
      <div style={{ textAlign: "left" }}>
        <Trans
          i18nKey="practice.notExercise"
          ns="exercise"
          components={{
            strong: <strong />,
          }}
        />
      </div>
    </Alert>
  );
};

export default NotExerciseInfo;
