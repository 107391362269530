import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import type { IOption } from "store/slices/topicPractice";

import { removeWrapperHTML } from "utils/removeHTML";

interface Props {
  qIndex: number;
  options: IOption[];
}

const OptionsText: FC<Props> = ({ qIndex, options }) => {
  return (
    <div>
      {options.map((option, oIndex) => {
        const text = `${option.value ?? ""})&nbsp;${removeWrapperHTML(option.additionalText ?? "")}`;
        return (
          <p key={`${qIndex}-${option.value ?? oIndex}`}>
            <Latex>{text}</Latex>
          </p>
        );
      })}
    </div>
  );
};

export default OptionsText;
