import { Image } from "react-bootstrap";

import LogoGray from "images/logos/tonatrenujes-gray.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <a href="https://zona.to-das.cz/_u_home-schooling-buy.php" target="__blank" rel="noreferrer noopener">
        <Image src={LogoGray} loading={"lazy"} />
      </a>
    </footer>
  );
};

export default Footer;
