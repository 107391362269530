import { useMemo, type FC } from "react";
import { Link } from "react-router-dom";

import { useAppSelector } from "store/hooks";
import { ReactComponent as StrawberrySVG } from "images/icons/strawberry.svg";
import { ReactComponent as WaterDropSVG } from "images/icons/waterDrop.svg";
import { getDaysFulfillsPlan, getElixitOfLife } from "store/slices/user";

interface Props {
  withLink?: boolean;
  className?: string;
}

const CurrentStreak: FC<Props> = ({ withLink = true, className }) => {
  const elixirOfLife = useAppSelector(getElixitOfLife);
  const daysFulfillsPlan = useAppSelector(getDaysFulfillsPlan);

  const renderStreaks = useMemo(
    () => (
      <span className="streaks-wrapper">
        <span className="streak streak--elixir">
          <span className="streak__content">
            <span className="streak__count">{elixirOfLife}</span>
            <span className="streak__icon">
              <WaterDropSVG />
            </span>
          </span>
          <span className="streak__title">Živá voda</span>
        </span>

        <span className="streak streak--strawberry">
          <span className="streak__content">
            <span className="streak__count">{daysFulfillsPlan}</span>
            <span className="streak__icon">
              <StrawberrySVG />
            </span>
          </span>
          <span className="streak__title">Moje řada</span>
        </span>
      </span>
    ),
    [elixirOfLife, daysFulfillsPlan],
  );

  if (withLink) {
    return (
      <div className={className}>
        <Link to={"/kalendar"} className="streak__link">
          {renderStreaks}
        </Link>
      </div>
    );
  }

  return <div className={className}>{renderStreaks}</div>;
};

export default CurrentStreak;
