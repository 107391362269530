import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { AnswerPractice } from "components";

import type { UserAssignmentDetailFragment, UserAssignmentForExerciseFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { getAssignmentResult } from "store/slices/topicPractice";
import type { TSolutionVariant } from "store/slices/exercise";

import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

interface Props {
  assignment: UserAssignmentDetailFragment | UserAssignmentForExerciseFragment;
  selectedOptions: Array<{ points: number | null; status: TSolutionVariant | null }>;
  timeToLearn?: number;
  onNextClick?: () => void;
}

const SelfEvaluationAnalysis: FC<Props> = ({ assignment, selectedOptions, timeToLearn, onNextClick }) => {
  const isShared = assignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true;

  const { t } = useTranslation(["assignments"]);
  const assignmentResult = useAppSelector(getAssignmentResult(assignment.id));

  const [progress, setProgress] = useState(false);
  const loadingData = useRef(false);

  const handleNextClick = () => {
    setProgress(true);
  };

  useEffect(() => {
    if (onNextClick === undefined || !progress) return;

    loadingData.current = true;
    onNextClick();
    setProgress(false);
  }, [progress]);

  return (
    <>
      <Latex>{assignment.assignment?.assignment?.assignmentText ?? ""}</Latex>

      {isShared ? (
        <div className="mb-3 mt-20px question">
          <AnswerPractice
            assignment={assignment.assignment}
            type={assignmentResult?.selfEvalAnswers[0]?.status ?? "danger"}
            index={0}
            userAssignmentId={assignment.id}
            timeToLearn={undefined}
            onNext={handleNextClick}
          >
            {getTextForSelfEvaluationOption(assignmentResult?.selfEvalAnswers[0]?.status ?? null)}
          </AnswerPractice>
        </div>
      ) : (
        assignmentResult?.selfEvalAnswers.map((value, index) => {
          const question = assignment.assignment?.assignment?.questions[index];
          const { imageUrl } = useImageUrlResolver(question?.selfEvaluation?.general?.image);

          return (
            <div key={index}>
              <Latex>{question?.heading ?? ""}</Latex>
              {imageUrl !== null ? <img src={imageUrl} /> : null}

              <div className="mt-20px question">
                <AnswerPractice
                  assignment={assignment.assignment}
                  type={value.status ?? "danger"}
                  index={index}
                  userAssignmentId={assignment.id}
                  timeToLearn={undefined}
                  onNext={handleNextClick}
                >
                  {getTextForSelfEvaluationOption(value.status)}
                </AnswerPractice>
              </div>
            </div>
          );
        })
      )}
      <div className="exercise__footer">
        <div className="mw-sm">
          {onNextClick !== undefined ? (
            <Button className={"w-100 text-uppercase"} onClick={handleNextClick} disabled={loadingData.current}>
              {t("continue", { ns: "common" })}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SelfEvaluationAnalysis;
