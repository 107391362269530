import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Loading } from "components";
import { AssignmentDetailPractice } from "features";

import { useGetAssignmentDetailForCmsQuery } from "api/generated";

import { processApiError } from "utils/processApiError";

const CMSAssignmentDetail = () => {
  const { assignmentId } = useParams();
  const navigate = useNavigate();

  if (assignmentId === undefined) {
    navigate("/");
    return null;
  }

  const { data, isLoading, error: apiError } = useGetAssignmentDetailForCmsQuery({ assignmentId });

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  if (isLoading) {
    return <Loading />;
  }

  if (data === undefined || data.assignments.length !== 1) {
    return (
      <Container className={"text-center pt-25px pt-sm-45px"}>
        <h3>Nepodařilo se načíst detail úlohy</h3>
      </Container>
    );
  }

  const assignmentData = data.assignments[0];
  const fakeUserAssignment = { id: "", assignment: assignmentData };

  const testId = assignmentData.general?.admissionTest.name ?? "";
  const testOrder = assignmentData.general?.testOrder;
  const title = typeof testId === "string" && typeof testOrder === "number" ? `${testId}_${testOrder}` : "";

  return (
    <div>
      <div className={"page-header  page-header--has-tabs"}>
        <Container className={"container-mw-md"}>
          <h1 className={"mb-0"}>{title}</h1>
        </Container>
      </div>

      <AssignmentDetailPractice assignment={fakeUserAssignment} userAssignmentId={""} />
    </div>
  );
};

export default CMSAssignmentDetail;
