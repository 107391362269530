import { useAppDispatch } from "store/hooks";
import { setAssignmentEvaluation as setExercise } from "store/slices/exercise";
import { setAssignmentEvaluation as setPractice } from "store/slices/topicPractice";

export const useEmptyAssignmentAnswer = () => {
  const dispatch = useAppDispatch();

  const setEmptyExerciseAnswer = (
    userAssignmentId: string,
    evaluateAnswers: (answers: Array<Array<string | null>>) => Array<"success" | "danger">,
  ) => {
    dispatch(
      setExercise({
        assignmentId: userAssignmentId,
        status: "incorrect",
        answerVariants: evaluateAnswers([]),
        correctAnswers: [],
      }),
    );
  };

  const setEmptyPracticeAnswer = (
    userAssignmentId: string,
    evaluateAnswers: (answers: Array<Array<string | null>>) => Array<"success" | "danger">,
  ) => {
    dispatch(
      setPractice({
        assignmentId: userAssignmentId,
        status: "incorrect",
        answerVariants: evaluateAnswers([]),
      }),
    );
  };

  return { setEmptyExerciseAnswer, setEmptyPracticeAnswer };
};
