export const translateEnvelopeId = (envelopeId: string) => {
  switch (envelopeId) {
    case "cervena":
      return "red";
    case "oranzova":
      return "yellow";
    case "zelena":
      return "green";
    default:
      return "";
  }
};

export const translateEnvelopeBEToApp = (envelopeNameBE: string) => {
  switch (envelopeNameBE) {
    case "red":
    case "green":
      return envelopeNameBE;
    case "yellow":
      return "orange";
    default:
      return "";
  }
};

export const translateEnvelopeCZToEng = (envelopeCz: string) => {
  switch (envelopeCz) {
    case "cervena":
      return "red";
    case "oranzova":
      return "orange";
    case "zelena":
      return "green";
    default:
      return null;
  }
};
