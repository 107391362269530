import { useTranslation } from "react-i18next";
import { Breadcrumb, Col, Container, Nav, OverlayTrigger, Popover, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconArrowBack from "../../../../components/Icons/IconArrowBack/IconArrowBack";
/* import { AchievementIcon } from "../../../../components"; */
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Button from "react-bootstrap/Button";

import { ReactComponent as IconArrowLeft } from "images/icons/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "images/icons/arrow-right.svg";
import { RewardsScroll } from "components";

function getBarGraphFillColor(percentage: number) {
  if (percentage >= 0.76) {
    return "#27AE60";
  } else if (percentage >= 0.51) {
    return "#FDC32F";
  } else if (percentage >= 0.26) {
    return "#F2994A";
  } else {
    return "#EB5757";
  }
}

interface LineChartsPopoverParams {
  payload: any;
}

const Statistics01 = () => {
  const { t } = useTranslation(["common", "statistics"]);

  const barGraphData = [
    {
      name: "24. - 30.4.",
      value: 1,
    },
    {
      name: "17. - 23.4.",
      value: 0.76,
    },
    {
      name: "10. - 16.4.",
      value: 0.8,
    },
    {
      name: "3. - 9.4.",
      value: 0.56,
    },
    {
      name: "27.3. - 2.4.",
      value: 0.62,
    },
    {
      name: "20. - 26.3.",
      value: 0.48,
    },
    {
      name: "13. - 19.3.",
      value: 0.49,
    },
    {
      name: "6. - 12.3.",
      value: 0.43,
    },
  ];

  const lineChartData = [
    {
      name: new Date("2023-03-27"),
      score: 40,
      testType: "prezenční",
      scoreBreakdown: {
        math: 20,
        czech: 20,
      },
    },
    {
      name: new Date("2023-04-03"),
      score: 45,
      testType: "prezenční",
      scoreBreakdown: {
        math: 20,
        czech: 25,
      },
    },
    {
      name: new Date("2023-04-10"),
      score: 65,
      testType: "prezenční",
      scoreBreakdown: {
        math: 20,
        czech: 45,
      },
    },
    {
      name: new Date("2023-04-17"),
      score: 80,
      testType: "prezenční",
      scoreBreakdown: {
        math: 40,
        czech: 40,
      },
    },
    {
      name: new Date("2023-04-30"),
      score: 85,
      testType: "prezenční",
      scoreBreakdown: {
        math: 45,
        czech: 40,
      },
    },
  ];

  const lineChartsSchoolData = [
    {
      name: "Gymnázium Sluníčko",
      color: "#27AE60",
      value: 90,
    },
    {
      name: "Gymnázium Petra Bezruče",
      color: "#17A4C2",
      value: 82,
    },
    {
      name: "Co je vlastně ta šedá čára?",
      color: "#9E9B8E",
      value: 77,
    },
  ];

  const lineChartsPopover = ({ payload }: LineChartsPopoverParams) => {
    const popoverDate = new Date(payload?.name);

    return (
      <Popover className={"line-graph__popover"}>
        <Popover.Body>
          <Row className={"mb-4px g-8px"}>
            <Col xs={12}>
              <p className={"mb-0 fw-bold"}>{`${popoverDate?.getDate()}. ${popoverDate?.getMonth() + 1}.`}</p>
            </Col>
          </Row>

          <Row className={"mb-4px g-8px"}>
            <Col xs={6}>
              <p className={"mb-0"}>Počet bodů:</p>
            </Col>
            <Col xs={6}>
              <p className={"mb-0"}>
                {payload?.score} (M {payload?.scoreBreakdown?.math}, ČJ {payload?.scoreBreakdown?.czech})
              </p>
            </Col>
          </Row>

          <Row className={"g-8px"}>
            <Col xs={6}>
              <p className={"mb-0"}>Typ testu:</p>
            </Col>
            <Col xs={6}>
              <p className={"mb-0"}>{payload?.testType}</p>
            </Col>
          </Row>

          <Row className={"mt-12px"}>
            <Col xs={12}>
              <Button>{"Podívat se na detailní rozbor"}</Button>
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <div className={"page-header page-header--has-tabs"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/"}>
                <IconArrowBack />
                {t("goHome")}
              </Link>
            </li>
          </Breadcrumb>

          <h1>{t("pageNameMyAchievements")}</h1>
        </Container>
      </div>

      <Container fluid className={"page-container pt-25px pt-sm-45px px-0"}>
        <Tab.Container defaultActiveKey={"badges"}>
          <Row>
            <Container className={"container-mw-md"}>
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey="badges">{t("tab.badges", { ns: "statistics" })}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="statistics">{t("tab.statistics", { ns: "statistics" })}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="rewards">{t("tab.rewards", { ns: "statistics" })}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Container>
          </Row>
          <Tab.Content className={"pt-45px"}>
            <Tab.Pane eventKey="badges">
              {/*  START TAB PANE BADGES  */}
              <Row>
                <Container className={"container-mw-md"}>
                  <h4 className="mb-16px">{t("sectionTitle.weeklyStatistics", { ns: "statistics" })}</h4>
                  <Row className={"g-8px mb-16px"}>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow" onClick={handleShow}>
                        {/* <AchievementIcon colorVariant={"orange"} icon={"calendar"} subIcon={2} /> */}
                        <span className={"achievement__badge"}>2x</span>
                        <p>
                          <strong>Plný týden</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"lightpurple"} icon={"list"} subIcon={"check"} /> */}
                        <p>
                          <strong>Plný týden s testem</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <span className={"achievement__badge"}>2x</span>
                        <p>
                          <strong>2x více času</strong>
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <h4 className="mb-16px">{t("sectionTitle.exerciseRewards", { ns: "statistics" })}</h4>
                  <Row className={"g-8px mb-16px"}>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"red"} icon={"exercise"} /> */}
                        <p>
                          <strong>První procvičování</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <p>
                          <strong>Nejdelší procvičování 90 min</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <p>Procvičování v řadě 5x po sobě</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <p>Nejdelší procvičování!</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"orange"} icon={"calendar"} subIcon={2} /> */}
                        <p>Dva týdny podle plánu!</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"lightgreen"} icon={"stopwatch"} /> */}
                        <p>Nejdelší procvičování!</p>
                      </div>
                    </Col>
                  </Row>

                  <h4 className="mb-16px">{t("sectionTitle.subjectMath", { ns: "statistics" })}</h4>
                  <Row className={"g-8px mb-16px"}>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"blue"} icon={"envelope"} /> */}
                        <p>
                          <strong>Všechno v zelené obálce</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"blue"} icon={"brain"} /> */}
                        <p>
                          <strong>Matematický génius</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <p>Geometrie</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <p>Sčítání</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"orange"} icon={"calendar"} subIcon={2} /> */}
                        <p>Rovnice</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"green"} icon={"stopwatch"} /> */}
                        <p>Zlomky</p>
                      </div>
                    </Col>
                  </Row>

                  <h4 className="mb-16px">{t("sectionTitle.subjectCzech", { ns: "statistics" })}</h4>
                  <Row className={"g-8px mb-16px"}>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"red"} icon={"envelope"} /> */}
                        <p>
                          <strong>Všechno v zelené obálce</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"red"} icon={"brain"} /> */}
                        <p>
                          <strong>Češtinářský génius</strong>
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <p>Geometrie</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                        <p>Sčítání</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow achievement--inactive">
                        {/* <AchievementIcon colorVariant={"orange"} icon={"calendar"} subIcon={2} /> */}
                        <p>Rovnice</p>
                      </div>
                    </Col>
                    <Col xs={4} sm={6} md={3} lg={2}>
                      <div className="achievement achievement--narrow">
                        {/* <AchievementIcon colorVariant={"green"} icon={"stopwatch"} /> */}
                        <p>Zlomky</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Row>
              {/*  END TAB PANE BADGES  */}
            </Tab.Pane>
            <Tab.Pane eventKey="statistics">
              {/*  START TAB PANE STATISTICS  */}
              <Row>
                <Container className="container-mw-md">
                  <h4 className={"mb-3"}>{t("sectionTitle.practiceExams", { ns: "statistics" })}</h4>

                  <div className="graph py-sm-32px line-graph">
                    <div className="graph__header mb-16px">
                      <Button variant="tertiary" className="btn-shadow">
                        <IconArrowLeft className={"icon"} />
                      </Button>
                      <p className="fw-bold mb-0">27. 3. – 30. 4.</p>
                      <Button variant="tertiary" className="btn-shadow">
                        <IconArrowRight className={"icon"} />
                      </Button>
                    </div>

                    <div className="line-graph__wrapper">
                      <ResponsiveContainer width="100%" height={434}>
                        <LineChart
                          width={500}
                          height={300}
                          data={lineChartData}
                          margin={{
                            top: 15,
                            right: 15,
                            left: 15,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" stroke="#DEDCD6" />
                          <XAxis
                            dataKey="name"
                            stroke="#DEDCD6"
                            padding={{
                              left: 36,
                              right: 36,
                            }}
                            tickFormatter={tick => {
                              const tickDate = new Date(tick);

                              return `${tickDate.getDate()}. ${tickDate.getMonth() + 1}.`;
                            }}
                          />
                          <YAxis
                            stroke="#DEDCD6"
                            unit={" b."}
                            domain={[
                              (dataMin: number) => dataMin - (dataMin % 5),
                              (dataMax: number) => dataMax + (5 - (dataMax % 5)),
                            ]}
                          />

                          <Legend
                            layout={"vertical"}
                            align={"center"}
                            verticalAlign={"bottom"}
                            content={props => {
                              // eslint-disable-next-line react/prop-types
                              const payload = props?.payload;

                              return (
                                <ul className={"recharts-default-legend"}>
                                  <li className={"recharts-legend-item"}>
                                    <p className={"mb-0 fw-bold text-center"}>Hranice pro přijetí:</p>
                                  </li>
                                  {payload?.map(function (item, index) {
                                    return item?.type !== "none" ? (
                                      <li className={"recharts-legend-item"} key={index}>
                                        <svg className="recharts-surface" width="22" height="22" viewBox="0 0 22 22">
                                          <line
                                            strokeWidth="3"
                                            fill="none"
                                            stroke={item?.color}
                                            strokeDasharray="3 3"
                                            x1="0"
                                            y1="11"
                                            x2="22"
                                            y2="11"
                                            className="recharts-legend-icon"
                                          ></line>
                                        </svg>
                                        <span className={"recharts-legend-item-text"}>{item?.value}</span>
                                      </li>
                                    ) : null;
                                  })}
                                </ul>
                              );
                            }}
                          />

                          {lineChartsSchoolData?.map(function (item, index) {
                            return (
                              <ReferenceLine
                                key={index}
                                y={item?.value}
                                stroke={item?.color}
                                strokeWidth={3}
                                strokeDasharray="3 3"
                              />
                            );
                          })}

                          {lineChartsSchoolData?.map(function (item, index) {
                            return (
                              <Line
                                key={index}
                                dataKey={""}
                                name={item?.name}
                                stroke={item?.color}
                                strokeWidth={3}
                                strokeDasharray="3 3"
                                legendType={"plainline"}
                              />
                            );
                          })}

                          <Line
                            dataKey="score"
                            stroke="#A75FE0"
                            strokeLinecap={"round"}
                            strokeWidth={5}
                            legendType={"none"}
                            activeDot={false}
                            dot={dot => {
                              return (
                                <OverlayTrigger
                                  trigger={["click", "focus"]}
                                  placement="bottom"
                                  rootClose
                                  defaultShow={false}
                                  overlay={lineChartsPopover({ payload: dot?.payload })}
                                >
                                  <circle
                                    r="5"
                                    stroke="#A75FE0"
                                    strokeLinecap="round"
                                    strokeWidth="12"
                                    fill="#A75FE0"
                                    width={dot?.width}
                                    height={dot?.height}
                                    strokeOpacity="0.4"
                                    cx={dot?.cx}
                                    cy={dot?.cy}
                                    className="recharts-dot recharts-line-dot"
                                  />
                                </OverlayTrigger>
                              );
                            }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  <h4 className={"mb-3"}>{t("sectionTitle.weeklySuccessRate", { ns: "statistics" })}</h4>

                  <div className="graph bar-graph">
                    <ResponsiveContainer width="100%" height={264}>
                      <BarChart layout="vertical" data={barGraphData} margin={{ right: 50 }}>
                        <XAxis type="number" hide />
                        <YAxis type="category" width={80} dataKey="name" stroke={"#B1AEA3"} />

                        <Bar
                          dataKey="value"
                          fill={"#0123456"}
                          barSize={11}
                          radius={[0, 11, 11, 0]}
                          label={{
                            fontSize: 14,
                            fontWeight: 700,
                            fill: "#3D3B32",
                            position: "right",
                            formatter: function (value: number) {
                              return `${Math.ceil(value * 100)} %`;
                            },
                          }}
                        >
                          {barGraphData.map(function (entry, index) {
                            return <Cell key={index} fill={getBarGraphFillColor(entry.value)} />;
                          })}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </Container>
              </Row>
              {/*  END TAB PANE STATISTICS  */}
            </Tab.Pane>
            <Tab.Pane eventKey="rewards">
              {/*  START TAB PANE REWARDS  */}
              <Row>
                <Container className={"container-mw-md"}>
                  <div className={"quote mb-16px"}>
                    <p>
                      Držíme Ti palce, víme, že to zvládneš, jen se musíš snažit. Držíme Ti palce, víme, že to zvládneš,
                      jen se musíš snažit.
                    </p>
                    <p>Máma a táta ❤️</p>
                  </div>
                </Container>
              </Row>

              <Row>
                <Container fluid>
                  <h4 className={"mb-3 text-center"}>{t("sectionTitle.monthlySuccessRate", { ns: "statistics" })}</h4>

                  <div className="rewards mb-3">
                    <div className="rewards__wrapper">
                      {[
                        {
                          text: "Společný výlet na kolech delší text",
                          label: "Září",
                          color: "#F2994A",
                        },
                        {
                          text: "Společný výlet na kolech delší text",
                          label: "Září",
                          color: "#EB5757",
                        },
                        {
                          text: "Společný výlet na kolech delší text",
                          label: "Září",
                          color: "#A75FE0",
                        },
                        {
                          text: "Společný výlet na kolech delší text",
                          label: "Září",
                          color: "#17A4C2",
                        },
                        {
                          text: "Společný výlet na kolech delší text",
                          label: "Září",
                          color: "#F3B61A",
                        },
                      ].map(function (item, index) {
                        return (
                          <div className="rewards__item" key={index}>
                            <div className="rewards__item__inner" style={{ backgroundColor: item?.color }}>
                              {item.text}
                            </div>
                            {item?.label != null ? <div className="rewards__item__label">{item.label}</div> : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <h4 className={"mb-3 text-center"}>{t("sectionTitle.examPointsRewarded", { ns: "statistics" })}</h4>

                  <RewardsScroll>
                    {[
                      {
                        text: "Nová sluchátka",
                        label: "75 bodů",
                        color: "#17A4C2",
                      },
                    ].map(function (item, index) {
                      return (
                        <div className="rewards__item" key={index}>
                          <div className="rewards__item__inner" style={{ backgroundColor: item?.color }}>
                            {item.text}
                          </div>
                          {item?.label != null ? <div className="rewards__item__label">{item.label}</div> : ""}
                        </div>
                      );
                    })}
                  </RewardsScroll>
                </Container>
              </Row>
              {/*  END TAB PANE REWARDS  */}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body className={"pb-40px"}>
          <Row>
            <Col xs={"auto"} className="px-0 flex-column d-flex justify-content-center">
              <Button className={"btn-circular btn-circular--sm"} variant={"light"}>
                <IconArrowLeft className={"flex-shrink-0"} />
              </Button>
            </Col>
            <Col className={"text-center"}>
              <h4 className={"mb-12px"}>Pilná včelka</h4>
              <p className={"mb-12px"}>
                Tento odznak jsi dostal za to, že jsi splnil delší cvičení, než bylo naplánováno. To je teda odhodlání,
                jsi frajer!
              </p>
              <div className={"achievement--inactive bg-white"}>
                {/* <AchievementIcon colorVariant={"lightpurple"} icon={"calendar"} subIcon={"check"} /> */}
              </div>
              <p className={"fs-13 mb-0 mt-8px"}>29.1.2023 - cvičení 90 min</p>
            </Col>
            <Col xs={"auto"} className="px-0 flex-column d-flex justify-content-center">
              <Button className={"btn-circular btn-circular--sm"} variant={"light"}>
                <IconArrowRight className={"flex-shrink-0"} />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Statistics01;
