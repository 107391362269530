import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";

import AnalysisLayout from "./_AnalysisLayout";
import SelfEvaluation from "./_SelfEvaluation";

import { AnswerPractice } from "components";

import { useLazyGetAssignmentAnswersQuery } from "api/generated";
import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { selectSimpleAnswer } from "store/slices/answer";

import { getSolutionVariant } from "utils/getSolutionVariant";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { processApiError } from "utils/processApiError";

/* W8WhtjNybz8RcI7cG4mQ */

interface Props {
  data: UserAssignmentForExerciseFragment;
}

const ViceJednoslovnychOdpovedi: FC<Props> = ({ data }) => {
  const assignment = data.assignment;
  if (assignment === null || assignment === undefined) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const selected = useAppSelector(selectSimpleAnswer);

  const [trigger, { data: answerData, isLoading, error: apiLazyError }] = useLazyGetAssignmentAnswersQuery();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const [answers, setAnswers] = useState<Array<Array<string | null>> | undefined>(undefined);

  useEffect(() => {
    if (isSelfEvaluation) return;

    trigger({ assignmentId: assignment.id }).catch(error => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  useEffect(() => {
    const responseAnswers = answerData?.assignments.map(
      answerAssignment => answerAssignment.assignment?.questions.map(question => question.correctAnswers),
    );

    if (responseAnswers === undefined || responseAnswers[0] === undefined || responseAnswers[0].length === 0) {
      return;
    }

    setAnswers(responseAnswers[0]);
  }, [answerData]);

  if (isSelfEvaluation) {
    return <SelfEvaluation userAssignment={data} />;
  }

  return (
    <AnalysisLayout>
      <div>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        <div className="mt-20px question">
          <Latex>{assignment.assignment?.questionsText ?? ""}</Latex>

          {assignment.assignment?.questions.map((question, qIndex) => {
            const type = getSolutionVariant(
              selected[qIndex],
              answers,
              qIndex,
              assignment.assignment?.isAnswerCaseSensitive ?? false,
            );

            return (
              <div key={qIndex} className="mt-20px ">
                <Latex>{question.heading ?? ""}</Latex>

                {isLoading ? null : (
                  <AnswerPractice assignment={assignment} type={type} index={qIndex} userAssignmentId={data.id}>
                    {selected[qIndex]}
                  </AnswerPractice>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </AnalysisLayout>
  );
};

export default ViceJednoslovnychOdpovedi;
