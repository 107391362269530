import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import { AnswerPractice } from "components";

import type { IAssignmentResult } from "store/slices/topicPractice";

import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* rPp7XgOY9DxQXaSbZsBQ */

interface Props {
  assignmentResult: IAssignmentResult;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const Razeni: FC<Props> = ({ assignmentResult, toggleModals }) => {
  const assignment = assignmentResult.assignment.assignment;
  if (assignment === undefined || assignment === null) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);

  const options =
    assignment.assignment?.questions.map(question => {
      return question.options.map(option => option.value);
    }) ?? [];

  return (
    <>
      <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className="mt-20px">
        {assignment.assignment?.questions.map((question, qIndex) => {
          return (
            <>
              <Latex>{question.heading ?? ""}</Latex>

              {question.options.map((option, oIndex) => {
                return (
                  <div
                    key={`text-${qIndex}-${oIndex}`}
                    className="assignment-option"
                    dangerouslySetInnerHTML={{ __html: option.additionalText ?? "" }}
                  ></div>
                );
              })}
              <div className="question question--list mt-20px">
                {options[qIndex].map((_option, oIndex) => {
                  const answer = assignmentResult.userAnswers[qIndex];
                  if (typeof answer !== "string") return null;

                  return (
                    <div key={`question-${oIndex}`} className="button-group">
                      <span>{`${oIndex + 1}.`}</span>
                      <AnswerPractice
                        key={`answer-${qIndex}`}
                        index={qIndex}
                        assignment={assignment}
                        type={assignmentResult.answerVariants?.[oIndex]}
                        toggleModals={toggleModals}
                        showBackOnSolution={true}
                        userAssignmentId={assignmentResult.assignment.id}
                      >
                        {isSelfEvaluation
                          ? getTextForSelfEvaluationOption(assignmentResult.selfEvalAnswers[oIndex]?.status ?? "danger")
                          : answer[oIndex]}
                      </AnswerPractice>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Razeni;
