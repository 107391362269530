import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PracticePageContent from "../_PracticePageContent";

import { useGetCombinedTopicPracticeAssignmentsQuery } from "api/generated";
import type { UserAssignmentPracticeFragment } from "api/generated";

import { processApiError } from "utils/processApiError";

const CombinedTopicPractice = () => {
  const { combinedTopicId } = useParams();
  if (combinedTopicId === undefined) return null;

  const { data, isLoading, error: apiError } = useGetCombinedTopicPracticeAssignmentsQuery({ combinedTopicId });

  const [allAssignments, setAllAssignments] = useState<UserAssignmentPracticeFragment[] | null>(null);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const assignments = data.userAssignments.items.filter(assignment => assignment.assignment?.isActive);
    setAllAssignments(assignments);
  }, [data]);

  return (
    <PracticePageContent
      isLoading={isLoading}
      assignments={allAssignments}
      title={data?.combinedTopics[0]?.name ?? ""}
      topicId={combinedTopicId}
    />
  );
};

export default CombinedTopicPractice;
