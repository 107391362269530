import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useState } from "react";
import type { FC } from "react";

import Task from "./Task";
import SelfEvaluationSolution from "./SelfEvaluationSolution";

import type { SelfEvaluationFragment, UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch } from "store/hooks";
import type { TAssignmentStatus, TSolutionVariant } from "store/slices/exercise";
import { setIsTimerShown } from "store/slices/timer";

import {
  getSelfEvaluationStatus,
  getSelfEvaluationVariant,
  getSelfEvaluationResults,
  getEvaluationVariantFromOption,
} from "utils/getSolutionVariant";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

export interface ISaveSelfEvaluationAnswerParams {
  userAssignmentId: string;
  selfEvalAnswer: Array<{ points: number | null; status: TSolutionVariant | null }>;
  status: TAssignmentStatus;
  answerVariants: TSolutionVariant[];
}

interface Props {
  userAssignment: UserAssignmentDetailFragment;
  userAssignmentId: string;
  onNextClick?: () => void;
  saveAnswer?: ({ userAssignmentId, selfEvalAnswer, status, answerVariants }: ISaveSelfEvaluationAnswerParams) => void;
}

const SelfEvaluation: FC<Props> = ({ userAssignment, onNextClick, userAssignmentId, saveAnswer }) => {
  if (userAssignment.assignment === undefined || userAssignment.assignment === null) return null;

  const isShared = userAssignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true;
  const questionsCount = userAssignment.assignment?.assignment?.questions.length ?? 0;
  const selfEvaluation = userAssignment.assignment?.selfEvaluation;

  const [shownStep, setShownStep] = useState<"task" | "options" | "summary" | "goNext">("task");
  const [selectedOptions, setSelectedOptions] = useState<
    Array<{ points: number | null; status: TSolutionVariant | null }>
  >(new Array(isShared ? 1 : questionsCount).fill({ points: null, status: null }));

  const { imageUrl: solutionImageURL } = useImageUrlResolver(selfEvaluation?.sharedSelfEvaluation?.general?.image);
  const dispatch = useAppDispatch();

  const handleEvaluationSelect = (index: number, option: number) => {
    let maxPoints = 0;

    if (isShared) {
      const points = userAssignment.assignment?.selfEvaluation?.sharedSelfEvaluation?.options
        .map(option => option.scoreReal)
        .filter((point): point is number => typeof point === "number");
      maxPoints = points === undefined ? 0 : Math.max(...points);
    } else {
      const points = userAssignment.assignment?.assignment?.questions[index].selfEvaluation?.options
        .map(option => option.scoreReal)
        .filter((point): point is number => typeof point === "number");
      maxPoints = points === undefined ? 0 : Math.max(...points);
    }

    setSelectedOptions(options => {
      const copy = [...options];
      copy[index] = {
        points: option,
        status: getEvaluationVariantFromOption(option, maxPoints),
      };

      return copy;
    });
  };

  const handleTaskSubmit = () => {
    dispatch(setIsTimerShown(false));
    setShownStep("options");
  };

  const handleOptionsSubmit = () => {
    if (saveAnswer !== undefined) {
      if (userAssignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true) {
        const data = userAssignment.assignment.selfEvaluation.sharedSelfEvaluation;
        if (data !== null && data !== undefined) {
          const points = userAssignment.assignment.selfEvaluation.sharedSelfEvaluation?.options
            .map(option => option.scoreReal)
            .filter((score): score is number => typeof score === "number");
          const maxPoints = points === undefined ? 0 : Math.max(...points);

          saveAnswer({
            userAssignmentId,
            selfEvalAnswer: getSelfEvaluationResults(selectedOptions, maxPoints),
            status: getSelfEvaluationStatus(selectedOptions, userAssignment.assignment?.scoring?.scoreMax),
            answerVariants: getSelfEvaluationVariant(selectedOptions, data),
          });
        }
      } else {
        const questions = userAssignment.assignment?.assignment?.questions;
        if (Array.isArray(questions)) {
          const data = questions
            .map(question => question.selfEvaluation)
            .filter((option): option is SelfEvaluationFragment => option !== null && option !== undefined);
          const maxPoints = data.map(selfEval => {
            const points = selfEval.options
              .map(option => option.scoreReal)
              .filter((score): score is number => typeof score === "number");
            return Math.max(...points);
          });

          saveAnswer({
            userAssignmentId,
            selfEvalAnswer: getSelfEvaluationResults(selectedOptions, maxPoints),
            status: getSelfEvaluationStatus(selectedOptions, userAssignment.assignment?.scoring?.scoreMax),
            answerVariants: getSelfEvaluationVariant(selectedOptions, data),
          });
        }
      }
    }

    setShownStep("summary");
    return false;
  };

  const handleNextClick = () => {
    onNextClick?.();
  };

  if (shownStep === "task") return <Task userAssignment={userAssignment} onSubmit={handleTaskSubmit} />;

  return (
    <div className="exercise">
      <div className="exercise__body">
        <div>
          <Latex>{selfEvaluation?.sharedSelfEvaluation?.general?.text ?? ""}</Latex>
          {solutionImageURL !== null ? <img src={solutionImageURL} /> : null}

          <SelfEvaluationSolution
            sendDataToBe={false}
            selectedOptions={selectedOptions}
            userAssignment={userAssignment}
            shownStep={shownStep}
            handleEvaluationSelect={handleEvaluationSelect}
            handleOptionsSubmit={handleOptionsSubmit}
            handleNextClick={handleNextClick}
          />
        </div>
      </div>
    </div>
  );
};

export default SelfEvaluation;
