import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

import type { UserAssignmentDetailFragment } from "api/generated";

export type TAssignmentStatus = "not_taken" | "correct" | "partial" | "incorrect";

export interface IOption {
  value?: string | null | undefined;
  additionalText?: string | null | undefined;
}

export type TSolutionVariant = "success" | "warning" | "danger";

export interface IAssignmentResult {
  assignment: UserAssignmentDetailFragment;
  userAnswers: Array<string | IOption>;
  selfEvalAnswers: Array<{ points: number | null; status: TSolutionVariant | null }>;
  answerVariants: TSolutionVariant[];
  status: TAssignmentStatus;
}

interface ITopicPracticeState {
  practiceResults: Record<string, IAssignmentResult>;
  assignmentList: Record<string, UserAssignmentDetailFragment[]>;
  selectedTime: number | undefined;
  practiceTopicName: string | null;
}

const initialState: ITopicPracticeState = {
  practiceResults: {},
  assignmentList: {},
  selectedTime: undefined,
  practiceTopicName: null,
};

const topicPracticeSlice = createSlice({
  name: "topicPractice",
  initialState,
  reducers: {
    setTopicAssignments: (
      state,
      action: PayloadAction<{ topicId: string; assignments: UserAssignmentDetailFragment[] }>,
    ) => {
      const { topicId, assignments } = action.payload;

      state.assignmentList[topicId] = assignments;

      const practiceAssignments: Record<string, IAssignmentResult> = {};

      for (const assignment of assignments) {
        const practiceAssignment: IAssignmentResult = {
          assignment,
          userAnswers: [],
          selfEvalAnswers: [],
          answerVariants: [],
          status: "not_taken",
        };

        practiceAssignments[assignment.id] = practiceAssignment;
      }
      state.practiceResults = practiceAssignments;
    },
    setAnswer: (state, action: PayloadAction<{ assignmentId: string; answers: Array<string | IOption> }>) => {
      const assignment = state.practiceResults[action.payload.assignmentId];
      if (assignment === undefined) return;

      assignment.userAnswers = action.payload.answers;
      state.practiceResults = {
        ...state.practiceResults,
        [action.payload.assignmentId]: assignment,
      };
    },
    setSelfEvalAnswers: (
      state,
      action: PayloadAction<{
        assignmentId: string;
        selfEvalAnswer: Array<{ points: number | null; status: TSolutionVariant | null }>;
      }>,
    ) => {
      const { assignmentId, selfEvalAnswer } = action.payload;
      const assignment = { ...state.practiceResults[assignmentId] };
      assignment.selfEvalAnswers = selfEvalAnswer;

      state.practiceResults = { ...state.practiceResults, [assignmentId]: assignment };
    },
    setAssignmentEvaluation: (
      state,
      action: PayloadAction<{ assignmentId: string; status: TAssignmentStatus; answerVariants: TSolutionVariant[] }>,
    ) => {
      const assignment = { ...state.practiceResults[action.payload.assignmentId] };
      if (assignment === undefined) return;

      assignment.status = action.payload.status;
      assignment.answerVariants = action.payload.answerVariants;
      state.practiceResults = {
        ...state.practiceResults,
        [action.payload.assignmentId]: assignment,
      };
    },
    removeResults: state => {
      state.practiceResults = {};
    },
    setSelectedTime: (state, action: PayloadAction<number | undefined>) => {
      state.selectedTime = action.payload;
    },
    setPracticeTopicName: (state, action: PayloadAction<string | null>) => {
      state.practiceTopicName = action.payload;
    },
  },
});

export default topicPracticeSlice.reducer;
export const {
  removeResults,
  setAssignmentEvaluation,
  setTopicAssignments,
  setAnswer,
  setSelfEvalAnswers,
  setSelectedTime,
  setPracticeTopicName,
} = topicPracticeSlice.actions;

export const getAssignments = (topicId: string) => (state: RootState) =>
  state.topicPractice.assignmentList[topicId] ?? [];
export const getAllResults = (state: RootState) => state.topicPractice.practiceResults;
export const getSimpleAnswer = (assignmentId: string) => (state: RootState) => {
  const answers = state.topicPractice.practiceResults[assignmentId].userAnswers;

  if (answers.length === 0 || typeof answers[0] === "string") return answers as string[];
  console.error("Trying to get simple answer on combined type: ", assignmentId);
  return [];
};

export const getCombinedAnswer = (assignmentId: string) => (state: RootState) => {
  const answers = state.topicPractice.practiceResults[assignmentId].userAnswers;

  if (answers.length === 0 || typeof answers[0] === "object") return answers as IOption[];

  console.error("Trying to get combined answer on simple type: ", assignmentId);
  return [];
};

export const getAnswerVariants = (assignmentId: string) => (state: RootState) => {
  return state.topicPractice.practiceResults[assignmentId].answerVariants;
};

export const getAssignmentResult = (assignmentId: string) => (state: RootState) => {
  return state.topicPractice.practiceResults[assignmentId];
};

export const getSelectedTime = (state: RootState) => state.topicPractice.selectedTime;

export const getPracticeTopicName = (state: RootState) => state.topicPractice.practiceTopicName;
