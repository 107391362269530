import { useEffect, useState } from "react";
import type { FC } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

import { EnvelopeExplanationModal, EnvelopesSummary } from "components";

import { useAppSelector } from "store/hooks";
import { buyTestsCount, unlockTestsCount } from "store/slices/user";

import packWaiting from "images/icons/pack-waiting.svg";
import packBuy from "images/icons/pack-buy.svg";

import type { EnvelopesSummaryType } from "api/generated";

export interface IEnvelopeCounts {
  green: number;
  yellow: number;
  red: number;
  sum: number;
}

const initialCounts = {
  green: 0,
  yellow: 0,
  red: 0,
  sum: 0,
};

interface Props {
  data: EnvelopesSummaryType | undefined;
}

const DashboardEnvelopesSummary: FC<Props> = ({ data }) => {
  if (data === undefined) return null;

  const { t } = useTranslation(["common"]);

  const [isModalShown, showModal] = useState(false);
  const [counts, setCounts] = useState<IEnvelopeCounts>(initialCounts);

  const testsToBuy = useAppSelector(buyTestsCount);
  const testsToUnlock = useAppSelector(unlockTestsCount);

  useEffect(() => {
    if (data === undefined) return;
    const { red, green, yellow } = data;

    const counts: IEnvelopeCounts = {
      red,
      green,
      yellow,
      sum: red + green + yellow,
    };

    setCounts(counts);
  }, [data]);

  return (
    <>
      <h4 className={"mb-3 mt-25px"}>Moje obálky ({t("assignmentsWithCount", { ns: "common", count: counts.sum })})</h4>

      <Row className={"g-20px"}>
        <Col lg={8}>
          <EnvelopesSummary data={counts} />
        </Col>
        <Col lg={4}>
          <Row className="flex-nowrap g-8px">
            <Col className={"flex-grow-0"}>
              <Image src={packWaiting} />
            </Col>
            <Col>
              {t("subjects.setsToComplete", { ns: "assignments", count: testsToUnlock ?? 0 })}{" "}
              <Button
                variant={"link"}
                onClick={() => {
                  showModal(true);
                }}
                className="p-0 m-0 border-none vertical-align-reset"
              >
                {t("subjects.howItWorks", { ns: "assignments" })}
              </Button>
            </Col>
          </Row>
          <hr className={"border-top-1px"} />
          <Row className="flex-nowrap g-8px">
            <Col className={"flex-grow-0"}>
              <Image src={packBuy} />
            </Col>
            <Col>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("subjects.testsToBuy", { ns: "assignments", count: testsToBuy ?? 0 }),
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <EnvelopeExplanationModal
        isShown={isModalShown}
        onHide={() => {
          showModal(false);
        }}
      />
    </>
  );
};

export default DashboardEnvelopesSummary;
