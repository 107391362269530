import { Route, Routes } from "react-router-dom";

import Faq from "./Faq";

const FaqRoutes = () => {
  return (
    <Routes>
      <Route index element={<Faq />} />
    </Routes>
  );
};

export default FaqRoutes;
