export enum Events {
  ONBOARDING_UPDATE = "onboarding_update",
}

interface IEventEmitter {
  _events: {
    [key in Events]?: Array<(data: any) => void>;
  };
  dispatch: (event: Events, data?: any) => void;
  subscribe: (event: Events, callback: (data?: any) => void) => void;
  unsubscribe: (event: Events) => void;
}

export const eventEmitter: IEventEmitter = {
  _events: {},
  dispatch(event, data) {
    const eventCallbacks = this._events[event];
    if (eventCallbacks === undefined) return;

    eventCallbacks.forEach(callback => {
      callback(data);
    });
  },
  subscribe(event: Events, callback: (data?: any) => any) {
    const eventCallbacks = this._events[event] ?? [];

    eventCallbacks.push(callback);
    this._events[event] = eventCallbacks;
  },
  unsubscribe(event: Events) {
    if (this._events[event] === undefined) return;
    delete this._events?.[event];
  },
};
