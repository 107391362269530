import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IBookmarkAssignment {
  id: string;
  subjectName: string | undefined;
}

interface IAssignmentNavigationState {
  topicAssignments: Record<string, string[]>;
  bookmarkAssignments: Record<string, IBookmarkAssignment[]>;
}

const initialState: IAssignmentNavigationState = {
  topicAssignments: {},
  bookmarkAssignments: {},
};

const assignmentNavigationSlice = createSlice({
  name: "assignmentNavigation",
  initialState,
  reducers: {
    setTopicAssignments: (state, action: PayloadAction<{ topicId: string; assignments: string[] }>) => {
      state.topicAssignments = {
        ...state.topicAssignments,
        [action.payload.topicId]: action.payload.assignments,
      };
    },
    setBookmarkAssignments: (state, action: PayloadAction<IBookmarkAssignment[]>) => {
      const sorted = action.payload.reduce<Record<string, IBookmarkAssignment[]>>((acc, assignment) => {
        const subjectName = assignment.subjectName;

        if (subjectName !== undefined) {
          if (acc[subjectName] === undefined) acc[subjectName] = [];
          acc[subjectName].push(assignment);
        }

        return acc;
      }, {});
      state.bookmarkAssignments = sorted;
    },
  },
});

export default assignmentNavigationSlice.reducer;
export const { setBookmarkAssignments, setTopicAssignments } = assignmentNavigationSlice.actions;

export const getTopicAssignments = (topicId: string) => (state: RootState) =>
  state.assignmentsNavigation.topicAssignments[topicId];

export const getBookmarkAssignments = (subjectName: string | undefined) => (state: RootState) => {
  if (subjectName === undefined || !(subjectName in state.assignmentsNavigation.bookmarkAssignments)) return null;

  return state.assignmentsNavigation.bookmarkAssignments[subjectName];
};
