import { Container } from "react-bootstrap";

import { TestsGraph, ExercisePercentageGraph } from "features";

const Statistics = () => {
  return (
    <Container className={"container-mw-md"}>
      <TestsGraph />
      <ExercisePercentageGraph />
    </Container>
  );
};

export default Statistics;
