import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface ITimer {
  startedAt: number | null;
  stoppedAt: number | null;
  time: number;
}

interface ITimers {
  global: ITimer;
  assignment: ITimer;
  learningTimer: ITimer;
  isShown: boolean;
}

const initialState: ITimers = {
  global: {
    startedAt: null,
    stoppedAt: null,
    time: 0,
  },
  assignment: {
    startedAt: null,
    stoppedAt: null,
    time: 0,
  },
  learningTimer: {
    startedAt: null,
    stoppedAt: null,
    time: 0,
  },
  isShown: true,
};

const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    startGlobalTimer: state => {
      const global = { ...state.global };

      global.startedAt = Date.now();
      global.stoppedAt = null;

      state.global = global;
    },
    startAssignmentTimer: state => {
      const assignment = { ...state.assignment };

      assignment.startedAt = Date.now();
      assignment.stoppedAt = null;

      state.assignment = assignment;
    },
    stopAllTimers: state => {
      const now = Date.now();

      if (state.global.startedAt !== null) {
        const global = { ...state.global };

        if (global.startedAt === null) return;

        global.time = global.time + now - global.startedAt;
        global.startedAt = null;

        if (global.stoppedAt === null) {
          global.stoppedAt = now;
        }

        state.global = global;
      }

      if (state.assignment.startedAt !== null) {
        const assignment = { ...state.assignment };

        if (assignment.startedAt === null) return;
        assignment.time = assignment.time + now - assignment.startedAt;
        assignment.startedAt = null;

        if (assignment.stoppedAt === null) {
          assignment.stoppedAt = now;
        }

        state.assignment = assignment;
      }

      if (state.learningTimer.startedAt !== null) {
        const learningTimer = { ...state.learningTimer };

        if (learningTimer.startedAt === null) return;

        learningTimer.time = learningTimer.time + now - learningTimer.startedAt;
        learningTimer.startedAt = null;

        if (learningTimer.stoppedAt === null) {
          learningTimer.stoppedAt = now;
        }

        state.learningTimer = learningTimer;
      }
    },
    stopAssignmentTimer: state => {
      const assignment = { ...state.assignment };

      if (assignment.startedAt === null) return;

      const now = Date.now();
      assignment.time = assignment.time + now - assignment.startedAt;
      assignment.startedAt = null;

      if (assignment.stoppedAt === null) {
        assignment.stoppedAt = now;
      }

      state.assignment = assignment;
    },
    resetAllTimers: state => {
      state.global = {
        startedAt: null,
        stoppedAt: null,
        time: 0,
      };
      state.assignment = {
        startedAt: null,
        stoppedAt: null,
        time: 0,
      };
      state.learningTimer = {
        startedAt: null,
        stoppedAt: null,
        time: 0,
      };
    },
    resetAssignmentTimer: state => {
      state.assignment = {
        time: 0,
        startedAt: null,
        stoppedAt: null,
      };
    },
    tick: state => {
      const global = { ...state.global };

      if (global.startedAt === null) return;

      const now = Date.now();
      global.time = global.time + now - global.startedAt;
      global.startedAt = now;

      state.global = global;
    },
    tickAssignment: state => {
      const assignment = { ...state.assignment };

      if (assignment.startedAt === null) return;

      const now = Date.now();
      assignment.time = assignment.time + now - assignment.startedAt;
      assignment.startedAt = now;

      state.assignment = assignment;
    },
    measurePracticeTime: state => {
      const assignment = { ...state.assignment };
      const now = Date.now();

      /* Just in case, startedAt should be always set */
      if (assignment.startedAt === null) {
        console.error("Assignment timer was measured even though it was not started yet!");
        assignment.time = 0;
      } else {
        assignment.time = assignment.time + now - assignment.startedAt;
      }

      assignment.startedAt = now;

      state.assignment = assignment;
    },
    startLearningTimer: state => {
      const learningTimer = { ...state.learningTimer };

      learningTimer.startedAt = Date.now();
      learningTimer.stoppedAt = null;

      state.learningTimer = learningTimer;
    },
    measureLearningTime: state => {
      const learningTimer = { ...state.learningTimer };
      if (learningTimer.startedAt === null) return;

      const now = Date.now();

      learningTimer.time = now - learningTimer.startedAt;

      state.learningTimer = learningTimer;
    },
    stopLearningTimer: state => {
      const learningTimer = { ...state.learningTimer };

      if (learningTimer.startedAt === null) return;

      const now = Date.now();
      learningTimer.time = learningTimer.time + now - learningTimer.startedAt;
      learningTimer.startedAt = null;

      if (learningTimer.stoppedAt === null) {
        learningTimer.stoppedAt = now;
      }

      state.learningTimer = learningTimer;
    },
    resetLearningTimer: state => {
      state.learningTimer = {
        time: 0,
        startedAt: null,
        stoppedAt: null,
      };
    },
    setGlobalTimer: (state, action: PayloadAction<number>) => {
      state.global.time = action.payload * 1000;
    },
    setIsTimerShown: (state, action: PayloadAction<boolean>) => {
      state.isShown = action.payload;
    },
  },
});

export default timerSlice.reducer;
export const {
  startGlobalTimer,
  startAssignmentTimer,
  stopAllTimers,
  stopAssignmentTimer,
  resetAllTimers,
  resetAssignmentTimer,
  tick,
  measurePracticeTime,
  startLearningTimer,
  measureLearningTime,
  stopLearningTimer,
  resetLearningTimer,
  setGlobalTimer,
  setIsTimerShown,
} = timerSlice.actions;

export const getGlobalTime = (state: RootState) => Math.round(state.timer.global.time / 1000);
export const getAssignmentTime = (state: RootState) => Math.round(state.timer.assignment.time / 1000);
export const getLearningTime = (state: RootState) => Math.round(state.timer.learningTimer.time / 1000);
export const isTimerShown = (state: RootState) => state.timer.isShown;
