import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";

import type { IMonth, IPoints } from "pages/Rewards/Rewards";

import { useDateNames } from "utils/hooks/useDateNames";
import { RewardsScroll } from "components";

const COLORS = ["#F2994A", "#EB5757", "#A75FE0", "#17A4C2", "#F3B61A"];

interface IRewards {
  monthRewards: IMonth[];
  pointsRewards: IPoints[];
}

interface Props {
  rewards: IRewards;
  message: string;
}

const RewardsOverview: FC<Props> = ({ rewards, message }) => {
  const { t } = useTranslation(["common", "statistics"]);
  const { monthNames } = useDateNames();

  const setMonthRewards = rewards.monthRewards.filter(reward => reward.text.length > 0);

  return (
    <>
      {message.length === 0 && setMonthRewards.length === 0 && rewards.pointsRewards.length === 0 ? (
        <Container className={"container-mw-md"}>Prozatím není zadána žádná odměna</Container>
      ) : null}

      {message.length > 0 ? (
        <Row>
          <Container className={"container-mw-md"}>
            <div className={"quote mb-16px"}>{message}</div>
          </Container>
        </Row>
      ) : null}

      <Row>
        <Container fluid>
          <>
            {setMonthRewards.length > 0 ? (
              <>
                <h4 className={"mb-3 text-center"}>{t("sectionTitle.monthlySuccessRate", { ns: "statistics" })}</h4>

                <RewardsScroll className={"mb-3"}>
                  {setMonthRewards.map((reward, index) => {
                    return (
                      <div className="rewards__item" key={index}>
                        <div className="rewards__item__inner" style={{ backgroundColor: COLORS[index % 5] }}>
                          {reward.text ?? ""}
                        </div>

                        <div className="rewards__item__label">{monthNames[reward.month]}</div>
                      </div>
                    );
                  })}
                </RewardsScroll>
              </>
            ) : null}

            {rewards.pointsRewards.length > 0 ? (
              <>
                <h4 className={"mb-3 text-center"}>{t("sectionTitle.examPointsRewarded", { ns: "statistics" })}</h4>
                <RewardsScroll>
                  {rewards.pointsRewards.map(function (reward, index) {
                    return (
                      <div className="rewards__item" key={index}>
                        <div className="rewards__item__inner" style={{ backgroundColor: COLORS[index % 5] }}>
                          {reward.text ?? ""}
                        </div>
                        {typeof reward.targetScore === "number" ? (
                          <div className="rewards__item__label">{reward.targetScore}</div>
                        ) : null}
                      </div>
                    );
                  })}
                </RewardsScroll>
              </>
            ) : null}
          </>
        </Container>
      </Row>
    </>
  );
};

export default RewardsOverview;
