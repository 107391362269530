import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CalendarInfo, Loading } from "components";
import { CalendarMonth } from "features";

import { useGetTimetableQuery } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectTimetable, setTimetable, setTests, selectTests } from "store/slices/timetable";
import { getTimetableCreateDate, selectAdmissionsDeadline } from "store/slices/user";

import { formatDate, calculateLastDayOfMonth } from "utils/calendarDateFormatting";
import { processApiError } from "utils/processApiError";

const getCalendarOffset = (firstCalendarDate: Date, today: Date) => {
  /**
   * Ok so the general idea is to calculate a negative margin that's applied to calendar on mobile that hides previous months.
   * So we take the start of the calendar "firstCalendarDate" and today "today" and calculate:
   * - How much months are there between these dates - each month has a header and a weekdays legend, there's also a gap between every month
   * - How much weeks are there in those months - we only hide the months, the current month should be visible, each week has the same height
   * - We substract one and a half of week of offset with some extra pixels - this is whats's visible
   */

  const MS_IN_DAY = 24 * 60 * 60 * 1000;

  const MONTH_HEADER_HEIGHT = 33.2;
  const MONTH_LEGEND_HEIGHT = 39;
  const MONTH_GAP = 12;
  const MONTH_WEEK_HEIGHT = 55.5;
  const ADJUST_OFFSET = -1.5 * MONTH_WEEK_HEIGHT - 8;

  const monthDifference = today.getMonth() - firstCalendarDate.getMonth();
  const dayDifference = Math.ceil((today.getTime() - firstCalendarDate.getTime()) / MS_IN_DAY) - today.getDate();
  const weekDifference =
    Math.ceil((dayDifference + firstCalendarDate.getDay() - 1) / 7) + (monthDifference > 1 ? monthDifference - 1 : 0);

  if (monthDifference > 0) {
    return (
      monthDifference * (MONTH_HEADER_HEIGHT + MONTH_LEGEND_HEIGHT) +
      (monthDifference - 1) * MONTH_GAP +
      weekDifference * MONTH_WEEK_HEIGHT +
      ADJUST_OFFSET
    );
  } else {
    return 0;
  }
};

const CalendarMonthOverview = () => {
  const { t } = useTranslation(["calendar"]);
  const dispatch = useAppDispatch();
  const timetableStoreData = useAppSelector(selectTimetable);
  const tests = useAppSelector(selectTests);
  const dateFrom = useAppSelector(getTimetableCreateDate);
  const admissionsDeadline = useAppSelector(selectAdmissionsDeadline);

  const startDate = typeof dateFrom === "string" ? new Date(dateFrom) : new Date("2023-09-01");
  const lastDate = calculateLastDayOfMonth(admissionsDeadline);
  const monthCount =
    (lastDate.getFullYear() - startDate.getFullYear()) * 12 + (lastDate.getMonth() - startDate.getMonth()) + 1;

  const today = new Date();

  const {
    data: timetableData,
    isLoading,
    isError,
    error: apiError,
    refetch,
    isFetching,
  } = useGetTimetableQuery({ dateFrom: formatDate(startDate), dateTo: formatDate(lastDate) });

  const [isCalendarShown, setCalendarShown] = useState(false);
  const [isLoadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setLoadingData(isLoading || isFetching);
  }, [isLoading, isFetching]);

  const showCalendar = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setCalendarShown(true);
  };

  useEffect(() => {
    refetch().finally(() => {});
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (timetableData === undefined || timetableData.timetable === undefined) return;

    dispatch(setTimetable(timetableData.timetable.timetable));

    const tests: string[] = [];
    for (const testDate of timetableData.testPresence) {
      tests.push(testDate.dateTime.split(" ")[0]);
    }
    dispatch(setTests(tests));
  }, [timetableData]);

  // TODO: calculate calendar offset by using the top position of the current month minus calendar gap and one and a half of the calendar row height
  const CALENDAR_OFFSET = getCalendarOffset(startDate, today);

  return (
    <Container className={"container-mw-md pt-25px page-container"}>
      <CalendarInfo />
      <div className="calendar-grid pt-32px">
        {isLoadingData ? (
          <Loading />
        ) : isError ? (
          <div></div>
        ) : timetableStoreData !== undefined ? (
          <>
            {!isCalendarShown && (
              <div className="calendar-grid__header d-md-none">
                <a href="#" className={"calendar-grid__header__link"} onClick={showCalendar}>
                  {t("displayPreviousDays", { ns: "calendar" })}
                </a>
              </div>
            )}

            <div className="calendar-grid__wrapper">
              <div className="calendar-grid__inner" style={{ marginTop: isCalendarShown ? 0 : CALENDAR_OFFSET * -1 }}>
                {Array.from(new Array(monthCount)).map((_val, index) => {
                  const firstDay = new Date(startDate.getFullYear(), startDate.getMonth() + index, 1);

                  return (
                    <CalendarMonth
                      key={firstDay.toDateString()}
                      date={firstDay}
                      tests={tests}
                      timetableData={timetableStoreData}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Container>
  );
};

export default CalendarMonthOverview;
