import { PracticeResults } from "features";

import { useAppSelector } from "store/hooks";
import { getAllResults } from "store/slices/topicPractice";

const Results = () => {
  const results = useAppSelector(getAllResults);

  return <PracticeResults results={results} />;
};

export default Results;
