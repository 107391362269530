import type { IExercise } from "store/slices/exercise";

import { saveToTimedLocalStorage, getFromTimedLocalStorage } from "utils/localStorage";

import { SESSION_STORAGE_KEY } from "const";

export interface ISessionSavedInStorage extends IExercise {
  spentTime: number;
}

export const saveSessionToStorage = (data: ISessionSavedInStorage, email: string | undefined | null) => {
  if (typeof email !== "string") return;

  const expiration = new Date();
  expiration.setDate(expiration.getDate() + 1);
  expiration.setHours(2, 0, 0, 0);

  const key = encodeEmail(email);
  saveToTimedLocalStorage(key, JSON.stringify(data), expiration.getTime());
};

export const getSessionFromStorage = (email: string | undefined | null) => {
  if (typeof email !== "string") return null;

  const key = encodeEmail(email);
  const dataString = getFromTimedLocalStorage(key);

  if (dataString === null) return null;

  return JSON.parse(dataString) as ISessionSavedInStorage;
};

export const removeSessionFromStorage = (email: string | undefined | null) => {
  if (typeof email !== "string") return null;

  const key = encodeEmail(email);
  window.localStorage.removeItem(key);
};

const encodeEmail = (email: string) => {
  const encodedEmail = btoa(email);
  return `${SESSION_STORAGE_KEY}-${encodedEmail}`;
};
