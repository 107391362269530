import { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { AnswerValidation, OvertimeModal, Tip } from "components";
import { SolutionModal } from "features";

import type { AssignmentPracticeFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getLearningTime,
  measureLearningTime,
  startLearningTimer /*, stopLearningTimer */,
  resetLearningTimer,
} from "store/slices/timer";
import { getTip, setTip } from "store/slices/exercise";
import { setIsSoundMuted } from "store/slices/user";

import { eventEmitter, Events } from "utils/events/EventEmitter";
import { isMobile } from "utils/isMobile";

import { ReactComponent as IconCheck } from "images/icons/check.svg";
import { ReactComponent as IconCross } from "images/icons/close.svg";
import { gtmTaskEvent } from "../../utils/gtmEvents";

interface Props {
  addIcon?: boolean;
  assignment: AssignmentPracticeFragment | undefined | null;
  children: ReactNode;
  index: number;
  answerValidation?: {
    userAnswer: string;
    correctAnswer: string;
    scoreMax: number;
  };
  subject?: string | null;
  timeToLearn?: number;
  tipDisabled?: boolean;
  type: "success" | "warning" | "danger" | undefined;
  userAssignmentId: string;
  onNext?: () => void;
  toggleModals?: (index: number | null) => void;
}

const AnswerExercise: FC<Props> = ({
  answerValidation,
  assignment,
  children,
  index,
  subject,
  timeToLearn,
  type,
  userAssignmentId,
  addIcon = false,
  tipDisabled = false,
  toggleModals,
  onNext,
}) => {
  if (assignment === undefined || assignment === null) return null;

  const dispatch = useAppDispatch();
  const time = useAppSelector(getLearningTime);
  const tip = useAppSelector(getTip(userAssignmentId, index));

  const [isModalVisible, setModalVisibility] = useState(false);
  const [isOvertimeModalVisible, setOvertimeModalVisibility] = useState(false);

  const { t } = useTranslation(["exercise"]);

  useEffect(() => {
    eventEmitter.dispatch(Events.ONBOARDING_UPDATE);
  }, []);

  useEffect(() => {
    if (isModalVisible || timeToLearn === undefined || onNext === undefined) return;

    dispatch(resetLearningTimer());
    dispatch(startLearningTimer());

    const interval = setInterval(() => {
      dispatch(measureLearningTime());
    }, 10000);

    return () => {
      clearInterval(interval);
      dispatch(resetLearningTimer());
    };
  }, [isModalVisible]);

  useEffect(() => {
    if (timeToLearn !== undefined && time >= timeToLearn) {
      setModalVisibility(false);
      setOvertimeModalVisibility(true);
    }
  }, [time]);

  const getIcon = () => {
    return type === "success" ? (
      <IconCheck className={"text-success ms-4px"} />
    ) : (
      <IconCross className={"text-danger ms-4px"} />
    );
  };

  const toggleModal = () => {
    if (toggleModals !== undefined) {
      toggleModals(isModalVisible ? null : index);
    }
    setModalVisibility(visibility => {
      dispatch(setIsSoundMuted(!visibility));
      return !visibility;
    });
  };

  const handleTipClick = (index: number, tip: boolean) => {
    dispatch(setTip({ userAssignmentId, index, tip }));
  };

  useEffect(() => {
    if (isModalVisible) {
      gtmTaskEvent("task-analysis", {
        id: assignment.id,
        subject,
      });
    }
  }, [isModalVisible]);

  return (
    <>
      <Alert variant={type ?? "danger"} className="flex-grow-1">
        <Row className="flex-nowrap">
          <Col xs={"auto"} className={"d-flex align-items-center my-auto flex-grow-1 flex-shrink-1"}>
            {children}
            {addIcon && getIcon()}
          </Col>
          <Col xs={"auto"} className={"ms-auto my-auto d-flex align-items-center"}>
            <Tip type={type} isChecked={tip} index={index} onClick={handleTipClick} isDisabled={tipDisabled} />
            {answerValidation !== undefined ? (
              <AnswerValidation
                assignmentId={assignment.id}
                data={answerValidation}
                index={index}
                onClick={handleTipClick}
                type={type}
                userAssignmentId={userAssignmentId}
              />
            ) : null}
            <Button variant={"select"} className={"btn-shadow ms-4px btn-smaller"} onClick={toggleModal} id="analysis">
              {isMobile() ? t("btn.analysisMobile") : t("btn.analysisDesktop")}
            </Button>
          </Col>
        </Row>
      </Alert>

      <SolutionModal
        isShown={isModalVisible}
        assignment={assignment}
        questionIndex={index}
        answerType={type ?? "danger"}
        toggleModal={toggleModal}
        userAssignmentId={userAssignmentId}
      >
        {children}
      </SolutionModal>

      {isOvertimeModalVisible && onNext !== undefined ? (
        <OvertimeModal userAssignmentId={userAssignmentId} onClose={onNext} />
      ) : null}
    </>
  );
};

export default AnswerExercise;
