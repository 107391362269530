// noinspection InvisibleCharacter

import Button from "react-bootstrap/Button";
import { ExerciseBar } from "../../../../features";
import { useTranslation } from "react-i18next";
import { Alert, ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";

import { ReactComponent as Iconcheck } from "images/icons/check.svg";
import { ReactComponent as IconCamera } from "images/icons/camera.svg";
import { useState } from "react";

const Exercise11 = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  const [checked, setChecked] = useState(false);

  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Nechápu to", value: "1" },
    { name: "Nic moc, ale tuším", value: "2" },
    { name: "Tohle mi jde", value: "3" },
    { name: "Je to má superschopnost", value: "4" },
  ];

  return (
    <div className="exercise">
      <div className="exercise__header">
        <ExerciseBar
          totalTime={600000}
          isTimerStopped={true}
          userAssignmentId={"test"}
          isProgressBarVisible={true}
          onQuit={() => {}}
        />

        <div className="progress-dots">
          <div className="dot success" />
          <div className="dot success" />
          <div className="dot current warning" />
        </div>
      </div>

      <div className="exercise__body pb-40px">
        <p className="py-20px">
          Bod C je vrchol rovnoramenného trojúhelníku ABC se základnou AB. Bod S je střed jednoho ramene tohoto
          trojúhelníku a na přímce q leží jeden z vrcholů A, B.
        </p>
        <p className="py-20px">
          <strong>
            Sestrojte vrcholy A, B trojúhelníku ABC, označte je písmeny a trojúhelník narýsujte. Najděte všechna řešení.
          </strong>
        </p>

        <Alert variant={"warning"}>
          <Row>
            <Col xs={"auto"} className={"my-auto"}>
              <Iconcheck className={"text-warning me-4px"} />
              Částečně vyřešeno
            </Col>
            <Col xs={"auto"} className={"ms-auto my-auto"}>
              <ToggleButton
                value={1}
                variant={"select"}
                className={"btn-shadow btn-emoji"}
                id="toggle-check"
                type="checkbox"
                checked={checked}
                onChange={e => {
                  setChecked(e.currentTarget.checked);
                }}
              >
                {t("btn.guessed", { ns: "exercise" })}
              </ToggleButton>
              <Button variant={"select"} className={"btn-shadow btn-smaller ms-4px"}>
                Rozbor
              </Button>
            </Col>
          </Row>{" "}
        </Alert>

        <Button variant="outline-secondary" className={"d-block btn-mobile-width mx-auto"}>
          <IconCamera className={"me-2"} style={{ marginBlockStart: -2 }} />
          Vyfotit mé řešení ke kontrole učitelům
        </Button>
      </div>

      <div className="exercise__footer exercise__footer--emphasized">
        <div className="mw-sm">
          <p className="text-center">
            <strong>Jak ti tento příklad šel?</strong>
          </p>
          <ButtonGroup className={"btn-group-select btn-shadow mb-12px"}>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="select"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={e => {
                  setRadioValue(e.currentTarget.value);
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <Button className={"w-100 text-uppercase"}>{t("continue")}</Button>
        </div>
      </div>
    </div>
  );
};

export default Exercise11;
