import { Navigate, Route, Routes } from "react-router-dom";

import Dev from "./Dev";
import Exercise from "./Excercise";
import Exercise01 from "./Excercise/Exercise01";
import Exercise02 from "./Excercise/Exercise02";
import Exercise03 from "./Excercise/Exercise03";
import Exercise04 from "./Excercise/Exercise04";
import Exercise05 from "./Excercise/Exercise05";
import Exercise06 from "./Excercise/Exercise06";
import Exercise07 from "./Excercise/Exercise07";
import Exercise08 from "./Excercise/Exercise08";
import Exercise09 from "./Excercise/Exercise09";
import Exercise10 from "./Excercise/Exercise10";
import Exercise11 from "./Excercise/Exercise11";

import Envelope from "./Envelope";
import Envelope01 from "./Envelope/Envelope01";
import Envelope02 from "./Envelope/Envelope02";
import Envelope03 from "./Envelope/Envelope03";

import Dashboard from "./Dashboard";
import Dashboard01 from "./Dashboard/Dashboard01";

import Results from "./Results";
import Results01 from "./Results/Results01";
import Results02 from "./Results/Results02";

import Help from "./Help";

import Statistics from "./Statistics";
import Statistics01 from "./Statistics/Statistics01";

const DevRoutes = () => {
  return (
    <Routes>
      <Route index element={<Dev />} />
      <Route path="/cviceni/*" element={<Exercise />} />
      <Route path="/obalky/*" element={<Envelope />} />
      <Route path="/ulohy/*" element={<Envelope />} />
      <Route path="/vysledky/*" element={<Results />} />
      <Route path="/napoveda/*" element={<Help />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/moje-vitezstvi/*" element={<Statistics />} />

      <Route path="/cviceni/zacatek_procvicovani" element={<Exercise01 />} />
      <Route path="/cviceni/zacatek_procvicovani_2a" element={<Exercise02 />} />
      <Route path="/cviceni/priklad_slovni_uloha_1" element={<Exercise03 />} />
      <Route path="/cviceni/priklad_slovni_uloha_1_overeno" element={<Exercise04 />} />
      <Route path="/cviceni/priklad_slovni_uloha_3a" element={<Exercise05 />} />
      <Route path="/cviceni/priklad_slovni_uloha_2_overeno" element={<Exercise06 />} />
      <Route path="/cviceni/priklad_rysovani_odpovedi" element={<Exercise07 />} />
      <Route path="/cviceni/priklad_rysovani_overeno_1" element={<Exercise11 />} />
      <Route path="/cviceni/priklad_modaly" element={<Exercise08 />} />
      <Route path="/cviceni/vyhodnoceni_1" element={<Exercise09 />} />
      <Route path="/cviceni/vyhodnoceni_2" element={<Exercise10 />} />

      <Route path="/ulohy/ulohy-predmety" element={<Envelope01 />} />
      <Route path="/ulohy/ulozene" element={<Envelope02 />} />
      <Route path="/ulohy/ulozene_strankovani" element={<Envelope03 />} />

      <Route path="/vysledky/tabs" element={<Results01 />} />
      <Route path="/vysledky/tabs-errors" element={<Results02 />} />

      <Route path="/dashboard/boy" element={<Dashboard01 />} />

      <Route path="/moje-vitezstvi/odznaky" element={<Statistics01 />} />

      <Route path="/*" element={<Navigate to="/dev" />} />
    </Routes>
  );
};

export default DevRoutes;
