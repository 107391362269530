import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

export const useQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getSearchParam = (param: string) => {
    return searchParams.get(param);
  };

  const setSearchParam = (paramName: string, paramValue: string) => {
    setSearchParams(currentParams => {
      currentParams.set(paramName, paramValue);
      return currentParams;
    });
  };

  const setEagerQueryParam = (paramName: string, paramValue: string) => {
    setSearchParams({ [paramName]: paramValue });
  };

  const hideSearchParams = () => {
    navigate(pathname, { replace: true });
  };

  const removeSearchParam = (paramName: string) => {
    const param = searchParams.get(paramName);

    if (param !== null) {
      searchParams.delete(paramName);
      setSearchParams(searchParams);
    }
  };

  const removeAllQueryParams = () => {
    setSearchParams({});
  };

  return {
    searchParams,
    getSearchParam,
    setEagerQueryParam,
    setSearchParam,
    hideSearchParams,
    removeAllQueryParams,
    removeSearchParam,
  };
};
