import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AssignmentDetailContent from "./AssignementDetailContent";

const AssignmentDetail = () => {
  const { superId, combinedId, assignmentId } = useParams();
  const [pageId, setPageId] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  if (assignmentId === undefined) {
    navigate("/");
    return null;
  }
  useEffect(() => {
    setPageId(assignmentId);
  }, [assignmentId]);

  if (pageId === undefined) return <div></div>;

  return <AssignmentDetailContent key={pageId} superId={superId} combinedId={combinedId} assignmentId={pageId} />;
};

export default AssignmentDetail;
