import Button from "react-bootstrap/Button";
import Modal, { type ModalProps } from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { TEACHER_EVALUATION_TOKEN_PARAM } from "const";
import type { NoStringIndexType } from "utils/types/NoStringIndexType";
import { useToken } from "./useToken";

interface Props extends NoStringIndexType<ModalProps> {
  onSubmit: () => void;
  userAssignmentId: string;
}

const TeacherEvaluationQrCodeModal: React.FC<Props> = ({
  className,
  onHide,
  onSubmit,
  show,
  size = "lg",
  userAssignmentId,
  ...restProps
}) => {
  const { t } = useTranslation("teacherEvaluation");
  const token = useToken();

  const urlToUpload = `${
    window.location.origin
  }/odeslani-uciteli/${userAssignmentId}?${TEACHER_EVALUATION_TOKEN_PARAM}=${token ?? ""}`;

  return (
    <Modal show={show} size={size} className={`modal--exercise ${className ?? ""}`} onHide={onHide} {...restProps}>
      <Modal.Header closeButton />
      <Modal.Body className="pb-60px pt-20px px-80px">
        <div className={"qr-block mb-20px"}>
          <QRCode value={urlToUpload} className={"qr-block__code"} />
          <div className={"qr-block__inner"}>
            <p>{t("qrDescriptionRow1")}</p>
            <p>{t("qrDescriptionRow2")}</p>
            <p>{t("qrDescriptionRow3")}</p>
          </div>
        </div>

        <div className="d-grid gap-8px">
          <Button variant="primary" className={"w-100 fs-15"} onClick={onSubmit}>
            {t("buttonPhotoConfirm")}
          </Button>
          <Button variant="outline-secondary" className={"w-100 text-uppercase"} onClick={onHide}>
            {t("close")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TeacherEvaluationQrCodeModal;
