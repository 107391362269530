// noinspection InvisibleCharacter

import { Form, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ExerciseBar } from "../../../../features";
import { useTranslation } from "react-i18next";

const Exercise07 = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  return (
    <div className="exercise">
      <div className="exercise__header">
        <ExerciseBar
          totalTime={600000}
          isTimerStopped={true}
          userAssignmentId={"test"}
          isProgressBarVisible={true}
          onQuit={() => {}}
        />

        <div className="progress-dots">
          <div className="dot success" />
          <div className="dot success" />
          <div className="dot current warning" />
        </div>
      </div>

      <div className="exercise__body">
        <p>
          <strong>Jak vypadá Tvoje řešení?</strong>
        </p>

        <div>
          <div className={"question question--select"}>
            <Form>
              <Form.Check type="radio" id={`inline-radio-1`}>
                <Form.Check.Input type="radio" name={"radioGroup"} />
                <Form.Check.Label>
                  <p>Přesně takhle jsem to nakreslil/a, je to obtaženo i pojmenované vrcholy.</p>
                  <Image src={"/images/dev/rysovani.svg"} />
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="radio" id={`inline-radio-2`}>
                <Form.Check.Input type="radio" name={"radioGroup"} />
                <Form.Check.Label>
                  <p>Nakreslil/a jsem jen jeden trojúhelník.</p>
                  <Image src={"/images/dev/rysovani.svg"} />
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="radio" id={`inline-radio-3`}>
                <Form.Check.Input type="radio" name={"radioGroup"} />
                <Form.Check.Label>
                  <p>
                    Žádný trojúhelník jsem nenakreslil/a, správně je nakreslen obraz bodu C ve středové souměrnosti se
                    středem S nebo je sestrojen nevyhovující trojúhelník, v němž je bod S středem základny, nikoli
                    ramene, ale ostatní podmínky zadání jsou splněny a konstrukce je přesná.
                  </p>
                  <Image src={"/images/dev/rysovani.svg"} />
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="radio" id={`inline-radio-4`}>
                <Form.Check.Input type="radio" name={"radioGroup"} />
                <Form.Check.Label>Vůbec tu úlohu nemám.</Form.Check.Label>
              </Form.Check>
            </Form>
          </div>
        </div>
      </div>

      <div className="exercise__footer">
        <div className="mw-sm">
          <Button className={"w-100 text-uppercase"} disabled>
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Exercise07;
