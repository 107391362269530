import { useEffect } from "react";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Container, Form, Row, ToggleButton } from "react-bootstrap";

import { ReactComponent as BgWaveTop } from "images/bg/bckg-branded-top.svg";
import { ReactComponent as OneThumb } from "images/icons/1palec.svg";
import { ReactComponent as TwoThumbs } from "images/icons/2palce.svg";
import { ReactComponent as ThreeThumbs } from "images/icons/3palce.svg";

import { useAppSelector } from "store/hooks";
import type { TAssignmentStatus } from "store/slices/exercise";
import { getGlobalTime } from "store/slices/timer";

import { gtmExerciseFinished } from "utils/gtmEvents";

const calculateNumberOfFinishedAssignments = <T extends { status: TAssignmentStatus }>(results: Record<string, T>) => {
  const entries = Object.values(results);
  const total = entries.length;
  const finished = entries.filter(assignment => assignment.status !== "not_taken").length;
  const correct = entries.filter(assignment => assignment.status === "correct").length;

  return {
    total,
    finished,
    correct,
  };
};

interface Props<T> {
  mood: number;
  moodText: string;
  results: Record<string, T>;
  onMoodSelect: (value: number) => void;
  onMoodTextChange: (text: string) => void;
  onSubmit: () => void;
  submitDisabled?: boolean;
  children?: ReactNode;
}

const PracticeEvaluation = <T extends { status: TAssignmentStatus }>({
  mood,
  moodText,
  results,
  onMoodSelect,
  onMoodTextChange,
  onSubmit,
  submitDisabled = false,
}: Props<T>) => {
  const globalTimeInSeconds = useAppSelector(getGlobalTime);

  const timeInMinutesSpent = Math.round(globalTimeInSeconds / 60);
  const finishedAssignments = calculateNumberOfFinishedAssignments(results);
  const successRation = finishedAssignments.correct / finishedAssignments.total;

  const { t } = useTranslation(["exercise"]);

  const getTextForSelfEvaluation = () => {
    switch (mood) {
      case 1:
        return t("evaluation.selectEmojiVerySad");
      case 2:
        return t("evaluation.selectEmojiSad");
      case 3:
        return t("evaluation.selectEmojiNeutral");
      case 4:
        return t("evaluation.selectEmojiHappy");
      case 5:
        return t("evaluation.selectEmojiVeryHappy");
      default:
        return "";
    }
  };

  useEffect(() => {
    gtmExerciseFinished(
      finishedAssignments.total,
      finishedAssignments.finished,
      finishedAssignments.correct,
      timeInMinutesSpent,
    );
  }, []);

  const getTitle = () => {
    if (successRation > 0.75) {
      return t("header.evaluation-3", { ns: "exercise" });
    }

    if (successRation > 0.5) {
      return t("header.evaluation-2", { ns: "exercise" });
    }

    return t("header.evaluation-1", { ns: "exercise" });
  };

  const getTitleIcon = () => {
    if (successRation > 0.75) {
      return <ThreeThumbs />;
    }

    if (successRation > 0.5) {
      return <TwoThumbs />;
    }

    return <OneThumb />;
  };

  return (
    <main className={"fullpage fullpage--split"}>
      <BgWaveTop className={"wave__top wave__top--pink"} />

      <div className="fullpage__content bg-cyan-200">
        <Container className="container-mw-sm text-center justify-content-center">
          <div className="page px-3 minh-0">
            <div className="page__content my-auto ms-sm-0 pt-50px">
              <div className="text-center mb-20px">{getTitleIcon()}</div>

              <h1 className="h4 mb-20px">{getTitle()}</h1>

              <Row className={"text-center row--border-inside gx-2"}>
                <Col>
                  <p className={"d-flex flex-column mb-0"}>
                    <strong className={"mb-8px"}>{t("label.questions", { ns: "exercise" })}</strong>
                    <span>{`${finishedAssignments.finished}/${finishedAssignments.total}`}</span>
                  </p>
                </Col>
                <Col>
                  <p className={"d-flex flex-column mb-0"}>
                    <strong className={"mb-8px"}>{t("label.time", { ns: "exercise" })}</strong>
                    <span>{`${timeInMinutesSpent} min`}</span>
                  </p>
                </Col>
                <Col>
                  <p className={"d-flex flex-column mb-0"}>
                    <strong className={"mb-8px"}>{t("label.correctAnswers", { ns: "exercise" })}</strong>
                    <span>{`${finishedAssignments.correct}/${finishedAssignments.finished}`}</span>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>

      <div className="fullpage__footer">
        <Container className="container-mw-sm">
          <Row className={"mb-20px"}>
            <Col className={"text-center"}>
              <h3 className={"mb-20px"} id="after-practice">
                {t("header.afterPractice", { ns: "exercise" })}
              </h3>
              <ButtonGroup className={"btn-group-emoji mb-20px"}>
                {[
                  { name: "🥺", value: 1 },
                  { name: "😞", value: 2 },
                  { name: "😐", value: 3 },
                  { name: "😊", value: 4 },
                  { name: "😁", value: 5 },
                ].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-1-${idx}`}
                    type="radio"
                    variant="select"
                    name="radio1"
                    value={radio.value}
                    checked={mood === radio.value}
                    onChange={e => {
                      const value = Number(e.currentTarget.value);
                      if (!isNaN(value)) {
                        onMoodSelect(value);
                      }
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              <p>{getTextForSelfEvaluation()}</p>
              {mood !== null ? (
                <Form.Control
                  as={"textarea"}
                  placeholder={t("info.evaluationBoxPlaceholder", { ns: "exercise" })}
                  onChange={e => {
                    onMoodTextChange(e.target.value);
                  }}
                  value={moodText}
                />
              ) : null}
            </Col>
          </Row>

          {/* Submit button */}
          {mood > 0 ? (
            <Button className={"text-uppercase w-100"} disabled={submitDisabled} onClick={onSubmit}>
              {t("btn.showResults", { ns: "exercise" })}
            </Button>
          ) : null}
        </Container>
      </div>
    </main>
  );
};

export default PracticeEvaluation;
