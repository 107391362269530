import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LayoutSimpleFluid from "./LayoutSimpleFluid";

import TeacherEvaluationUpload from "pages/TeacherEvaluationUpload";

import { NotAuthenticated } from "features";

const TeacherEvaluationRouter = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/*" element={<LayoutSimpleFluid />}>
          <Route index element={<NotAuthenticated />} />
          <Route path="odeslani-uciteli/:userAssignmentId" element={<TeacherEvaluationUpload />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default TeacherEvaluationRouter;
