import { useEffect, useState } from "react";
import type { FC } from "react";
import { Nav, NavItem } from "react-bootstrap";

import { ResultModal } from "components";

import type { IAssignmentResult } from "store/slices/topicPractice";

import { ReactComponent as IconCheck } from "images/icons/check.svg";
import { ReactComponent as IconWarning } from "images/icons/check-partly.svg";
import { ReactComponent as IconError } from "images/icons/close.svg";

import { removeHTML, containsLatex } from "utils/removeHTML";
import { gtmTaskEvent } from "../../utils/gtmEvents";

interface Props {
  result: IAssignmentResult;
  type: "correct" | "partial" | "incorrect" | "not_taken";
  index: number;
}

const ResultBox: FC<Props> = ({ result, type, index }) => {
  const [isResultModalOpen, setResultModalOpen] = useState(false);

  const getClassNameByType = () => {
    switch (type) {
      case "correct":
        return "text-success";
      case "partial":
        return "text-warning";
      default:
        return "text-danger";
    }
  };

  const getIconByType = () => {
    switch (type) {
      case "correct":
        return <IconCheck className={"me-12px"} />;
      case "partial":
        return <IconWarning className={"me-12px"} />;
      case "incorrect":
        return <IconError className={"me-12px"} />;
    }
  };

  const getTitle = () => {
    const topicName = result.assignment.topic?.name ?? "";

    if (typeof topicName === "string") {
      return `${index + 1}. ${topicName}`;
    }

    return `${index + 1}.`;
  };

  const getContent = () => {
    const text =
      result.assignment.assignment?.assignment?.questions[0]?.heading ??
      result.assignment.assignment?.assignment?.assignmentText ??
      "";

    if (containsLatex(text)) return "";

    return removeHTML(text);
  };

  useEffect(() => {
    if (isResultModalOpen) {
      gtmTaskEvent("task-post-analysis", {
        id: result.assignment.id,
        subject: result.assignment.subject?.appName,
        result: result.status,
      });
    }
  }, [isResultModalOpen]);

  return (
    <>
      <NavItem>
        <Nav.Link
          className={"nav-link--with-arrow fw-normal flex-column"}
          onClick={() => {
            setResultModalOpen(true);
          }}
        >
          <p className={getClassNameByType()}>
            {getIconByType()}
            {/* TODO: get topic name */}
            <strong>{getTitle()}</strong>
          </p>
          <p className={"line-clamp-2"}>{getContent()}</p>
        </Nav.Link>
      </NavItem>

      <ResultModal
        isOpen={isResultModalOpen}
        onClose={() => {
          setResultModalOpen(false);
        }}
        result={result}
      />
    </>
  );
};

export default ResultBox;
