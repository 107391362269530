import { useTranslation } from "react-i18next";
import { Breadcrumb, Container, Nav, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconArrowBack from "../../../components/Icons/IconArrowBack/IconArrowBack";
import { LinkContainer } from "react-router-bootstrap";

const Dashboard = () => {
  const { t } = useTranslation(["common", "settings"]);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-sm"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/dev"}>
                <IconArrowBack />
                {t("back")}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-0"}>Dashboard</h1>
        </Container>
      </div>

      <Container className={"container-mw-sm page-container pt-25px pt-sm-45px"}>
        <Nav className={"nav-menu"}>
          <NavItem>
            <LinkContainer to="/dev/dashboard/boy">
              <Nav.Link className="nav-link--with-arrow">dashboard - boy</Nav.Link>
            </LinkContainer>
          </NavItem>
        </Nav>
      </Container>
    </>
  );
};

export default Dashboard;
