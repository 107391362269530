import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectUserAddressing, setWelcomed, selectFirstPreferredSchool } from "store/slices/user";

import { processApiError } from "utils/processApiError";

import { useSetWelcomedMutation } from "api/generated";

const WelcomeNewUser = () => {
  const { t } = useTranslation(["onboarding"]);
  const preferredSchool = useAppSelector(selectFirstPreferredSchool);
  const userVocative = useAppSelector(selectUserAddressing);
  const dispatch = useAppDispatch();

  const [trigger, { error: apiMutationError }] = useSetWelcomedMutation();
  const [isShown, setShown] = useState(true);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const handleClose = () => {
    setLoading(true);
    trigger()
      .then(response => {
        if ("data" in response) {
          dispatch(setWelcomed());
          setShown(false);
        } else {
          console.error(response);
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  const translationRoot =
    preferredSchool === null
      ? "welcomeModal.noSchool"
      : typeof preferredSchool.pointsForAcceptance === "number" && preferredSchool.pointsForAcceptance > 0
      ? "welcomeModal.withPoints"
      : "welcomeModal.withoutPoints";

  return (
    <Modal show={isShown} onHide={handleClose} className={"modal--welcome"}>
      <Modal.Header closeButton />
      <Modal.Body>
        <h4 className={"text-center mb-12px"}>{t(`${translationRoot}.heading`, { name: userVocative })}</h4>
        <p
          className={"text-center mt-20px mb-20px quote quote--tight"}
          dangerouslySetInnerHTML={{
            __html: t(`${translationRoot}.content`, {
              schoolName: preferredSchool?.name ?? "",
              count: preferredSchool?.pointsForAcceptance ?? 0,
            }),
          }}
        ></p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" className={"w-100 text-uppercase"} onClick={handleClose} disabled={isLoading}>
          {t(`${translationRoot}.close`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeNewUser;
