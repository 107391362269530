import { useEffect, useState } from "react";
import type { FC } from "react";

import { WelcomeBadgeModal } from "components";

import { badgesConfiguration } from "pages/Statistics/Tabs/badgesConfiguration";
import type { IBadgeResult } from "pages/Statistics/Tabs/Badges";

import type { CheckAchievementsFragment } from "api/generated";

interface Props {
  data: CheckAchievementsFragment | undefined;
}

const CheckWelcomeBadges: FC<Props> = ({ data }) => {
  if (data === undefined) return null;

  const [shownBadgeIndex, setShownBadge] = useState(0);
  const [gainedBadges, setGainedBadges] = useState<IBadgeResult[]>([]);

  useEffect(() => {
    if (data === undefined) return;

    const badges: IBadgeResult[] = [];

    for (const achievement of data.achievementsNew) {
      const type = achievement.type === "sessions" ? `sessions-${achievement.goal}` : achievement.type;
      const config = badgesConfiguration.find(config => config.type === type);
      if (config === undefined) continue;

      const result: IBadgeResult = {
        category: config.category,
        icon: config.iconGained,
        iconGained: config.iconGained,
        iconGray: config.iconGray,
        isEarned: true,
        modalTextEarned: config.modalTextEarned,
        modalTextNotEarned: config.modalTextNotEarned,
        title: config.title,
        type: config.type,
        count: config.count,
        dateEarned: new Date(achievement.dateAchieved),
        key: config.key,
      };

      badges.push(result);
    }

    setGainedBadges(badges);
  }, [data]);

  const goNext = () => {
    setShownBadge(index => {
      return shownBadgeIndex + 1 >= gainedBadges.length ? 0 : index + 1;
    });
  };

  const goPrev = () => {
    setShownBadge(index => {
      return shownBadgeIndex <= 0 ? gainedBadges.length - 1 : index - 1;
    });
  };

  const onClose = () => {
    setGainedBadges([]);
  };

  if (data === undefined || gainedBadges.length === 0) return null;

  return (
    <WelcomeBadgeModal
      badge={gainedBadges[shownBadgeIndex]}
      isNavShown={gainedBadges.length > 1}
      isShown={true}
      goNext={goNext}
      goPrev={goPrev}
      onClose={onClose}
    />
  );
};

export default CheckWelcomeBadges;
