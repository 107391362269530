import { useRef, useState, useEffect } from "react";
import type { CSSProperties } from "react";
import Button from "react-bootstrap/Button";

import { INTERVAL_TO_SHOW_ONBOARDING_TOOLTIP_BUTTON } from "const";

export interface IOnboardingTooltip {
  key: string;
  beforeTitleText?: string;
  title: string;
  description: JSX.Element | JSX.Element[];
  buttonText: string;
  elementId?: string;
  placement?: "bottom" | "top";
}

const HIGHLIGHT_MIN_SIZE = 50;
const HIGHLIGHT_SPACING = 5;
const ARROW_SIZE_ADJUSTED = 16;
const ARROW_SIZE = ARROW_SIZE_ADJUSTED / Math.sqrt(2);
const TOOLTIP_HIGHLIGHT_OFFSET = 5;

const getTooltipStyles = (highlightedElement: HTMLElement | null, placement?: "bottom" | "top"): CSSProperties => {
  const TOOLTIP_WIDTH = highlightedElement !== null ? 300 : 340;
  const DEFAULT_OFFSET_TOP = "35%";
  const MINIMAL_HORIZONTAL_SPACING = 5;

  if (highlightedElement === null) {
    return {
      top: DEFAULT_OFFSET_TOP,
      margin: "auto",
      left: 0,
      right: 0,
      width: TOOLTIP_WIDTH,
    };
  }

  const highlightedElementRect = highlightedElement.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  let left = highlightedElementRect.left + highlightedElement.offsetWidth / 2 - TOOLTIP_WIDTH / 2;

  if (left < MINIMAL_HORIZONTAL_SPACING) {
    left = MINIMAL_HORIZONTAL_SPACING;
  }

  if (left + TOOLTIP_WIDTH + MINIMAL_HORIZONTAL_SPACING > screen.width) {
    left = screen.width - TOOLTIP_WIDTH - MINIMAL_HORIZONTAL_SPACING;
  }

  let top;

  if (placement !== null && typeof placement === "string" && placement === "top") {
    const tooltipHeight = document.getElementById("onboardingTooltip")?.getBoundingClientRect().height;
    const TOOLTIP_HEIGHT = tooltipHeight != null && !isNaN(Number(tooltipHeight.toString())) ? tooltipHeight : 0;

    // Place onboarding tooltip on top
    top =
      highlightedElementRect.top -
      bodyRect.top -
      (TOOLTIP_HEIGHT + TOOLTIP_HIGHLIGHT_OFFSET * 2 + ARROW_SIZE_ADJUSTED / 2);
  } else {
    // If no placement is provided or placement is not "top" position tooltip by default on the bottom of the highlighted element
    if (highlightedElement.offsetHeight + HIGHLIGHT_SPACING * 2 >= HIGHLIGHT_MIN_SIZE) {
      top =
        highlightedElementRect.top -
        bodyRect.top +
        highlightedElement.offsetHeight +
        ARROW_SIZE_ADJUSTED / 2 +
        TOOLTIP_HIGHLIGHT_OFFSET * 2;
    } else {
      top =
        highlightedElementRect.top -
        bodyRect.top +
        highlightedElement.offsetHeight +
        (HIGHLIGHT_MIN_SIZE - highlightedElement.offsetHeight) / 2 +
        ARROW_SIZE_ADJUSTED / 2 +
        TOOLTIP_HIGHLIGHT_OFFSET;
    }
  }

  return {
    top,
    left,
    width: TOOLTIP_WIDTH,
  };
};

const getArrowStyles = (highlightedElement: HTMLElement, placement?: "top" | "bottom"): CSSProperties => {
  const highlightedElementRect = highlightedElement.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  let top;

  if (placement !== null && typeof placement === "string" && placement === "top") {
    // Place onboarding tooltip arrow on top
    top =
      highlightedElementRect.top -
      bodyRect.top -
      (ARROW_SIZE_ADJUSTED + TOOLTIP_HIGHLIGHT_OFFSET * 2 - (ARROW_SIZE_ADJUSTED - ARROW_SIZE) / 2);
  } else {
    // If no placement is provided or placement is not "top" position tooltip arrow by default on the bottom of the highlighted element
    if (highlightedElement.offsetHeight + HIGHLIGHT_SPACING * 2 >= HIGHLIGHT_MIN_SIZE) {
      top =
        highlightedElementRect.top -
        bodyRect.top +
        highlightedElement.offsetHeight +
        TOOLTIP_HIGHLIGHT_OFFSET * 2 +
        (ARROW_SIZE_ADJUSTED - ARROW_SIZE) / 2;
    } else {
      top =
        highlightedElementRect.top -
        bodyRect.top +
        highlightedElement.offsetHeight +
        (HIGHLIGHT_MIN_SIZE - highlightedElement.offsetHeight) / 2 +
        TOOLTIP_HIGHLIGHT_OFFSET +
        (ARROW_SIZE_ADJUSTED - ARROW_SIZE) / 2;
    }
  }

  const arrowStyles = {
    top,
    left: `${
      highlightedElementRect.left +
      highlightedElement.offsetWidth / 2 -
      ARROW_SIZE_ADJUSTED / 2 +
      (ARROW_SIZE_ADJUSTED - ARROW_SIZE) / 2
    }px`,
    width: `${ARROW_SIZE}px`,
    height: `${ARROW_SIZE}px`,
  };

  return arrowStyles;
};

const getHighlightStyles = (highlightedElement: HTMLElement): CSSProperties => {
  const highlightedElementRect = highlightedElement.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  const highlightStyles = {
    top: `${
      highlightedElement.offsetHeight + HIGHLIGHT_SPACING * 2 < HIGHLIGHT_MIN_SIZE
        ? highlightedElementRect.top - bodyRect.top - (HIGHLIGHT_MIN_SIZE - highlightedElement.offsetHeight) / 2
        : highlightedElementRect.top - bodyRect.top - HIGHLIGHT_SPACING
    }px`,
    left: `${
      highlightedElement.offsetWidth + HIGHLIGHT_SPACING * 2 < HIGHLIGHT_MIN_SIZE
        ? highlightedElementRect.left - (HIGHLIGHT_MIN_SIZE - highlightedElement.offsetWidth) / 2
        : highlightedElementRect.left - HIGHLIGHT_SPACING
    }px`,
    width: `${
      highlightedElement.offsetWidth + HIGHLIGHT_SPACING * 2 < HIGHLIGHT_MIN_SIZE
        ? HIGHLIGHT_MIN_SIZE
        : highlightedElement.offsetWidth + HIGHLIGHT_SPACING * 2
    }px`,
    height: `${
      highlightedElement.offsetHeight + HIGHLIGHT_SPACING * 2 < HIGHLIGHT_MIN_SIZE
        ? HIGHLIGHT_MIN_SIZE
        : highlightedElement.offsetHeight + HIGHLIGHT_SPACING * 2
    }px`,
  };

  return highlightStyles;
};

const updateStyling = (highlightedElement: HTMLElement | null, placement?: "top" | "bottom") => {
  return {
    modal: getTooltipStyles(highlightedElement, placement),
    arrow: highlightedElement === null ? null : getArrowStyles(highlightedElement, placement),
    highlight: highlightedElement === null ? null : getHighlightStyles(highlightedElement),
  };
};

interface Props {
  data: IOnboardingTooltip | null;
  highlightedElement: HTMLElement | null;
  onConfirm: () => void;
}

const isElementVisible = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const viewportHeight = document.documentElement.clientHeight;

  return rect.top >= 0 && rect.bottom <= viewportHeight;
};

const OnboardingTooltip = ({ data, highlightedElement, onConfirm }: Props) => {
  if (data === null) return null;

  const [positionStyles, setPositionStyles] = useState(updateStyling(highlightedElement, data?.placement));
  const [isButtonVisible, setButtonVisibility] = useState(false);
  const tooltip = useRef<HTMLDivElement | null>(null);
  const tooltipHighlight = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tooltip.current !== null) {
      if (highlightedElement === null) {
        if (!isElementVisible(tooltip.current)) {
          tooltip.current.scrollIntoView({
            behavior: "smooth",
            block: data?.placement === "top" ? "end" : "start",
          });
        }
      } else {
        let scrolltoElement;

        if (tooltip.current?.getBoundingClientRect()?.top > highlightedElement?.getBoundingClientRect()?.top) {
          // Tooltip below highlighted element
          if (!isElementVisible(tooltip.current)) {
            scrolltoElement = tooltipHighlight.current !== null ? tooltipHighlight.current : highlightedElement;
          }
        } else {
          // Tooltip above highlighted element
          if (!isElementVisible(tooltip.current)) {
            scrolltoElement = tooltip.current;
          }
        }

        if (scrolltoElement !== null && typeof scrolltoElement !== "undefined") {
          scrolltoElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }

    const timer = setTimeout(() => {
      setButtonVisibility(true);
    }, INTERVAL_TO_SHOW_ONBOARDING_TOOLTIP_BUTTON);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const updatePosition = () => {
    setPositionStyles(updateStyling(highlightedElement, data?.placement));
  };

  useEffect(() => {
    setPositionStyles(updateStyling(highlightedElement, data?.placement));
    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, [highlightedElement]);

  return (
    <div className={"tooltip-wrapper"} key={data.key}>
      <div className={"tooltip-backdrop"} />
      {highlightedElement !== null && (
        <div
          className={"tooltip-carret"}
          style={{
            ...positionStyles.arrow,
          }}
        ></div>
      )}
      <div
        className={`tooltip tooltip--onboarding show ${
          highlightedElement !== null ? "py-28px px-24px" : "py-40px px-32px text-center"
        }`}
        style={positionStyles?.modal != null ? { ...positionStyles.modal } : {}}
        id={"onboardingTooltip"}
        ref={tooltip}
      >
        <div className="tooltip__content">
          {data.beforeTitleText === undefined ? null : <p>{data.beforeTitleText}</p>}
          <p>
            <strong>{data.title}</strong>
          </p>
          {data.description}
        </div>
        {isButtonVisible ? (
          <Button className={"mt-20px w-100"} onClick={onConfirm}>
            {data.buttonText}
          </Button>
        ) : (
          <Button className={"mt-20px w-100"} onClick={onConfirm} disabled>
            {data.buttonText}
          </Button>
        )}
      </div>
      <div
        className="tooltip-highlight"
        style={{
          ...positionStyles.highlight,
        }}
        ref={tooltipHighlight}
      />
    </div>
  );
};

export default OnboardingTooltip;
