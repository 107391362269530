import {
  Alert,
  Badge,
  ButtonGroup,
  Col,
  Container,
  Form,
  Image,
  Nav,
  NavItem,
  Row,
  Tab,
  Table,
  ToggleButton,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { LinkContainer } from "react-router-bootstrap";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { ReactComponent as IconCheck } from "images/icons/check.svg";

import IconBookmark from "images/icons/bookmark.svg";

const Envelope02 = () => {
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Nechápu to", value: "1" },
    { name: "Nic moc, ale tuším", value: "2" },
    { name: "Tohle mi jde", value: "3" },
    { name: "Je to má superschopnost", value: "4" },
  ];

  const [showModalExercise, setShowModalExercise] = useState(false);
  const [showModalExplanation, setShowModalExplanation] = useState(false);

  const handleExerciseModalClose = () => {
    setShowModalExercise(false);
  };
  const handleExerciseModalShow = () => {
    setShowModalExercise(true);
  };

  const handleExplanationModalClose = () => {
    setShowModalExplanation(false);
  };
  const handleExplanationModalShow = () => {
    setShowModalExplanation(true);
    setShowModalExercise(false);
  };

  const handleExplanationModalBack = () => {
    setShowModalExplanation(false);
    setShowModalExercise(true);
  };

  return (
    <>
      <div className={"page-header page-header--has-tabs page-header--no-tabs-desktop"}>
        <Container className={"container-mw-md"}>
          <h1>Projdi si své výsledky</h1>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Tab.Container defaultActiveKey={"first"}>
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="first">
                Chybné odpovědi{" "}
                <Badge bg={""} className={"badge-circular"}>
                  0
                </Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                Správné odpovědi{" "}
                <Badge bg={"success"} className={"badge-circular"}>
                  2
                </Badge>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <p className="text-center mx-5">Tak se na to musí, žádná špatná odpověď, jsi frajer! 👊</p>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Nav className={"nav-menu"}>
                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"} onClick={handleExerciseModalShow}>
                    <p className={"text-success"}>
                      <IconCheck className={"me-12px"} />
                      <strong>2. Rovnice</strong>
                    </p>
                    <p>Vypočítejte níže uvedenou rovnici:</p>
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"} onClick={handleExerciseModalShow}>
                    <p className={"text-success"}>
                      <IconCheck className={"me-12px"} />
                      <strong>4. Text</strong>
                    </p>
                    <p>Seřaďte jednotlivé části textu (A-F) tak, aby byla dodržena textová návaznost.</p>
                  </Nav.Link>
                </NavItem>
              </Nav>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <hr className={"thin yellow"} />

        <Row>
          <Col className="text-center">
            <LinkContainer to={"/dev/vysledky"}>
              <Button className="btn-mobile-width">Zavřít výsledky a ukončit procvičování</Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>

      {/* START : MODAL WITH RESULT DETAIL */}
      <Modal show={showModalExercise} onHide={handleExerciseModalClose} className={"modal--exercise"} size={"lg"}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="exercise">
            <div className="exercise__body">
              <p className="py-20px">
                Ve škole, která má v každém ročníku dvě třídy (A,B), proběhla soutěž ve sběru papíru. V tabulkách jsou
                uvedeny některé údaje z této soutěže.
              </p>

              <Row className={"gx-2"}>
                <Col xs={7}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th colSpan={3}>První ročník</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.A</td>
                        <td>1.B</td>
                        <td>celkem</td>
                      </tr>
                      <tr>
                        <td>600 kg</td>
                        <td>600 kg</td>
                        <td>1 200 kg</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs={5}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th colSpan={2}>Druhý ročník</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2.A</td>
                        <td>2.B</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>

                <p className="mb-20px">
                  <strong>Přiřaďte ke každé úloze (15.1 - 15.3) odpovídající výsledek (A-F).</strong>
                </p>
              </Row>
              <div>
                <div className={"question"}>
                  <p>Třída 2.A nasbírala o 25% méně papíru než třída 1.A.</p>

                  <Form.Group>
                    <Form.Label>Kolik kg papíru nasbírala třída 2.A?</Form.Label>
                    <Alert variant={"success"}>
                      <Row>
                        <Col xs={"auto"} className={"my-auto"}>
                          450 kg
                          <IconCheck className={"text-success ms-4px"} />
                        </Col>
                        <Col xs={"auto"} className={"ms-auto my-auto"}>
                          <ToggleButton
                            value={1}
                            variant={"select"}
                            className={"btn-shadow btn-emoji"}
                            id="toggle-check"
                            type="checkbox"
                            checked={checked}
                            onChange={e => {
                              setChecked(e.currentTarget.checked);
                            }}
                          >
                            🤷‍♀️
                          </ToggleButton>
                          <Button
                            variant={"select"}
                            className={"btn-shadow ms-4px"}
                            onClick={handleExplanationModalShow}
                          >
                            Rozbor
                          </Button>
                        </Col>
                      </Row>
                    </Alert>
                  </Form.Group>
                </div>

                <div className={"question"}>
                  <p>Třída 1. B nasbírala o 20% více papíru než třída 2.B.</p>
                  <Form.Group>
                    <Form.Label>Kolik kg papíru nasbírala třída 2.B?</Form.Label>
                    <Alert variant={"success"}>
                      <Row>
                        <Col xs={"auto"} className={"my-auto"}>
                          500 kg
                          <IconCheck className={"text-success ms-4px"} />
                        </Col>
                        <Col xs={"auto"} className={"ms-auto my-auto"}>
                          <ToggleButton
                            value={1}
                            variant={"select"}
                            className={"btn-shadow btn-emoji"}
                            id="toggle-check"
                            type="checkbox"
                            checked={checked}
                            onChange={e => {
                              setChecked(e.currentTarget.checked);
                            }}
                          >
                            🤷‍♀️
                          </ToggleButton>
                          <Button
                            variant={"select"}
                            className={"btn-shadow ms-4px"}
                            onClick={handleExplanationModalShow}
                          >
                            Rozbor
                          </Button>
                        </Col>
                      </Row>
                    </Alert>
                  </Form.Group>
                </div>

                <div className={"question"}>
                  <p>Ze všech žáků prvního ročníku nasbíraly dívky o 50% více papíru než chlapci.</p>
                  <Form.Group>
                    <Form.Label>Kolik kg papíru nasbírala třída 2.B?</Form.Label>
                    <Alert variant={"warning"}>
                      <Row>
                        <Col xs={"auto"} className={"my-auto"}>
                          480 kg
                          <IconCheck className={"text-warning ms-4px"} />
                        </Col>
                        <Col xs={"auto"} className={"ms-auto my-auto"}>
                          <ToggleButton
                            value={1}
                            variant={"select"}
                            className={"btn-shadow btn-emoji"}
                            id="toggle-check"
                            type="checkbox"
                            checked={checked}
                            onChange={e => {
                              setChecked(e.currentTarget.checked);
                            }}
                          >
                            🤷‍♀️
                          </ToggleButton>
                          <Button
                            variant={"select"}
                            className={"btn-shadow ms-4px"}
                            onClick={handleExplanationModalShow}
                          >
                            Rozbor
                          </Button>
                        </Col>
                      </Row>{" "}
                    </Alert>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={"modal-footer--yellow-bg"}>
          <p className="text-center w-100">
            <strong>Jak ti tento příklad šel?</strong>
          </p>
          <div className="modal-footer-btn-wrapper">
            <ButtonGroup className={"btn-group-select btn-shadow mb-12px"}>
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant="select"
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={e => {
                    setRadioValue(e.currentTarget.value);
                  }}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <div className="modal-footer-btn-stretch">
              <Button variant={"outline-secondary-darker"}>
                <Image className={"me-2"} src={IconBookmark} />
                Uložit na později
              </Button>
              <Button variant={"outline-secondary-darker"} onClick={handleExerciseModalClose}>
                Zavřít
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* END : MODAL WITH RESULT DETAIL */}

      {/* START : MODAL WITH RESULT EXPLANATION */}
      <Modal show={showModalExplanation} onHide={handleExerciseModalClose} className={"modal--exercise"} size={"lg"}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="exercise">
            <div className="exercise__body">
              <p>
                <a onClick={handleExplanationModalBack} href={"#"}>
                  Zobrazit zadání
                </a>
              </p>

              <p>Ze všech žáků prvního ročníku nasbíraly dívky o 50% více papíru než chlapci.</p>
              <Form.Label>Kolik kg papíru nasbírala třída 2.B?</Form.Label>
              <Alert variant={"success"}>
                <Row>
                  <Col xs={"auto"} className={"my-auto"}>
                    480 kg
                    <IconCheck className={"text-success ms-4px"} />
                  </Col>
                </Row>
              </Alert>

              <hr className={"in-modal thin gray-200"} />

              <div>
                <p>
                  <strong>Postup řešení</strong>
                </p>

                <div className={"video-wrapper"}>
                  <iframe
                    src="https://player.vimeo.com/video/843884564?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>

                <div className="mt-3">
                  <p>
                    Dívky z prvního ročníku nasbíraly{" "}
                    <strong>
                      150 % toho, co chlapci z prvního ročníku a všichni žáci z prvního ročníku nasbírali 250 % toho, co
                      chlapci z prvního ročníku:
                    </strong>
                  </p>
                  <p>
                    <strong>Všichni žáci z prvního ročníku</strong>
                    <br /> 250 % … 1200 kg
                  </p>
                  <p className="mb-0">
                    <strong>Chlapci z prvního ročníku</strong>
                    <br /> 100 % … <strong className="text-decoration-underline">480 kg</strong> (1200 ∶ 2,5 = 480)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer-btn-wrapper">
            <div className="modal-footer-btn-stretch">
              <Button variant={"outline-secondary-darker"}>
                <Image className={"me-2"} src={IconBookmark} />
                Uložit na později
              </Button>
              <Button variant={"outline-secondary-darker"} onClick={handleExplanationModalClose}>
                Zavřít
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* END : MODAL WITH RESULT EXPLANATION */}
    </>
  );
};

export default Envelope02;
