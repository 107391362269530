import type { IOnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip";

export const tooltipTexts: IOnboardingTooltip[] = [
  {
    key: "time",
    title: "",
    description: (
      <p>
        Počet úloh je určen tak, aby byl u každé úlohy čas podívat se na videorozbor řešení. Určitě toho využij a na
        videorozbory se v rámci tréninku dívej. 👀
        <br />
        Čas tréninku je navíc orientační. Vyřešíš to rychleji? Super pro tebe – máš splněno. Potřebuješ více času?
        Klidně pokračuj, trénink se po uplynutí času nevypne. 🧘
      </p>
    ),
    buttonText: "Zobrazit další tip",
    elementId: "timer",
  },
];
