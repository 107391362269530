import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import type { TagManagerArgs } from "react-gtm-module";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import { initializeApp, getApp } from "firebase/app";
import { getStorage } from "firebase/storage";

import * as Sentry from "@sentry/react";

import i18n from "lang";

import App from "./App";

import { store } from "./store/store";

/*
  FIREBASE
*/

const initializeFirebase = () => {
  try {
    getApp();
  } catch (error) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };

    const app = initializeApp(firebaseConfig);
    getStorage(app);
  }
};

initializeFirebase();

/*
  GTM
*/

if (process.env.REACT_APP_ENV === "production") {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: "GTM-MV9PHVQP",
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  };

  TagManager.initialize(tagManagerArgs);
}

/*
  SENTRY
*/

Sentry.init({
  enabled: process.env.REACT_APP_ENV !== "local",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: process.env.REACT_APP_ENV === "local" ? 0 : 1.0,
  replaysSessionSampleRate: process.env.REACT_APP_ENV !== "production" ? 1 : 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_GIT_SHA,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </StrictMode>,
);
