import { useTranslation } from "react-i18next";
import { Breadcrumb, Container, Nav, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconArrowBack from "../../../components/Icons/IconArrowBack/IconArrowBack";
import { LinkContainer } from "react-router-bootstrap";

const Exercise = () => {
  const { t } = useTranslation(["common", "settings"]);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-sm"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/dev"}>
                <IconArrowBack />
                {t("back")}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-0"}>Cviceni</h1>
        </Container>
      </div>

      <Container className={"container-mw-sm page-container pt-25px pt-sm-45px"}>
        <Nav className={"nav-menu"}>
          <NavItem>
            <LinkContainer to="/dev/cviceni/zacatek_procvicovani">
              <Nav.Link className={"nav-link--with-arrow"}>zacatek procvicovani</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/zacatek_procvicovani_2a">
              <Nav.Link className={"nav-link--with-arrow"}>zacatek procvicovani 2a</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/priklad_slovni_uloha_1">
              <Nav.Link className={"nav-link--with-arrow"}>priklad - slovni uloha 1</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/priklad_slovni_uloha_1_overeno">
              <Nav.Link className={"nav-link--with-arrow"}>priklad - slovni uloha 1 overeno 2</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/priklad_slovni_uloha_3a">
              <Nav.Link className={"nav-link--with-arrow"}>priklad - slovni uloha 3a</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/priklad_slovni_uloha_2_overeno">
              <Nav.Link className={"nav-link--with-arrow"}>priklad - slovni uloha 2 - overeno</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/priklad_rysovani_odpovedi">
              <Nav.Link className={"nav-link--with-arrow"}>priklad - rysovani - odpovedi</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/priklad_rysovani_overeno_1">
              <Nav.Link className={"nav-link--with-arrow"}>priklad - rysovani - overeno 1</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/priklad_modaly">
              <Nav.Link className={"nav-link--with-arrow"}>priklad - modaly</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/vyhodnoceni_1">
              <Nav.Link className={"nav-link--with-arrow"}>vyhodnoceni 1</Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/dev/cviceni/vyhodnoceni_2">
              <Nav.Link className={"nav-link--with-arrow"}>vyhodnoceni 2</Nav.Link>
            </LinkContainer>
          </NavItem>
        </Nav>
      </Container>
    </>
  );
};

export default Exercise;
