import { useCallback, useEffect, useRef, useState } from "react";
import type { FC, PropsWithChildren } from "react";

import { useAppSelector } from "store/hooks";
import { selectSound, selectIsSoundMuted } from "store/slices/user";
import { ClassroomAmbienceContext } from "./context";

const ClassroomAmbienceProvider: FC<PropsWithChildren> = ({ children }) => {
  const withSound = useAppSelector(selectSound);
  const isSoundMuted = useAppSelector(selectIsSoundMuted);
  const [isSoundOn, setIsSoundOn] = useState<boolean>(false);

  const ref = useRef<HTMLAudioElement | null>(null);

  const pause = useCallback(() => {
    if (ref.current === null) return;
    ref.current.pause();
    setIsSoundOn(false);
  }, [ref]);

  const play = useCallback(() => {
    if (ref.current === null) return;
    ref.current.play().catch(console.error);
    setIsSoundOn(true);
  }, [ref]);

  const toggleSound = useCallback(() => {
    if (isSoundOn) pause();
    else play();
  }, [isSoundOn, pause, play]);

  const handleToggleSound = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // Safari requires a user interaction (like a click or tap) before it will play audio
      // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/

      event.stopPropagation();

      if (ref.current === null) return;

      ref.current.volume = 1;
      ref.current.play().catch(console.error);

      toggleSound();
    },
    [toggleSound, ref],
  );

  const startDelayedAudio = useCallback(() => {
    document.removeEventListener("click", startDelayedAudio);

    if (withSound !== true || ref.current === null || !ref.current.paused) return;

    ref.current.play().catch(console.error);
  }, [withSound, ref]);

  useEffect(() => {
    document.addEventListener("click", startDelayedAudio);

    return () => {
      document.removeEventListener("click", startDelayedAudio);
    };
  }, [startDelayedAudio]);

  useEffect(() => {
    if (withSound === true) play();
    else pause();
  }, [pause, play, withSound]);

  useEffect(() => {
    if (ref.current === null) return;
    ref.current.muted = isSoundMuted;
  }, [isSoundMuted]);

  return (
    <ClassroomAmbienceContext.Provider value={{ isSoundOn, onToggleSound: handleToggleSound }}>
      {children}

      <audio ref={ref} style={{ display: "none" }} loop>
        <source src="/audio/sum_tridy.mp4" type="audio/mp4" />
      </audio>
    </ClassroomAmbienceContext.Provider>
  );
};

export default ClassroomAmbienceProvider;
