import type { FC } from "react";

interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
}

const AlmostFull: FC<Props> = ({ foregroundColor, backgroundColor }) => {
  return (
    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      {backgroundColor !== null ? (
        <path
          d="M0.927734 55.6166L1.55538 61.9451L8.06801 66.0079L25.1725 70.7738L41.8062 66.7892L57.6553 48.8194L68.6398 33.5059L67.306 32.3341L60.9506 30.6152L53.5753 29.4433L38.9816 27.2556H35.5293L32.7047 28.7401L30.194 31.2402L26.9771 33.5059L21.9556 37.0999L0.927734 55.6166Z"
          fill={backgroundColor}
        />
      ) : (
        ""
      )}
      <path
        opacity="0.3"
        d="M0.927734 55.6166L1.55538 61.9451L8.06801 66.0079L25.1725 70.7738L41.8062 66.7892L57.6553 48.8194L68.6398 33.5059L67.306 32.3341L60.9506 30.6152L53.5753 29.4433L38.9816 27.2556H35.5293L32.7047 28.7401L30.194 31.2402L26.9771 33.5059L21.9556 37.0999L0.927734 55.6166Z"
        fill="#34322E"
      />
      <path
        d="M0.657104 55.7234L17.6851 40.7211C19.8743 38.7228 24.9314 34.3773 27.6461 32.9822C31.0394 31.2382 32.0245 26.8782 37.4975 27.2052C42.9706 27.5322 58.9519 30.2572 60.7033 30.5842C62.1044 30.8458 65.5925 31.7832 67.1615 32.2192"
        stroke="#34322E"
      />
      <path d="M38.3465 28.6719C38.5306 29.4881 38.7885 31.7404 38.3465 34.2191" stroke="#34322E" />
      <path
        d="M48.3046 62.3065C49.35 61.175 57.5315 51.5345 58.5315 49.9503C59.3142 48.7104 64.2532 42.3503 66.793 39.0186C66.8491 38.9451 66.9266 38.742 66.6226 38.6794C64.0478 38.1495 59.0056 37 57.8005 37C56.2941 37 55.792 36 52.2772 36H30.1839C25.9995 40 16.3254 48.9 11.1033 52.5C5.88128 56.1 3.42522 58.6106 2.75972 59.5195C2.68126 59.6758 2.7381 59.7686 2.82384 59.791C5.07577 60.3793 10.1898 61.6089 14.5333 62.3065C20.1694 63.2117 32.4416 65.5653 33.9416 65.5653C35.4415 65.5653 37.3948 66.5 38.7199 66.5H43.7411C44.832 66.5 47.2592 63.438 48.3046 62.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M3.51141 59.4853C7.46773 57.9397 16.1002 54.5771 18.9796 53.4914C22.5788 52.1343 31.9571 46.8754 32.6427 46.1969C33.3283 45.5183 32.6427 43.5392 32.6427 41.447C32.6427 39.8875 32.443 37.6772 30.2577 36.1316"
        stroke="#78746D"
      />
      <path
        d="M48.3046 59.3065C49.35 58.175 57.5315 48.5345 58.5315 46.9503C59.3142 45.7104 64.2532 39.3503 66.793 36.0186C66.8491 35.9451 66.9266 35.742 66.6226 35.6794C64.0478 35.1495 59.0056 34 57.8005 34C56.2941 34 55.792 33 52.2772 33H30.1839C25.9995 37 16.3254 45.9 11.1033 49.5C5.88128 53.1 3.42522 55.6106 2.75972 56.5195C2.68126 56.6758 2.7381 56.7686 2.82384 56.791C5.07577 57.3793 10.1898 58.6089 14.5333 59.3065C20.1694 60.2117 32.4416 62.5653 33.9416 62.5653C35.4415 62.5653 37.3948 63.5 38.7199 63.5H43.7411C44.832 63.5 47.2592 60.438 48.3046 59.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.3046 56.3065C49.35 55.175 57.5315 45.5345 58.5315 43.9503C59.3142 42.7104 64.2532 36.3503 66.793 33.0186C66.8491 32.9451 66.9266 32.742 66.6226 32.6794C64.0478 32.1495 59.0056 31 57.8005 31C56.2941 31 55.792 30 52.2772 30H30.1839C25.9995 34 16.3254 42.9 11.1033 46.5C5.88128 50.1 3.42522 52.6106 2.75972 53.5195C2.68126 53.6758 2.7381 53.7686 2.82384 53.791C5.07577 54.3793 10.1898 55.6089 14.5333 56.3065C20.1694 57.2117 32.4416 59.5653 33.9416 59.5653C35.4415 59.5653 37.3948 60.5 38.7199 60.5H43.7411C44.832 60.5 47.2592 57.438 48.3046 56.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.3046 53.3065C49.35 52.175 57.5315 42.5345 58.5315 40.9503C59.3142 39.7104 64.2532 33.3503 66.793 30.0186C66.8491 29.9451 66.9266 29.742 66.6226 29.6794C64.0478 29.1495 59.0056 28 57.8005 28C56.2941 28 55.792 27 52.2772 27H30.1839C25.9995 31 16.3254 39.9 11.1033 43.5C5.88128 47.1 3.42522 49.6106 2.75972 50.5195C2.68126 50.6758 2.7381 50.7686 2.82384 50.791C5.07577 51.3793 10.1898 52.6089 14.5333 53.3065C20.1694 54.2117 32.4416 56.5653 33.9416 56.5653C35.4415 56.5653 37.3948 57.5 38.7199 57.5H43.7411C44.832 57.5 47.2592 54.438 48.3046 53.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.3046 50.3065C49.35 49.175 57.5315 39.5345 58.5315 37.9503C59.3142 36.7104 64.2532 30.3503 66.793 27.0186C66.8491 26.9451 66.9266 26.742 66.6226 26.6794C64.0478 26.1495 59.0056 25 57.8005 25C56.2941 25 55.792 24 52.2772 24H30.1839C25.9995 28 16.3254 36.9 11.1033 40.5C5.88128 44.1 3.42522 46.6106 2.75972 47.5195C2.68126 47.6758 2.7381 47.7686 2.82384 47.791C5.07577 48.3793 10.1898 49.6089 14.5333 50.3065C20.1694 51.2117 32.4416 53.5653 33.9416 53.5653C35.4415 53.5653 37.3948 54.5 38.7199 54.5H43.7411C44.832 54.5 47.2592 51.438 48.3046 50.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.3046 47.3065C49.35 46.175 57.5315 36.5345 58.5315 34.9503C59.3142 33.7104 64.2532 27.3503 66.793 24.0186C66.8491 23.9451 66.9266 23.742 66.6226 23.6794C64.0478 23.1495 59.0056 22 57.8005 22C56.2941 22 55.792 21 52.2772 21H30.1839C25.9995 25 16.3254 33.9 11.1033 37.5C5.88128 41.1 3.42522 43.6106 2.75972 44.5195C2.68126 44.6758 2.7381 44.7686 2.82384 44.791C5.07577 45.3793 10.1898 46.6089 14.5333 47.3065C20.1694 48.2117 32.4416 50.5653 33.9416 50.5653C35.4415 50.5653 37.3948 51.5 38.7199 51.5H43.7411C44.832 51.5 47.2592 48.438 48.3046 47.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.2455 44.3065C49.2909 43.175 57.4724 33.5345 58.4724 31.9503C59.2551 30.7104 64.1941 24.3503 66.734 21.0186C66.79 20.9451 66.8675 20.742 66.5635 20.6794C63.9887 20.1495 58.9465 19 57.7414 19C56.235 19 55.7329 18 52.2181 18H30.1248C25.9405 22 16.2663 30.9 11.0442 34.5C5.8222 38.1 3.36613 40.6106 2.70064 41.5195C2.62218 41.6758 2.67902 41.7686 2.76476 41.791C5.01669 42.3793 10.1307 43.6089 14.4742 44.3065C20.1104 45.2117 32.3826 47.5653 33.8825 47.5653C35.3824 47.5653 37.3357 48.5 38.6608 48.5H43.682C44.7729 48.5 47.2001 45.438 48.2455 44.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M3.45233 41.4853C7.40864 39.9397 16.0411 36.5771 18.9205 35.4914C22.5197 34.1343 33.9065 29.8754 34.5921 29.1969C35.2777 28.5183 34.0928 25.3854 34.0928 23.2932C34.0928 21.7337 32.3839 19.6772 30.1986 18.1316"
        stroke="#78746D"
      />
      <path d="M31.7527 26.9549C31.4555 27.1213 30.9504 27.543 31.307 27.8981" stroke="#78746D" strokeLinecap="round" />
      {foregroundColor !== null ? (
        <path
          d="M14.3821 59L0.824829 56L2.33119 66C2.16382 66.6667 2.43161 68 4.84179 68C7.85451 68 18.9011 71.5 22.9181 72.5C26.9351 73.5 31.4541 73 36.9775 73.5C42.5008 74 43.254 73.5 44.5093 72.5C47.0199 70.5 47.522 68.5 50.5347 65C53.5474 61.5 60.075 53 61.0792 51C62.0835 49 68.611 43.5 69.6152 42.5C70.6195 41.5 70.1174 37.5 70.6195 35.5C71.0212 33.9 68.1089 32.5 66.6025 32C67.272 32.3333 68.611 33.1 68.611 33.5C68.611 34 67.1046 34.5 66.1004 35.5C65.0962 36.5 62.0835 40.5 60.5771 42.5C59.0707 44.5 57.5644 46 55.0538 48.4999L55.0538 48.5C53.0453 50.5 45.5135 59.6667 41.9987 64C39.4881 63.5 34.1656 62.4 32.9605 62C31.7554 61.6 27.7719 61.3333 24.9266 61C24.9266 62.5 25.027 64.9 21.4117 64.5C17.7965 64.1 14.8842 60.1667 14.3821 59Z"
          fill={foregroundColor}
        />
      ) : (
        ""
      )}
      <path
        d="M14.3821 59L0.824829 56L2.33119 66C2.16382 66.6667 2.43161 68 4.84179 68C7.85451 68 18.9011 71.5 22.9181 72.5C26.9351 73.5 31.4541 73 36.9775 73.5C42.5008 74 43.254 73.5 44.5093 72.5C47.0199 70.5 47.522 68.5 50.5347 65C53.5474 61.5 60.075 53 61.0792 51C62.0835 49 68.611 43.5 69.6152 42.5C70.6195 41.5 70.1174 37.5 70.6195 35.5C71.0212 33.9 68.1089 32.5 66.6025 32C67.272 32.3333 68.611 33.1 68.611 33.5C68.611 34 67.1046 34.5 66.1004 35.5C65.0962 36.5 62.0835 40.5 60.5771 42.5C59.0707 44.5 57.5644 46 55.0538 48.4999L55.0538 48.5C53.0453 50.5 45.5135 59.6667 41.9987 64C39.4881 63.5 34.1656 62.4 32.9605 62C31.7554 61.6 27.7719 61.3333 24.9266 61C24.9266 62.5 25.027 64.9 21.4117 64.5C17.7965 64.1 14.8842 60.1667 14.3821 59Z"
        stroke="#34322E"
      />
      <path d="M42.1124 66.0178C42.2966 66.8341 42.5544 69.0863 42.1124 71.5651" stroke="#34322E" />
    </svg>
  );
};

export default AlmostFull;
