import type { TSolutionVariant, TAssignmentStatus } from "store/slices/exercise";
import type { IOption } from "store/slices/topicPractice";

import type { SelfEvaluationFragment } from "api/generated";

export const getSolutionVariant = (
  value: string | null | undefined,
  correctAnswers: Array<Array<string | null>> | undefined,
  index: number,
  isCaseSensitive: boolean,
) => {
  if (
    correctAnswers === undefined ||
    correctAnswers.length === 0 ||
    correctAnswers[index] === undefined ||
    typeof value !== "string"
  ) {
    return "danger";
  }

  const questionAnswers = correctAnswers[index]
    .filter((answer): answer is string => answer !== null)
    .map(answer => (isCaseSensitive ? answer.trim() : answer?.toLocaleLowerCase().trim()));

  const caseSensitiveValue = isCaseSensitive ? value : value?.toLocaleLowerCase();
  return questionAnswers.includes(caseSensitiveValue.trim()) ? "success" : "danger";
};

export const getSelfEvaluationVariant = (
  values: Array<{ points: number | null; status: TSolutionVariant | null }>,
  data: SelfEvaluationFragment | SelfEvaluationFragment[],
) => {
  const variants: Array<"success" | "warning" | "danger"> = [];

  if (Array.isArray(data)) {
    for (let i = 0; i < values.length; i++) {
      const points = data[i].options
        .map(option => option.scoreReal)
        .filter((point): point is number => typeof point === "number");
      const maxPoints = Math.max(...points);
      variants.push(getEvaluationVariantFromOption(values[i].points, maxPoints));
    }
  } else {
    const points = data.options
      .map(option => option.scoreReal)
      .filter((point): point is number => typeof point === "number");
    const maxPoints = Math.max(...points);
    for (let i = 0; i < values.length; i++) {
      variants.push(getEvaluationVariantFromOption(values[i].points, maxPoints));
    }
  }

  return variants;
};

export const getEvaluationVariantFromOption = (value: number | null, maxPoints: number) => {
  if (value === null || value === 0) return "danger";
  if (value >= maxPoints) return "success";

  return "warning";
};

export const getSelfEvaluationStatus = (
  values: Array<{ points: number | null; status: TSolutionVariant | null }>,
  scoreMax: number | null | undefined,
) => {
  if (typeof scoreMax !== "number") return "incorrect";
  if (values.every(value => value === null)) return "not_taken";

  let pointsGained = 0;
  for (const value of values) {
    if (value.points === null) continue;
    pointsGained += value.points;
  }

  if (pointsGained >= scoreMax) {
    return "correct";
  }
  if (pointsGained === 0) {
    return "incorrect";
  }
  return "partial";
};

interface TSolution {
  points: number;
  status: TSolutionVariant;
}

export const getSelfEvaluationResults = (
  values: Array<{ points: number | null; status: TSolutionVariant | null }>,
  questionsPoints: Array<number | null> | number | null,
): TSolution[] => {
  if (!Array.isArray(questionsPoints)) {
    if (questionsPoints === null) {
      return values.map((value): TSolution => {
        return {
          points: value.points ?? 0,
          status: typeof value.points === "number" && value.points > 0 ? "success" : "danger",
        };
      });
    }

    return values.map((value): TSolution => {
      return {
        points: value.points ?? 0,
        status: getEvaluationVariantFromOption(value.points, questionsPoints),
      };
    });
  }

  return values.map((value, index): TSolution => {
    return {
      points: value.points ?? 0,
      status: getEvaluationVariantFromOption(value.points, questionsPoints[index] ?? 0),
    };
  });
};

const calculateCountOfCorrectAnswers = (
  answers: string[],
  solutions: Array<Array<string | null>>,
  isCaseSensitive: boolean,
) => {
  let correctCount = 0;

  const caseSensitiveSolutions = isCaseSensitive
    ? solutions
    : solutions.map(question =>
        question.filter((answer): answer is string => answer !== null).map(answer => answer.toLocaleLowerCase()),
      );
  caseSensitiveSolutions.forEach((questionAnswers, index) => {
    const userAnswer = isCaseSensitive ? answers?.[index]?.trim() : answers?.[index]?.trim().toLocaleLowerCase();
    if (questionAnswers.includes(userAnswer)) correctCount++;
  });

  return correctCount;
};

export const evaluateGenericType = (
  answers: string[],
  solutions: Array<Array<string | null>>,
  isCaseSensitive: boolean,
) => {
  const correct = calculateCountOfCorrectAnswers(answers, solutions, isCaseSensitive);

  if (correct === 0) return "incorrect";
  if (correct === answers.length) return "correct";

  return "partial";
};

export const evaluateABCD = (answers: IOption[], correctAnswers: Array<Array<string | null>>) => {
  let correct = 0;

  const loweredAnswers = correctAnswers?.map(question => question.map(answer => answer?.toLocaleLowerCase()));
  loweredAnswers.forEach((questionAnswers, index) => {
    const caseSensitiveUserAnswer = answers?.[index]?.value?.toLocaleLowerCase();
    if (typeof caseSensitiveUserAnswer === "string" && questionAnswers.includes(caseSensitiveUserAnswer.trim()))
      correct++;
  });

  if (correct === 0) return "incorrect";
  if (correct === answers.length) return "correct";
  if (correct === answers.length - 1) return "partial";

  return "incorrect";
};

export const evaluateSorting = (answers: string[], solutions: Array<Array<string | null>>) => {
  const correct = calculateCountOfCorrectAnswers(answers, solutions, false);

  if (correct === answers.length) return "correct";
  return "incorrect";
};

export const evaluateAnoNe = (answers: string[], solutions: Array<Array<string | null>>) => {
  const correct = calculateCountOfCorrectAnswers(answers, solutions, false);

  if (correct === 0) return "incorrect";
  if (correct === answers.length) return "correct";
  if (correct === answers.length - 1) return "partial";

  return "incorrect";
};

export const calculateMaxPoints = (data: SelfEvaluationFragment | undefined | null) => {
  if (data === null || data === undefined) return 0;

  const options = Object.values(data.options);
  let maxValue = 0;

  for (const option of options) {
    const score = option.scoreReal;

    if (typeof score !== "number") continue;
    maxValue = Math.max(maxValue, score);
  }

  return maxValue;
};

export const getSolutionVariantFromPoints = (pointsGained: number, pointsMax: number): TAssignmentStatus => {
  if (pointsGained === 0) return "incorrect";

  if (pointsGained >= pointsMax) return "correct";

  return "partial";
};
