import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";
import { Form } from "react-bootstrap";

import Layout from "./_Layout";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectSimpleAnswer, setSimpleAnswer } from "store/slices/answer";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* W8WhtjNybz8RcI7cG4mQ */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const ViceJednoslovnychOdpovedi: FC<Props> = ({ data, onSubmit }) => {
  const questionsCount = data.assignment?.assignment?.questions.length;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const selected = useAppSelector(selectSimpleAnswer);
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const handleChange = (index: number, value: string) => {
    const newAnswers = selected.length === 0 ? new Array(questionsCount).fill("") : [...selected];
    newAnswers[index] = value;

    dispatch(setSimpleAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return selected.length > 0 && selected.every(answer => answer.length > 0);
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()}>
      <div>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        <div className="mt-20px question">
          <Latex>{data.assignment?.assignment?.questionsText ?? ""}</Latex>

          {data.assignment?.assignment?.questions.map((question, qIndex) => {
            return (
              <div key={qIndex} className="mt-20px ">
                <Latex>{question.heading ?? ""}</Latex>
                {imageUrl !== null ? <img src={imageUrl} /> : null}

                <Form.Control
                  placeholder={question.placeholder ?? ""}
                  onChange={event => {
                    handleChange(qIndex, event.target.value);
                  }}
                ></Form.Control>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default ViceJednoslovnychOdpovedi;
