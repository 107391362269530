import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./Layout";
import LayoutSimple from "./LayoutSimple";
import LayoutSimpleWide from "./LayoutSimpleWide";
import LayoutSimpleFluid from "./LayoutSimpleFluid";

import Dashboard from "./Dashboard/Dashboard";

import AssignmentsRoutes from "./Assignments";
import BookmarksRoutes from "./Bookmarks";
import CalendarRoutes from "./Calendar";
import CMSAssignmentDetailRoutes from "./CMSAssignmentDetail";
import ExerciseRoutes from "./Exercise";
import FaqRoutes from "./Faq";
import OnboardingRoutes from "./Onboarding";
import PracticeRoutes from "./Practice";
import SettingsRoutes from "./Settings";
import StatisticsRoutes from "./Statistics";

import OnboardingExercise from "./Onboarding/Exercise";
import OnboardingResults from "./Onboarding/ExerciseResults";

import { PracticeResults } from "./Practice/Results";
import { MixedPractice } from "./Practice/MixedPractice";
import { MhdPractice } from "./Practice/MhdPractice";

/* DEV IMPORTS START */
import DevRoutes from "./Dev";
import Exercise01 from "./Dev/Excercise/Exercise01";
import Exercise02 from "./Dev/Excercise/Exercise02";
import Exercise03 from "./Dev/Excercise/Exercise03";
import Exercise04 from "./Dev/Excercise/Exercise04";
import Exercise05 from "./Dev/Excercise/Exercise05";
import Exercise06 from "./Dev/Excercise/Exercise06";
import Exercise07 from "./Dev/Excercise/Exercise07";
import Exercise08 from "./Dev/Excercise/Exercise08";
import Exercise09 from "./Dev/Excercise/Exercise09";
import Exercise10 from "./Dev/Excercise/Exercise10";
import Results01 from "./Dev/Results/Results01";
import Results02 from "./Dev/Results/Results02";
import TeacherEvaluationUpload from "./TeacherEvaluationUpload";
/* DEV IMPORTS END */

const UserRouter = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/*" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="dev/*" element={<DevRoutes />} />
          <Route path="dotazy/*" element={<FaqRoutes />} />
          <Route path="kalendar/*" element={<CalendarRoutes />} />
          <Route path="nastaveni/*" element={<SettingsRoutes />} />
          <Route path="statistiky/*" element={<StatisticsRoutes />} />
          <Route path="ulohy/*" element={<AssignmentsRoutes />} />
          <Route path="ulohy-z-cms/*" element={<CMSAssignmentDetailRoutes />} />
          <Route path="ulozene/*" element={<BookmarksRoutes />} />
        </Route>

        <Route path="/*" element={<LayoutSimpleWide />}>
          <Route path="onboarding/priklad" element={<OnboardingExercise />} />
          <Route path="procvicovani/*" element={<PracticeRoutes />} />
        </Route>

        <Route path="/*" element={<LayoutSimple />}>
          <Route path="onboarding/*" element={<OnboardingRoutes />} />
        </Route>

        <Route path="cviceni/*" element={<ExerciseRoutes />} />
        <Route path="procvicovani/mix" element={<MixedPractice />} />
        <Route path="procvicovani/mhd" element={<MhdPractice />} />

        {/* DEV ROUTES START */}
        <Route path="/*" element={<Layout />}>
          <Route path="dev/*" element={<DevRoutes />} />
        </Route>

        <Route path="/*" element={<LayoutSimple />}>
          <Route path="dev/cviceni/zacatek_procvicovani" element={<Exercise01 />} />
          <Route path="dev/cviceni/zacatek_procvicovani_2a" element={<Exercise02 />} />
        </Route>

        <Route path="/*" element={<LayoutSimpleWide />}>
          <Route path="dev/cviceni/priklad_slovni_uloha_1" element={<Exercise03 />} />
          <Route path="dev/cviceni/priklad_slovni_uloha_1_overeno" element={<Exercise04 />} />
          <Route path="dev/cviceni/priklad_slovni_uloha_3a" element={<Exercise05 />} />
          <Route path="dev/cviceni/priklad_slovni_uloha_2_overeno" element={<Exercise06 />} />
          <Route path="dev/cviceni/priklad_rysovani_odpovedi" element={<Exercise07 />} />
          <Route path="dev/cviceni/priklad_modaly" element={<Exercise08 />} />
          <Route path="dev/cviceni/vyhodnoceni_1" element={<Exercise09 />} />
          <Route path="dev/cviceni/vyhodnoceni_2" element={<Exercise10 />} />
        </Route>

        <Route path="/*" element={<LayoutSimpleFluid />}>
          <Route path="dev/vysledky/tabs" element={<Results01 />} />
          <Route path="dev/vysledky/tabs-errors" element={<Results02 />} />
          <Route path="procvicovani/vysledky" element={<PracticeResults />} />
          <Route path="onboarding/priklad-vysledky" element={<OnboardingResults />} />
          <Route path="odeslani-uciteli/:userAssignmentId" element={<TeacherEvaluationUpload />} />
        </Route>
        {/* DEV ROUTES END */}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default UserRouter;
