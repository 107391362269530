import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PracticePageContent from "../_PracticePageContent";

import { useGetEnvelopeTopicPracticeAssignmentsQuery } from "api/generated";
import type { UserAssignmentPracticeFragment } from "api/generated";

import { translateEnvelopeId } from "utils/translateEnvelopeId";
import { processApiError } from "utils/processApiError";

const EnvelopeTopicPractice = () => {
  const { envelopeId, topicId } = useParams();
  if (envelopeId === undefined || topicId === undefined) return null;

  const topicIdKey = `${topicId}-${envelopeId}`;

  const {
    data,
    isLoading,
    error: apiError,
  } = useGetEnvelopeTopicPracticeAssignmentsQuery({
    envelopeId: translateEnvelopeId(envelopeId),
    topicId,
  });

  const [allAssignments, setAllAssignments] = useState<UserAssignmentPracticeFragment[] | null>(null);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const assignments = data.userAssignments.items.filter(assignment => assignment.assignment?.isActive);
    setAllAssignments(assignments);
  }, [data]);

  return (
    <PracticePageContent
      isLoading={isLoading}
      assignments={allAssignments}
      title={data?.topics[0]?.name ?? ""}
      topicId={topicIdKey}
    />
  );
};

export default EnvelopeTopicPractice;
