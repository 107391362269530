import { Col, Form, Row, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ExerciseBar } from "../../../../features";
import Select from "react-select";

const Exercise03 = () => {
  return (
    <div className="exercise">
      <div className="exercise__header">
        <ExerciseBar
          totalTime={600000}
          isTimerStopped={true}
          userAssignmentId={"test"}
          isProgressBarVisible={true}
          onQuit={() => {}}
        />

        <div className="progress-dots">
          <div className="dot current" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>

      <div className="exercise__body">
        <p className="py-20px">
          Ve škole, která má v každém ročníku dvě třídy (A,B), proběhla soutěž ve sběru papíru. V tabulkách jsou uvedeny
          některé údaje z této soutěže.
        </p>

        <Row className={"gx-2"}>
          <Col xs={7}>
            <Table bordered>
              <thead>
                <tr>
                  <th colSpan={3}>První ročník</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.A</td>
                  <td>1.B</td>
                  <td>celkem</td>
                </tr>
                <tr>
                  <td>600 kg</td>
                  <td>600 kg</td>
                  <td>1 200 kg</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={5}>
            <Table bordered>
              <thead>
                <tr>
                  <th colSpan={2}>Druhý ročník</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.A</td>
                  <td>2.B</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </Table>
          </Col>

          <p className="mb-20px">
            <strong>Přiřaďte ke každé úloze (15.1 - 15.3) odpovídající výsledek (A-F).</strong>
          </p>
        </Row>
        <div>
          <div className={"question"}>
            <p>Třída 2.A nasbírala o 25% méně papíru než třída 1.A.</p>

            <Form.Group>
              <Form.Label>Kolik kg papíru nasbírala třída 2.A?</Form.Label>
              <Select
                name="subject"
                className={"react-select-container"}
                classNamePrefix="react-select"
                placeholder={"Zadejte odpověď"}
                options={[
                  { value: 400, label: "400 kg" },
                  { value: 450, label: "450 kg" },
                  { value: 500, label: "500 kg" },
                  { value: 550, label: "550 kg" },
                ]}
              />
            </Form.Group>
          </div>

          <div className={"question"}>
            <p>Třída 1. B nasbírala o 20% více papíru než třída 2.B.</p>
            <Form.Group>
              <Form.Label>Kolik kg papíru nasbírala třída 2.B?</Form.Label>
              <Select
                name="subject"
                className={"react-select-container"}
                classNamePrefix="react-select"
                placeholder={"Zadejte odpověď"}
                options={[
                  { value: 400, label: "400 kg" },
                  { value: 450, label: "450 kg" },
                  { value: 500, label: "500 kg" },
                  { value: 550, label: "550 kg" },
                ]}
              />
            </Form.Group>
          </div>

          <div className={"question"}>
            <p>Ze všech žáků prvního ročníku nasbíraly dívky o 50% více papíru než chlapci.</p>
            <Form.Group>
              <Form.Label>Kolik kg papíru nasbírala třída 2.B?</Form.Label>
              <Select
                name="subject"
                className={"react-select-container"}
                classNamePrefix="react-select"
                placeholder={"Zadejte odpověď"}
                options={[
                  { value: 400, label: "400 kg" },
                  { value: 450, label: "450 kg" },
                  { value: 500, label: "500 kg" },
                  { value: 550, label: "550 kg" },
                ]}
              />
            </Form.Group>
          </div>
        </div>
      </div>

      <div className="exercise__footer">
        <div className="mw-sm">
          <Button className={"w-100 text-uppercase"}>Ověřit výsledek</Button>
        </div>
      </div>
    </div>
  );
};

export default Exercise03;
