import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as GreenEnvelope } from "images/icons/30-obalka-green.svg";
import { ReactComponent as OrangeEnvelope } from "images/icons/30-obalka-orange.svg";
import { ReactComponent as RedEnvelope } from "images/icons/30-obalka-red.svg";

interface Props {
  envelopesCount: {
    red: number | null;
    orange: number | null;
    green: number | null;
  };
  withTitle?: boolean;
}

const EnvelopesOverview: FC<Props> = ({ envelopesCount, withTitle = false }) => {
  const { t } = useTranslation(["exercise"]);

  return (
    <div className="envelope-overview">
      {withTitle ? <span>{t("info.envelopesOverview")}</span> : null}
      <div className="envelope-overview__counts">
        {envelopesCount.green !== null ? (
          <div
            className={`envelope-overview__envelope ${
              envelopesCount.green === 0 ? "envelope-overview__envelope--grey" : ""
            }`}
          >
            <GreenEnvelope />
            <div className="envelope-overview__envelope__label envelope-overview__envelope__label--green">
              {envelopesCount.green}
            </div>
          </div>
        ) : null}

        {envelopesCount.orange !== null ? (
          <div
            className={`envelope-overview__envelope ${
              envelopesCount.orange === 0 ? "envelope-overview__envelope--grey" : ""
            }`}
          >
            <OrangeEnvelope />
            <div className="envelope-overview__envelope__label envelope-overview__envelope__label--orange">
              {envelopesCount.orange}
            </div>
          </div>
        ) : null}

        {envelopesCount.red !== null ? (
          <div
            className={`envelope-overview__envelope ${
              envelopesCount.red === 0 ? "envelope-overview__envelope--grey" : ""
            }`}
          >
            <RedEnvelope />
            <div className="envelope-overview__envelope__label envelope-overview__envelope__label--red">
              {envelopesCount.red}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EnvelopesOverview;
