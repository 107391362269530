import { ReactComponent as StrawberrySVG } from "images/icons/strawberry.svg";
import { ReactComponent as WaterDrop } from "images/icons/waterDrop.svg";

const CalendarInfo = () => {
  return (
    <ul className="calendar-info">
      <li className="calendar-info__item">
        <span className="calendar-info__icon">
          <StrawberrySVG />
        </span>
        <div className="calendar-info__description">
          <strong>Jak se počítá řada?</strong> Za každý den, kdy splníš trénink, ti přidáme jahodu. Řadu ti přeruší
          slimák, pokud v daný den do půlnoci nedokončíš naplánovaný trénink.
        </div>
      </li>

      <li className="calendar-info__item">
        <span className="calendar-info__icon">
          <WaterDrop />
        </span>
        <div className="calendar-info__description">
          <strong>Slimák ti překazil řadu?</strong> Nevadí.{" "}
          <strong>Na každý týden dostaneš jednu kapku živé vody</strong>, která zhatí slimákovy temné plány, a tak o
          řadu nepřijdeš. <strong>Kapka se použije sama</strong>, pokud v daných 24 hodinách ve dni neprocvičuješ.
          Nemusíš tedy myslet na to, že ji musíš zadat, aby se řada nezrušila. Jen nezapomeň, že pokud vynecháš, další
          den už musíš trénovat, jinak o řadu přijdeš.
        </div>
      </li>
    </ul>
  );
};

export default CalendarInfo;
