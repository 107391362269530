import JsPDF from "jspdf";

import { formatDate } from "utils/calendarDateFormatting";

export const generateImagePDF = async (images: string[], fileName?: string) => {
  const imagePromises = images.map(
    async url =>
      await new Promise<HTMLImageElement>(resolve => {
        try {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = url;
        } catch (error) {
          console.error(error);
        }
      }),
  );

  const loadedImages = await Promise.all(imagePromises);

  const pdf = new JsPDF({
    unit: "mm",
    format: "a4",
    orientation: "portrait",
  });

  const offset = 10;

  for (const [index, img] of Array.from(loadedImages.entries())) {
    try {
      const props = pdf.getImageProperties(img);

      const ratio = props.width / props.height;
      const imageWidth = pdf.internal.pageSize.getWidth() - 2 * offset;
      const imageHeight = imageWidth / ratio;

      pdf.addImage(img, "PNG", offset, offset, imageWidth, imageHeight);

      if (index < loadedImages.length - 1) {
        pdf.addPage();
      }
    } catch (error) {
      console.error(error);
    }
  }

  pdf.save(fileName ?? `to-natrenujes_${formatDate(new Date())}`);
};
