import { useEffect, useState } from "react";
import type { FC } from "react";
import Button from "react-bootstrap/Button";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";

import { BookmarkIcon, PauseExerciseModal } from "components";

import { useAppSelector, useAppDispatch } from "store/hooks";
import {
  getGlobalTime,
  stopAllTimers,
  startAssignmentTimer,
  startGlobalTimer,
  tick,
  isTimerShown,
} from "store/slices/timer";

import { ReactComponent as PauseIcon } from "images/icons/pause.svg";
import { ClassroomAmbienceButton, ClassroomAmbienceProvider } from "features/ClassroomAmbience";

interface Props {
  isProgressBarVisible: boolean;
  totalTime: number;
  isTimerStopped: boolean;
  userAssignmentId: string | undefined;
  onQuit: () => void;
}

const ExerciseBar: FC<Props> = ({ isProgressBarVisible, totalTime, isTimerStopped, userAssignmentId, onQuit }) => {
  const [isPauseModalVisible, setPauseModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const time = useAppSelector(getGlobalTime);
  const isTimerVisible = useAppSelector(isTimerShown);

  /* Prevents page reload, well... kind of */
  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = () => {
      /* TODO: save data to local storage */
      return "";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const onBackButtonEvent = (e: Event) => {
    e.preventDefault();
    setPauseModalVisible(true);
  };

  /* Prevents go back */
  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const getTimeFromTimestamp = (timestamp: number) => {
    const remainingTime = Math.abs(totalTime - timestamp);

    const date = new Date(remainingTime * 1000);
    const hours = date.getUTCHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${hours * 60 + minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handlePauseClick = () => {
    setPauseModalVisible(true);
    dispatch(stopAllTimers());
  };

  const handleModalClose = () => {
    setPauseModalVisible(false);
    dispatch(startGlobalTimer());
    dispatch(startAssignmentTimer());
  };

  useEffect(() => {
    if (isTimerStopped) return;

    const interval = setInterval(() => {
      dispatch(tick());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ClassroomAmbienceProvider>
      {isProgressBarVisible ? <ProgressBar now={Math.max(0, (1 - time / totalTime) * 100)} /> : null}

      <Container className="lh-1 user-select-none px-3">
        <Row className="py-20px">
          <Col className="d-flex align-items-center">
            {isTimerVisible ? (
              <strong id="timer" className="timer">
                {getTimeFromTimestamp(time)}
              </strong>
            ) : null}
          </Col>
          <Col className="text-end">
            <ClassroomAmbienceButton />

            {typeof userAssignmentId === "string" && userAssignmentId.length > 0 ? (
              <BookmarkIcon className="ms-20px" userAssignmentId={userAssignmentId} />
            ) : null}

            <Button size={"sm"} variant={"navigation"} onClick={handlePauseClick} className="ms-20px p-0">
              <PauseIcon />
            </Button>
          </Col>
        </Row>

        {isPauseModalVisible ? <PauseExerciseModal onClose={handleModalClose} onQuit={onQuit} /> : null}
      </Container>
    </ClassroomAmbienceProvider>
  );
};

export default ExerciseBar;
