import { SUBJECT_PIE_COLORS } from "components/SubjectsPie/SubjectsPie";
import type { ISubjectDataForPie } from "components/SubjectsPie/SubjectsPie";

import type { SessionCreateMutation, UserAssignmentForExerciseMinimumFragment } from "api/generated";

export const parseDataForPieChart = (data: SessionCreateMutation): ISubjectDataForPie[] => {
  const result: Record<string, ISubjectDataForPie> = {};

  for (const assignment of data.sessionCreate.userAssignments) {
    const subjectName = assignment.subject?.appName;

    if (subjectName === undefined || subjectName === null) continue;

    if (subjectName in result) {
      result[subjectName].value++;
      continue;
    }

    result[subjectName] = {
      name: subjectName,
      value: 1,
      color: SUBJECT_PIE_COLORS[subjectName] ?? "white",
    };
  }

  return Object.values(result);
};

export const parsePracticeDataForPieChart = (
  assignments: UserAssignmentForExerciseMinimumFragment[],
): ISubjectDataForPie[] => {
  const result: Record<string, ISubjectDataForPie> = {};

  for (const assignment of assignments) {
    const subjectName = assignment.subject?.appName;

    if (subjectName === undefined || subjectName === null) continue;

    if (subjectName in result) {
      result[subjectName].value++;
      continue;
    }

    result[subjectName] = {
      name: subjectName,
      value: 1,
      color: SUBJECT_PIE_COLORS[subjectName] ?? "white",
    };
  }

  return Object.values(result);
};
