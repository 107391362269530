import type { FC } from "react";
import { CalendarDayBig } from "features";

import { getPrevMonday, formatDate } from "utils/calendarDateFormatting";

import type { StudyDayFragment } from "api/generated";

interface IDayData {
  date: Date;
  isFinished: boolean;
  isTest: boolean;
  isDeadLine: boolean;
  isPlannedExercise: boolean;
  exerciseDuration: number;
  exerciseRealDuration: number;
  status: string | null;
}

const prepareCalendarData = ({ studyDays, tests, admissionsDeadline, dateOfRegistration }: Props["data"]) => {
  const today = new Date();
  const thisMonday = getPrevMonday(new Date());
  const weekDayIndexes = Array.from(Array(7).keys());
  const results: IDayData[] = [];

  for (const index of weekDayIndexes) {
    const dayDate = new Date(thisMonday.getTime());
    dayDate.setDate(dayDate.getDate() + index);
    const isFuture = dayDate.getTime() > today.getTime();
    const isToday = formatDate(today) === formatDate(dayDate);
    const isDeadLine = formatDate(dayDate) === admissionsDeadline;
    const isAfterAdmissionDate =
      typeof admissionsDeadline === "string" &&
      admissionsDeadline.length > 0 &&
      formatDate(dayDate) > admissionsDeadline;

    const isAfterRegistrationDate = dateOfRegistration === null || formatDate(dayDate) >= dateOfRegistration;

    const formattedDate = formatDate(dayDate);
    const dayData = studyDays.find(studyDay => studyDay.date === formattedDate);

    const dayResult = {
      date: dayDate,
      isFinished: dayData?.sessionFinished ?? false,
      isTest: tests.includes(formattedDate),
      isPlannedExercise:
        (isToday || isFuture) &&
        typeof dayData?.duration === "number" &&
        dayData.duration > 0 &&
        !isAfterAdmissionDate &&
        isAfterRegistrationDate,
      exerciseDuration: dayData?.duration ?? 0,
      exerciseRealDuration: dayData?.durationReal ?? 0,
      isDeadLine,
      status: dayData?.status ?? null,
    };

    results.push(dayResult);
  }

  return results;
};

interface Props {
  data: {
    studyDays: StudyDayFragment[];
    tests: string[];
    admissionsDeadline?: string;
    dateOfRegistration: string | null;
  };
}

const CalendarWeek: FC<Props> = ({ data }) => {
  const today = new Date();

  const parsedData = prepareCalendarData(data);

  const createCalendarDay = (day: IDayData) => {
    const isToday = formatDate(today) === formatDate(day.date);

    return (
      <CalendarDayBig
        key={day.date.getTime()}
        date={day.date}
        isToday={isToday}
        isFinished={day.isFinished}
        withTest={day.isTest}
        isDeadline={day.isDeadLine}
        isPlannedExercise={day.isPlannedExercise}
        exerciseDuration={day.exerciseDuration}
        exerciseRealDuration={day.exerciseRealDuration}
        longDate={true}
        longStatusDescription={true}
        status={day.status}
      />
    );
  };

  return (
    <div className={"calendar"}>
      <div className="react-calendar week-calendar">
        {parsedData.map(function (day) {
          return createCalendarDay(day);
        })}
      </div>
    </div>
  );
};

export default CalendarWeek;
