import { useEffect, useState } from "react";

import { getFileNameExtension } from "utils/getFileNameExtension";
import { processApiError } from "utils/processApiError";

import { useSolutionUploadConfirmMutation, useSolutionUploadUrlMutation } from "api/generated";

interface Props {
  token: string;
  userAssignmentId: string;
}

export const useTeacherEvaluationUpload = ({ token, userAssignmentId }: Props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const isError = error !== undefined;
  const isVerificationError =
    ((error?.startsWith("token verification failed") ?? false) || error?.startsWith("No result returned by query")) ??
    false;

  const [solutionUploadUrl] = useSolutionUploadUrlMutation();
  const [confirmUpload, { error: apiConfirmMutationError }] = useSolutionUploadConfirmMutation();

  useEffect(() => {
    if (apiConfirmMutationError === undefined) return;

    processApiError(apiConfirmMutationError);
  }, [apiConfirmMutationError]);

  const prepare = async (file: File) => {
    const fileSize = file.size;
    const fileExtension = getFileNameExtension(file.name);
    const contentType = file.type;

    if (fileExtension === undefined) throw new Error("File extension is required");

    const response = await solutionUploadUrl({ contentType, fileSize, fileExtension, userAssignmentId });

    if ("data" in response) {
      return {
        id: response.data.solutionUploadUrl.uploadId,
        url: response.data.solutionUploadUrl.url,
      };
    }

    throw response.error;
  };

  const process = async (file: File, uploadUrl: string) => {
    const response = await fetch(uploadUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Auth-Token": token,
        "Content-Type": "application/octet-stream",
        "Content-Length": file.size.toString(),
      },
    });

    if (!response.ok) {
      throw new Error("Failed to upload file");
    }

    return response;
  };

  const upload = async (file: File) => {
    setIsUploading(true);

    try {
      const uploadCredentials = await prepare(file);
      const response = await process(file, uploadCredentials.url);
      await confirmUpload({ solutionUploadId: uploadCredentials.id });
      setIsSuccess(response.ok);
    } catch (error) {
      setError(String((error as any).message));
    } finally {
      setIsUploading(false);
    }
  };

  return {
    isError,
    isUploading,
    isSuccess,
    isVerificationError,
    upload,
  };
};
