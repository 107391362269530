import type { FC, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import { useState } from "react";

interface Props {
  children: ReactNode;
  lines?: number;
  className?: string;
  showMore?: boolean;
}

const TruncateText: FC<Props> = ({ className, children, lines, showMore = true }) => {
  const [clampLines, setClampLines] = useState(true);

  function disableClampLines() {
    setClampLines(false);
  }

  return (
    <>
      <div className={`truncate ${className ?? ""}`} style={clampLines ? { WebkitLineClamp: lines ?? "initial" } : {}}>
        {children}
      </div>

      {lines !== null && clampLines && showMore ? (
        <Button onClick={disableClampLines} variant={"link"} className={"link d-block py-0 w-100"}>
          Zobrazit celý text
        </Button>
      ) : (
        ""
      )}
    </>
  );
};

export default TruncateText;
