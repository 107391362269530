import { Navigate, Route, Routes } from "react-router-dom";

import Exercise from "./Exercise";
import ExerciseIntroduction from "./ExerciseIntroduction";
import ExerciseEvaluation from "./ExerciseEvaluation";
import Introduction from "./Introduction";
import PhoneValidation from "./PhoneValidation";
import StudyPreferences from "./StudyPreferences";

const OnboardingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/onboarding/uvod/1" />} />

      <Route path="/">
        <Route path="/uvod/:screenId" element={<Introduction />} />
        <Route path="/overeni-telefonu" element={<PhoneValidation />} />
        <Route path="/priklad" element={<Exercise />} />
        <Route path="/priklad-uvod" element={<ExerciseIntroduction />} />
        <Route path="/priklad-vyhodnoceni" element={<ExerciseEvaluation />} />
        <Route path="/preference" element={<StudyPreferences />} />

        <Route path="*" element={<Navigate to="/onboarding/uvod/1" />} />
      </Route>
    </Routes>
  );
};

export default OnboardingRoutes;
