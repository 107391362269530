import { useEffect } from "react";

import PracticePageContent from "../_PracticePageContent";

import { useGetBookmarkedPracticeAssignmentsQuery } from "api/generated";

import { processApiError } from "utils/processApiError";

const TOPIC_ID = "bookmark";

const BookmarkedPractice = () => {
  const { data, isLoading, error: apiError } = useGetBookmarkedPracticeAssignmentsQuery();

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  return (
    <PracticePageContent
      isLoading={isLoading}
      assignments={data?.bookmarks.map(bookmark => bookmark.userAssignment) ?? null}
      title="Uložené úlohy"
      topicId={TOPIC_ID}
    />
  );
};

export default BookmarkedPractice;
