import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface ITokenState {
  token: string | null;
  parentToken: string | null;
  teacherEvaluationToken: string | null;
}

const initialState: ITokenState = {
  token: null,
  parentToken: null,
  teacherEvaluationToken: null,
};

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setParentToken: (state, action: PayloadAction<string | null>) => {
      state.parentToken = action.payload;
    },
    setTeacherEvaluationToken: (state, action: PayloadAction<string | null>) => {
      state.teacherEvaluationToken = action.payload;
    },
  },
});

export default tokenSlice.reducer;
export const { setToken, setParentToken, setTeacherEvaluationToken } = tokenSlice.actions;

export const selectToken = (state: RootState) => state.token.token;
export const selectParentToken = (state: RootState) => state.token.parentToken;
export const selectTeacherEvaluationToken = (state: RootState) => state.token.teacherEvaluationToken;
