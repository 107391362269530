import { combineReducers } from "@reduxjs/toolkit";
import { api } from "api/generated";

import answerReducer from "./slices/answer";
import assignmentsReducer from "./slices/assignments";
import assignmentNavigationReducer from "./slices/assignmentNavigation";
import bookmarksReducer from "./slices/bookmarks";
import envelopeNavigationReducer from "./slices/envelopeNavigation";
import exerciseReducer from "./slices/exercise";
import queryParamsReducer from "./slices/queryParams";
import timerReducer from "./slices/timer";
import tokenReducer from "./slices/token";
import topicPracticeReducer from "./slices/topicPractice";
import timetableReducer from "./slices/timetable";
import userReducer from "./slices/user";

const rootReducer = combineReducers({
  api: api.reducer,
  answer: answerReducer,
  assignments: assignmentsReducer,
  assignmentsNavigation: assignmentNavigationReducer,
  bookmarks: bookmarksReducer,
  envelopeNavigation: envelopeNavigationReducer,
  exercise: exerciseReducer,
  queryParams: queryParamsReducer,
  timer: timerReducer,
  token: tokenReducer,
  topicPractice: topicPracticeReducer,
  timetable: timetableReducer,
  user: userReducer,
});

export default rootReducer;
