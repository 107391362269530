function Bookmark() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5443_3163)">
        <path
          d="M12 0.998047H6C4.89543 0.998047 4 1.89348 4 2.99805V15.6834L9 11.3322L14 15.6834V2.99805C14 1.89348 13.1046 0.998047 12 0.998047Z"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_5443_3163">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Bookmark;
