import { useEffect, useState } from "react";
import type { FC } from "react";
import { Button, Modal, ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Loading } from "components";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getAssignmentMaxPoints,
  changeAnswerVariant,
  setPoints,
  setStatus,
  getAnswersStatus,
} from "store/slices/exercise";
import { getUserEmail } from "store/slices/user";

import { processApiError } from "utils/processApiError";
import { getSolutionVariantFromPoints } from "utils/getSolutionVariant";

import { INCORRECT_ANSWER_REPORT_GOOGLE_URL } from "const";

import { useValidateAnswerMutation } from "api/generated";

interface Props {
  assignmentId: string;
  data: {
    correctAnswer: string;
    scoreMax: number;
    userAnswer: string;
  };
  index: number;
  type: "success" | "warning" | "danger" | undefined;
  userAssignmentId: string;
  onClick: (index: number, value: boolean) => void;
}

const AnswerValidation: FC<Props> = ({ assignmentId, data, index, type, userAssignmentId }) => {
  if (type !== "danger") return null;

  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(getUserEmail);
  const assignmentMaxPoints = useAppSelector(getAssignmentMaxPoints(userAssignmentId));
  const answersStatus = useAppSelector(getAnswersStatus(userAssignmentId));

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [validateOnBE, { error: apiMutationError }] = useValidateAnswerMutation();

  const { t } = useTranslation(["exercise"]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const showModal = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const sendToGoogle = async () => {
    const requestBody = {
      env: process.env.REACT_APP_ENV?.slice(0, 4),
      userEmail: userEmail ?? "",
      assignmentId,
      questionIndex: index + 1,
      correctAnswer: data.correctAnswer,
      userAnswer: data.userAnswer,
    };

    await fetch(INCORRECT_ANSWER_REPORT_GOOGLE_URL, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    return undefined;
  };

  const sendToBE = async (index: number) => {
    const copy = [...answersStatus];
    copy[index] = true;

    const response = await validateOnBE({ userAssignmentId, questions: copy });

    if ("data" in response) {
      const points = response.data.claimEvaluation.scoreReal;
      dispatch(setPoints({ userAssignmentId, points }));

      const status = getSolutionVariantFromPoints(points, assignmentMaxPoints);
      dispatch(setStatus({ userAssignmentId, status }));
    }
  };

  const processAnswerReport = async () => {
    try {
      setIsLoading(true);
      const promises = [
        dispatch(changeAnswerVariant({ userAssignmentId, index, type: "success" })),
        sendToGoogle(),
        sendToBE(index),
      ];

      await Promise.all(promises);

      setIsLoading(false);
      setModalOpen(false);
    } catch (error) {
      /* TODO: log error */
      setIsError(true);
    }
  };

  const handleConfirm = () => {
    processAnswerReport().catch(console.error);
  };

  return (
    <>
      <ToggleButton
        value={1}
        variant={"select"}
        className={"btn-shadow btn-emoji btn-emoji--thin"}
        type="checkbox"
        id={`toggle-validation_${index}`}
        onChange={showModal}
      >
        {t("btn.validation", { ns: "exercise" })}
      </ToggleButton>

      <Modal
        show={modalOpen}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <span>Error</span>
          ) : (
            <>
              <p className="fs-16 text-center fw-bold mb-20px">{t("answerValidation.heading")}</p>

              <div className="question question--thin mb-20px">
                <div>{t("answerValidation.yourAnswer")}:</div>
                <div className="mb-3">
                  <strong>{data.userAnswer}</strong>
                </div>
                <div>{t("answerValidation.correctAnswer")}:</div>
                <div>
                  <strong>{data.correctAnswer}</strong>
                </div>
              </div>

              <p className="text-center mb-20px">{t("answerValidation.description")}</p>

              <Button className={"w-100 mb-2"} onClick={handleConfirm}>
                {t("answerValidation.confirm")}
              </Button>
              <Button
                variant="outline-secondary"
                className={"w-100 text-uppercase"}
                onClick={() => {
                  handleClose();
                }}
              >
                {t("back", { ns: "common" })}
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AnswerValidation;
