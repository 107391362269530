import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { tooltipTexts } from "./TooltipTexts";

import { OnboardingTooltip } from "components";
import { PracticeEvaluation } from "features";

import { useAppSelector } from "store/hooks";
import { getResults } from "store/slices/exercise";
import { getUserEmail } from "store/slices/user";

import { gtmExerciseSelfEvaluation } from "utils/gtmEvents";
import { removeSessionFromStorage } from "utils/sessionStorageHandler";

const setHighlightClass = (element: HTMLElement) => {
  element.classList.add("highlight-tooltip");
};

const resetHighlightClass = (element: HTMLElement) => {
  element.classList.remove("highlight-tooltip");
};

const ExerciseEvaluation = () => {
  const navigate = useNavigate();
  const userEmail = useAppSelector(getUserEmail);

  const [selfEvalValue, setSelfEvalValue] = useState<number>(0);
  const [selfEvalText, setSelfEvalText] = useState<string>("");

  const results = useAppSelector(getResults);
  const [shownTooltipIndex, setShownTooltipIndex] = useState<number | null>(null);

  const highlightedElement = useRef<HTMLElement | null>(null);
  const shownTooltips = useRef<number[]>([]);
  const processingTooltip = useRef<boolean>(false);

  useEffect(() => {
    showNextTooltip();
  }, []);

  const showNextTooltip = () => {
    if (
      processingTooltip.current ||
      highlightedElement.current !== null ||
      shownTooltips.current.length >= tooltipTexts.length
    )
      return;

    processingTooltip.current = true;

    for (let tooltipIndex = 0; tooltipIndex < tooltipTexts.length; tooltipIndex++) {
      if (shownTooltips.current.includes(tooltipIndex)) continue;

      const elementId = tooltipTexts[tooltipIndex]?.elementId;
      highlightedElement.current =
        elementId !== null && typeof elementId === "string" ? document.getElementById(elementId) : null;

      if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
        shownTooltips.current.push(tooltipIndex);
        setHighlightClass(highlightedElement.current);
        setShownTooltipIndex(tooltipIndex);

        processingTooltip.current = false;

        return;
      }
    }

    processingTooltip.current = false;
  };

  const onTooltipClose = () => {
    if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
      resetHighlightClass(highlightedElement.current);
    }

    highlightedElement.current = null;
    setShownTooltipIndex(null);
    showNextTooltip();
  };

  const handlePracticeEvaluationSubmit = () => {
    gtmExerciseSelfEvaluation(selfEvalValue);
    removeSessionFromStorage(userEmail);

    navigate("/onboarding/priklad-vysledky");
  };

  return (
    <>
      <PracticeEvaluation
        mood={selfEvalValue}
        moodText={selfEvalText}
        results={results}
        onMoodSelect={setSelfEvalValue}
        onMoodTextChange={setSelfEvalText}
        onSubmit={handlePracticeEvaluationSubmit}
      />

      <OnboardingTooltip
        key={shownTooltipIndex}
        data={shownTooltipIndex === null ? null : tooltipTexts[shownTooltipIndex]}
        onConfirm={onTooltipClose}
        highlightedElement={highlightedElement.current ?? null}
      />
    </>
  );
};

export default ExerciseEvaluation;
