import { Outlet, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Col, Container, Image, Navbar, NavbarBrand, Offcanvas, Row } from "react-bootstrap";

import Logo from "images/logos/tonatrenujes.svg";
import { ReactComponent as HamburgerMenu } from "images/icons/hamburger.svg";
import { Menu } from "../components/Menu";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Footer } from "../components/Footer";
import { CurrentStreak } from "components";

const Layout = () => {
  const [isShown, setIsShown] = useState(false);
  const { pathname } = useLocation();

  const handleClose = () => {
    setIsShown(false);
  };
  const handleShow = () => {
    setIsShown(true);
  };

  useEffect(() => {
    setIsShown(false);
  }, [pathname]);

  return (
    <>
      <nav className={"navigation"}>
        <Navbar className="flex-sm-column w-100">
          <LinkContainer to="/">
            <NavbarBrand className={"mx-0 pb-sm-60px"}>
              <Image src={Logo} loading={"eager"} />
            </NavbarBrand>
          </LinkContainer>

          <div className={"navigation__desktop"}>
            <Menu />
          </div>

          <div className={"navigation__mobile"}>
            <CurrentStreak />
            <Button variant="light" onClick={handleShow} className={"btn-circular hamburger"}>
              <HamburgerMenu />
            </Button>
          </div>
        </Navbar>
      </nav>

      <Container fluid>
        <Row>
          <Col className={"col-main py-20px"} as={"main"}>
            <Outlet />
          </Col>
        </Row>

        <Row>
          <Col className={"col-main"}>
            <Footer />
          </Col>
        </Row>
      </Container>

      <Offcanvas show={isShown} onHide={handleClose} placement={"end"} className={"offcanvas-menu"}>
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <Menu />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Layout;
