import { Route, Routes } from "react-router-dom";

import CMSAssignmentDetail from "./CMSAssignmentDetail";

const CMSAssignmentDetailRoutes = () => {
  return (
    <Routes>
      <Route path="/:assignmentId" element={<CMSAssignmentDetail />} />
    </Routes>
  );
};

export default CMSAssignmentDetailRoutes;
