import type { IOnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip";

export const tooltipTexts: IOnboardingTooltip[] = [
  {
    key: "set-time",
    title: "Čas procvičování",
    description: (
      <p>Pokud máš na procvičování více času, přidej si, čím více času budeš procvičovat, tím lépe pro Tebe.</p>
    ),
    buttonText: "Rozumím",
    elementId: "timer",
  },
];
