import type { FC } from "react";

import { BookmarkIconAsButton } from "components";
import { TopicAssignmentsNavigation } from "features";

interface Props {
  assignmentId: string;
  combinedId: string;
  onNavigate: (assignmentId: string) => void;
  title: string;
}

const TopicAssignmentDetailHeader: FC<Props> = ({ assignmentId, combinedId, title, onNavigate }) => {
  return (
    <div className="assignment-detail-header">
      <div className="assignment-detail-header__title">
        <h1 className={"mb-0"}>{title}</h1>
      </div>
      <div className={"me-4px px-2"}>
        <BookmarkIconAsButton userAssignmentId={assignmentId} />
      </div>
      <div className="border-start border-1 ps-2">
        <TopicAssignmentsNavigation assignmentId={assignmentId} combinedId={combinedId} onNavigate={onNavigate} />
      </div>
    </div>
  );
};

export default TopicAssignmentDetailHeader;
