import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";
import { Form } from "react-bootstrap";

import Layout from "./_Layout";
import { ABCDOddeleneOption, ABCDOddeleneOptionsText } from "components";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectStructuredAnswer, setStructuredAnswer } from "store/slices/answer";
import type { IOption } from "store/slices/topicPractice";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* cYZzbh1FcF7C1Eq3H7ka */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const ABCDOddelene: FC<Props> = ({ data, onSubmit }) => {
  const questionsCount = data.assignment?.assignment?.questions.length ?? 0;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const selected = useAppSelector(selectStructuredAnswer);
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const handleCheck = (index: number, option: IOption) => {
    const newAnswers = selected.length === 0 ? new Array(questionsCount).fill({ value: undefined }) : [...selected];
    newAnswers[index] = option;

    dispatch(setStructuredAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return (
      selected.length > 0 && selected.every(answer => typeof answer?.value === "string" && answer?.value.length > 0)
    );
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()}>
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      {data.assignment?.assignment?.questions.map((question, qIndex) => {
        return (
          <div key={qIndex} className={"mt-20px"}>
            <Latex>{question.heading ?? ""}</Latex>
            <ABCDOddeleneOptionsText key={`${qIndex}`} qIndex={qIndex} options={question.options} />

            <Form className="mt-20px question">
              <ABCDOddeleneOption
                qIndex={qIndex}
                onClick={handleCheck}
                options={question.options}
                selected={selected}
              />
            </Form>
          </div>
        );
      })}
    </Layout>
  );
};

export default ABCDOddelene;
