export const formatDate = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${date.getMonth() + 1}-${
    date.getDate() < 10 ? "0" : ""
  }${date.getDate()}`;
};

export const getPrevMonday = (date: Date) => {
  const prevMondayOfGivenDate = new Date(date.getTime());
  prevMondayOfGivenDate.setDate(prevMondayOfGivenDate.getDate() - ((prevMondayOfGivenDate.getDay() + 6) % 7));

  return prevMondayOfGivenDate;
};

export const formatShortDate = (date: Date) => {
  return `${date.getDate()}. ${date.getMonth() + 1}.`;
};

export const formatLongDate = (date: Date) => {
  return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;
};

export const getDayNameFromIndex = (dayIndex: number) => {
  switch (dayIndex) {
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
    case 7:
      return "sunday";
    default:
      throw new Error(`Unknown day index: ${dayIndex}`);
  }
};

export const dayNameToDayIndex = (dayName: string) => {
  switch (dayName) {
    case "monday":
      return 1;
    case "tuesday":
      return 2;
    case "wednesday":
      return 3;
    case "thursday":
      return 4;
    case "friday":
      return 5;
    case "saturday":
      return 6;
    case "sunday":
      return 7;
    default:
      throw new Error(`Unknown day name: ${dayName}`);
  }
};

export const calculateLastDayOfMonth = (date: string) => {
  const dateObj = new Date(date);
  return new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0);
};
