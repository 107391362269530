import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { AbilityScore, AnswerExercise, GainedScore } from "components";

import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setAbilityScore, getAssignmentResult, getSessionId, setTimeLearn } from "store/slices/exercise";
import { getAssignmentTime, startLearningTimer, stopAssignmentTimer, stopLearningTimer } from "store/slices/timer";
import type { TSolutionVariant } from "store/slices/exercise";

import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";
import { isSingleAbilityScore, isSelfEvaluationForEveryAssignment } from "utils/valueTranslators";
import { getAbilityScoreOptions } from "utils/changeAbilityScore";
import { logGeneralExerciseError } from "utils/processApiError";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

import { TeacherEvaluationButton } from "features/TeacherEvaluation";

import { ONBOARDING_STORE_ID } from "const";

interface Props {
  assignment: UserAssignmentForExerciseFragment;
  selectedOptions: Array<{ points: number | null; status: TSolutionVariant | null }>;
  timeToLearn?: number;
  sendDataToBe: boolean | null | undefined;
  showAbilityScore: boolean;
  tipDisabled?: boolean;
  onNextClick?: () => void;
}

const SelfEvaluationAnalysis: FC<Props> = ({
  assignment,
  selectedOptions,
  timeToLearn,
  sendDataToBe,
  showAbilityScore,
  tipDisabled = false,
  onNextClick,
}) => {
  const isShared = assignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true;
  const abilityScoreShown =
    sendDataToBe === true &&
    showAbilityScore &&
    assignment.assignment !== undefined &&
    assignment.assignment !== null &&
    isSingleAbilityScore(assignment.assignment);
  const canTeacherEvaluate = assignment.assignment?.scoring?.canTeacherEvaluate === true;

  const { t } = useTranslation(["assignments"]);
  const dispatch = useAppDispatch();
  const assignmentResult = useAppSelector(getAssignmentResult(assignment.id));
  const time = useAppSelector(getAssignmentTime);
  const sessionId = useAppSelector(getSessionId);
  const isOnboarding = sessionId === ONBOARDING_STORE_ID;

  const [progress, setProgress] = useState(false);

  const getScore = () => {
    let totalPoints = 0;
    for (const value of selectedOptions) {
      if (value.points === null) continue;
      totalPoints += value.points;
    }

    return totalPoints;
  };

  const handleNextClick = () => {
    dispatch(stopAssignmentTimer());
    setProgress(true);
  };

  const handleTeacherEvaluationModalOpen = () => {
    dispatch(stopLearningTimer());
  };

  const handleTeacherEvaluationModalHide = () => {
    dispatch(startLearningTimer());
  };

  useEffect(() => {
    if (!progress) return;

    if (onNextClick === undefined) {
      logGeneralExerciseError({ message: "Unable to continue: onNextClick is not defined" }, assignment?.id, undefined);

      return;
    }

    dispatch(setTimeLearn({ assignmentId: assignment.id, timeLearn: time }));
    onNextClick();
    setProgress(false);
  }, [progress]);

  const handleAbilityScoreSelect = (score: number, index?: number) => {
    if (index === undefined) {
      dispatch(setAbilityScore({ assignmentId: assignment.id, score }));
      return;
    }

    let abilityScore = assignmentResult?.abilityScore;

    if (abilityScore === undefined || typeof abilityScore === "number") {
      abilityScore = new Array(selectedOptions.length).fill(undefined);
    }
    const copy = [...abilityScore];
    copy[index] = score;
    dispatch(setAbilityScore({ assignmentId: assignment.id, score: copy }));
  };

  return (
    <>
      <Latex>{assignment.assignment?.assignment?.assignmentText ?? ""}</Latex>

      {isShared ? (
        <div className="mb-3 mt-20px question">
          <AnswerExercise
            subject={assignment.subject?.appName}
            assignment={assignment.assignment}
            type={assignmentResult?.selfEvalAnswers[0]?.status ?? "danger"}
            index={0}
            userAssignmentId={assignment.id}
            timeToLearn={sendDataToBe === true ? timeToLearn : undefined}
            onNext={handleNextClick}
            tipDisabled={tipDisabled}
          >
            {getTextForSelfEvaluationOption(assignmentResult?.selfEvalAnswers[0]?.status ?? null)}
          </AnswerExercise>
        </div>
      ) : (
        assignmentResult?.selfEvalAnswers.map((value, index) => {
          const question = assignment.assignment?.assignment?.questions[index];
          const { imageUrl } = useImageUrlResolver(question?.selfEvaluation?.general?.image);

          return (
            <div key={index}>
              <Latex>{question?.heading ?? ""}</Latex>
              {imageUrl !== null ? <img src={imageUrl} /> : null}

              <div className="mb-3 mt-20px question">
                <AnswerExercise
                  subject={assignment.subject?.appName}
                  assignment={assignment.assignment}
                  type={value.status ?? "danger"}
                  index={index}
                  userAssignmentId={assignment.id}
                  timeToLearn={sendDataToBe === true ? timeToLearn : undefined}
                  onNext={handleNextClick}
                >
                  {getTextForSelfEvaluationOption(value.status)}
                </AnswerExercise>

                {abilityScoreShown &&
                assignment.assignment !== null &&
                isSelfEvaluationForEveryAssignment(assignment.assignment) ? (
                  <>
                    <hr className={"my-25px"} />

                    <AbilityScore
                      isSelfEvaluated={true}
                      options={getAbilityScoreOptions(true, value.status)}
                      selectedValue={
                        typeof assignmentResult?.abilityScore === "number" ? assignmentResult?.abilityScore : 0
                      }
                      onValueSelect={value => {
                        handleAbilityScoreSelect(value, index);
                      }}
                      index={index}
                      key={index}
                    />
                  </>
                ) : null}
              </div>
            </div>
          );
        })
      )}

      {canTeacherEvaluate && !isOnboarding ? (
        <TeacherEvaluationButton
          className="mb-3"
          onModalHide={handleTeacherEvaluationModalHide}
          onModalOpen={handleTeacherEvaluationModalOpen}
          onSubmit={handleTeacherEvaluationModalHide}
          userAssignmentId={assignment.id}
        />
      ) : null}

      <div className="mb-12px mt-32px">
        <GainedScore points={getScore()} maxPoints={assignment.assignment?.scoring?.scoreMax ?? null} />
      </div>

      <div
        className={`exercise__footer  ${
          abilityScoreShown && isSingleAbilityScore(assignment.assignment) ? "exercise__footer--emphasized" : ""
        }`}
      >
        <div className="mw-sm">
          {abilityScoreShown && isSingleAbilityScore(assignment.assignment) ? (
            <AbilityScore
              isSelfEvaluated={true}
              options={getAbilityScoreOptions(true, undefined)}
              selectedValue={typeof assignmentResult?.abilityScore === "number" ? assignmentResult?.abilityScore : 0}
              onValueSelect={handleAbilityScoreSelect}
              index={0}
            />
          ) : null}

          {onNextClick !== undefined ? (
            <Button
              className={"w-100 text-uppercase"}
              onClick={handleNextClick}
              disabled={showAbilityScore && assignmentResult?.abilityScore === undefined}
            >
              {t("continue", { ns: "common" })}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SelfEvaluationAnalysis;
