import type { CSSProperties, FC } from "react";

import { sessionResultEnum } from "utils/calculateSessionResult";

import { useTranslation } from "react-i18next";
import { useDateNames } from "../../utils/hooks/useDateNames";
import { useSessionDescription } from "../../utils/hooks/useSessionDescription";

import SnailSVG from "images/icons/snail.svg";
import StrawberrySVG from "images/icons/strawberry.svg";
import WaterDropSVG from "images/icons/waterDrop.svg";

const getBackgroundImageStyles = (status: string | null) => {
  if (status === null) return undefined;

  const styles: CSSProperties = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  if (status === "strawberry") {
    styles.color = "#FFF";
    styles.backgroundImage = `url(${StrawberrySVG})`;
    styles.backgroundPosition = "55% 25%";
  } else if (status === "slime") {
    styles.color = "#FFF";
    styles.backgroundImage = `url(${SnailSVG})`;
    styles.backgroundPosition = "55% 25%";
  } else if (status === "elixir_of_life") {
    styles.color = "#FFF";
    styles.backgroundImage = `url(${WaterDropSVG})`;
    styles.backgroundPosition = "55% 25%";
  }

  return styles;
};

interface Props {
  date: Date;
  isToday: boolean;
  isFinished: boolean;
  withTest: boolean;
  isDeadline: boolean;
  isPlannedExercise: boolean;
  exerciseDuration: number;
  exerciseRealDuration?: number;
  longDate?: boolean;
  longStatusDescription?: boolean;
  status: string | null;
}

const CalendarDay: FC<Props> = ({
  date,
  isToday,
  isFinished,
  withTest,
  isDeadline,
  isPlannedExercise,
  exerciseDuration,
  exerciseRealDuration = 0,
  longDate = false,
  longStatusDescription = false,
  status,
}) => {
  const renderSessionDescription = () => {
    return (
      <span className="d-none d-md-inline">
        {getSessionDescriptionByType(
          isPlannedExercise
            ? sessionResultEnum.PLANNED
            : isFinished
            ? sessionResultEnum.DONE
            : sessionResultEnum.PLANNED,
        )}
      </span>
    );
  };

  const { getShortDayNameFromDayIndex } = useDateNames();
  const { getSessionDescriptionByType } = useSessionDescription();

  const { t } = useTranslation(["calendar"]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dateMidnight = new Date(date.setHours(0, 0, 0, 0));
  const isFuture = dateMidnight.getTime() > today.getTime();

  const exerciseRealDurationMinutes = Math.round(exerciseRealDuration / 60);

  return (
    <div
      className={`react-calendar__month-view__days__day__inner ${isToday ? "today" : ""}`}
      style={getBackgroundImageStyles(status)}
    >
      {isDeadline ? (
        <div className={"label label-pink"}>{t("dayOfDeadline", { ns: "calendar" })}</div>
      ) : withTest ? (
        <div className={"label label-purple"}>{t("dayOfTest", { ns: "calendar" })}</div>
      ) : null}
      <strong className={isDeadline ? "text-pink" : withTest ? "text-purple" : ""}>
        {longDate ? (
          <>
            {getShortDayNameFromDayIndex(date.getDay())}
            <span className="d-none d-md-inline">{` ${date.getDate()}. ${date.getMonth() + 1}.`}</span>
          </>
        ) : (
          date.getDate()
        )}
      </strong>
      {isPlannedExercise && status === null ? <div className={`status-dot mx-auto d-print-none gray`} /> : null}

      {longStatusDescription ? (
        <div className={`status-text`}>
          {renderSessionDescription()}
          <br />
          {isFuture && isPlannedExercise && (
            <>
              <span className="d-none d-md-inline">
                {exerciseDuration} {t("minute", { ns: "common", count: exerciseDuration })}
              </span>
              <span className="d-md-none">
                {exerciseDuration}
                {t("minuteShort", { ns: "common" })}
              </span>
            </>
          )}

          {!isFuture && (isPlannedExercise || !isFinished) && (
            <>
              <span className="d-none d-md-inline">
                {exerciseRealDurationMinutes} {t("minute", { ns: "common", count: exerciseRealDurationMinutes })}
              </span>
              <span className="d-md-none">
                {exerciseRealDurationMinutes}
                {t("minuteShort", { ns: "common" })}
              </span>
            </>
          )}
        </div>
      ) : null}
      {exerciseDuration > 0 && (
        <time className={isToday ? "text-white" : "text-muted"}>
          {exerciseDuration} {t("minute", { ns: "common", count: exerciseDuration })}
        </time>
      )}
    </div>
  );
};

export default CalendarDay;
