import type { FC } from "react";
import { Image } from "react-bootstrap";

import starMonoEmpty from "images/icons/star-monochrome-empty.svg";
import starMonoFull from "images/icons/star-monochrome-full.svg";
// import starMonoHalf from "images/icons/star-monochrome-half.svg";

import starColorEmpty from "images/icons/star-color-empty.svg";
import starColorFull from "images/icons/star-color-full.svg";
// import starColorHalf from "images/icons/star-color-half.svg";

interface Props {
  rating: number;
  stars?: number;
  color?: boolean;
  className?: string;
}

const StarRating: FC<Props> = ({ rating, className = "", stars = 5, color = false }) => {
  const ratingDecimal = rating * stars;

  /**
     Star rating logic:
    1 star: 0%-20%
    2 stars: > 20% & < 40%
    3 stars: > 40% & < 60%
    4 stars: > 60% & < 80%
    5 stars: > 80%
   */

  return (
    <>
      <div className={`star-rating ${className}`}>
        {[...Array(stars)].map((e, i) => {
          let starSrc;

          if (ratingDecimal >= i) {
            starSrc = !color ? starMonoFull : starColorFull;
          } else {
            starSrc = !color ? starMonoEmpty : starColorEmpty;
          }

          return <Image key={i} src={starSrc} />;
        })}
      </div>
    </>
  );
};

export default StarRating;
