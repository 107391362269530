import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";

import AnalysisLayout from "./_AnalysisLayout";
import SelfEvaluation from "./_SelfEvaluation";

import { AnswerPractice } from "components";

import { useLazyGetAssignmentAnswersQuery } from "api/generated";
import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { selectStructuredAnswer } from "store/slices/answer";

import { getSolutionVariant } from "utils/getSolutionVariant";
import { removeWrapperHTML } from "utils/removeHTML";
import { ABCDOptionText } from "utils/formatTextForAnalysis";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { processApiError } from "utils/processApiError";

/* cYZzbh1FcF7C1Eq3H7ka */

interface Props {
  data: UserAssignmentForExerciseFragment;
}

const ABCDOddelene: FC<Props> = ({ data }) => {
  const assignment = data.assignment;
  if (assignment === null || assignment === undefined) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const selected = useAppSelector(selectStructuredAnswer);

  const [trigger, { data: answerData, isLoading, error: apiLazyError }] = useLazyGetAssignmentAnswersQuery();
  const [answers, setAnswers] = useState<Array<Array<string | null>> | undefined>(undefined);
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  useEffect(() => {
    trigger({ assignmentId: assignment.id }).catch(error => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  useEffect(() => {
    const responseAnswers = answerData?.assignments.map(
      answerAssignment =>
        answerAssignment.assignment?.questions.map(question => {
          if (assignment.assignment?.isAnswerCaseSensitive === true) return question.correctAnswers;
          return question.correctAnswers
            .filter((answer): answer is string => answer !== null)
            .map(answer => answer.toLocaleLowerCase());
        }),
    );

    if (responseAnswers === undefined || responseAnswers[0] === undefined || responseAnswers[0].length === 0) {
      return;
    }

    setAnswers(responseAnswers[0]);
  }, [answerData]);

  if (isSelfEvaluation) {
    return <SelfEvaluation userAssignment={data} />;
  }

  return (
    <AnalysisLayout>
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      {isLoading
        ? null
        : assignment.assignment?.questions.map((question, qIndex) => {
            const type = getSolutionVariant(
              selected[qIndex]?.value,
              answers,
              qIndex,
              assignment.assignment?.isAnswerCaseSensitive ?? false,
            );

            const text = ABCDOptionText(selected[qIndex]);

            return (
              <div key={qIndex} className={"mt-20px"}>
                <div>
                  <Latex>{question.heading ?? ""}</Latex>

                  {question.options.map((option, oIndex) => {
                    return (
                      <p key={`${qIndex}-${option.value ?? oIndex}`}>
                        <span>{`${option.value ?? ""}) `}</span>
                        <span
                          dangerouslySetInnerHTML={{ __html: removeWrapperHTML(option.additionalText ?? "") }}
                        ></span>
                      </p>
                    );
                  })}
                </div>

                <AnswerPractice assignment={assignment} index={qIndex} type={type} userAssignmentId={data.id}>
                  {text}
                </AnswerPractice>
              </div>
            );
          })}
    </AnalysisLayout>
  );
};

export default ABCDOddelene;
