import type { FC, MouseEvent } from "react";
import { Trans } from "react-i18next";
import { Alert } from "react-bootstrap";

import { Link } from "react-router-dom";

import { generateImagePDF } from "utils/generateImagePDF";

interface Props {
  imagesUrl: string[];
}

const IsGeometry: FC<Props> = ({ imagesUrl }) => {
  const handleButtonClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    generateImagePDF(imagesUrl).catch(console.error);
  };

  return (
    <>
      <Alert variant={"danger"} className={"alert--icon-danger"}>
        <Trans
          i18nKey="warning.geometryExercise"
          ns="exercise"
          components={{
            lnk: <Link to="#" onClick={handleButtonClick} />,
            strong: <strong />,
          }}
        />
      </Alert>
    </>
  );
};

export default IsGeometry;
