import { useEffect, useState } from "react";
import type { FC } from "react";

import { RewardsOverview } from "components";
import type { IMonth, IPoints } from "pages/Rewards/Rewards";

import type { GetStatisticsDataQuery } from "api/generated";

interface IRewards {
  monthRewards: IMonth[];
  pointsRewards: IPoints[];
}

interface Props {
  data: GetStatisticsDataQuery["parentWatch"] | undefined;
}

const Rewards: FC<Props> = ({ data }) => {
  const [rewards, setRewards] = useState<IRewards>({ monthRewards: [], pointsRewards: [] });

  useEffect(() => {
    if (data === undefined) return;
    const listOfMonthRewards: IMonth[] = [];
    const listOfTestRewards: IPoints[] = [];

    for (const reward of data.rewards) {
      if (reward.month !== undefined && reward.month !== null) {
        const monthFix: IMonth = { ...reward, month: reward.month - 1 };
        listOfMonthRewards.push(monthFix);
      }
      if (reward.targetScore !== undefined && reward.targetScore !== null) listOfTestRewards.push(reward as IPoints);
    }

    setRewards({ monthRewards: listOfMonthRewards, pointsRewards: listOfTestRewards });
  }, [data]);

  return <RewardsOverview rewards={rewards} message={data?.message ?? ""} />;
};

export default Rewards;
