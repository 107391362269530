import type { FC } from "react";
import Select from "react-select";

import type { IOption } from "store/slices/topicPractice";

interface Props {
  index: number;
  placeholder: string;
  options: IOption[];
  onChange: (index: number, value: string) => void;
}

const Option: FC<Props> = ({ index, placeholder, options, onChange }) => {
  return (
    <Select
      name="subject"
      className={"react-select-container"}
      classNamePrefix="react-select"
      placeholder={placeholder}
      options={options.map(option => {
        return {
          value: option.value,
          label: option.value,
        };
      })}
      onChange={e => {
        onChange(index, e?.value ?? "");
      }}
    />
  );
};

export default Option;
