import { useState, useEffect } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";

import Layout from "./_Layout";

import { AchievementBadge } from "features";

import type { IBadgeResult } from "../Badges";

interface Props {
  data: IBadgeResult[];
  onClick: (type: string) => void;
}

const Plan: FC<Props> = ({ data, onClick }) => {
  const { t } = useTranslation();
  const [badges, setBadges] = useState<IBadgeResult[]>([]);

  useEffect(() => {
    const badges = data.reduce((accumulator, badge) => {
      const savedBadge = accumulator.get(badge.type);
      if (savedBadge === undefined) {
        const count = typeof badge.count === "number" && badge.isEarned ? 1 : badge.count;
        accumulator.set(badge.type, { ...badge, count });
        return accumulator;
      }

      savedBadge.count = typeof savedBadge.count === "number" ? savedBadge.count + 1 : savedBadge.count;
      accumulator.set(badge.type, savedBadge);
      return accumulator;
    }, new Map<string, IBadgeResult>());

    setBadges(Array.from(badges.values()));
  }, [data]);

  return (
    <Layout title={t("sectionTitle.planRewards", { ns: "statistics" })}>
      <>
        {badges.map(badge => (
          <Col xs={4} sm={6} md={3} lg={2} key={badge.type}>
            <AchievementBadge
              icon={badge.icon}
              count={badge.count}
              isEarned={badge.isEarned}
              title={badge.title}
              type={badge.type}
              onClick={onClick}
            />
          </Col>
        ))}
      </>
    </Layout>
  );
};

export default Plan;
