import type { FC } from "react";

interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
}

const Full: FC<Props> = ({ foregroundColor, backgroundColor }) => {
  return (
    <svg width="71" height="81" viewBox="0 0 71 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      {backgroundColor !== null ? (
        <path
          d="M0.867798 56.4545L1.4928 62.783L7.97792 66.8458L25.0102 71.6117L41.5737 67.6271L57.3559 49.6573L68.294 34.3438L66.9658 33.172L60.6373 31.4531L53.2931 30.2812L38.761 28.0935H35.3233L32.5106 29.578L30.0105 32.0781L26.8072 34.3438L21.8069 37.9378L0.867798 56.4545Z"
          fill={backgroundColor}
        />
      ) : (
        ""
      )}
      <path
        opacity="0.3"
        d="M0.867798 56.4545L1.4928 62.783L7.97792 66.8458L25.0102 71.6117L41.5737 67.6271L57.3559 49.6573L68.294 34.3438L66.9658 33.172L60.6373 31.4531L53.2931 30.2812L38.761 28.0935H35.3233L32.5106 29.578L30.0105 32.0781L26.8072 34.3438L21.8069 37.9378L0.867798 56.4545Z"
        fill="#34322E"
      />
      <path
        d="M0.598267 56.5613L17.5544 41.559C19.7344 39.5606 24.7701 35.2152 27.4733 33.82C30.8522 32.0761 31.8332 27.7161 37.2832 28.0431C42.7331 28.3701 58.6469 31.0951 60.3909 31.4221C61.7861 31.6837 65.2595 32.621 66.8219 33.057"
        stroke="#34322E"
      />
      <path d="M38.1285 29.5098C38.3119 30.326 38.5686 32.5782 38.1285 35.057" stroke="#34322E" />
      <path
        d="M48.0446 63.1444C49.0856 62.0129 57.2326 52.3724 58.2283 50.7882C59.0078 49.5483 63.9259 43.1882 66.455 39.8565C66.5108 39.783 66.588 39.5799 66.2853 39.5173C63.7214 38.9873 58.7004 37.8379 57.5004 37.8379C56.0004 37.8379 55.5004 36.8379 52.0004 36.8379H30.0004C25.8337 40.8379 16.2004 49.7379 11.0004 53.3379C5.8004 56.9379 3.35471 59.4485 2.69202 60.3574C2.61389 60.5137 2.67049 60.6065 2.75587 60.6289C4.99829 61.2172 10.0907 62.4468 14.4159 63.1444C20.0282 64.0496 32.2486 66.4032 33.7422 66.4032C35.2358 66.4032 37.1808 67.3379 38.5004 67.3379H43.5004C44.5867 67.3379 47.0036 64.2759 48.0446 63.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M3.44055 60.3232C7.38016 58.7776 15.9762 55.4149 18.8434 54.3293C22.4275 52.9721 31.7662 47.7133 32.4489 47.0347C33.1315 46.3562 32.4489 44.377 32.4489 42.2848C32.4489 40.7254 32.2499 38.5151 30.0739 36.9695"
        stroke="#78746D"
      />
      <path
        d="M48.0446 60.1444C49.0856 59.0129 57.2326 49.3724 58.2283 47.7882C59.0078 46.5483 63.9259 40.1882 66.455 36.8565C66.5108 36.783 66.588 36.5799 66.2853 36.5173C63.7214 35.9873 58.7004 34.8379 57.5004 34.8379C56.0004 34.8379 55.5004 33.8379 52.0004 33.8379H30.0004C25.8337 37.8379 16.2004 46.7379 11.0004 50.3379C5.8004 53.9379 3.35471 56.4485 2.69202 57.3574C2.61389 57.5137 2.67049 57.6065 2.75587 57.6289C4.99829 58.2172 10.0907 59.4468 14.4159 60.1444C20.0282 61.0496 32.2486 63.4032 33.7422 63.4032C35.2358 63.4032 37.1808 64.3379 38.5004 64.3379H43.5004C44.5867 64.3379 47.0036 61.2759 48.0446 60.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.0446 57.1444C49.0856 56.0129 57.2326 46.3724 58.2283 44.7882C59.0078 43.5483 63.9259 37.1882 66.455 33.8565C66.5108 33.783 66.588 33.5799 66.2853 33.5173C63.7214 32.9873 58.7004 31.8379 57.5004 31.8379C56.0004 31.8379 55.5004 30.8379 52.0004 30.8379H30.0004C25.8337 34.8379 16.2004 43.7379 11.0004 47.3379C5.8004 50.9379 3.35471 53.4485 2.69202 54.3574C2.61389 54.5137 2.67049 54.6065 2.75587 54.6289C4.99829 55.2172 10.0907 56.4468 14.4159 57.1444C20.0282 58.0496 32.2486 60.4032 33.7422 60.4032C35.2358 60.4032 37.1808 61.3379 38.5004 61.3379H43.5004C44.5867 61.3379 47.0036 58.2759 48.0446 57.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.0446 54.1444C49.0856 53.0129 57.2326 43.3724 58.2283 41.7882C59.0078 40.5483 63.9259 34.1882 66.455 30.8565C66.5108 30.783 66.588 30.5799 66.2853 30.5173C63.7214 29.9873 58.7004 28.8379 57.5004 28.8379C56.0004 28.8379 55.5004 27.8379 52.0004 27.8379H30.0004C25.8337 31.8379 16.2004 40.7379 11.0004 44.3379C5.8004 47.9379 3.35471 50.4485 2.69202 51.3574C2.61389 51.5137 2.67049 51.6065 2.75587 51.6289C4.99829 52.2172 10.0907 53.4468 14.4159 54.1444C20.0282 55.0496 32.2486 57.4032 33.7422 57.4032C35.2358 57.4032 37.1808 58.3379 38.5004 58.3379H43.5004C44.5867 58.3379 47.0036 55.2759 48.0446 54.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.0446 51.1444C49.0856 50.0129 57.2326 40.3724 58.2283 38.7882C59.0078 37.5483 63.9259 31.1882 66.455 27.8565C66.5108 27.783 66.588 27.5799 66.2853 27.5173C63.7214 26.9873 58.7004 25.8379 57.5004 25.8379C56.0004 25.8379 55.5004 24.8379 52.0004 24.8379H30.0004C25.8337 28.8379 16.2004 37.7379 11.0004 41.3379C5.8004 44.9379 3.35471 47.4485 2.69202 48.3574C2.61389 48.5137 2.67049 48.6065 2.75587 48.6289C4.99829 49.2172 10.0907 50.4468 14.4159 51.1444C20.0282 52.0496 32.2486 54.4032 33.7422 54.4032C35.2358 54.4032 37.1808 55.3379 38.5004 55.3379H43.5004C44.5867 55.3379 47.0036 52.2759 48.0446 51.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.0446 48.1444C49.0856 47.0129 57.2326 37.3724 58.2283 35.7882C59.0078 34.5483 63.9259 28.1882 66.455 24.8565C66.5108 24.783 66.588 24.5799 66.2853 24.5173C63.7214 23.9873 58.7004 22.8379 57.5004 22.8379C56.0004 22.8379 55.5004 21.8379 52.0004 21.8379H30.0004C25.8337 25.8379 16.2004 34.7379 11.0004 38.3379C5.8004 41.9379 3.35471 44.4485 2.69202 45.3574C2.61389 45.5137 2.67049 45.6065 2.75587 45.6289C4.99829 46.2172 10.0907 47.4468 14.4159 48.1444C20.0282 49.0496 32.2486 51.4032 33.7422 51.4032C35.2358 51.4032 37.1808 52.3379 38.5004 52.3379H43.5004C44.5867 52.3379 47.0036 49.2759 48.0446 48.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.0446 45.1444C49.0856 44.0129 57.2326 34.3724 58.2283 32.7882C59.0078 31.5483 63.9259 25.1882 66.455 21.8565C66.5108 21.783 66.588 21.5799 66.2853 21.5173C63.7214 20.9873 58.7004 19.8379 57.5004 19.8379C56.0004 19.8379 55.5004 18.8379 52.0004 18.8379H30.0004C25.8337 22.8379 16.2004 31.7379 11.0004 35.3379C5.8004 38.9379 3.35471 41.4485 2.69202 42.3574C2.61389 42.5137 2.67049 42.6065 2.75587 42.6289C4.99829 43.2172 10.0907 44.4468 14.4159 45.1444C20.0282 46.0496 32.2486 48.4032 33.7422 48.4032C35.2358 48.4032 37.1808 49.3379 38.5004 49.3379H43.5004C44.5867 49.3379 47.0036 46.2759 48.0446 45.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.0446 42.1444C49.0856 41.0129 57.2326 31.3724 58.2283 29.7882C59.0078 28.5483 63.9259 22.1882 66.455 18.8565C66.5108 18.783 66.588 18.5799 66.2853 18.5173C63.7214 17.9873 58.7004 16.8379 57.5004 16.8379C56.0004 16.8379 55.5004 15.8379 52.0004 15.8379H30.0004C25.8337 19.8379 16.2004 28.7379 11.0004 32.3379C5.8004 35.9379 3.35471 38.4485 2.69202 39.3574C2.61389 39.5137 2.67049 39.6065 2.75587 39.6289C4.99829 40.2172 10.0907 41.4468 14.4159 42.1444C20.0282 43.0496 32.2486 45.4032 33.7422 45.4032C35.2358 45.4032 37.1808 46.3379 38.5004 46.3379H43.5004C44.5867 46.3379 47.0036 43.2759 48.0446 42.1444Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M3.44055 39.3232C7.38016 37.7776 15.9762 34.4149 18.8434 33.3293C22.4275 31.9721 33.7662 27.7133 34.4489 27.0347C35.1315 26.3562 33.9517 23.2233 33.9517 21.1311C33.9517 19.5716 32.2499 17.5151 30.0739 15.9695"
        stroke="#78746D"
      />
      <path
        d="M31.6215 24.7928C31.3256 24.9592 30.8225 25.3809 31.1776 25.736"
        stroke="#78746D"
        strokeLinecap="round"
      />
      {foregroundColor !== null ? (
        <path
          d="M14.2653 59.8379L0.765259 56.8379L2.26526 66.8379C2.09859 67.5046 2.36526 68.8379 4.76526 68.8379C7.76526 68.8379 18.7653 72.3379 22.7653 73.3379C26.7653 74.3379 31.2653 73.8379 36.7653 74.3379C42.2653 74.8379 43.0153 74.3379 44.2653 73.3379C46.7653 71.3379 47.2653 69.3379 50.2653 65.8379C53.2653 62.3379 59.7653 53.8379 60.7653 51.8379C61.7653 49.8379 68.2653 44.3379 69.2653 43.3379C70.2653 42.3379 69.7653 38.3379 70.2653 36.3379C70.6653 34.7379 67.7653 33.3379 66.2653 32.8379C66.9319 33.1712 68.2653 33.9379 68.2653 34.3379C68.2653 34.8379 66.7653 35.3379 65.7653 36.3379C64.7653 37.3379 61.7653 41.3379 60.2653 43.3379C58.7653 45.3379 57.2653 46.8379 54.7653 49.3379C52.7653 51.3379 45.2653 60.5046 41.7653 64.8379C39.2653 64.3379 33.9653 63.2379 32.7653 62.8379C31.5653 62.4379 27.5986 62.1712 24.7653 61.8379C24.7653 63.3379 24.8653 65.7379 21.2653 65.3379C17.6653 64.9379 14.7653 61.0046 14.2653 59.8379Z"
          fill={foregroundColor}
        />
      ) : (
        ""
      )}
      <path
        d="M14.2653 59.8379L0.765259 56.8379L2.26526 66.8379C2.09859 67.5046 2.36526 68.8379 4.76526 68.8379C7.76526 68.8379 18.7653 72.3379 22.7653 73.3379C26.7653 74.3379 31.2653 73.8379 36.7653 74.3379C42.2653 74.8379 43.0153 74.3379 44.2653 73.3379C46.7653 71.3379 47.2653 69.3379 50.2653 65.8379C53.2653 62.3379 59.7653 53.8379 60.7653 51.8379C61.7653 49.8379 68.2653 44.3379 69.2653 43.3379C70.2653 42.3379 69.7653 38.3379 70.2653 36.3379C70.6653 34.7379 67.7653 33.3379 66.2653 32.8379C66.9319 33.1712 68.2653 33.9379 68.2653 34.3379C68.2653 34.8379 66.7653 35.3379 65.7653 36.3379C64.7653 37.3379 61.7653 41.3379 60.2653 43.3379C58.7653 45.3379 57.2653 46.8379 54.7653 49.3379C52.7653 51.3379 45.2653 60.5046 41.7653 64.8379C39.2653 64.3379 33.9653 63.2379 32.7653 62.8379C31.5653 62.4379 27.5986 62.1712 24.7653 61.8379C24.7653 63.3379 24.8653 65.7379 21.2653 65.3379C17.6653 64.9379 14.7653 61.0046 14.2653 59.8379Z"
        stroke="#34322E"
      />
      <path d="M41.8785 66.8557C42.0619 67.672 42.3186 69.9242 41.8785 72.403" stroke="#34322E" />
      <path
        d="M18.0709 14.9406C18.3211 15.6914 18.2679 16.0634 18.1636 16.4979M18.1274 19.3112C18.1893 19.5722 18.2378 20.089 18.1636 20.7736M19.3928 18.0458C20.109 18.0822 21.015 18.0934 21.2043 18.006M16.762 17.9149C16.4161 17.8846 15.8876 17.8421 15.1593 17.9149"
        stroke="#78746D"
        strokeLinecap="round"
      />
      <path
        d="M44.3703 3C44.5364 3.49826 44.501 3.74521 44.4318 4.03355M44.4078 5.90074C44.4489 6.07396 44.4811 6.41696 44.4318 6.87134M45.2477 5.0609C45.723 5.08507 46.3243 5.09248 46.45 5.03447M43.5016 4.97405C43.272 4.9539 42.9213 4.92571 42.4379 4.97405"
        stroke="#78746D"
        strokeLinecap="round"
      />
      <path
        d="M63.858 1C64.3238 2.39751 64.2246 3.09017 64.0306 3.89891M63.9632 9.136C64.0783 9.62183 64.1686 10.5839 64.0306 11.8583M66.3188 6.7804C67.652 6.84819 69.3385 6.86897 69.691 6.70628M61.4214 6.5368C60.7774 6.48031 59.7937 6.40122 58.4379 6.5368"
        stroke="#78746D"
        strokeLinecap="round"
      />
      <path
        d="M62.5923 61.7211C62.7678 62.2477 62.7304 62.5088 62.6573 62.8135M62.6319 64.7871C62.6753 64.9702 62.7093 65.3328 62.6573 65.8131M63.5196 63.8994C64.0221 63.925 64.6576 63.9328 64.7905 63.8715M61.674 63.8076C61.4313 63.7863 61.0606 63.7565 60.5497 63.8076"
        stroke="#78746D"
        strokeLinecap="round"
      />
      <path
        d="M4.73564 21.7515C4.8249 22.0193 4.8059 22.1521 4.76871 22.307M4.75581 23.3106C4.77786 23.4037 4.79517 23.588 4.76871 23.8322M5.2072 22.8592C5.46267 22.8722 5.78584 22.8762 5.85339 22.845M4.26873 22.8125C4.14533 22.8017 3.95683 22.7865 3.69702 22.8125"
        stroke="#78746D"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Full;
