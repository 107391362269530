import { useState, useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { getPhoneValidationDatetimeLast } from "store/slices/user";
import { RESEND_CODE_TIMEOUT } from "const";

const useGetTimeToResendCode = () => {
  const [remainingTime, setRemainingTime] = useState<number>(RESEND_CODE_TIMEOUT);
  const [isResendDisabled, setResendDisabled] = useState(true);
  const phoneValidationDateTimeLast = useAppSelector(getPhoneValidationDatetimeLast);

  const calculateRemainingTime = () => {
    const now = new Date();
    if (phoneValidationDateTimeLast !== undefined && phoneValidationDateTimeLast !== null) {
      const diff = now.getTime() - new Date(phoneValidationDateTimeLast).getTime();
      const remaining = RESEND_CODE_TIMEOUT - Math.floor(diff / 1000);
      setRemainingTime(remaining > 0 ? remaining : 0);
      setResendDisabled(remaining > 0);
    } else {
      setResendDisabled(true);
    }
  };

  useEffect(() => {
    calculateRemainingTime();
  }, [phoneValidationDateTimeLast]);

  useEffect(() => {
    if (remainingTime <= 0) {
      setResendDisabled(false);
      return;
    }

    const interval = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [remainingTime]);

  return { remainingTime, isResendDisabled };
};

export default useGetTimeToResendCode;
