import FileInput from "components/FileInput/FileInput";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useTeacherEvaluationUpload } from "./useTeacherEvaluationUpload";

interface Props {
  className?: string;
  onSuccess?: () => void;
  token: string;
  userAssignmentId: string;
}

const TeacherEvaluationUploadForm: React.FC<Props> = ({ className, onSuccess, token, userAssignmentId }) => {
  const { t } = useTranslation(["teacherEvaluation"]);
  const { isError, isUploading, isSuccess, isVerificationError, upload } = useTeacherEvaluationUpload({
    token,
    userAssignmentId,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files === null || files?.length === 0) return;

    if (files.length > 1) {
      alert("Můžeš nahrát pouze jeden soubor");
      return;
    }

    const file = files[0];

    upload(file)
      .then(() => {
        if (onSuccess !== undefined) onSuccess();
      })
      .catch(console.error);
  };

  return (
    <div className={`d-flex flex-column align-items-center justify-content-center flex-grow-1 ${className ?? ""}`}>
      {(() => {
        switch (true) {
          case isUploading:
            return <div>{t("uploadStateUploading")}</div>;
          case isSuccess:
            return <div className="text-success">{t("uploadStateSuccess")}</div>;
          case isVerificationError:
            return <div className="text-danger">{t("uploadStateVerificationError")}</div>;
          default:
            return (
              <Form className="d-flex flex-column align-items-center">
                <h1 className="mb-4">{t("uploadTitle")}</h1>
                {isError && <div className="text-danger">{t("uploadStateError")}</div>}
                <FileInput accept="image/*" className="mt-2" label={t("uploadLabel")} onChange={handleChange} />
              </Form>
            );
        }
      })()}
    </div>
  );
};

export default TeacherEvaluationUploadForm;
