import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { Fragment, useEffect, useState } from "react";
import type { FC } from "react";

import Layout from "./_Layout";

import { AnswerPractice, ABCDOptions } from "components";
import { PracticeSelfEvaluation } from "features";

import { useLazyGetAssignmentAnswersQuery } from "api/generated";
import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { getAnswerVariants, getCombinedAnswer, setAnswer, setAssignmentEvaluation } from "store/slices/topicPractice";
import type { IOption } from "store/slices/topicPractice";
import { setIsTimerShown } from "store/slices/timer";

import { evaluateABCD, getSolutionVariant } from "utils/getSolutionVariant";
import { ABCDOptionText } from "utils/formatTextForAnalysis";
import { useSavePracticeSelfEvaluation } from "utils/hooks/useSavePracticeSelfEvaluation";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { eventEmitter, Events } from "utils/events/EventEmitter";
import { processApiError } from "utils/processApiError";
import { useEmptyAssignmentAnswer } from "utils/hooks/useEmptyAssignmentAnswer";

/* ky8UIEVUI2t6j78KohBn */

interface Props {
  data: UserAssignmentDetailFragment;
  onNextClick: () => void;
}

const ABCD: FC<Props> = ({ data, onNextClick }) => {
  const questionsCount = data.assignment?.assignment?.questions.length ?? 0;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const savePracticeSelfEvaluation = useSavePracticeSelfEvaluation();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(getCombinedAnswer(data.id));
  const answerVariants = useAppSelector(getAnswerVariants(data.id));

  const [trigger, { data: answerData, isLoading, error: apiLazyError }] = useLazyGetAssignmentAnswersQuery();
  const [answers, setAnswers] = useState<Array<Array<string | null>> | undefined>(undefined);
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);
  const { setEmptyPracticeAnswer } = useEmptyAssignmentAnswer();

  useEffect(() => {
    dispatch(setAnswer({ assignmentId: data.id, answers: new Array(questionsCount).fill({ value: undefined }) }));
    eventEmitter.dispatch(Events.ONBOARDING_UPDATE);
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);

    setEmptyPracticeAnswer(data.id, evaluateAnswers);
    setAnswers([]);
  }, [apiLazyError]);

  useEffect(() => {
    const responseAnswers = answerData?.assignments.map(
      assignment =>
        assignment.assignment?.questions.map(question => {
          if (data.assignment?.assignment?.isAnswerCaseSensitive === true) {
            return question.correctAnswers;
          }

          return question.correctAnswers
            .filter((answer): answer is string => answer !== null)
            .map(answer => answer.toLocaleLowerCase());
        }),
    );

    if (responseAnswers === undefined || responseAnswers[0] === undefined || responseAnswers[0].length === 0) {
      return;
    }

    dispatch(setIsTimerShown(false));
    dispatch(
      setAssignmentEvaluation({
        assignmentId: data.id,
        status: evaluateABCD(selected, responseAnswers[0]),
        answerVariants: evaluateAnswers(responseAnswers[0]),
      }),
    );
    setAnswers(responseAnswers[0]);
  }, [answerData]);

  const evaluateAnswers = (answers: Array<Array<string | null>>) => {
    if (data.assignment?.assignment === undefined || data.assignment?.assignment === null) return [];

    return data.assignment.assignment.questions.map((_question, qIndex) => {
      return getSolutionVariant(
        selected[qIndex].value,
        answers,
        qIndex,
        data.assignment?.assignment?.isAnswerCaseSensitive ?? false,
      );
    });
  };

  const onSubmit = () => {
    if (data.assignment?.id === undefined) return;

    trigger({ assignmentId: data.assignment.id }).catch(error => {
      console.error(error);
    });
  };

  const handleCheck = (index: number, option: IOption) => {
    const copy = [...selected];
    copy[index] = option;
    dispatch(setAnswer({ assignmentId: data.id, answers: copy }));
  };

  const areAnswersFilled = () => {
    return selected.length > 0 && selected.every(answer => typeof answer.value === "string" && answer.value.length > 0);
  };

  if (isSelfEvaluation) {
    if (data.assignment === undefined || data.assignment === null) return null;

    return (
      <PracticeSelfEvaluation
        userAssignment={data}
        userAssignmentId={data.id}
        onNextClick={onNextClick}
        saveAnswer={savePracticeSelfEvaluation}
      />
    );
  }

  return (
    <Layout
      onSubmit={onSubmit}
      isSubmitDisabled={!areAnswersFilled() || isLoading}
      isAnalysis={answers !== undefined}
      goNext={onNextClick}
    >
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} alt={""} /> : null}

      {data.assignment?.assignment?.questions.map((question, qIndex) => {
        if (answers === undefined) {
          return (
            <div key={qIndex}>
              <Latex>{question.heading ?? ""}</Latex>
              <ABCDOptions options={question.options} qIndex={qIndex} value={selected[qIndex]} onClick={handleCheck} />
            </div>
          );
        }

        if (data.assignment === undefined || data.assignment === null) return null;

        return (
          <Fragment key={`answer-${qIndex}`}>
            <strong>
              <Latex>{question.heading ?? ""}</Latex>
            </strong>

            <AnswerPractice
              index={qIndex}
              assignment={data.assignment}
              type={answerVariants?.[qIndex] ?? "incorrect"}
              userAssignmentId={data.id}
              subject={data.subject?.appName}
            >
              {ABCDOptionText(selected[qIndex])}
            </AnswerPractice>
          </Fragment>
        );
      })}
    </Layout>
  );
};

export default ABCD;
