import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PracticePageContent from "../_PracticePageContent";

import { useGetSuperTopicPracticeAssignmentsQuery } from "api/generated";
import type { UserAssignmentPracticeFragment } from "api/generated";

import { processApiError } from "utils/processApiError";

const SuperTopicPractice = () => {
  const { superTopicId } = useParams();
  if (superTopicId === undefined) return null;

  const { data, isLoading, error: apiError } = useGetSuperTopicPracticeAssignmentsQuery({ superTopicId });

  const [allAssignments, setAllAssignments] = useState<UserAssignmentPracticeFragment[] | null>(null);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const assignments = data.userAssignments.items.filter(assignment => assignment.assignment?.isActive);
    setAllAssignments(assignments);
  }, [data]);

  return (
    <PracticePageContent
      isLoading={isLoading}
      assignments={allAssignments}
      title={data?.superTopics[0]?.name ?? ""}
      topicId={superTopicId}
    />
  );
};

export default SuperTopicPractice;
