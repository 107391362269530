import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { Fragment } from "react";

import type { FC } from "react";

import Layout from "./_Layout";

import { ABCDOptions } from "components";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectStructuredAnswer, setStructuredAnswer } from "store/slices/answer";
import type { IOption } from "store/slices/topicPractice";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* ky8UIEVUI2t6j78KohBn */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const ABCD: FC<Props> = ({ data, onSubmit }) => {
  const questionsCount = data.assignment?.assignment?.questions.length ?? 0;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const selected = useAppSelector(selectStructuredAnswer);
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const handleCheck = (index: number, option: IOption) => {
    const newAnswers = selected?.length === 0 ? new Array(questionsCount).fill({ value: undefined }) : [...selected];
    newAnswers[index] = option;

    dispatch(setStructuredAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return (
      selected.length > 0 && selected.every(answer => typeof answer?.value === "string" && answer.value.length > 0)
    );
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()}>
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      {data.assignment?.assignment?.questions.map((question, qIndex) => (
        <Fragment key={qIndex}>
          <Latex>{question.heading ?? ""}</Latex>
          <ABCDOptions options={question.options} qIndex={qIndex} value={selected[qIndex]} onClick={handleCheck} />
        </Fragment>
      ))}
    </Layout>
  );
};

export default ABCD;
