import type { FC } from "react";
import { ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  type: "success" | "warning" | "danger" | undefined;
  isChecked: boolean;
  index: number;
  isDisabled?: boolean;
  onClick: (index: number, value: boolean) => void;
}

const Tip: FC<Props> = ({ type, isChecked, index, isDisabled = false, onClick }) => {
  // Show only, if answer is not wrong
  if (type !== "success" && type !== "warning") return null;

  // If on results screen, show only if is checked
  if (isDisabled && !isChecked) return null;

  const { t } = useTranslation(["exercise"]);

  return (
    <ToggleButton
      value={1}
      variant={"select"}
      className={"btn-shadow btn-emoji btn-emoji--thin"}
      type="checkbox"
      checked={isChecked || isDisabled}
      id={`toggle-check_${index}`}
      onChange={e => {
        onClick(index, e.currentTarget.checked);
      }}
      title={t("btnTitle.guessed", { ns: "exercise" })}
      disabled={isDisabled}
    >
      <div id="tip">
        {t("btn.guessed", { ns: "exercise" })}
        {isDisabled ? <small>tipnuto</small> : null}
      </div>
    </ToggleButton>
  );
};

export default Tip;
