import type { FC } from "react";

import SelfEvaluationAnalysis from "features/SelfEvaluation/Practice/SelfEvaluationAnalysis";

import type { IAssignmentResult } from "store/slices/topicPractice";

/* rL4n9VXYXv2RntyoGQlj */

interface Props {
  assignmentResult: IAssignmentResult;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const Rysovani: FC<Props> = ({ assignmentResult, toggleModals }) => {
  const assignment = assignmentResult.assignment.assignment;
  if (assignment === undefined || assignment === null) return null;

  return (
    <SelfEvaluationAnalysis
      assignment={assignmentResult.assignment}
      selectedOptions={assignmentResult.selfEvalAnswers}
      onNextClick={undefined}
      timeToLearn={undefined}
    />
  );
};

export default Rysovani;
