import { Fragment, useEffect, useMemo, useState } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { DotNavigation, ExerciseBar } from "features";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetAllTimers, startGlobalTimer, stopAllTimers, setIsTimerShown } from "store/slices/timer";
import { getAllResults, getSelectedTime } from "store/slices/topicPractice";

import { getAssignmentPracticeByType } from "utils/getAssignmentByType";
import { eventEmitter, Events } from "utils/events/EventEmitter";
import { gtmExerciseQuit, gtmTaskEvent } from "../../utils/gtmEvents";
import { AnimatedCapybaraResult } from "../../components";

interface Props {
  assignments: UserAssignmentDetailFragment[];
  redirectURL?: string;
}

const Practice: FC<Props> = ({ assignments, redirectURL = "/" }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const results = useAppSelector(getAllResults);
  const selectedTime = useAppSelector(getSelectedTime);

  const [activeAssignment, setActiveAssignment] = useState<UserAssignmentDetailFragment | undefined>(
    assignments[0] ?? undefined,
  );

  const practiceTotalTime = useMemo(() => {
    const totalTime = assignments.reduce((accumulator, assignment) => {
      if (typeof assignment.timeToLearn === "number") accumulator += assignment.timeToLearn;
      if (typeof assignment.timeToSolve === "number") accumulator += assignment.timeToSolve;

      return accumulator;
    }, 0);

    return selectedTime === undefined ? totalTime : Math.min(selectedTime * 60, totalTime);
  }, [assignments]);

  useEffect(() => {
    dispatch(resetAllTimers());
    dispatch(startGlobalTimer());
    dispatch(setIsTimerShown(true));

    return () => {
      dispatch(stopAllTimers());
    };
  }, []);

  const goNextAssignment = () => {
    eventEmitter.dispatch(Events.ONBOARDING_UPDATE);
    if (activeAssignment === undefined) return;

    const index = assignments.findIndex(assignment => assignment.id === activeAssignment.id);

    if (index === assignments.length - 1) {
      navigate(redirectURL);
      return;
    }

    setActiveAssignment(assignments[index + 1]);
    dispatch(setIsTimerShown(true));

    // Scroll to top on new task
    window.scrollTo({
      top: 0,
      behavior: "auto" as ScrollBehavior,
    });
  };

  const handleExerciseQuit = () => {
    if (activeAssignment !== undefined) {
      const currentAssignmentIndex = assignments.findIndex(item => {
        return item.id === activeAssignment.id;
      });

      gtmExerciseQuit(currentAssignmentIndex + 1, assignments.length);
    }

    navigate("/");
  };

  useEffect(() => {
    if (activeAssignment !== undefined) {
      gtmTaskEvent("task-shown", {
        id: activeAssignment.id,
        subject: activeAssignment.subject?.appName,
        questionIndex:
          assignments.findIndex(item => {
            return item.id === activeAssignment.id;
          }) + 1,
      });
    }
  }, [activeAssignment]);

  return (
    <>
      <div className="exercise__header">
        <ExerciseBar
          totalTime={practiceTotalTime}
          isTimerStopped={false}
          userAssignmentId={activeAssignment?.id}
          isProgressBarVisible={true}
          onQuit={handleExerciseQuit}
        />
        <DotNavigation assignments={assignments} activeAssignmentId={activeAssignment?.id} results={results} />
      </div>

      {activeAssignment?.id !== undefined && results[activeAssignment?.id]?.status === "correct" && (
        <AnimatedCapybaraResult />
      )}

      {activeAssignment === undefined ? (
        /* TODO: translate */
        <div>Nastala chyba při zobrazování úlohy</div>
      ) : (
        <Fragment key={activeAssignment.id}>
          {getAssignmentPracticeByType(
            activeAssignment.assignment?.assignment?.assignmentType.type,
            activeAssignment,
            goNextAssignment,
          )}
        </Fragment>
      )}
    </>
  );
};

export default Practice;
