import type { FC } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";

import { EnvelopesOverview } from "components";
import IconArrowForward from "components/Icons/IconArrowForward/IconArrowForward";
import { StarRating } from "../StarRating";

interface Props {
  topic: {
    topicName: string;
    href: string;
    topicPercentage?: number | null;
  };
  envelopeCounts: { red: number | null; orange: number | null; green: number | null } | undefined;
  showStars: boolean;
}

const Topic: FC<Props> = ({ topic, envelopeCounts, showStars = true }) => {
  const { topicName, href, topicPercentage } = topic;

  return (
    <LinkContainer to={href}>
      <Nav.Link className="topic-box">
        <div>
          <strong>{topicName}</strong>
          {showStars ? <StarRating color={true} rating={Math.min(topicPercentage ?? 0, 100) / 100} /> : null}
        </div>
        <div className="topic-box__assignments-count">
          {envelopeCounts !== undefined ? <EnvelopesOverview envelopesCount={envelopeCounts} /> : null}
          <IconArrowForward />
        </div>
      </Nav.Link>
    </LinkContainer>
  );
};

export default Topic;
