import { useTranslation } from "react-i18next";
import { sessionResultEnum } from "../calculateSessionResult";

import type { TSessionResult } from "../calculateSessionResult";

export const useSessionDescription = () => {
  const { t } = useTranslation(["common"]);
  const getSessionDescriptionByType = (type: TSessionResult) => {
    switch (type) {
      case sessionResultEnum.PLANNED:
        return t("session.planned");
      case sessionResultEnum.NOT_PLANED:
      default:
        return null;
    }
  };

  return { getSessionDescriptionByType };
};
