import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import { AbilityScore, AnswerExercise } from "components";

import { useAppSelector } from "store/hooks";
import { getAbilityScore } from "store/slices/exercise";
import type { IAssignmentResult, IOption } from "store/slices/exercise";

import { generalOptionText } from "utils/formatTextForAnalysis";
import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { isSelfEvaluationForEveryAssignment } from "utils/valueTranslators";
import { getAbilityScoreOptions } from "utils/changeAbilityScore";

import type { UserAssignmentForExerciseFragment } from "api/generated";

/* Vg88XS8doR7IqSBg5PuQ */

interface Props {
  assignmentResult: IAssignmentResult<IOption | string>;
  assignmentDetail: UserAssignmentForExerciseFragment;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const ViceMoznostiDole: FC<Props> = ({ assignmentDetail, assignmentResult, toggleModals }) => {
  const assignment = assignmentDetail.assignment;
  if (assignment === undefined || assignment === null) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);
  const abilityScore = useAppSelector(getAbilityScore(assignmentDetail.id));

  const getAbilityScoreOnIndex = (index: number) => {
    if (abilityScore === undefined || typeof abilityScore === "number") return 0;

    return abilityScore[index];
  };

  return (
    <>
      <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className={"mt-20px"}>
        {assignment.assignment?.questions.map((question, index) => {
          const options = getAbilityScoreOptions(isSelfEvaluation, assignmentResult.answersVariants?.[index]);

          return (
            <div key={index} className="question">
              <Latex>{question.heading ?? ""}</Latex>

              <AnswerExercise
                subject={assignmentDetail.subject?.appName}
                key={`answer-${index}`}
                index={index}
                assignment={assignment}
                type={assignmentResult.answersVariants?.[index]}
                toggleModals={toggleModals}
                userAssignmentId={assignmentDetail.id}
                addIcon={!isSelfEvaluation}
                tipDisabled={true}
              >
                {isSelfEvaluation
                  ? getTextForSelfEvaluationOption(assignmentResult.selfEvalAnswers[index]?.status ?? "danger")
                  : generalOptionText(assignmentResult.answers?.[index] ?? "")}
              </AnswerExercise>

              {isSelfEvaluationForEveryAssignment(assignment) &&
              question.showAbilityScore === true &&
              options !== null ? (
                <>
                  <hr className={"my-25px"} />
                  <div className="mw-sm">
                    <AbilityScore
                      isSelfEvaluated={isSelfEvaluation}
                      options={options}
                      selectedValue={getAbilityScoreOnIndex(index)}
                      onValueSelect={() => {}}
                      index={index}
                      key={index}
                    />
                  </div>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ViceMoznostiDole;
