// noinspection InvisibleCharacter

import Button from "react-bootstrap/Button";
import { ExerciseBar } from "../../../../features";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

import { ReactComponent as Messengerimage } from "images/logos/messenger.svg";
import { ReactComponent as Instagramimage } from "images/logos/instagram.svg";
import { Image } from "react-bootstrap";

const Exercise08 = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  const [show, setShow] = useState({
    propojeni1: false,
    propojeni2: false,
    pozastaveno1: false,
    pozastaveno2: false,
    qrkod: false,
  });

  const handleModalState = (newState: any) => {
    setShow(show => ({
      ...show,
      ...newState,
    }));
  };

  return (
    <div className="exercise">
      <div className="exercise__inner">
        <div className="exercise__header">
          <ExerciseBar
            totalTime={600000}
            isTimerStopped={true}
            userAssignmentId={"test"}
            isProgressBarVisible={true}
            onQuit={() => {}}
          />

          <div className="progress-dots">
            <div className="dot success" />
            <div className="dot success" />
            <div className="dot current warning" />
          </div>
        </div>

        <div className="exercise__body">
          <p>
            <strong>Modály</strong>
          </p>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 8,
            }}
          >
            <div>
              {/* START : modal propojeni aplikace 1 */}
              <Button
                variant="outline-secondary"
                onClick={() => {
                  handleModalState({
                    propojeni1: true,
                  });
                }}
              >
                priklad - rysovani - propojeni 1
              </Button>

              <Modal
                show={show?.propojeni1}
                onHide={() => {
                  handleModalState({
                    propojeni1: false,
                  });
                }}
              >
                <Modal.Header closeButton />
                <Modal.Body>
                  <p className={"text-center"}>
                    Nemáš napojený ani Instagram, ani Whatsapp, učitel tě nemá jak kontaktovat. Propoj si aplikaci co
                    nejdřív, ať můžeš z procvičování získat co nejvíce! 🙂
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className={"w-100 text-uppercase"}
                    onClick={() => {
                      handleModalState({
                        propojeni1: false,
                      });
                    }}
                  >
                    Propojit aplikaci
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className={"w-100 text-uppercase"}
                    onClick={() => {
                      handleModalState({
                        propojeni1: false,
                      });
                    }}
                  >
                    Nechci propojit
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* END : modal propojeni aplikace 1 */}
            </div>

            <div>
              {/* START : modal propojeni aplikace 2 */}
              <Button
                variant="outline-secondary"
                onClick={() => {
                  handleModalState({
                    propojeni2: true,
                  });
                }}
              >
                priklad - rysovani - propojeni 2
              </Button>

              <Modal
                show={show?.propojeni2}
                onHide={() => {
                  handleModalState({
                    propojeni2: false,
                  });
                }}
              >
                <Modal.Header closeButton />
                <Modal.Body className="pb-40px">
                  <h3 className={"text-center mb-20px"}>Přes co Ti můžeme psát?</h3>
                  <div className={"d-grid gap-8px"}>
                    <Button
                      variant="outline-secondary"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          propojeni2: false,
                        });
                      }}
                    >
                      <Instagramimage className={"me-8px"} />
                      Instagram
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          propojeni2: false,
                        });
                      }}
                    >
                      <Messengerimage className={"me-8px"} />
                      Messenger
                    </Button>
                  </div>
                  <p className={"text-center my-20px"}>nebo</p>
                  <Button
                    variant="outline-secondary"
                    className={"w-100 text-uppercase"}
                    onClick={() => {
                      handleModalState({
                        propojeni2: false,
                      });
                    }}
                  >
                    Pouze SMS
                  </Button>
                </Modal.Body>
              </Modal>
              {/* END : modal propojeni aplikace 2 */}
            </div>

            <div>
              {/* START : Modal pozastaveno */}
              <Button
                variant="outline-secondary"
                onClick={() => {
                  handleModalState({
                    pozastaveno1: true,
                  });
                }}
              >
                priklad - pozastaveno
              </Button>

              <Modal
                show={show?.pozastaveno1}
                onHide={() => {
                  handleModalState({
                    pozastaveno1: false,
                  });
                }}
              >
                <Modal.Header closeButton />
                <Modal.Body className="pb-40px">
                  <div className="mb-8px text-center">
                    <Image src={"/images/capy-paused.svg"} />
                  </div>
                  <h3 className={"text-center mb-20px"}>Pozastaveno</h3>
                  <div className={"d-grid gap-8px"}>
                    <Button
                      variant="outline-success"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          pozastaveno1: false,
                        });
                      }}
                    >
                      Zapnout zvuk třídy
                    </Button>
                    <Button
                      variant="success"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          pozastaveno1: false,
                        });
                      }}
                    >
                      Procvičovat dále
                    </Button>
                  </div>
                  <p className={"text-center my-20px"}>nebo</p>
                  <Button
                    variant="outline-danger"
                    className={"w-100 text-uppercase"}
                    onClick={() => {
                      handleModalState({
                        pozastaveno1: false,
                      });
                    }}
                  >
                    Ukončit test
                  </Button>
                </Modal.Body>
              </Modal>
              {/* END : Modal pozastaveno */}
            </div>

            <div>
              {/* START : Modal ukoncit test */}
              <Button
                variant="outline-secondary"
                onClick={() => {
                  handleModalState({
                    pozastaveno2: true,
                  });
                }}
              >
                priklad - pozastaveno 2
              </Button>

              <Modal
                show={show?.pozastaveno2}
                onHide={() => {
                  handleModalState({
                    pozastaveno2: false,
                  });
                }}
              >
                <Modal.Header closeButton />
                <Modal.Body className="pb-40px">
                  <div className="mb-8px text-center">
                    <Image src={"/images/capy-beware.svg"} />
                  </div>
                  <h3 className={"text-center mb-20px"}>
                    Vzdát se,
                    <br /> není dobrý nápad
                  </h3>
                  <div className={"d-grid gap-8px"}>
                    <Button
                      variant="success"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          pozastaveno2: false,
                        });
                      }}
                    >
                      Chci dále procvičovat
                    </Button>
                    <Button
                      variant="outline-danger"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          pozastaveno2: false,
                        });
                      }}
                    >
                      Ukončit test
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              {/* END : Modal ukoncit test */}
            </div>

            <div>
              {/* START : QR kod */}
              <Button
                variant="outline-secondary"
                onClick={() => {
                  handleModalState({
                    qrkod: true,
                  });
                }}
              >
                priklad - QR kod
              </Button>

              <Modal
                show={show?.qrkod}
                size={"lg"}
                className={"modal--exercise"}
                onHide={() => {
                  handleModalState({
                    qrkod: false,
                  });
                }}
              >
                <Modal.Header closeButton />
                <Modal.Body className="pb-60px pt-20px px-80px">
                  <div className={"qr-block mb-20px"}>
                    <Image src={"/images/dev/qrkod.png"} className={"qr-block__code"} />
                    <div className={"qr-block__inner"}>
                      <p>1. Naskenuj QR kód svým telefonem</p>
                      <p>2. V mobilu vyfoť a odešli</p>
                      <p>3. Hotovo! Pokračuj v procvičování!</p>
                    </div>
                  </div>

                  <div className={"d-grid gap-8px"}>
                    <Button
                      variant="primary"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          qrkod: false,
                        });
                      }}
                    >
                      {t("btn.photoConfirm", { ns: "exercise" })}
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className={"w-100 text-uppercase"}
                      onClick={() => {
                        handleModalState({
                          pozastaveno2: false,
                        });
                      }}
                    >
                      {t("close", { ns: "common" })}
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              {/* END : QR kod */}
            </div>
          </div>
        </div>

        <div className="exercise__footer">
          <div className="mw-sm">
            <LinkContainer to={"dev/cviceni"}>
              <Button className={"w-100 text-uppercase"}>{t("back")}</Button>
            </LinkContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exercise08;
