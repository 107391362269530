import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as ScreenImage } from "images/icons/vyhodnoceni-3.svg";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";

import { ReactComponent as BgWaveTop } from "images/bg/bckg-branded-top.svg";
import { ReactComponent as BgWaveBottom } from "images/bg/bckg-branded-bottom.svg";
import { Container } from "react-bootstrap";

const ExerciseIntroduction = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  return (
    <main className={"fullpage bg-primary-subtle"}>
      <BgWaveTop className={"wave__top"} />

      <Container className="container-mw-sm text-center fullpage__content">
        <div className="page px-3">
          <div className="page__content my-auto ms-sm-0 flex-grow-0">
            <ScreenImage className={"mx-auto"} />
            <h1 className={"h2 mb-20px"}>
              <Trans
                i18nKey="exerciseIntroduction.heading"
                ns="onboarding"
                components={{
                  highlight: <span className={"text-highlight"} />,
                  br: <br />,
                }}
              />
            </h1>
            <p className={"mb-40px"}>{t("exerciseIntroduction.text", { ns: "onboarding" })}</p>
          </div>
        </div>
      </Container>

      <div className="fullpage__footer">
        <BgWaveBottom className={"wave__bottom"} />

        <Container className="container-mw-sm py-20px">
          <LinkContainer to="/onboarding/priklad">
            <Button className={"text-uppercase w-100"}>{t("continue")}</Button>
          </LinkContainer>
        </Container>
      </div>
    </main>
  );
};

export default ExerciseIntroduction;
