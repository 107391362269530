import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IAssignments {
  onboarding: IAssignmentData[];
}

interface IAssignmentData {
  id: string;
  solution: any;
}

const initialState: IAssignments = {
  onboarding: [],
};

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {
    setOnboardingAssignments: (state, action: PayloadAction<IAssignmentData>) => {
      state.onboarding = action.payload.solution;
    },
  },
});

export default assignmentsSlice.reducer;
export const { setOnboardingAssignments } = assignmentsSlice.actions;

export const selectSolution = (type: "onboarding", id: string) => (state: RootState) =>
  state.assignments[type].find(assignment => assignment.id === id);
