import { useEffect, useState } from "react";
import { Breadcrumb, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useUpdateSoundMutation } from "api/generated";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectSound, setSound } from "store/slices/user";
import { Link } from "react-router-dom";

import IconArrowBack from "../../../components/Icons/IconArrowBack/IconArrowBack";

import { processApiError } from "utils/processApiError";

const BackgroundSound = () => {
  const withSound = useAppSelector(selectSound);
  const dispatch = useAppDispatch();
  const [updateSoundMutation, { error: apiMutationError }] = useUpdateSoundMutation();
  const { t } = useTranslation(["common", "settings"]);

  const [checked, setChecked] = useState(withSound === true);

  useEffect(() => {
    setChecked(withSound === true);
  }, [withSound]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const handleChange = () => {
    updateSoundMutation({ withSound: !checked })
      .then(() => {
        dispatch(setSound(!checked));
      })
      .catch(error => {
        console.error(error);
      });
    setChecked(checked => !checked);
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-sm"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/nastaveni"}>
                <IconArrowBack />
                {t("pageNameSettings", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>
          <h1 className={"mb-0"}>{t("pageNameBackgroundSound", { ns: "common" })}</h1>
        </Container>
      </div>

      <Container className={"container-mw-sm page-container pt-25px pt-sm-45px"}>
        <Form.Check
          type="switch"
          checked={checked}
          onChange={handleChange}
          label={t("backgroundSound.heading", { ns: "settings" })}
        />
        <p className={"mt-3"}>{t("backgroundSound.text", { ns: "settings" })}</p>
      </Container>
    </>
  );
};

export default BackgroundSound;
