import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { Fragment } from "react";

import type { FC } from "react";

import { AnswerPractice } from "components";

import type { IAssignmentResult } from "store/slices/topicPractice";

import { ABCDOptionText } from "utils/formatTextForAnalysis";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

interface Props {
  assignmentResult: IAssignmentResult;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const ABCD: FC<Props> = ({ assignmentResult, toggleModals }) => {
  const assignment = assignmentResult.assignment.assignment;
  if (assignment === undefined || assignment === null) return null;

  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);

  return (
    <>
      <Latex>{assignmentResult.assignment.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      {assignment.assignment?.questions.map((question, qIndex) => {
        return (
          <Fragment key={`answer-${qIndex}`}>
            <Latex>{question.heading ?? ""}</Latex>

            <AnswerPractice
              index={qIndex}
              assignment={assignment}
              type={assignmentResult.answerVariants?.[qIndex]}
              toggleModals={toggleModals}
              showBackOnSolution={true}
              userAssignmentId={assignmentResult.assignment.id}
            >
              {ABCDOptionText(assignmentResult.userAnswers[qIndex])}
            </AnswerPractice>
          </Fragment>
        );
      })}
    </>
  );
};

export default ABCD;
