import { Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { CurrentStreak } from "components";

import { CalendarMonthOverview } from "features";

import { ReactComponent as PrinterIcon } from "images/icons/printer.svg";

import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

const MonthOverview = () => {
  const { t } = useTranslation(["common", "calendar"]);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <div className="page-title__heading">
            <div>
              <Breadcrumb>
                <li className={"breadcrumb-item"}>
                  <Link to={"/"}>
                    <IconArrowBack />
                    {t("pageNameHome")}
                  </Link>
                </li>
              </Breadcrumb>
              <h1>{t("monthCalendar", { ns: "calendar" })}</h1>

              <div className={"btn-bag"}>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    window.print();
                  }}
                  className={"btn-icon btn-shadow border-0"}
                >
                  <PrinterIcon />
                  {t("printToPdf", { ns: "calendar" })}
                </Button>
              </div>
            </div>

            <CurrentStreak withLink={false} className="d-none d-md-block" />
          </div>
        </Container>
      </div>

      <CalendarMonthOverview />
    </>
  );
};

export default MonthOverview;
