import type { TSolutionVariant } from "store/slices/exercise";

export const changeDistinctAbilityScore = (
  value: number,
  index: number,
  scores: Array<number | undefined> | number | undefined,
  questionsCount: number,
) => {
  const data = Array.isArray(scores) ? [...scores] : new Array(questionsCount).fill(undefined);

  data[index] = value;
  return data;
};

export const getAbilityScoreOptions = (isSelfEvaluated: boolean, answerStatus: TSolutionVariant | null | undefined) => {
  if (isSelfEvaluated) return selfEvaluateOptions;

  const abilityScoreHidden = answerStatus === "success" || answerStatus === "warning";
  return abilityScoreHidden ? null : incorrectAnswerOptions;
};

const selfEvaluateOptions = [
  {
    text: "Nechápu to",
    score: 1,
  },
  {
    text: "Nic moc, ale tuším",
    score: 2.4,
  },
  {
    text: "Tohle mi jde",
    score: 3.5,
  },
  {
    text: "Je to má superschopnost",
    score: 5,
  },
];
/*
const correctAnswerOptions = [
  {
    text: "Dalo to práci",
    score: 2.4,
  },
  {
    text: "Tipl jsem výsledek",
    score: 2.7,
  },
  {
    text: "Zvládám levou zadní",
    score: 5,
  },
]; */

const incorrectAnswerOptions = [
  {
    text: "Vůbec to nechápu",
    score: 1,
  },
  {
    text: "Trochu jsem se chytal",
    score: 2.1,
  },
  {
    text: "Myslel jsem si, že to umím",
    score: 2.25,
  },
];
