import type { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";

import { Loading } from "components/Loading";

interface Props {
  isLoading: boolean;
  onClose: () => void;
}

const Bookmarked: FC<Props> = ({ isLoading, onClose }) => {
  return (
    <Modal
      show={true}
      onHide={() => {
        onClose();
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body className="pb-40px">
        {isLoading ? (
          <Loading></Loading>
        ) : (
          <>
            <div className="mb-8px text-center">
              <Image src={"/images/bookmark-saved.svg"} />
            </div>
            <h3 className={"text-center mb-20px"}>Otázku jsme uložili!</h3>
            <p className={"text-center mb-20px"}>Najdeš ji v sekci „úlohy“, ať se k ní kdykoliv můžeš vrátit 🙂 </p>
            <Button
              variant="outline-secondary"
              className={"w-100 text-uppercase"}
              onClick={() => {
                onClose();
              }}
            >
              Zavřít
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Bookmarked;
