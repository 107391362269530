import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LayoutSimpleFluid from "./LayoutSimpleFluid";

import Rewards from "pages/Rewards";

import { NotAuthenticated } from "features";

const ParentRouter = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/*" element={<LayoutSimpleFluid />}>
          <Route index element={<NotAuthenticated />} />
          <Route path="odmeny-rodice" element={<Rewards />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default ParentRouter;
