import { removeHTML } from "utils/removeHTML";

export interface ISearchAssignment {
  text: string;
  questionText: string;
  questionHeadings: string[];
  href: string;
  testId: string | null;
  testOrder: number | null;
  envelopeType: string | null | undefined;
}

export const searchInAssignment = (assignment: ISearchAssignment, search: string) => {
  const loweredSearch = search.toLocaleLowerCase();

  return (
    removeHTML(assignment.text.toLocaleLowerCase()).normalize().includes(loweredSearch) ||
    removeHTML(assignment.questionText.toLocaleLowerCase()).normalize().includes(loweredSearch) ||
    assignment.questionHeadings.find(question =>
      removeHTML(question.toLocaleLowerCase()).normalize().includes(loweredSearch),
    ) !== undefined
  );
};
