import type { TSolutionVariant } from "store/slices/topicPractice";
import i18n from "i18next";

import { ReactComponent as IconCheck } from "images/icons/check.svg";
import { ReactComponent as IconCross } from "images/icons/close.svg";

export const getTextForSelfEvaluationOption = (status: TSolutionVariant | null) => {
  const icon = () => {
    return status === "danger" ? <IconCross className={"mx-4px"} /> : <IconCheck className={"mx-4px"} />;
  };

  const text = () => {
    switch (status) {
      case "success":
        return i18n.t("exercise:evaluation.allCorrectShort");
      case "warning":
        return i18n.t("exercise:evaluation.partiallyCorrectShort");
      case "danger":
        return i18n.t("exercise:evaluation.allWrongShort");
      default:
        return "Chybí text";
    }
  };

  return (
    <strong className={status !== null ? `evaluation-option text-${status}` : "evaluation-option"}>
      {icon()}

      <span>{text()}</span>
    </strong>
  );
};
