import * as React from "react";

function IconHelp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 2C12.866 2 16 5.13401 16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M8.22083 11.7145C8.37414 11.5737 8.56656 11.5033 8.7981 11.5033C9.02963 11.5033 9.22049 11.5753 9.37067 11.7192C9.52086 11.8631 9.59595 12.0446 9.59595 12.2636C9.59595 12.4889 9.52086 12.6751 9.37067 12.8221C9.22049 12.9692 9.02963 13.0427 8.7981 13.0427C8.5603 13.0427 8.36632 12.9692 8.21613 12.8221C8.06595 12.6751 7.99086 12.4889 7.99086 12.2636C7.99086 12.0384 8.06751 11.8553 8.22083 11.7145Z"
        fill="currentColor"
      />
      <path
        d="M7.77497 5.72124C8.16294 5.56479 8.57908 5.48657 9.02337 5.48657C9.43638 5.48657 9.80245 5.56166 10.1216 5.71185C10.4407 5.86203 10.6926 6.07636 10.8772 6.35482C11.0618 6.63329 11.1541 6.95712 11.1541 7.32633C11.1541 7.59541 11.1119 7.83007 11.0274 8.03031C10.9429 8.23056 10.8334 8.4089 10.6989 8.56535C10.5643 8.72179 10.422 8.87041 10.2718 9.0112C10.1216 9.152 9.97923 9.29749 9.84469 9.44768C9.71015 9.59786 9.60064 9.76525 9.51616 9.94986C9.43168 10.1345 9.38945 10.3519 9.38945 10.6022H8.21613L8.20049 10.5115C8.19214 10.4489 8.18797 10.3822 8.18797 10.3112C8.18797 10.0296 8.22708 9.78403 8.3053 9.5744C8.38353 9.36476 8.48208 9.18173 8.60098 9.02528C8.71988 8.86884 8.84659 8.72648 8.98113 8.5982C9.11567 8.46992 9.24239 8.34633 9.36129 8.22743C9.48018 8.10854 9.57874 7.98495 9.65696 7.85666C9.73518 7.72838 9.77429 7.58289 9.77429 7.42019C9.77429 7.17614 9.68825 6.98372 9.51616 6.84292C9.34408 6.70212 9.10472 6.63173 8.7981 6.63173C8.51024 6.63173 8.21926 6.70369 7.92515 6.84762C7.63104 6.99154 7.37448 7.19179 7.15546 7.44835L6.8457 6.3689L6.94972 6.25454C7.16733 6.0331 7.44242 5.85533 7.77497 5.72124Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconHelp;
