import type { FC } from "react";
import { InputGroup, Form } from "react-bootstrap";
import Select, { components } from "react-select";
import type {
  ControlProps,
  IndicatorsContainerProps,
  OptionProps,
  SingleValue,
  ValueContainerProps,
  InputProps,
} from "react-select";
import ReactWorldFlags from "react-world-flags";

import phoneCodes from "./phoneCodes.json";
import { useTranslation } from "react-i18next";

interface ICode {
  name: string;
  value: string;
  label: string;
  code: string;
}

interface Props {
  onPhoneChange: (phone: string) => void;
  label: string;
  phoneNumber: string;
}

const parsePhoneNumber = (phoneNumber: string) => {
  let code = "+420";
  let phone = phoneNumber;

  if (phoneNumber.trim().startsWith("+")) {
    const phoneParts = phoneNumber.split(" ");

    if (phoneParts.length === 2) {
      code = phoneParts[0];
      phone = phoneParts[1];
    } else {
      for (const item of phoneCodes) {
        if (phoneNumber.startsWith(item.label)) {
          code = item.label;
          phone = phoneNumber.split(code)[1];
          break;
        }
      }
    }
  }
  return { code, phone };
};

const PhoneInput: FC<Props> = ({ label, onPhoneChange, phoneNumber }) => {
  const { code, phone } = parsePhoneNumber(phoneNumber);

  const { t } = useTranslation(["common"]);

  const handlePhoneChange = (newPhone: string) => {
    onPhoneChange(`${code} ${newPhone}`);
  };

  const handleCodeChange = (newVal: SingleValue<ICode>) => {
    const code = newVal?.value;

    if (typeof code === "string") {
      onPhoneChange(`${code.replace(/[^+0-9]/g, "")} ${phone}`);
    }
  };

  const CustomOption = ({ children, ...props }: OptionProps<ICode>) => {
    return (
      <components.Option {...props}>
        <ReactWorldFlags code={props.data.code} width="24px" className="phone-input__flag" /> {children}
      </components.Option>
    );
  };

  const CustomControl = ({ children, ...props }: ControlProps<ICode>) => {
    const value = props.getValue();

    if (value === undefined || value.length !== 1) {
      return (
        <components.Control {...props} className="phone-select__value">
          {children}
        </components.Control>
      );
    }

    const code = value[0].value;
    const country = phoneCodes.find(item => item.value === code);

    if (country === undefined) return null;

    return (
      <components.Control {...props} className="phone-select__value">
        <ReactWorldFlags code={country.code} width="24px" className="phone-input__flag" /> {children}
      </components.Control>
    );
  };

  const CustomIndicator = ({ children, ...props }: IndicatorsContainerProps<ICode>) => {
    return (
      <components.IndicatorsContainer {...props} className="phone-select__indicator">
        {children}
      </components.IndicatorsContainer>
    );
  };

  const CustomValueContainer = ({ children, ...props }: ValueContainerProps<ICode>) => {
    return (
      <components.ValueContainer {...props} className="phone-select__value-container">
        {children}
      </components.ValueContainer>
    );
  };

  const CustomInput = ({ children, ...props }: InputProps<ICode>) => {
    return (
      <components.Input {...props} className="phone-select__input">
        {children}
      </components.Input>
    );
  };

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <InputGroup className="mb-32px phone-input">
        <Select
          className="phone-select"
          options={phoneCodes}
          value={phoneCodes.find(phoneCode => phoneCode.value === code)}
          onChange={handleCodeChange}
          isMulti={false}
          classNamePrefix={"phone-select"}
          isSearchable={false}
          components={{
            Option: CustomOption,
            Control: CustomControl,
            IndicatorSeparator: null,
            IndicatorsContainer: CustomIndicator,
            ValueContainer: CustomValueContainer,
            Input: CustomInput,
          }}
          noOptionsMessage={() => t("phoneInputPrefixNotFound")}
        />
        <Form.Control
          type="tel"
          name="phone"
          placeholder={t("phoneInputPlaceholder")}
          pattern="[0-9]{9}"
          maxLength={13}
          value={phone ?? ""}
          onChange={e => {
            handlePhoneChange(e.target.value.replace(/[^0-9]/g, ""));
          }}
          className="phone-input__phone"
        />
      </InputGroup>
    </>
  );
};

export default PhoneInput;
