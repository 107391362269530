import type { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import type { ITopic } from "components/Box/BlackEnvelope";

interface Props {
  topic: ITopic;
  onClose: () => void;
  onConfirm: (topic: ITopic) => void;
}

const BlackEnvelopeTopic: FC<Props> = ({ topic, onClose, onConfirm }) => {
  return (
    <Modal show={true}>
      <Modal.Header />
      <Modal.Body>
        <p className={"text-center"}>Chci Tě pochválit za to, jak se snažíš! Jde Ti to skvěle!</p>
        <p>
          Téma{" "}
          <strong>
            &quot;{topic.combinedTopicName}: {topic.name}&quot; je pro Tebe teď trochu náročné. Prober ho s rodiči,
            učiteli nebo na doučování.
          </strong>{" "}
          Jakmile to budeš chápat, dej nám vědět!
        </p>
      </Modal.Body>
      <Modal.Footer style={{ margin: "auto" }}>
        <div className={"d-grid gap-8px"}>
          <Button
            variant="success"
            className={"w-100 text-uppercase"}
            onClick={() => {
              onConfirm(topic);
            }}
          >
            Doučil jsem se to, chci to do procvičování
          </Button>
          <Button variant={"outline-secondary-darker"} className={"w-100 text-uppercase"} onClick={onClose}>
            Pracuju na tom :)
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BlackEnvelopeTopic;
