import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";

import Task from "./Task";
import SelfEvaluationSolution from "./SelfEvaluationSolution";

import { useCheckAnswersMutation } from "api/generated";
import type { SelfEvaluationFragment, UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getAssignmentTime,
  startAssignmentTimer,
  resetAssignmentTimer,
  measurePracticeTime,
  setIsTimerShown,
} from "store/slices/timer";
import type { IOption, TAssignmentStatus, TSolutionVariant, IAssignmentResult } from "store/slices/exercise";

import {
  getSelfEvaluationStatus,
  getSelfEvaluationVariant,
  getSelfEvaluationResults,
  getEvaluationVariantFromOption,
} from "utils/getSolutionVariant";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { processAnswerResponse } from "utils/processAnswerResponse";
import { processExerciseError } from "utils/processApiError";

export interface ISaveSelfEvaluationAnswerParams {
  userAssignmentId: string;
  selfEvalAnswer: Array<{ points: number | null; status: TSolutionVariant | null }>;
  status: TAssignmentStatus;
  answerVariants: TSolutionVariant[];
}

interface Props {
  userAssignment: UserAssignmentDetailFragment;
  userAssignmentId: string;
  evaluateAnswer: boolean;
  results: IAssignmentResult<IOption | string> | undefined;
  onNextClick?: () => void;
  saveAnswer?: ({ userAssignmentId, selfEvalAnswer, status, answerVariants }: ISaveSelfEvaluationAnswerParams) => void;
}

const SelfEvaluation: FC<Props> = ({
  evaluateAnswer,
  userAssignment,
  results,
  onNextClick,
  userAssignmentId,
  saveAnswer,
}) => {
  if (userAssignment.assignment === undefined || userAssignment.assignment === null) return null;

  const isShared = userAssignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true;
  const questionsCount = userAssignment.assignment?.assignment?.questions.length ?? 0;
  const selfEvaluation = userAssignment.assignment?.selfEvaluation;

  const dispatch = useAppDispatch();
  const time = useAppSelector(getAssignmentTime);
  const [sendAnswers, { error: apiMutationError, isLoading }] = useCheckAnswersMutation();

  const [shownStep, setShownStep] = useState<"task" | "options" | "summary" | "goNext">("task");
  const [selectedOptions, setSelectedOptions] = useState<
    Array<{ points: number | null; status: TSolutionVariant | null }>
  >(new Array(isShared ? 1 : questionsCount).fill({ points: null, status: null }));
  const { imageUrl: solutionImageURL } = useImageUrlResolver(selfEvaluation?.sharedSelfEvaluation?.general?.image);

  useEffect(() => {
    let totalPoints = 0;
    for (const value of selectedOptions) {
      if (value.points === null) continue;
      totalPoints += value.points;
    }

    processExerciseError(apiMutationError, {
      assignmentId: userAssignmentId,
      selfEvaluation: totalPoints,
      userAnswers: null,
      timeToSolve: time ?? 0,
    });
  }, [apiMutationError]);

  useEffect(() => {
    if (results?.correctAnswers !== undefined) {
      setShownStep("summary");
    }
  }, [results]);

  const handleEvaluationSelect = (index: number, option: number) => {
    let maxPoints = 0;

    if (isShared) {
      const points = userAssignment.assignment?.selfEvaluation?.sharedSelfEvaluation?.options
        .map(option => option.scoreReal)
        .filter((point): point is number => typeof point === "number");
      maxPoints = points === undefined ? 0 : Math.max(...points);
    } else {
      const points = userAssignment.assignment?.assignment?.questions[index].selfEvaluation?.options
        .map(option => option.scoreReal)
        .filter((point): point is number => typeof point === "number");
      maxPoints = points === undefined ? 0 : Math.max(...points);
    }

    setSelectedOptions(options => {
      const copy = [...options];
      copy[index] = {
        points: option,
        status: getEvaluationVariantFromOption(option, maxPoints),
      };

      return copy;
    });
  };

  const handleTaskSubmit = () => {
    setShownStep("options");
    dispatch(setIsTimerShown(false));
  };

  const handleOptionsSubmit = () => {
    if (typeof userAssignmentId === "string" && evaluateAnswer) {
      let totalPoints = 0;
      for (const value of selectedOptions) {
        if (value.points === null) continue;
        totalPoints += value.points;
      }

      sendAnswers({
        assignmentId: userAssignmentId,
        selfEvaluation: totalPoints,
        userAnswers: null,
        timeToSolve: time ?? 0,
      })
        .then(response => {
          processAnswerResponse(response, userAssignment);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          dispatch(measurePracticeTime());
          dispatch(resetAssignmentTimer());
          dispatch(startAssignmentTimer());
        });
    }

    if (saveAnswer !== undefined) {
      if (userAssignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true) {
        const data = userAssignment.assignment.selfEvaluation.sharedSelfEvaluation;
        if (data !== null && data !== undefined) {
          const points = userAssignment.assignment.selfEvaluation.sharedSelfEvaluation?.options
            .map(option => option.scoreReal)
            .filter((score): score is number => typeof score === "number");
          const maxPoints = points === undefined ? 0 : Math.max(...points);

          saveAnswer({
            userAssignmentId,
            selfEvalAnswer: getSelfEvaluationResults(selectedOptions, maxPoints),
            status: getSelfEvaluationStatus(selectedOptions, userAssignment.assignment?.scoring?.scoreMax),
            answerVariants: getSelfEvaluationVariant(selectedOptions, data),
          });
        }
      } else {
        const questions = userAssignment.assignment?.assignment?.questions;
        if (Array.isArray(questions)) {
          const data = questions
            .map(question => question.selfEvaluation)
            .filter((option): option is SelfEvaluationFragment => option !== null && option !== undefined);
          const maxPoints = data.map(selfEval => {
            const points = selfEval.options
              .map(option => option.scoreReal)
              .filter((score): score is number => typeof score === "number");
            return Math.max(...points);
          });

          saveAnswer({
            userAssignmentId,
            selfEvalAnswer: getSelfEvaluationResults(selectedOptions, maxPoints),
            status: getSelfEvaluationStatus(selectedOptions, userAssignment.assignment?.scoring?.scoreMax),
            answerVariants: getSelfEvaluationVariant(selectedOptions, data),
          });
        }
      }
    }

    setShownStep("summary");
  };

  const handleNextClick = () => {
    if (saveAnswer !== undefined) {
      if (userAssignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true) {
        const data = userAssignment.assignment.selfEvaluation.sharedSelfEvaluation;
        if (data !== null && data !== undefined) {
          const points = userAssignment.assignment.selfEvaluation.sharedSelfEvaluation?.options
            .map(option => option.scoreReal)
            .filter((score): score is number => typeof score === "number");
          const maxPoints = points === undefined ? 0 : Math.max(...points);

          saveAnswer({
            userAssignmentId,
            selfEvalAnswer: getSelfEvaluationResults(selectedOptions, maxPoints),
            status: getSelfEvaluationStatus(selectedOptions, userAssignment.assignment?.scoring?.scoreMax),
            answerVariants: getSelfEvaluationVariant(selectedOptions, data),
          });
        }
      } else {
        const questions = userAssignment.assignment?.assignment?.questions;
        if (Array.isArray(questions)) {
          const data = questions
            .map(question => question.selfEvaluation)
            .filter((option): option is SelfEvaluationFragment => option !== null && option !== undefined);
          const maxPoints = data.map(selfEval => {
            const points = selfEval.options
              .map(option => option.scoreReal)
              .filter((score): score is number => typeof score === "number");
            return Math.max(...points);
          });

          saveAnswer({
            userAssignmentId,
            selfEvalAnswer: getSelfEvaluationResults(selectedOptions, maxPoints),
            status: getSelfEvaluationStatus(selectedOptions, userAssignment.assignment?.scoring?.scoreMax),
            answerVariants: getSelfEvaluationVariant(selectedOptions, data),
          });
        }
      }
    }

    onNextClick?.();
  };

  if (shownStep === "task") return <Task userAssignment={userAssignment} onSubmit={handleTaskSubmit} />;

  return (
    <div className="exercise">
      <div className="exercise__body">
        <div>
          <Latex>{selfEvaluation?.sharedSelfEvaluation?.general?.text ?? ""}</Latex>
          {solutionImageURL !== null ? <img src={solutionImageURL} /> : null}

          <SelfEvaluationSolution
            sendDataToBe={true}
            selectedOptions={selectedOptions}
            userAssignment={userAssignment}
            shownStep={shownStep}
            handleEvaluationSelect={handleEvaluationSelect}
            handleOptionsSubmit={handleOptionsSubmit}
            handleNextClick={handleNextClick}
            showAbilityScore={true}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default SelfEvaluation;
