// noinspection InvisibleCharacter

import { ButtonGroup, ToggleButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ExerciseBar } from "../../../../features";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TruncateText } from "../../../../components";

const Exercise05 = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  const [radioValue1, setRadioValue1] = useState("1");
  const [radioValue2, setRadioValue2] = useState("1");
  const [radioValue3, setRadioValue3] = useState("1");

  return (
    <div className="exercise">
      <div className="exercise__header">
        <ExerciseBar
          totalTime={600000}
          isTimerStopped={true}
          userAssignmentId={"test"}
          isProgressBarVisible={true}
          onQuit={() => {}}
        />

        <div className="progress-dots">
          <div className="dot current" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>

      <div className="exercise__body">
        <p>
          <strong>Šťastný muž</strong>
        </p>
        <TruncateText lines={3}>
          <p>
            V jednom z mnoha alarmujících článků o úpadku západní morálky popisuje třeba měsíčník The Atlantic průzkum,
            podle něhož se 20 procent lidí v práci (na Západě) záměrně fláká. Je to prý proto, že jsou nešťastní a
            ztratili motivaci.{" "}
            <strong>
              Šéfové firem dokonce najímají různé experty na štěstí, aby své zaměstnance povzbudili k lepším výkonům,
              často však bez valného výsledku.
            </strong>
          </p>
          <p>
            Do této situace přichází Matthieu Ricard, muž, který vyrostl v Paříži v rodině slavných intelektuálů a byl
            ve Francii považován za jednu z největších nadějí moderní vědy v oblasti molekulární biologie, ale před
            třicítkou udělal zásadní životní obrat. Vydal se do Indie, kde strávil 25 let pohroužen do meditací v
            buddhistických klášterech, koncem devadesátých let se nicméně vrátil zpět na Západ. Navyklý na prostředí,
            kde smyslem existence je „stát se lepší bytostí“, rozhodl se podělit o tuto zkušenost se západní společností
            a nabídnout jí „Ariadninu nit, která nám pomůže najít cestu z labyrintu“, v němž se naše civilizace
            ztratila.
          </p>
          <p>
            Proti všem současným hlasatelům věčných pravd má navíc Matthieu Ricard jednu velkou výhodu: o své duchovní
            zkušenosti mluví racionálním jazykem vědy, kterou Západ uctívá jako nástroj poznání. Po pobytu v klášteře se
            totiž vrátil do laboratoře, aby blahodárnost altruismu, jemuž se naučil na Východě, zkoumal západní metodou
            vědy.
          </p>
          <p>
            <em>(https://www.respekt.cz/z-noveho-cisla/mistr-sveta-ve-stesti, 2015)</em>
          </p>
        </TruncateText>
        <p className={"mt-3"}>
          <strong>
            Rozhodněte o každém z následujících tvrzení, zda jednoznačně vyplývá z výchozího textu (ANO), či nikoli (NE)
          </strong>
        </p>
        <div>
          <div className={"question"}>
            <div className="mw-sm">
              <ButtonGroup className={"btn-group-select btn-shadow"}>
                {[
                  { name: "Ano", value: "yes" },
                  { name: "Ne", value: "no" },
                ].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-1-${idx}`}
                    type="radio"
                    variant="select"
                    name="radio1"
                    value={radio.value}
                    checked={radioValue1 === radio.value}
                    onChange={e => {
                      setRadioValue1(e.currentTarget.value);
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>

          <div className={"question"}>
            <div className="mw-sm">
              <ButtonGroup className={"btn-group-select btn-shadow"}>
                {[
                  { name: "Ano", value: "yes" },
                  { name: "Ne", value: "no" },
                ].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-2-${idx}`}
                    type="radio"
                    variant="select"
                    name="radio2"
                    value={radio.value}
                    checked={radioValue2 === radio.value}
                    onChange={e => {
                      setRadioValue2(e.currentTarget.value);
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>

          <div className={"question"}>
            <div className="mw-sm">
              <ButtonGroup className={"btn-group-select btn-shadow"}>
                {[
                  { name: "Ano", value: "yes" },
                  { name: "Ne", value: "no" },
                ].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-3-${idx}`}
                    type="radio"
                    variant="select"
                    name="radio3"
                    value={radio.value}
                    checked={radioValue3 === radio.value}
                    onChange={e => {
                      setRadioValue3(e.currentTarget.value);
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>

      <div className="exercise__footer">
        <div className="mw-sm">
          <Button className={"w-100 text-uppercase"} disabled>
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Exercise05;
