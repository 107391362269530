import { Alert } from "react-bootstrap";

const GenericBox = () => {
  return (
    <Alert variant="info">
      <div>
        {/* TODO: translate */}
        <strong>Řeš na papír</strong>
      </div>
      <div>Až budeš mít hotovo, pokračuj.</div>
    </Alert>
  );
};

export default GenericBox;
