import type { FC } from "react";
import { Alert } from "react-bootstrap";

import type { OutageFragment } from "api/generated";

interface Props {
  data: OutageFragment[] | undefined;
}

const OutageBox: FC<Props> = ({ data }) => {
  return (
    <>
      {data?.map((outage, index) => {
        const { endTime, startTime, text } = outage;
        const now = new Date();

        if (new Date(endTime) < now || new Date(startTime) > now || typeof text !== "string" || text.length === 0) {
          return null;
        }

        return (
          <Alert variant="danger" key={index} className="mb-28px">
            <p dangerouslySetInnerHTML={{ __html: text }} className="text-center"></p>
          </Alert>
        );
      })}
    </>
  );
};

export default OutageBox;
