import type { FC } from "react";

interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
}

const ThreeQuarters: FC<Props> = ({ foregroundColor, backgroundColor }) => {
  return (
    <svg width="71" height="80" viewBox="0 0 71 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      {backgroundColor !== null ? (
        <path
          d="M0.996094 55.6166L1.62109 61.9451L8.10622 66.0079L25.1385 70.7738L41.702 66.7892L57.4841 48.8194L68.4223 33.5059L67.0941 32.3341L60.7656 30.6152L53.4214 29.4433L38.8893 27.2556H35.4516L32.6389 28.7401L30.1388 31.2402L26.9355 33.5059L21.9352 37.0999L0.996094 55.6166Z"
          fill={backgroundColor}
        />
      ) : (
        ""
      )}
      <path
        opacity="0.3"
        d="M0.996094 55.6166L1.62109 61.9451L8.10622 66.0079L25.1385 70.7738L41.702 66.7892L57.4841 48.8194L68.4223 33.5059L67.0941 32.3341L60.7656 30.6152L53.4214 29.4433L38.8893 27.2556H35.4516L32.6389 28.7401L30.1388 31.2402L26.9355 33.5059L21.9352 37.0999L0.996094 55.6166Z"
        fill="#34322E"
      />
      <path
        d="M0.726562 55.7234L17.6827 40.7211C19.8627 38.7228 24.8984 34.3773 27.6016 32.9822C30.9805 31.2382 31.9615 26.8782 37.4115 27.2052C42.8614 27.5322 58.7752 30.2572 60.5192 30.5842C61.9144 30.8458 65.3878 31.7832 66.9502 32.2192"
        stroke="#34322E"
      />
      <path d="M38.2568 28.6719C38.4402 29.4881 38.6969 31.7404 38.2568 34.2191" stroke="#34322E" />
      <path
        d="M48.1729 62.3065C49.2139 61.175 57.3609 51.5345 58.3566 49.9503C59.1361 48.7104 64.0542 42.3503 66.5833 39.0186C66.6391 38.9451 66.7163 38.742 66.4136 38.6794C63.8497 38.1495 58.8287 37 57.6287 37C56.1287 37 55.6287 36 52.1287 36H30.1287C25.962 40 16.3287 48.9 11.1287 52.5C5.92869 56.1 3.483 58.6106 2.82031 59.5195C2.74219 59.6758 2.79879 59.7686 2.88416 59.791C5.12659 60.3793 10.219 61.6089 14.5442 62.3065C20.1565 63.2117 32.3769 65.5653 33.8705 65.5653C35.3641 65.5653 37.3091 66.5 38.6287 66.5H43.6287C44.715 66.5 47.1319 63.438 48.1729 62.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M3.56885 59.4853C7.50846 57.9397 16.1045 54.5771 18.9717 53.4914C22.5558 52.1343 31.8945 46.8754 32.5772 46.1969C33.2598 45.5183 32.5772 43.5392 32.5772 41.447C32.5772 39.8875 32.3782 37.6772 30.2022 36.1316"
        stroke="#78746D"
      />
      <path
        d="M48.1729 59.3065C49.2139 58.175 57.3609 48.5345 58.3566 46.9503C59.1361 45.7104 64.0542 39.3503 66.5833 36.0186C66.6391 35.9451 66.7163 35.742 66.4136 35.6794C63.8497 35.1495 58.8287 34 57.6287 34C56.1287 34 55.6287 33 52.1287 33H30.1287C25.962 37 16.3287 45.9 11.1287 49.5C5.92869 53.1 3.483 55.6106 2.82031 56.5195C2.74219 56.6758 2.79879 56.7686 2.88416 56.791C5.12659 57.3793 10.219 58.6089 14.5442 59.3065C20.1565 60.2117 32.3769 62.5653 33.8705 62.5653C35.3641 62.5653 37.3091 63.5 38.6287 63.5H43.6287C44.715 63.5 47.1319 60.438 48.1729 59.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.1729 56.3065C49.2139 55.175 57.3609 45.5345 58.3566 43.9503C59.1361 42.7104 64.0542 36.3503 66.5833 33.0186C66.6391 32.9451 66.7163 32.742 66.4136 32.6794C63.8497 32.1495 58.8287 31 57.6287 31C56.1287 31 55.6287 30 52.1287 30H30.1287C25.962 34 16.3287 42.9 11.1287 46.5C5.92869 50.1 3.483 52.6106 2.82031 53.5195C2.74219 53.6758 2.79879 53.7686 2.88416 53.791C5.12659 54.3793 10.219 55.6089 14.5442 56.3065C20.1565 57.2117 32.3769 59.5653 33.8705 59.5653C35.3641 59.5653 37.3091 60.5 38.6287 60.5H43.6287C44.715 60.5 47.1319 57.438 48.1729 56.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M48.1729 53.3065C49.2139 52.175 57.3609 42.5345 58.3566 40.9503C59.1361 39.7104 64.0542 33.3503 66.5833 30.0186C66.6391 29.9451 66.7163 29.742 66.4136 29.6794C63.8497 29.1495 58.8287 28 57.6287 28C56.1287 28 55.6287 27 52.1287 27H30.1287C25.962 31 16.3287 39.9 11.1287 43.5C5.92869 47.1 3.483 49.6106 2.82031 50.5195C2.74219 50.6758 2.79879 50.7686 2.88416 50.791C5.12659 51.3793 10.219 52.6089 14.5442 53.3065C20.1565 54.2117 32.3769 56.5653 33.8705 56.5653C35.3641 56.5653 37.3091 57.5 38.6287 57.5H43.6287C44.715 57.5 47.1319 54.438 48.1729 53.3065Z"
        fill="white"
        stroke="#78746D"
      />
      {foregroundColor !== null ? (
        <path
          d="M14.3936 59L0.893555 56L2.39355 66C2.22689 66.6667 2.49355 68 4.89355 68C7.89355 68 18.8936 71.5 22.8936 72.5C26.8936 73.5 31.3936 73 36.8936 73.5C42.3936 74 43.1436 73.5 44.3936 72.5C46.8936 70.5 47.3936 68.5 50.3936 65C53.3936 61.5 59.8936 53 60.8936 51C61.8936 49 68.3936 43.5 69.3936 42.5C70.3936 41.5 69.8936 37.5 70.3936 35.5C70.7936 33.9 67.8936 32.5 66.3936 32C67.0602 32.3333 68.3936 33.1 68.3936 33.5C68.3936 34 66.8936 34.5 65.8936 35.5C64.8936 36.5 61.8936 40.5 60.3936 42.5C58.8936 44.5 57.3936 46 54.8936 48.5C52.8936 50.5 45.3936 59.6667 41.8936 64C39.3936 63.5 34.0936 62.4 32.8936 62C31.6936 61.6 27.7269 61.3333 24.8936 61C24.8936 62.5 24.9936 64.9 21.3936 64.5C17.7936 64.1 14.8936 60.1667 14.3936 59Z"
          fill={foregroundColor}
        />
      ) : (
        ""
      )}
      <path
        d="M14.3936 59L0.893555 56L2.39355 66C2.22689 66.6667 2.49355 68 4.89355 68C7.89355 68 18.8936 71.5 22.8936 72.5C26.8936 73.5 31.3936 73 36.8936 73.5C42.3936 74 43.1436 73.5 44.3936 72.5C46.8936 70.5 47.3936 68.5 50.3936 65C53.3936 61.5 59.8936 53 60.8936 51C61.8936 49 68.3936 43.5 69.3936 42.5C70.3936 41.5 69.8936 37.5 70.3936 35.5C70.7936 33.9 67.8936 32.5 66.3936 32C67.0602 32.3333 68.3936 33.1 68.3936 33.5C68.3936 34 66.8936 34.5 65.8936 35.5C64.8936 36.5 61.8936 40.5 60.3936 42.5C58.8936 44.5 57.3936 46 54.8936 48.5C52.8936 50.5 45.3936 59.6667 41.8936 64C39.3936 63.5 34.0936 62.4 32.8936 62C31.6936 61.6 27.7269 61.3333 24.8936 61C24.8936 62.5 24.9936 64.9 21.3936 64.5C17.7936 64.1 14.8936 60.1667 14.3936 59Z"
        stroke="#34322E"
      />
      <path d="M42.0068 66.0178C42.1902 66.8341 42.4469 69.0863 42.0068 71.5651" stroke="#34322E" />
      <path
        d="M48.0446 50.3065C49.0856 49.175 57.2326 39.5345 58.2283 37.9503C59.0078 36.7104 63.9259 30.3503 66.455 27.0186C66.5108 26.9451 66.588 26.742 66.2853 26.6794C63.7214 26.1495 58.7004 25 57.5004 25C56.0004 25 55.5004 24 52.0004 24H30.0004C25.8337 28 16.2004 36.9 11.0004 40.5C5.8004 44.1 3.35471 46.6106 2.69202 47.5195C2.61389 47.6758 2.67049 47.7686 2.75587 47.791C4.99829 48.3793 10.0907 49.6089 14.4159 50.3065C20.0282 51.2117 32.2486 53.5653 33.7422 53.5653C35.2358 53.5653 37.1808 54.5 38.5004 54.5H43.5004C44.5867 54.5 47.0036 51.438 48.0446 50.3065Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M3.44049 47.4853C7.3801 45.9397 15.9761 42.5771 18.8434 41.4914C22.4274 40.1343 33.7661 35.8754 34.4488 35.1969C35.1315 34.5183 33.9516 31.3854 33.9516 29.2932C33.9516 27.7337 32.2499 25.6772 30.0739 24.1316"
        stroke="#78746D"
      />
      <path
        d="M31.6215 32.9549C31.3256 33.1213 30.8225 33.543 31.1776 33.8981"
        stroke="#78746D"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ThreeQuarters;
