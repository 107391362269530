import { Badge, Col, Container, Nav, NavItem, Row, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { ReactComponent as IconCheck } from "images/icons/check.svg";
import { ReactComponent as IconWarning } from "images/icons/check-partly.svg";
import { ReactComponent as IconError } from "images/icons/close.svg";
import { TruncateText } from "../../../../components";
import { LinkContainer } from "react-router-bootstrap";

const Envelope02 = () => {
  return (
    <>
      <div className={"page-header page-header--has-tabs page-header--no-tabs-desktop"}>
        <Container className={"container-mw-md"}>
          <h1>Projdi si své výsledky</h1>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Tab.Container defaultActiveKey={"first"}>
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="first">
                Chybné odpovědi{" "}
                <Badge bg={"danger"} className={"badge-circular"}>
                  4
                </Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                Správné odpovědi{" "}
                <Badge bg={"success"} className={"badge-circular"}>
                  2
                </Badge>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Nav className={"nav-menu"}>
                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"}>
                    <p className={"text-warning"}>
                      <IconWarning className={"me-12px"} />
                      <strong>1. Procenta</strong>
                    </p>
                    <TruncateText lines={2} showMore={false}>
                      <p>
                        Ve škole, která má v každém ročníku dvě třídy (A,B), proběhla soutěž ve sběru proběhla soutěž ve
                        sběru proběhla soutěž ve sběru proběhla soutěž ve sběru proběhla soutěž ve sběru.
                      </p>
                    </TruncateText>
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"}>
                    <p className={"text-warning"}>
                      <IconWarning className={"me-12px"} />
                      <strong>3. Geometrie</strong>
                    </p>
                    <TruncateText lines={2} showMore={false}>
                      <p>Bod C je vrchol rovnoramenného trojúhelníku ABC se základnou AB.</p>
                    </TruncateText>
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"}>
                    <p className={"text-warning"}>
                      <IconWarning className={"me-12px"} />
                      <strong>5. Poměry</strong>
                    </p>
                    <TruncateText lines={2} showMore={false}>
                      <p>Přiřaďte k jednotlivým významovým poměrům (1-3) větu (A-E), v níž se daný</p>
                    </TruncateText>
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"}>
                    <p className={"text-danger"}>
                      <IconError className={"me-12px"} />
                      <strong>6. Pravopis</strong>
                    </p>
                    <TruncateText lines={2} showMore={false}>
                      <p>Která z následujících vět je zapsána pravopisně správně?</p>
                    </TruncateText>
                  </Nav.Link>
                </NavItem>
              </Nav>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Nav className={"nav-menu"}>
                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"}>
                    <p className={"text-success"}>
                      <IconCheck className={"me-12px"} />
                      <strong>2. Rovnice</strong>
                    </p>
                    <TruncateText lines={2} showMore={false}>
                      <p>Vypočítejte níže uvedenou rovnici:</p>
                    </TruncateText>
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link className={"nav-link--with-arrow fw-normal flex-column"}>
                    <p className={"text-success"}>
                      <IconCheck className={"me-12px"} />
                      <strong>4. Text</strong>
                    </p>
                    <TruncateText lines={2} showMore={false}>
                      <p>Seřaďte jednotlivé části textu (A-F) tak, aby byla dodržena textová návaznost.</p>
                    </TruncateText>
                  </Nav.Link>
                </NavItem>
              </Nav>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <hr className={"thin yellow"} />

        <Row>
          <Col className="text-center">
            <LinkContainer to={"/dev/vysledky"}>
              <Button className="btn-mobile-width">Zavřít výsledky a ukončit procvičování</Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Envelope02;
