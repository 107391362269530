import "katex/dist/katex.min.css";

import Latex from "react-latex-next";

import { removeWrapperHTML } from "utils/removeHTML";

import type { IOption } from "store/slices/topicPractice";

export const getTextByAssignmentType = (type: string, value: string | IOption) => {
  switch (type) {
    case "abcd":
      return ABCDOptionText(value);
    case "abcd_separated":
      return ABCDOddeleneOptionText(value);
    case "yes_no":
      return AnoNeOptionText(value);
    default:
      return generalOptionText(value);
  }
};

export const ABCDOptionText = (option: IOption | undefined | null | string) => {
  if (option === undefined || option === null || typeof option === "string") return null;
  if (option?.value === undefined) return "";

  const text = `${option.value ?? ""})&nbsp;${removeWrapperHTML(option.additionalText ?? "")}`;
  return <Latex>{text}</Latex>;
};

export const ABCDOddeleneOptionText = (option: IOption | string) => {
  if (typeof option === "string") return null;

  return <Latex>{(option.value ?? "").concat(")")}</Latex>;
};

export const AnoNeOptionText = (value: string | IOption) => {
  if (typeof value !== "string") return null;
  return <span>{value === "A" ? "ANO" : "NE"}</span>;
};

export const generalOptionText = (value: string | IOption) => {
  if (typeof value !== "string") return null;

  return <Latex>{value}</Latex>;
};
