import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { Container } from "react-bootstrap";

import { Loading, SubjectsPie } from "components";
import type { ISubjectDataForPie } from "components/SubjectsPie/SubjectsPie";
import Practice from "features/TopicPractice/PracticeElement";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectPreferredSubject } from "store/slices/user";
import { setTopicAssignments, setSelectedTime, setPracticeTopicName } from "store/slices/topicPractice";

import { parsePracticeDataForPieChart } from "utils/parseDataForPieChart";
import { processApiError } from "utils/processApiError";

import { useLazyGetPracticeMhdQuery, useLazyGetUserAssignmentPaginatedQuery } from "api/generated";
import type { UserAssignmentForExerciseMinimumFragment, UserAssignmentDetailFragment } from "api/generated";

import { ReactComponent as IconPlus } from "images/icons/plus.svg";
import { ReactComponent as IconMinus } from "images/icons/minus.svg";
import TagManager from "react-gtm-module";

import { MAX_ASSIGNMENTS_PER_REQUEST } from "const";

interface IPageData {
  subjects: null | string[];
  pieChart: ISubjectDataForPie[];
}

const TIME_STEP = 5;
const TIME_MIN = 5;
const TIME_MAX = 90;

const MhdPractice = () => {
  const { t } = useTranslation(["exercise"]);
  const dispatch = useAppDispatch();

  const [trigger, { error: apiLazyMinimumError }] = useLazyGetPracticeMhdQuery();
  const [getAssignmentsData, { error: apiLazyDataError, isLoading: loadingDetails }] =
    useLazyGetUserAssignmentPaginatedQuery();

  const preferredSubject = useAppSelector(selectPreferredSubject);

  const [loadingState, setLoadingState] = useState({ isUpdate: true, isInitialLoad: true });
  const [selectedTime, selectTime] = useState(TIME_MIN);
  const [apiData, setApiData] = useState<IPageData>({ subjects: null, pieChart: [] });
  const [assignments, setAssignments] = useState<UserAssignmentForExerciseMinimumFragment[]>([]);
  const [practiceAssignments, setPracticeAssignments] = useState<UserAssignmentDetailFragment[]>([]);

  const loadingData = useRef(false);

  const sortSubjects = (subjects: string[]) => {
    subjects.sort((a, b) => a.localeCompare(b));

    if (typeof preferredSubject === "string" && subjects.includes(preferredSubject)) {
      /* Moves preferred subject to last position */
      subjects.push(subjects.splice(subjects.indexOf(preferredSubject), 1)[0]);
    }

    return subjects;
  };

  useEffect(() => {
    if (apiLazyMinimumError === undefined) return;

    processApiError(apiLazyMinimumError);
  }, [apiLazyMinimumError]);

  useEffect(() => {
    if (apiLazyDataError === undefined) return;

    processApiError(apiLazyDataError);
  }, [apiLazyDataError]);

  useEffect(() => {
    if (loadingData.current) return;
    loadingData.current = true;

    setLoadingState(state => {
      return {
        isUpdate: true,
        isInitialLoad: state.isInitialLoad,
      };
    });

    trigger({ length: selectedTime * 60 })
      .then(data => {
        if ("data" in data) {
          const assignments = data.data?.trainingMhd.userAssignments;
          if (assignments === undefined) return;

          setAssignments(assignments);
          const parsedData = parsePracticeDataForPieChart(assignments);
          const subjects = sortSubjects(parsedData.map(data => data.name));

          setApiData({ subjects, pieChart: parsedData });
        } else {
          setApiData({ subjects: [], pieChart: [] });
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        loadingData.current = false;
        setLoadingState({
          isUpdate: false,
          isInitialLoad: false,
        });
      });
  }, [selectedTime]);

  const increaseTime = () => {
    selectTime(prev => prev + TIME_STEP);
  };

  const decreaseTime = () => {
    if (TIME_MIN + TIME_STEP <= selectedTime) {
      selectTime(prev => prev - TIME_STEP);
    }
  };

  const startExercise = async (subject: string) => {
    try {
      const ids = assignments.map(assignment => assignment.id);
      const assignmentsData = await getAllAssignmentDetails(ids);

      TagManager.dataLayer({
        dataLayer: {
          event: "exercise-started",
          subject,
          duration: selectedTime,
          questionCount: apiData.pieChart.reduce((accumulator, subjectData) => accumulator + subjectData.value, 0),
        },
      });

      dispatch(setSelectedTime(selectedTime));
      dispatch(setTopicAssignments({ assignments: assignmentsData, topicId: "mhd" }));
      dispatch(setPracticeTopicName(null));

      setPracticeAssignments(assignmentsData);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserAssignments = async (userAssignments: UserAssignmentForExerciseMinimumFragment[], ids: string[]) => {
    const data = await getAssignmentsData({ assignmentIds: ids });

    if ("data" in data) {
      const assignments = data.data?.userAssignments.items.filter(assignment => assignment.assignment?.isActive);

      if (Array.isArray(assignments)) {
        userAssignments.push(...assignments);
      }
    }

    if ("error" in data) {
      console.error(data.data);
    }
  };

  const getAllAssignmentDetails = async (ids: string[]) => {
    try {
      const userAssignments: UserAssignmentDetailFragment[] = [];

      const assignmentPromises: Array<Promise<void>> = [];
      let offset = 0;

      do {
        assignmentPromises.push(
          getUserAssignments(userAssignments, ids.slice(offset, offset + MAX_ASSIGNMENTS_PER_REQUEST)),
        );
        offset += MAX_ASSIGNMENTS_PER_REQUEST;
      } while (offset < ids.length);

      await Promise.all(assignmentPromises);

      return userAssignments;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  if (practiceAssignments.length > 0) {
    return (
      <Container className={"container-mw-md layout--wide"}>
        <Practice assignments={practiceAssignments} redirectURL="/procvicovani/vyhodnoceni" />;
      </Container>
    );
  }

  return loadingState.isInitialLoad ? (
    <Container className={"container-mw-sm layout--narrow"}>
      <div className={"fullpage bg-primary-subtle"}>
        <Loading />
      </div>
    </Container>
  ) : (
    <>
      <main
        className={
          "h-100 flex-grow-1 overflow-hidden d-flex flex-column justify-content-between justify-content-sm-start"
        }
      >
        <div className="page">
          <Container className={"container-mw-md layout--narrow mb-sm-32px"}>
            <div className="page__header">
              <LinkContainer to="/">
                <Button variant={"close"} />
              </LinkContainer>
            </div>
          </Container>
          <Container className={"container-mw-sm layout--narrow mb-auto"}>
            <div className="page__content">
              <p className="text-center mb-12px">
                <strong>{t("label.youGotMoreTime", { ns: "exercise" })}</strong>
              </p>

              <div className={`increment-group ${apiData.pieChart.length > 0 ? "" : "mb-32px"}`}>
                <Button
                  variant={"primary"}
                  className={"btn-circular"}
                  onClick={decreaseTime}
                  disabled={loadingState.isUpdate || selectedTime <= TIME_MIN || apiData.subjects === null}
                >
                  <IconMinus />
                </Button>
                <p className={"h2 mb-0"}>
                  {selectedTime} {t("minuteShort", { ns: "common" })}
                </p>
                <Button
                  variant={"primary"}
                  className={"btn-circular"}
                  onClick={increaseTime}
                  disabled={
                    loadingState.isUpdate ||
                    apiData.subjects === null ||
                    apiData.subjects.length === 0 ||
                    selectedTime >= TIME_MAX
                  }
                >
                  <IconPlus />
                </Button>
              </div>

              {apiData.pieChart.length > 0 ? (
                <SubjectsPie data={apiData.pieChart} />
              ) : (
                <div
                  style={{
                    height: 174,
                  }}
                />
              )}
            </div>

            <div className="page__footer mt-auto">
              {apiData.subjects === null ? null : (
                <>
                  <p className="text-center mb-12px">
                    {apiData.subjects.length > 0 ? (
                      <strong>{t("header.startWith")}</strong>
                    ) : (
                      <strong>{t("header.noAssignments")}</strong>
                    )}
                  </p>

                  {apiData.subjects.map((subject, index) => {
                    return (
                      <Button
                        key={subject}
                        type={"submit"}
                        className={"w-100 text-uppercase"}
                        onClick={() => {
                          startExercise(subject).catch(console.error);
                        }}
                        {...(index === 0 ? {} : { variant: "outline-primary" })}
                        disabled={loadingDetails}
                      >
                        {subject}
                      </Button>
                    );
                  })}
                </>
              )}
            </div>
          </Container>
        </div>
      </main>
    </>
  );
};

export default MhdPractice;
