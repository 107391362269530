import type { FC } from "react";

import SelfEvaluation from "./_SelfEvaluation";

import type { UserAssignmentForExerciseFragment } from "api/generated";

/* rL4n9VXYXv2RntyoGQlj */

interface Props {
  data: UserAssignmentForExerciseFragment;
}

const Rysovani: FC<Props> = ({ data }) => {
  return <SelfEvaluation userAssignment={data} />;
};

export default Rysovani;
