import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "components";
import { SubjectsTab, GreenEnvelopeHeading, OrangeEnvelopeHeading, RedEnvelopeHeading } from "features";

import { useGetEnvelopeSubjectsTopicsQuery } from "api/generated";

import { sortEnvelopeSubjects } from "utils/sortTopics";
import { processApiError } from "utils/processApiError";
import { translateEnvelopeId } from "utils/translateEnvelopeId";

const EnvelopeSubjects = () => {
  const { t } = useTranslation(["common", "assignments"]);
  const navigate = useNavigate();
  const { envelopeId } = useParams();

  if (envelopeId === undefined) {
    navigate("/");
    return null;
  }

  const translatedEnvelopeName = translateEnvelopeId(envelopeId);
  if (translatedEnvelopeName === "") return null;

  const {
    data,
    isLoading,
    error: apiError,
  } = useGetEnvelopeSubjectsTopicsQuery({
    isGreen: envelopeId === "zelena",
    isOrange: envelopeId === "oranzova",
    isRed: envelopeId === "cervena",
  });

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  const getTopicHref = (topicId: string) => {
    if (envelopeId === undefined) return "";

    return `/ulohy/obalka/${envelopeId}/${topicId}`;
  };

  const getHeading = () => {
    if (data === undefined) return null;

    const totalAssignments = data.envelopesSummary.green + data.envelopesSummary.yellow + data.envelopesSummary.red;

    switch (envelopeId) {
      case "zelena":
        return (
          <GreenEnvelopeHeading
            percentage={data.envelopesSummary.green / totalAssignments}
            assignmentsCount={data.envelopesSummary.green ?? 0}
          />
        );
      case "oranzova":
        return (
          <OrangeEnvelopeHeading
            percentage={data.envelopesSummary.yellow / totalAssignments}
            assignmentsCount={data.envelopesSummary.yellow ?? 0}
          />
        );
      case "cervena":
        return (
          <RedEnvelopeHeading
            percentage={data.envelopesSummary.red / totalAssignments}
            assignmentsCount={data.envelopesSummary.red ?? 0}
          />
        );
      default:
        return <h1>Neznámá obálka</h1>;
    }
  };

  const getFallbackText = () => {
    switch (envelopeId) {
      case "zelena":
        return t("nothingInGreenEnvelope", { ns: "envelope" });
      case "oranzova":
        return t("nothingInOrangeEnvelope", { ns: "envelope" });
      case "cervena":
        return t("nothingInRedEnvelope", { ns: "envelope" });
      default:
        return "";
    }
  };

  return (
    <>
      <div className={"page-header page-header--has-tabs"}>{getHeading()}</div>
      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        {isLoading ? (
          <Loading />
        ) : data === undefined ? null : (
          <SubjectsTab
            data={sortEnvelopeSubjects(data, getTopicHref, envelopeId)}
            fallbackText={getFallbackText()}
            showStars={false}
          />
        )}
      </Container>
    </>
  );
};

export default EnvelopeSubjects;
