import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import { AnswerPractice } from "components";

import type { IAssignmentResult } from "store/slices/topicPractice";

import { generalOptionText } from "utils/formatTextForAnalysis";
import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* Vg88XS8doR7IqSBg5PuQ */

interface Props {
  assignmentResult: IAssignmentResult;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const ViceMoznostiDole: FC<Props> = ({ assignmentResult, toggleModals }) => {
  const assignment = assignmentResult.assignment.assignment;
  if (assignment === undefined || assignment === null) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);

  return (
    <>
      <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className={"mt-20px"}>
        {assignment.assignment?.questions.map((question, index) => {
          return (
            <div key={index} className="question">
              <Latex>{question.heading ?? ""}</Latex>

              <AnswerPractice
                key={`answer-${index}`}
                index={index}
                assignment={assignment}
                type={assignmentResult.answerVariants?.[index]}
                toggleModals={toggleModals}
                showBackOnSolution={true}
                userAssignmentId={assignmentResult.assignment.id}
              >
                {isSelfEvaluation
                  ? getTextForSelfEvaluationOption(assignmentResult.selfEvalAnswers[index]?.status ?? "danger")
                  : generalOptionText(assignmentResult.userAnswers[index])}
              </AnswerPractice>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ViceMoznostiDole;
