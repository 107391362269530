import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";
import { Form } from "react-bootstrap";

interface Props {
  selectIndex: number;
  optionIndex: number;
  score: number | null;
  scoreReal: number;
  imagePath: string | undefined;
  optionText: string | null | undefined;
  handleEvaluationSelect: (index: number, points: number) => void;
}
const SelfEvaluationOption: FC<Props> = ({
  selectIndex,
  optionIndex,
  score,
  scoreReal,
  optionText,
  imagePath,
  handleEvaluationSelect,
}) => {
  const id = `${selectIndex}-${optionIndex}`;

  return (
    <Form.Check type="radio">
      {optionIndex !== 0 ? <hr className={"my-12px"} /> : null}
      <Form.Check.Input
        type="radio"
        name={"radioGroup"}
        onClick={() => {
          handleEvaluationSelect(selectIndex, scoreReal);
        }}
        checked={score === scoreReal}
        onChange={() => {}}
        id={id}
      />
      <Form.Check.Label htmlFor={id} className={"fw-normal"}>
        <Latex>{optionText ?? ""}</Latex>
        {imagePath !== undefined && imagePath.length > 0 ? (
          <img src={imagePath} style={{ maxWidth: "100%" }} className="mt-8px" />
        ) : null}
      </Form.Check.Label>
    </Form.Check>
  );
};

export default SelfEvaluationOption;
