import type { FC } from "react";
import Button from "react-bootstrap/Button";

import { useBookmarks } from "utils/hooks/useBookmarks";

import { ReactComponent as IconBookmark } from "images/icons/bookmark.svg";
import { useTranslation } from "react-i18next";
import { Overlay, Popover } from "react-bootstrap";
import { useState } from "react";

interface Props {
  userAssignmentId: string;
}

const BookmarkAsButton: FC<Props> = ({ userAssignmentId }) => {
  const { toggleBookmark, isLoadingData, isBookmarked } = useBookmarks(userAssignmentId);

  const [target, setTarget] = useState(null);
  const [popoverShown, togglePopover] = useState(false);

  const { t } = useTranslation(["common"]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    toggleBookmark();
    togglePopover(true);
    setTarget(event.target as any);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    togglePopover(false);
  };

  return (
    <Button
      variant={"outline-secondary-darker"}
      onClick={handleClick}
      disabled={isLoadingData}
      className="position-relative"
    >
      <Overlay
        show={popoverShown && isBookmarked && !isLoadingData}
        placement="top"
        target={target}
        containerPadding={20}
      >
        <Popover placement={"top"} className={"popover-success"}>
          <button className={"close"} onClick={handleClose} />
          <Popover.Body>
            <p>
              <strong>Otázku jsme uložili</strong> {/* Todo: replace with title? */}
            </p>
            <p className={"mb-0"}>Otázku najdeš v sekci „obálky“ a můžeš se k ní kdykoliv vrátit.</p>{" "}
            {/* Todo: Replace with message body? */}
          </Popover.Body>
        </Popover>
      </Overlay>

      <IconBookmark fill={isBookmarked ? "black" : "none"} className={"me-2"} />
      {isBookmarked ? t("saved") : t("saveForLater")}
    </Button>
  );
};

export default BookmarkAsButton;
