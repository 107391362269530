import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Layout from "./_Layout";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectSimpleAnswer, setSimpleAnswer } from "store/slices/answer";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* rPp7XgOY9DxQXaSbZsBQ */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const Razeni: FC<Props> = ({ data, onSubmit }) => {
  const options =
    data.assignment?.assignment?.questions.map(question => {
      return question.options.map(option => option.value);
    }) ?? [];
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const selected = useAppSelector(selectSimpleAnswer);
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const handleCheck = (index: number, position: number, newValue: string | null | undefined) => {
    const newAnswers: string[] =
      selected.length === 0 ? new Array(data.assignment?.assignment?.questions.length).fill("") : [...selected];
    const originalString = newAnswers[index];
    const optionsCount = options[index].length;

    let newString = originalString;

    if (typeof newString !== "string" || newString.length !== optionsCount) {
      newString = " ".repeat(optionsCount);
    }

    newString = `${newString.substring(0, position)}${newValue ?? ""}${newString.substring(position + 1)}`;
    newAnswers[index] = newString;
    dispatch(setSimpleAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return selected.length > 0 && selected.every(answer => answer !== " ");
  };

  const isChecked = (qIndex: number, vIndex: number, option: string | null | undefined) => {
    if (typeof option !== "string") return false;

    const answers = selected[qIndex];
    const optionsCount = options[qIndex].length;

    if (answers === undefined || answers.length !== optionsCount) return false;

    return answers[vIndex] === option;
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()}>
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className="mt-20px">
        {data.assignment?.assignment?.questions.map((question, qIndex) => {
          return (
            <div key={qIndex}>
              <Latex>{question.heading ?? ""}</Latex>

              {question.options.map((option, oIndex) => {
                return (
                  <div
                    key={`text-${qIndex}-${oIndex}`}
                    className="assignment-option"
                    dangerouslySetInnerHTML={{ __html: option.additionalText ?? "" }}
                  ></div>
                );
              })}
              <div className="question question--list mt-20px">
                {options[qIndex].map((_values, vIndex) => (
                  <div key={`question-${vIndex}`} className="button-group">
                    <span>{`${vIndex + 1}.`}</span>
                    <ButtonGroup className={"btn-group-select btn-shadow"}>
                      {options[qIndex].map((option, rIndex) => (
                        <ToggleButton
                          key={rIndex}
                          id={`radio-${qIndex}-${vIndex}-${rIndex}`}
                          type="radio"
                          variant="select"
                          value={option ?? ""}
                          checked={isChecked(qIndex, vIndex, option)}
                          onChange={() => {
                            handleCheck(qIndex, vIndex, option);
                          }}
                        >
                          {option}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Razeni;
