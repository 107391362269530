import type { IOnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip";

export const tooltipTexts: IOnboardingTooltip[] = [
  {
    key: "assignment-result",
    title: "Odpovědi",
    description: (
      <p>
        {"Tady najdeš svoje odpovědi. Můžeš si na každou otázku kliknout, dostaneš se tak na její rozbor a vysvětlení."}
      </p>
    ),
    buttonText: "Chápu :)",
    elementId: "assignment-result",
  },
];

export const doneOnboardingTooltip: IOnboardingTooltip = {
  key: "all-done",
  title: "A je to hotovo!",
  description: <p>{"Teď už víš, jak to u nás funguje a můžeme začít procvičovat. Těšíme se a držíme palce :)"}</p>,
  buttonText: "Spustit aplikaci",
};
