import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

import { GenericBox, PrintAttachmentBox } from "components";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { extractFileNameFromPath } from "utils/extractFileNameFromPath";

interface Props {
  userAssignment: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const Task: FC<Props> = ({ userAssignment, onSubmit }) => {
  const assignment = userAssignment.assignment?.assignment;

  const { t } = useTranslation(["assignments"]);

  const { imageUrl } = useImageUrlResolver(assignment?.assignmentImage);
  const { imageUrl: printAttachmentUrl } = useImageUrlResolver(assignment?.printingAttachment);

  return (
    <div className="exercise">
      <div className="exercise__body">
        <Latex>{assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        <Latex>{assignment?.questionsText ?? ""}</Latex>

        {assignment?.questions.map((question, qIndex) => {
          return (
            <div key={qIndex} className="mt-20px ">
              <Latex>{question.heading ?? ""}</Latex>
            </div>
          );
        })}

        {printAttachmentUrl !== null ? (
          <PrintAttachmentBox
            printAttachmentURL={printAttachmentUrl}
            pdfName={extractFileNameFromPath(assignment?.printingAttachment)}
          />
        ) : (
          <GenericBox />
        )}
      </div>
      <div className="exercise__footer">
        <div className="mw-sm px-3">
          <Button className={"w-100 text-uppercase"} onClick={onSubmit}>
            {t("detail.submitSelfEvaluation", { ns: "assignments" })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Task;
