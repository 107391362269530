import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";
import { Form } from "react-bootstrap";

import Layout from "./_Layout";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectSimpleAnswer, setSimpleAnswer } from "store/slices/answer";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* PhSEP6BeiTiGFLCmXcN2 */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const Rovnice: FC<Props> = ({ data, onSubmit }) => {
  const questionsCount = data.assignment?.assignment?.questions.length;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod.scoringMethod === "sebeopravení";

  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const selected = useAppSelector(selectSimpleAnswer);
  const dispatch = useAppDispatch();

  const handleChange = (index: number, value: string) => {
    const newAnswers = selected.length === 0 ? new Array(questionsCount).fill("") : [...selected];
    newAnswers[index] = value;

    dispatch(setSimpleAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return isSelfEvaluation || (selected.length > 0 && selected.every(answer => answer.length > 0));
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()} isSelfEvaluation={isSelfEvaluation}>
      <div>
        <div>
          <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
          {imageUrl !== null ? <img src={imageUrl} /> : null}
        </div>

        {isSelfEvaluation ? (
          <>
            <div
              className="mt-20px p-mb-0 question"
              dangerouslySetInnerHTML={{ __html: data.assignment?.assignment?.questionsText ?? "" }}
            ></div>
            {data.assignment?.assignment?.questions.map((question, index) => {
              return (
                <div key={index}>
                  <Latex>{question.heading ?? ""}</Latex>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {data.assignment?.assignment?.questions.map((question, index) => (
              <div key={index} className="mt-20px question">
                <Latex>{question.heading ?? ""}</Latex>
                <Form.Control
                  onChange={event => {
                    handleChange(index, event.target.value);
                  }}
                ></Form.Control>
              </div>
            ))}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Rovnice;
