import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { tooltipTexts } from "./TooltipTexts";

import { Loading, OnboardingTooltip } from "components";
import Test from "pages/Exercise/02_Test/Test";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setExerciseAssignments, resetExercise, getAssignments } from "store/slices/exercise";
import { startGlobalTimer } from "store/slices/timer";

import { useGetOnboardingAssignmentsQuery } from "api/generated";

import { eventEmitter, Events } from "utils/events/EventEmitter";
import { processApiError } from "utils/processApiError";

import { ONBOARDING_STORE_ID } from "const";

const setHighlightClass = (element: HTMLElement) => {
  element.classList.add("highlight-tooltip");
};

const resetHighlightClass = (element: HTMLElement) => {
  element.classList.remove("highlight-tooltip");
};

const ExampleExercise = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading, error: apiError } = useGetOnboardingAssignmentsQuery();
  const assignments = useAppSelector(getAssignments);
  const navigate = useNavigate();

  const { t } = useTranslation(["common", "exercise"]);

  const [shownTooltipIndex, setShownTooltipIndex] = useState<number | null>(0);

  const highlightedElement = useRef<HTMLElement | null>();
  const shownTooltips = useRef<number[]>([0]);
  const processingTooltip = useRef<boolean>(false);

  useEffect(() => {
    // Works for first few tooltips
    showNextTooltip();
  });

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    dispatch(resetExercise());

    eventEmitter.subscribe(Events.ONBOARDING_UPDATE, showNextTooltip);
    return () => {
      eventEmitter.unsubscribe(Events.ONBOARDING_UPDATE);
    };
  }, []);

  const showNextTooltip = () => {
    if (
      processingTooltip.current ||
      highlightedElement.current !== null ||
      shownTooltips.current.length >= tooltipTexts.length
    ) {
      dispatch(startGlobalTimer());
      return;
    }

    processingTooltip.current = true;

    for (let tooltipIndex = 0; tooltipIndex < tooltipTexts.length; tooltipIndex++) {
      if (shownTooltips.current.includes(tooltipIndex)) continue;

      const elementId = tooltipTexts[tooltipIndex]?.elementId;
      highlightedElement.current =
        elementId !== null && typeof elementId === "string" ? document.getElementById(elementId) : null;

      if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
        shownTooltips.current.push(tooltipIndex);
        setHighlightClass(highlightedElement.current);
        setShownTooltipIndex(tooltipIndex);

        processingTooltip.current = false;

        return;
      }
    }

    processingTooltip.current = false;
  };

  useEffect(() => {
    if (data === undefined) return;

    dispatch(
      setExerciseAssignments({
        sessionId: ONBOARDING_STORE_ID,
        assignments: data?.userAssignments.items ?? [],
      }),
    );
  }, [data]);

  const onTooltipClose = () => {
    if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
      resetHighlightClass(highlightedElement.current);
    }

    highlightedElement.current = null;
    setShownTooltipIndex(null);
    showNextTooltip();
  };

  const handleTestFinish = () => {
    navigate("/onboarding/priklad-vyhodnoceni");
  };

  const handleQuit = () => {
    navigate("/");
  };

  return (
    <>
      {isLoading ? (
        <div className={"fullpage bg-primary-subtle"}>
          <Loading />
        </div>
      ) : assignments.length === 0 ? (
        <div className={"fullpage bg-danger-subtle"}>
          <div className="d-flex justify-content-center align-items-center my-60px">
            <h3 className={"mb-0"}>{t("message.errorNoExercises", { ns: "exercise" })}</h3>
          </div>
        </div>
      ) : (
        <div className="exercise">
          <OnboardingTooltip
            key={shownTooltipIndex}
            data={shownTooltipIndex === null ? null : tooltipTexts[shownTooltipIndex]}
            onConfirm={onTooltipClose}
            highlightedElement={highlightedElement.current ?? null}
          />

          <Test
            onFinishingLastAssignment={handleTestFinish}
            onQuit={handleQuit}
            evaluateAnswer={false}
            isOnboarding={true}
          />
        </div>
      )}
    </>
  );
};

export default ExampleExercise;
