import { TeacherEvaluationUploadForm, useTeacherEvaluationToken } from "features/TeacherEvaluation";
import { useParams } from "react-router-dom";

const TeacherEvaluationUpload = () => {
  const { userAssignmentId } = useParams();
  const teacherEvaluationToken = useTeacherEvaluationToken();

  if (teacherEvaluationToken === null || userAssignmentId === undefined) return null;

  return <TeacherEvaluationUploadForm token={teacherEvaluationToken} userAssignmentId={userAssignmentId} />;
};
export default TeacherEvaluationUpload;
