import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { areBookmarksLoaded, saveBookmark, saveBookmarks, deleteBookmark, getBookmark } from "store/slices/bookmarks";

import { useLazyGetAllBookmarksQuery, useDeleteBookmarkMutation, useSaveBookmarkMutation } from "api/generated";

import { processApiError } from "utils/processApiError";

export const useBookmarks = (assignmentId: string) => {
  const [getBookmarks, { isLoading, error: apiLazyError }] = useLazyGetAllBookmarksQuery();
  const [deleteBookmarkMutation, { error: apiDeleteError }] = useDeleteBookmarkMutation();
  const [saveBookmarkMutation, { error: apiSaveError }] = useSaveBookmarkMutation();

  const bookmarksLoaded = useAppSelector(areBookmarksLoaded);
  const bookmark = useAppSelector(getBookmark(assignmentId));
  const dispatch = useAppDispatch();

  const [isLoadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  useEffect(() => {
    if (apiDeleteError === undefined) return;

    processApiError(apiDeleteError);
  }, [apiDeleteError]);

  useEffect(() => {
    if (apiSaveError === undefined) return;

    processApiError(apiSaveError);
  }, [apiSaveError]);

  const toggleBookmark = () => {
    if (Boolean(isLoading) || isLoadingData) return;

    setLoadingData(true);

    if (bookmark === undefined) {
      saveBookmarkMutation({ assignmentId })
        .then(response => {
          if ("data" in response) {
            const bookmarkData = {
              bookmarkId: response.data.setBookmark.id,
              assignmentId,
            };
            dispatch(saveBookmark(bookmarkData));
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setLoadingData(false);
        });
      return;
    }

    deleteBookmarkMutation({ bookmarkId: bookmark.bookmarkId })
      .then(response => {
        if ("data" in response) {
          dispatch(deleteBookmark({ assignmentId }));
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    if (bookmarksLoaded) return;

    getBookmarks()
      .then(bookmarks => {
        if ("data" in bookmarks) {
          if (bookmarks.data === undefined) return;

          const data = bookmarks.data.bookmarks.map(bookmark => {
            return {
              bookmarkId: bookmark.id,
              assignmentId: bookmark.userAssignment.id,
            };
          });
          dispatch(saveBookmarks(data));
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return { toggleBookmark, isLoadingData, isBookmarked: bookmark !== undefined };
};
