import { Alert, Button, Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "store/hooks";
import { setPracticeTopicName } from "store/slices/topicPractice";

import busImage from "images/icons/bus.svg";

import { useTranslation } from "react-i18next";

const PracticeInMhd = () => {
  const { t } = useTranslation(["exercise"]);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(setPracticeTopicName(null));
    navigate("/procvicovani/mhd");
  };

  return (
    <>
      <h4 className={"mb-3 mt-25px"}>{t("header.extraExercvise")}</h4>
      <Alert variant={"success"}>
        <Row className="g-12px">
          <Col xs={"auto"} style={{ width: 50 }}>
            <Image src={busImage} />
          </Col>
          <Col>
            <p className={"mb-12px"}>
              Nemáš u sebe počítač ani papíry a přesto si chceš procvičit pár příkladů navíc? Super přístup, chválíme tě
              🙂. <strong>Nezapomeň ale, že tohle procvičování se ti nepočítá do plánu.</strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={"auto"} style={{ width: 50 }} className={"d-none d-sm-block"}></Col>
          <Col className={"well__btn"}>
            <Button variant="success" className={"text-uppercase"} onClick={handleClick}>
              {t("btn.practiceInPublicTransport")}
            </Button>
          </Col>
        </Row>
      </Alert>
    </>
  );
};

export default PracticeInMhd;
