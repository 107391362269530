import { useEffect, useState } from "react";
import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { Alert, Container } from "react-bootstrap";

import Practice from "./PracticeElement";

import { IsGeometry, Stopwatch } from "components";

import { useLazyGetUserAssignmentPaginatedQuery } from "api/generated";
import type { UserAssignmentPracticeFragment, UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch } from "store/hooks";
import { setTopicAssignments, setPracticeTopicName } from "store/slices/topicPractice";

import { getFirebaseImageURL } from "utils/getFirebaseImageURL";
import { processApiError } from "utils/processApiError";

import { ReactComponent as IconPlus } from "images/icons/plus.svg";
import { ReactComponent as IconMinus } from "images/icons/minus.svg";

import { DEFAULT_ASSIGNMENTS_COUNT, MAX_ASSIGNMENTS_PER_REQUEST } from "const";

interface Props {
  assignmentsList: UserAssignmentPracticeFragment[];
  topicId: string;
  title: string;
}

const TopicPractice: FC<Props> = ({ assignmentsList, topicId, title }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "exercise"]);

  const [trigger, { error: apiLazyError, isLoading: loadingDetails }] = useLazyGetUserAssignmentPaginatedQuery();

  const [assignmentsCount, setAssignmentsCount] = useState<number>(DEFAULT_ASSIGNMENTS_COUNT);
  const [geometryImages, setGeometryImages] = useState<Array<string | undefined>>([]);
  const [assignments, setAssignments] = useState<UserAssignmentPracticeFragment[]>(assignmentsList);
  const [assignmentsToPractice, setAssignmentsToPractice] = useState<UserAssignmentDetailFragment[]>([]);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  const getImageUrls = async (allAssignments: UserAssignmentPracticeFragment[]) => {
    const urls: Array<Promise<string | undefined> | undefined> = [];

    for (const assignment of allAssignments) {
      const imagePath = assignment.assignment?.assignment?.printingAttachment;

      if (typeof imagePath === "string") {
        urls.push(
          getFirebaseImageURL(imagePath)
            .then(url => url)
            .catch(error => {
              console.error(error);
              return undefined;
            }),
        );
      } else {
        urls.push(undefined);
      }
    }

    setGeometryImages(await Promise.all(urls));
  };

  useEffect(() => {
    setGeometryImages([]);
    setAssignmentsCount(Math.min(assignmentsList.length, 3));

    if (assignmentsList.length <= 0) {
      setAssignments([]);
      return;
    }

    setAssignments(assignmentsList);
    getImageUrls(assignmentsList).catch(console.error);
  }, [assignmentsList]);

  const increaseCount = () => {
    if (assignmentsCount < assignmentsList.length) {
      setAssignmentsCount(prev => prev + 1);
    }
  };

  const decreaseCount = () => {
    if (assignmentsCount > 1) {
      setAssignmentsCount(prev => prev - 1);
    }
  };

  const calculateTime = () => {
    return assignments.slice(0, assignmentsCount).reduce((accumulator, item) => {
      accumulator += ((item.timeToLearn ?? 0) + (item.timeToSolve ?? 0)) / 60;
      return accumulator;
    }, 0);
  };

  const startExercise = async () => {
    try {
      const ids = assignments.slice(0, assignmentsCount).map(assignment => assignment.id);
      const assignmentsData = await getAllAssignmentDetails(ids);

      dispatch(setTopicAssignments({ assignments: assignmentsData, topicId }));
      dispatch(setPracticeTopicName(title));
      setAssignmentsToPractice(assignmentsData);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserAssignments = async (userAssignments: UserAssignmentPracticeFragment[], ids: string[]) => {
    const data = await trigger({ assignmentIds: ids });

    if ("data" in data) {
      const assignments = data.data?.userAssignments.items.filter(assignment => assignment.assignment?.isActive);

      if (Array.isArray(assignments)) {
        userAssignments.push(...assignments);
      }
    }

    if ("error" in data) {
      console.error(data.data);
    }
  };

  const getAllAssignmentDetails = async (ids: string[]) => {
    try {
      const userAssignments: UserAssignmentDetailFragment[] = [];

      const assignmentPromises: Array<Promise<void>> = [];
      let offset = 0;

      do {
        assignmentPromises.push(
          getUserAssignments(userAssignments, ids.slice(offset, offset + MAX_ASSIGNMENTS_PER_REQUEST)),
        );
        offset += MAX_ASSIGNMENTS_PER_REQUEST;
      } while (offset < ids.length);

      await Promise.all(assignmentPromises);

      return userAssignments;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  if (assignmentsToPractice.length > 0) {
    return (
      <Container className={"container-mw-md layout--wide"}>
        <Practice assignments={assignmentsToPractice} redirectURL="/procvicovani/vyhodnoceni" />
      </Container>
    );
  }

  return (
    <>
      <main
        className={
          "h-100 flex-grow-1 overflow-hidden d-flex flex-column justify-content-between justify-content-sm-start"
        }
      >
        <div className="page">
          <Container className={"container-mw-md layout--narrow mb-sm-32px"}>
            <div className="page__header">
              <LinkContainer to="/">
                <Button variant={"close"} />
              </LinkContainer>
            </div>
          </Container>

          <Container className={"container-mw-sm layout--narrow mb-auto flex-grow-1"}>
            <div className="page__content">
              <h1 className={"h2 mb-40px text-center"}>
                <Trans
                  i18nKey="header.exerciseStart"
                  ns="exercise"
                  values={{ exerciseType: title }}
                  components={{
                    highlight: <span className={"text-highlight"} />,
                    br: <br />,
                  }}
                />
              </h1>

              <p className="text-center mb-12px">
                <strong>{t("label.howManyExercises", { ns: "exercise" })}</strong>
              </p>

              <div className="increment-group mb-32px">
                <Button
                  variant={"primary"}
                  className={"btn-circular"}
                  onClick={decreaseCount}
                  disabled={assignmentsCount <= 1}
                >
                  <IconMinus />
                </Button>
                <p className={"h2 mb-0"}>
                  {assignmentsCount} {t("exercise", { ns: "exercise", count: assignmentsCount })}
                </p>
                <Button
                  variant={"primary"}
                  className={"btn-circular"}
                  onClick={increaseCount}
                  disabled={assignmentsCount >= assignmentsList.length}
                >
                  <IconPlus />
                </Button>
              </div>

              <p className="text-center mb-12px">
                <strong>{t("label.timeEstimate", { ns: "exercise" })}</strong>
              </p>

              <Stopwatch minutes={calculateTime()} className={"mb-32px"} />

              <Alert variant={"info"}>{t("info.getSupplies", { ns: "exercise" })}</Alert>

              {geometryImages.slice(0, assignmentsCount).filter(url => typeof url === "string").length > 0 ? (
                <IsGeometry
                  imagesUrl={geometryImages
                    .slice(0, assignmentsCount)
                    .filter((url): url is string => typeof url === "string")}
                />
              ) : null}
            </div>

            <div className="page__footer">
              {assignments.length === 0 ? (
                <p className="text-center mb-12px">
                  <strong>{t("header.noAssignments", { ns: "exercise" })}</strong>
                </p>
              ) : (
                <Button
                  type={"submit"}
                  className={"w-100 text-uppercase"}
                  onClick={() => {
                    startExercise().catch(console.error);
                  }}
                  disabled={loadingDetails}
                >
                  {t("btn.start", { ns: "exercise" })}
                </Button>
              )}
            </div>
          </Container>
        </div>
      </main>
    </>
  );
};

export default TopicPractice;
