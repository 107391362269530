import { Navigate, Route, Routes } from "react-router-dom";

import Statistics from "./Statistics";

const StatisticsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Statistics />} />
      <Route path="*" element={<Navigate to="/statistiky" />} />
    </Routes>
  );
};

export default StatisticsRoutes;
