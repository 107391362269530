import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import i18n from "i18next";

import { ReactComponent as IconPlus } from "images/icons/plus.svg";
import { ReactComponent as IconMinus } from "images/icons/minus.svg";
import { useState } from "react";
import { Link } from "react-router-dom";

import { PieChart, Pie, Cell } from "recharts";
import { Trans, useTranslation } from "react-i18next";

const COUNT_MIN = 1;
const COUNT_MAX = 10;

export interface TLabelFormatter {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const data = [
  { name: "Matematika", value: 2, color: "#17A4C2" },
  { name: "Čeština", value: 1, color: "#F46969" },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: TLabelFormatter) => {
  const radius = outerRadius;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const textXposition = x > cx ? x + 18 : x - 18;

  return (
    <>
      <circle cx={x} cy={y} r="10" fill="#3D3B32" fillOpacity="0.4" />
      <circle cx={x} cy={y} r="4" fill="white" />

      <text x={textXposition} y={y} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
        <tspan className={"recharts-label-name"} x={textXposition} dy="0">{`${data[index]?.name}`}</tspan>
        <tspan className={"recharts-label-value"} x={textXposition} dy="1.2em">{`${i18n.t("question", {
          ns: "common",
          count: data[index]?.value,
        })}`}</tspan>
      </text>
    </>
  );
};

const Exercise01 = () => {
  const [time, setTime] = useState<number>(1);

  const incrementCount = () => {
    setTime(time + 1);
  };

  const decrementTime = () => {
    setTime(time - 1);
  };

  const { t } = useTranslation();

  return (
    <main className={"h-100 overflow-hidden d-flex flex-column justify-content-between justify-content-sm-center"}>
      <div className="page">
        <div className="page__header">
          <LinkContainer to="/dev/cviceni/">
            <Button variant={"close"} />
          </LinkContainer>
        </div>

        <div className="page__content">
          <p className="text-center mb-12px">
            <strong>{t("label.youGotMoreTime", { ns: "exercise" })}</strong>
          </p>

          <div className="increment-group mb-32px">
            <Button
              variant={"primary"}
              className={"btn-circular"}
              onClick={decrementTime}
              disabled={time === COUNT_MIN}
            >
              <IconMinus />
            </Button>
            <p className={"h2 mb-0"}>
              {time} {t("minuteShort", { ns: "common" })}
            </p>
            <Button
              variant={"primary"}
              className={"btn-circular"}
              onClick={incrementCount}
              disabled={time === COUNT_MAX}
            >
              <IconPlus />
            </Button>
          </div>

          <div className="mb-32px exercise-chart">
            <PieChart width={330} height={110}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={55}
                dataKey="value"
                blendStroke
                legendType={"circle"}
                startAngle={90}
                endAngle={450}
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry?.color} />
                ))}
              </Pie>
            </PieChart>
          </div>

          <Alert variant={"info"}>
            <p>
              <strong>Připrav si</strong>
            </p>
            <ul>
              <li>čistý papír A4 a psací potřeby,</li>
              <li>
                <Link to={"#"}>vytištěné pomocné vzorečky</Link>,
              </li>
              <li>rýsovací potřeby</li>
            </ul>
            <p>a jakmile budeš moct, jdeme do toho 👊🙂</p>
          </Alert>

          <Alert variant={"danger"} className={"alert--icon-danger"}>
            <Trans
              i18nKey="warning.geometryExercise"
              ns="exercise"
              components={{
                lnk: <Link to={"#"} />,
                strong: <strong />,
              }}
            />
          </Alert>
        </div>

        <div className="page__footer">
          <p className="text-center mb-12px">
            <strong>Čím chceš dnes začít?</strong>
          </p>
          <Button type={"submit"} className={"w-100 text-uppercase"}>
            Matematika
          </Button>
          <Button type={"submit"} className={"w-100 text-uppercase"} variant="outline-primary">
            Čeština
          </Button>
        </div>
      </div>
    </main>
  );
};

export default Exercise01;
