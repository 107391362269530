import * as React from "react";

function IconArrowBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" fill="none" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m6.5 2.5-3 3 3 3" />
    </svg>
  );
}

export default IconArrowBack;
