import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import { AbilityScore, AnswerExercise } from "components";

import { useAppSelector } from "store/hooks";
import { getAbilityScore } from "store/slices/exercise";
import type { IAssignmentResult, IOption } from "store/slices/exercise";

import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { isSelfEvaluationForEveryAssignment } from "utils/valueTranslators";
import { getAbilityScoreOptions } from "utils/changeAbilityScore";

import type { UserAssignmentForExerciseFragment } from "api/generated";

/* rPp7XgOY9DxQXaSbZsBQ */

interface Props {
  assignmentResult: IAssignmentResult<IOption | string>;
  assignmentDetail: UserAssignmentForExerciseFragment;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const Razeni: FC<Props> = ({ assignmentDetail, assignmentResult, toggleModals }) => {
  const assignment = assignmentDetail.assignment;
  if (assignment === undefined || assignment === null) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);
  const abilityScore = useAppSelector(getAbilityScore(assignmentDetail.id));

  const getAbilityScoreOnIndex = (index: number) => {
    if (abilityScore === undefined || typeof abilityScore === "number") return 0;

    return abilityScore[index];
  };

  const options =
    assignment.assignment?.questions.map(question => {
      return question.options.map(option => option.value);
    }) ?? [];

  return (
    <>
      <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className="mt-20px">
        {assignment.assignment?.questions.map((question, qIndex) => {
          return (
            <>
              <Latex>{question.heading ?? ""}</Latex>

              {question.options.map((option, oIndex) => {
                return (
                  <div
                    key={`text-${qIndex}-${oIndex}`}
                    className="assignment-option"
                    dangerouslySetInnerHTML={{ __html: option.additionalText ?? "" }}
                  ></div>
                );
              })}
              <div className="question question--list mt-20px">
                {options[qIndex].map((_option, oIndex) => {
                  const selfEvalOptions = getAbilityScoreOptions(
                    isSelfEvaluation,
                    assignmentResult.answersVariants?.[qIndex],
                  );
                  const answer = assignmentResult.answers?.[qIndex];
                  if (typeof answer !== "string") return null;

                  return (
                    <div key={`question-${oIndex}`} className="button-group">
                      <span>{`${oIndex + 1}.`}</span>
                      <AnswerExercise
                        subject={assignmentDetail.subject?.appName}
                        key={`answer-${qIndex}`}
                        index={qIndex}
                        assignment={assignment}
                        type={assignmentResult.answersVariants?.[oIndex]}
                        toggleModals={toggleModals}
                        userAssignmentId={assignmentDetail.id}
                        addIcon={!isSelfEvaluation}
                        tipDisabled={true}
                      >
                        {isSelfEvaluation
                          ? getTextForSelfEvaluationOption(assignmentResult.selfEvalAnswers[oIndex]?.status ?? "danger")
                          : answer[oIndex]}
                      </AnswerExercise>
                      {isSelfEvaluationForEveryAssignment(assignment) &&
                      question.showAbilityScore === true &&
                      selfEvalOptions !== null ? (
                        <>
                          <hr className={"my-25px"} />
                          <div className="mw-sm">
                            <AbilityScore
                              isSelfEvaluated={isSelfEvaluation}
                              options={selfEvalOptions}
                              selectedValue={getAbilityScoreOnIndex(qIndex)}
                              onValueSelect={() => {}}
                              index={qIndex}
                              key={qIndex}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Razeni;
