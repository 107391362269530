import type { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

interface Props {
  isSelfEvaluated: boolean;
  options: Array<{ text: string; score: number }> | null;
  selectedValue: number | undefined;
  index: number;
  isSelfEvalDisabled?: boolean;
  onValueSelect: (score: number) => void;
}

const AbilityScore: FC<Props> = ({
  isSelfEvaluated,
  isSelfEvalDisabled = false,
  options,
  selectedValue,
  index,
  onValueSelect,
}) => {
  if (options === null) return null;

  /* TODO: translate */
  const title = isSelfEvaluated ? "Jak ti tento příklad šel?" : "Jak ti to šlo?";

  return (
    <>
      <p className="text-center">
        <strong>{title}</strong>
      </p>
      <ButtonGroup className={"btn-group-select btn-shadow mb-16px"}>
        {options.map(option => (
          <ToggleButton
            key={`${index}-${option.text}`}
            type="radio"
            variant="select"
            name={`${index}-radio`}
            value={option.score}
            checked={option.score === selectedValue}
            onClick={() => {
              onValueSelect(option.score);
            }}
            className={isSelfEvalDisabled ? "btn--disabled" : ""}
          >
            {option.text}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
};

export default AbilityScore;
