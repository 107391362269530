import {
  ABCDDetailAnalysis,
  ABCDOddeleneDetailAnalysis,
  AnoNeDetailAnalysis,
  JednoslovneOdpovediDetailAnalysis,
  RazeniDetailAnalysis,
  RovniceDetailAnalysis,
  RysovaniDetailAnalysis,
  ViceJednoslovnychOdpovediDetailAnalysis,
  ViceMoznostiDoleDetailAnalysis,
  ViceMoznostiNahoreDetailAnalysis,
  ViceslovneOdpovediDetailAnalysis,
} from "features";

import type { UserAssignmentForExerciseFragment } from "api/generated";

export const getAnalysisDetailByType = (
  type: string | null | undefined,
  assignment: UserAssignmentForExerciseFragment,
) => {
  switch (type) {
    case "abcd":
      return <ABCDDetailAnalysis data={assignment} />;
    case "abcd_separated":
      return <ABCDOddeleneDetailAnalysis data={assignment} />;
    case "yes_no":
      return <AnoNeDetailAnalysis data={assignment} />;
    case "drawing":
      return <RysovaniDetailAnalysis data={assignment} />;
    case "sorting":
      return <RazeniDetailAnalysis data={assignment} />;
    case "multiple_words":
      return <ViceslovneOdpovediDetailAnalysis data={assignment} />;
    case "individual_word":
      return <JednoslovneOdpovediDetailAnalysis data={assignment} />;
    case "multiple_options_bottom":
      return <ViceMoznostiDoleDetailAnalysis data={assignment} />;
    case "multiple_options_top":
      return <ViceMoznostiNahoreDetailAnalysis data={assignment} />;
    case "equation":
      return <RovniceDetailAnalysis data={assignment} />;
    case "multiple_individual_words":
      return <ViceJednoslovnychOdpovediDetailAnalysis data={assignment} />;
    default:
      return null;
  }
};
