import type { FC } from "react";

import SelfEvaluationOptions from "./SelfEvaluationOptions";
import SelfEvaluationAnalysis from "./SelfEvaluationAnalysis";

import type { UserAssignmentDetailFragment } from "api/generated";

import type { TSolutionVariant } from "store/slices/exercise";

import { getTimeToLearn } from "utils/getTimeToLearn";

interface Props {
  sendDataToBe: boolean | null | undefined;
  selectedOptions: Array<{ points: number | null; status: TSolutionVariant | null }>;
  shownStep: "options" | string;
  userAssignment: UserAssignmentDetailFragment;
  showAbilityScore?: boolean;
  isLoading: boolean;
  handleEvaluationSelect: (index: number, option: number) => void;
  handleOptionsSubmit: () => void;
  handleNextClick: (() => void) | undefined;
}

const SelfEvaluationSolution: FC<Props> = ({
  sendDataToBe,
  selectedOptions,
  userAssignment,
  shownStep,
  showAbilityScore = false,
  isLoading,
  handleEvaluationSelect,
  handleOptionsSubmit,
  handleNextClick,
}) => {
  if (userAssignment.assignment === undefined || userAssignment.assignment === null) return null;

  return (
    <>
      {shownStep === "options" ? (
        <SelfEvaluationOptions
          assignment={userAssignment.assignment}
          selectedOptions={selectedOptions}
          handleEvaluationSelect={handleEvaluationSelect}
          onConfirm={handleOptionsSubmit}
          isLoading={isLoading}
          userAssignmentId={userAssignment.id}
        />
      ) : (
        <SelfEvaluationAnalysis
          assignment={userAssignment}
          selectedOptions={selectedOptions}
          sendDataToBe={sendDataToBe}
          onNextClick={handleNextClick}
          timeToLearn={sendDataToBe === true ? getTimeToLearn(userAssignment.timeToLearn) : undefined}
          showAbilityScore={showAbilityScore}
        />
      )}
    </>
  );
};

export default SelfEvaluationSolution;
