import type { FC } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { EnvelopeStackIcon } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useAppDispatch } from "store/hooks";
import { removeResults } from "store/slices/topicPractice";

interface Props {
  assignmentsCount: number;
  percentage: number;
}

const GreenEnvelopeHeading: FC<Props> = ({ assignmentsCount, percentage }) => {
  const { t } = useTranslation(["common", "assignment"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handlePracticeStart = () => {
    dispatch(removeResults());
    navigate("/procvicovani/obalka/zelena");
  };

  return (
    <Container className={"container-mw-md mb-20px mb-sm-0"}>
      <div className="bookmarked">
        <div className="bookmarked__header">
          <div className="bookmarked__title">
            <Breadcrumb>
              <li className={"breadcrumb-item"}>
                <Link to={"/"}>
                  <IconArrowBack />
                  {t("back")}
                </Link>
              </li>
            </Breadcrumb>

            <h1>
              {t("pageTitle.greenEnvelopeCount", {
                ns: "envelope",
                count: assignmentsCount,
              })}
            </h1>
          </div>

          <EnvelopeStackIcon type="green" percentage={Math.min(percentage / 100, 1)} />
        </div>

        <p>Skvěle! Tyhle úlohy ti fakt jdou. Je ale dobré se k nim jednou za čas vrátit a připomenout si je.</p>
      </div>

      <Button
        className={"text-uppercase"}
        onClick={() => {
          handlePracticeStart();
        }}
      >
        {t("trainThisTypeOfAssignments", { ns: "assignments" })}
      </Button>
    </Container>
  );
};

export default GreenEnvelopeHeading;
