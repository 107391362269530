import { useState } from "react";
import { Button, type ButtonProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import type { NoStringIndexType } from "utils/types/NoStringIndexType";
import TeacherEvaluationModal from "./TeacherEvaluationModal";
import { isMobile } from "utils/isMobile";
import { ReactComponent as IconCamera } from "images/icons/camera.svg";

interface Props extends Omit<NoStringIndexType<ButtonProps>, "onClick"> {
  onModalHide?: () => void;
  onModalOpen?: () => void;
  onSubmit: () => void;
  userAssignmentId: string;
}

const TeacherEvaluationButton: React.FC<Props> = ({
  className,
  onModalHide,
  onModalOpen,
  onSubmit,
  userAssignmentId,
  variant = "outline-secondary",
  ...restProps
}) => {
  const { t } = useTranslation("teacherEvaluation");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalHide = () => {
    setIsModalOpen(false);
    if (onModalHide !== undefined) onModalHide();
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    if (onModalOpen !== undefined) onModalOpen();
  };

  const handleModalSubmit = () => {
    handleModalHide();
    onSubmit();
  };

  return (
    <>
      <Button
        className={`w-100 d-inline-flex fs-15 ${className ?? ""}`}
        onClick={handleModalOpen}
        variant={variant}
        {...restProps}
      >
        <IconCamera className="me-2" />
        {t("buttonTeacherEvaluation")}
      </Button>

      {isModalOpen && (
        <TeacherEvaluationModal
          onHide={handleModalHide}
          onSubmit={handleModalSubmit}
          show={isModalOpen}
          userAssignmentId={userAssignmentId}
          variant={isMobile() ? "mobile" : "desktop"}
        />
      )}
    </>
  );
};

export default TeacherEvaluationButton;
