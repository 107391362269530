import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import { AbilityScore, AnswerExercise } from "components";

import { useAppSelector } from "store/hooks";
import { getAbilityScore } from "store/slices/exercise";
import type { IAssignmentResult, IOption } from "store/slices/exercise";

import { generalOptionText } from "utils/formatTextForAnalysis";
import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { isSelfEvaluationForEveryAssignment } from "utils/valueTranslators";
import { getAbilityScoreOptions } from "utils/changeAbilityScore";

import type { UserAssignmentForExerciseFragment } from "api/generated";

/* gMrM0Kqx0aR88Z5kYMlK */

interface Props {
  assignmentResult: IAssignmentResult<IOption | string>;
  assignmentDetail: UserAssignmentForExerciseFragment;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const ViceslovneOdpovedi: FC<Props> = ({ assignmentDetail, assignmentResult, toggleModals }) => {
  const assignment = assignmentDetail.assignment;
  if (assignment === undefined || assignment === null) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);
  const abilityScore = useAppSelector(getAbilityScore(assignmentDetail.id));

  const getAbilityScoreOnIndex = (index: number) => {
    if (abilityScore === undefined || typeof abilityScore === "number") return 0;

    return abilityScore[index];
  };

  return (
    <div>
      <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      {assignment.assignment?.questions.map((question, qIndex) => {
        return (
          <div key={qIndex} className="mt-20px question">
            <Latex>{question.heading ?? ""}</Latex>

            <AnswerExercise
              subject={assignmentDetail.subject?.appName}
              key={`answer-${qIndex}`}
              index={qIndex}
              assignment={assignment}
              type={assignmentResult.answersVariants?.[qIndex]}
              toggleModals={toggleModals}
              userAssignmentId={assignmentDetail.id}
              addIcon={!isSelfEvaluation}
              tipDisabled={true}
            >
              {isSelfEvaluation
                ? getTextForSelfEvaluationOption(assignmentResult.selfEvalAnswers[qIndex]?.status ?? "danger")
                : generalOptionText(assignmentResult.answers?.[qIndex] ?? "")}
            </AnswerExercise>

            {isSelfEvaluationForEveryAssignment(assignment) && question.showAbilityScore === true ? (
              <>
                <hr className={"my-25px"} />
                <div className="mw-sm">
                  <AbilityScore
                    isSelfEvaluated={isSelfEvaluation}
                    options={getAbilityScoreOptions(isSelfEvaluation, assignmentResult.answersVariants?.[qIndex])}
                    selectedValue={getAbilityScoreOnIndex(qIndex)}
                    onValueSelect={() => {}}
                    index={qIndex}
                    key={qIndex}
                  />
                </div>
              </>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ViceslovneOdpovedi;
