import type { FC } from "react";

import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

/* rL4n9VXYXv2RntyoGQlj */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const Rysovani: FC<Props> = ({ data, onSubmit }) => {
  return <SelfEvaluationTask key={data.id} userAssignment={data} onSubmit={onSubmit} />;
};

export default Rysovani;
