import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "react-bootstrap";

interface Props {
  points: number | null;
  maxPoints: number | null;
}

const GainedScore: FC<Props> = ({ points, maxPoints }) => {
  let state: "danger" | "warning" | "success" = "danger";

  if (maxPoints === null || points === null || points === 0) {
    state = "danger";
  } else if (maxPoints === 0) {
    state = "success";
  } else if (points < maxPoints) {
    state = "warning";
  } else if (points >= maxPoints) {
    state = "success";
  }

  const { t } = useTranslation(["exercise"]);

  return (
    <>
      <div className="gained-score">
        {t("gainedPoints")}
        <Badge bg={state} className={"badge-circular"}>
          {points ?? 0}
        </Badge>
      </div>
    </>
  );
};

export default GainedScore;
