import type { FC, ReactNode } from "react";
import { Row } from "react-bootstrap";

interface Props {
  title: string;
  children: ReactNode;
}

const Layout: FC<Props> = ({ title, children }) => {
  return (
    <>
      <h4 className="mb-16px">{title}</h4>
      <Row className={"g-8px mb-16px"}>{children}</Row>
    </>
  );
};

export default Layout;
