import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";

import Layout from "./_Layout";

import { AchievementBadge } from "features";

import type { IBadgeResult } from "../Badges";

interface Props {
  data: IBadgeResult[];
  onClick: (type: string) => void;
}

const Practice: FC<Props> = ({ data, onClick }) => {
  const { t } = useTranslation();

  return (
    <Layout title={t("sectionTitle.exerciseRewards", { ns: "statistics" })}>
      <>
        {data.map(badge => (
          <Col xs={4} sm={6} md={3} lg={2} key={badge.type}>
            <AchievementBadge
              icon={badge.icon}
              count={badge.count}
              isEarned={badge.isEarned}
              title={badge.title}
              type={badge.type}
              onClick={onClick}
            />
          </Col>
        ))}
      </>
    </Layout>
  );
};

export default Practice;
