import { useEffect, useState } from "react";
import type { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { BadgeModal } from "components";
import { AchievementBadge } from "features";

import { badgesConfiguration } from "pages/Statistics/Tabs/badgesConfiguration";
import type { IBadgeResult } from "pages/Statistics/Tabs/Badges";

import type { LastAchievementFragment } from "api/generated";

interface Props {
  data: LastAchievementFragment[] | undefined;
}

const NewestBadges: FC<Props> = ({ data }) => {
  if (data === undefined) return null;

  const [badges, setBadges] = useState<IBadgeResult[]>([]);
  const [modalBadge, setModalBadge] = useState<IBadgeResult | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (data === undefined) return;

    const parsedBadges: IBadgeResult[] = [];

    for (const achievement of data) {
      const type = achievement.type === "sessions" ? `sessions-${achievement.goal}` : achievement.type;
      const config = badgesConfiguration.find(config => config.type === type);
      if (config === undefined) continue;

      const result: IBadgeResult = {
        category: config.category,
        icon: config.iconGained,
        iconGained: config.iconGained,
        iconGray: config.iconGray,
        isEarned: true,
        modalTextEarned: config.modalTextEarned,
        modalTextNotEarned: config.modalTextNotEarned,
        title: config.title,
        type: config.type,
        count: config.count,
        dateEarned: new Date(achievement.dateAchieved),
        key: config.key,
      };

      parsedBadges.push(result);
    }

    setBadges(parsedBadges);
  }, [data]);

  const handleBadgeClick = (type: string) => {
    const badgeToShow = badges.find(badge => badge.type === type);

    if (badgeToShow === undefined) return;

    setModalBadge(badgeToShow);
  };

  const handleModalClose = () => {
    setModalBadge(null);
  };

  const handleNextBadge = () => {
    if (modalBadge === null) return;

    const index = badges.findIndex(badge => badge.type === modalBadge.type);

    if (index === -1) return;
    if (index >= badges.length - 1) {
      setModalBadge(badges[0]);
      return;
    }

    setModalBadge(badges[index + 1]);
  };

  const handlePrevBadge = () => {
    if (modalBadge === null) return;

    const index = badges.findIndex(badge => badge.type === modalBadge.type);

    if (index === -1) return;
    if (index <= 0) {
      setModalBadge(badges[badges.length - 1]);
      return;
    }

    setModalBadge(badges[index - 1]);
  };

  return (
    <>
      <h4 className={"mb-3 mt-25px"}>Poslední získané odznaky</h4>
      <Row className={"g-2"}>
        {badges.length === 0 ? (
          <div>{t("badges.noBadge", { ns: "dashboard" })}</div>
        ) : (
          badges.map((badge, index) => (
            <Col xs={6} md={3} key={index}>
              <AchievementBadge
                icon={badge.icon}
                count={badge.count}
                isEarned={badge.isEarned}
                title={badge.title}
                type={badge.type}
                onClick={handleBadgeClick}
                isNewBadge={true}
              />
            </Col>
          ))
        )}
      </Row>
      <Row className={"text-center mt-3"}>
        <Link to={"/statistiky"}>{t("badges.cta", { ns: "dashboard" })}</Link>
      </Row>

      <BadgeModal
        key={`${modalBadge?.type ?? "badge-modal"}`}
        badge={modalBadge}
        goNext={handleNextBadge}
        goPrev={handlePrevBadge}
        isShown={modalBadge !== null}
        onClose={handleModalClose}
        isNavShown={badges.length > 1}
      />
    </>
  );
};

export default NewestBadges;
