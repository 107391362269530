import type { FC, ReactNode } from "react";
import { Container, Nav, Tab } from "react-bootstrap";

import type { TTabs } from "./tabs.type";

interface Props {
  assignment: ReactNode;
  analysis: ReactNode;
  tabs: TTabs[];
  activeTab: TTabs;
  changeActiveTab: (activeTab: TTabs | null) => void;
}

const AssignmentDetailLayout: FC<Props> = ({ assignment, analysis, tabs, activeTab, changeActiveTab }) => {
  return (
    <Container className={"container-mw-md pt-25px pt-sm-45px page-container"}>
      <Tab.Container
        activeKey={activeTab}
        onSelect={key => {
          changeActiveTab(key as TTabs | null);
        }}
        mountOnEnter={true}
      >
        <Nav>
          {tabs.map(tab => {
            return (
              <Nav.Item key={tab}>
                <Nav.Link eventKey={tab}>{tab}</Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>

        <Tab.Content>
          <Tab.Pane key={tabs[0]} eventKey={tabs[0]}>
            {assignment}
          </Tab.Pane>

          <Tab.Pane key={tabs[1]} eventKey={tabs[1]}>
            {analysis}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default AssignmentDetailLayout;
