import type { ISaveSelfEvaluationAnswerParams } from "features/SelfEvaluation/Exercise/_SelfEvaluation";

import { useAppDispatch } from "store/hooks";
import { setSelfEvalAnswers, setAssignmentEvaluation } from "store/slices/exercise";

export const useSaveExerciseSelfEvaluation = () => {
  const dispatch = useAppDispatch();

  const saveSelfEvaluationAnswer = ({
    userAssignmentId,
    selfEvalAnswer,
    answerVariants,
    status,
  }: ISaveSelfEvaluationAnswerParams) => {
    dispatch(setSelfEvalAnswers({ assignmentId: userAssignmentId, selfEvalAnswer }));
    dispatch(
      setAssignmentEvaluation({
        assignmentId: userAssignmentId,
        status,
        answerVariants,
        correctAnswers: [],
      }),
    );
  };

  return saveSelfEvaluationAnswer;
};
