import { useEffect, useState } from "react";
import type { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useClassroomAmbienceContext } from "features/ClassroomAmbience/context";

import { gtmExercisePaused } from "utils/gtmEvents";

interface FirstScreenProps {
  onClose: () => void;
  onNextScreen: () => void;
}

const FirstScreen: FC<FirstScreenProps> = ({ onClose, onNextScreen }) => {
  const { isSoundOn, onToggleSound } = useClassroomAmbienceContext();

  const { t } = useTranslation(["common", "exercise"]);

  return (
    <>
      <div className="mb-8px text-center">
        <Image src={"/images/capy-paused.svg"} />
      </div>
      <h3 className={"text-center mb-20px"}>{t("header.paused", { ns: "exercise" })}</h3>
      <div className={"d-grid gap-8px"}>
        <Button variant="outline-success" className={"w-100 text-uppercase"} onClick={onToggleSound}>
          {t(isSoundOn ? "btn.turnSoundOff" : "btn.turnSoundOn", { ns: "exercise" })}
        </Button>
        <Button variant="success" className={"w-100 text-uppercase"} onClick={onClose}>
          {t("btn.continueFurther", { ns: "exercise" })}
        </Button>
      </div>
      <p className={"text-center my-20px"}>{t("or", { ns: "common" })}</p>
      <Button variant="outline-danger" className={"w-100 text-uppercase"} onClick={onNextScreen}>
        {t("btn.exerciseEnd", { ns: "exercise" })}
      </Button>
    </>
  );
};

interface SecondScreenProps {
  onClose: () => void;
  onQuit: () => void;
}

const SecondScreen: FC<SecondScreenProps> = ({ onClose, onQuit }) => {
  const { t } = useTranslation(["exercise"]);

  return (
    <>
      <div className="mb-8px text-center">
        <Image src={"/images/capy-beware.svg"} />
      </div>
      <h3
        className={"text-center mb-20px"}
        dangerouslySetInnerHTML={{ __html: t("header.dontGiveUp", { ns: "exercise" }) }}
      />
      <div className={"d-grid gap-8px"}>
        <Button variant="success" className={"w-100 text-uppercase"} onClick={onClose}>
          {t("btn.exerciseContinue", { ns: "exercise" })}
        </Button>
        <Button variant="outline-danger" className={"w-100 text-uppercase"} onClick={onQuit}>
          {t("btn.exerciseEnd", { ns: "exercise" })}
        </Button>
      </div>
    </>
  );
};

interface Props {
  onClose: () => void;
  onQuit: () => void;
}

const PauseModal: FC<Props> = ({ onClose, onQuit }) => {
  const [step, setStep] = useState(0);

  const goNext = () => {
    setStep(1);
  };

  useEffect(() => {
    gtmExercisePaused();
  }, []);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton />
      <Modal.Body className="pb-40px">
        {step === 0 ? (
          <FirstScreen onClose={onClose} onNextScreen={goNext} />
        ) : (
          <SecondScreen onClose={onClose} onQuit={onQuit} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PauseModal;
