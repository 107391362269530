import { useState } from "react";
import type { FC } from "react";
import { ToggleButton } from "react-bootstrap";

import { BookmarkedModal } from "components/Modal/Bookmarked";

import { ReactComponent as BookmarkIcon } from "images/icons/bookmark.svg";

import { useBookmarks } from "utils/hooks/useBookmarks";

interface Props {
  userAssignmentId: string;
}

const BookmarkIconAsButton: FC<Props> = ({ userAssignmentId }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { toggleBookmark, isBookmarked, isLoadingData } = useBookmarks(userAssignmentId);

  const handleButtonClick = () => {
    if (isLoadingData) return;

    toggleBookmark();
    if (!isBookmarked) {
      setModalVisible(true);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <ToggleButton
        type="checkbox"
        variant="select"
        value="test"
        checked={isBookmarked}
        className="btn-xl btn-shadow bookmark-button"
        onClick={handleButtonClick}
      >
        <BookmarkIcon fill="none" />
      </ToggleButton>
      {modalVisible ? <BookmarkedModal isLoading={isLoadingData} onClose={handleModalClose} /> : null}
    </>
  );
};

export default BookmarkIconAsButton;
