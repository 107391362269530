import type { ModalProps } from "react-bootstrap";
import type { NoStringIndexType } from "utils/types/NoStringIndexType";
import TeacherEvaluationUploadModal from "./TeacherEvaluationUploadModal";
import TeacherEvaluationQrCodeModal from "./TeacherEvaluationQrCodeModal";

interface Props extends NoStringIndexType<ModalProps> {
  onSubmit: () => void;
  userAssignmentId: string;
  variant?: "mobile" | "desktop";
}

const TeacherEvaluationModal: React.FC<Props> = ({ variant = "desktop", ...restProps }) => {
  if (variant === "mobile") {
    return <TeacherEvaluationUploadModal {...restProps} />;
  }

  return <TeacherEvaluationQrCodeModal {...restProps} />;
};

export default TeacherEvaluationModal;
