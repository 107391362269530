import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import Layout from "./_Layout";
import { AnoNeOption } from "components";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectSimpleAnswer, setSimpleAnswer } from "store/slices/answer";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* 4AZ0iichEnEayhhea5LI */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const AnoNe: FC<Props> = ({ data, onSubmit }) => {
  const questionsCount = data.assignment?.assignment?.questions.length ?? 0;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const selected = useAppSelector(selectSimpleAnswer);
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const handleCheck = (index: number, value: string) => {
    const newAnswers = selected.length === 0 ? new Array(questionsCount).fill("") : [...selected];
    newAnswers[index] = value;

    dispatch(setSimpleAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return selected.length > 0 && selected.every(answer => answer.length > 0);
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()}>
      <div>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        {data.assignment?.assignment?.questions.map((question, qIndex) => {
          return (
            <div key={question.heading} className="mt-20px question">
              <Latex>{question.heading ?? ""}</Latex>
              <AnoNeOption qIndex={qIndex} selectedValue={selected[qIndex]} onChange={handleCheck} />
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default AnoNe;
