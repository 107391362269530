import React, { type FC, type ReactNode, useState } from "react";
interface Props {
  children?: ReactNode;
  className?: string;
}

export type TShadowTypes = "rewards--shadow-start" | null | "rewards--shadow-end";
const RewardsScroll: FC<Props> = ({ children, className }) => {
  const [shadow, setShadow] = useState<TShadowTypes>("rewards--shadow-start");

  const onScrollHandler = (e: React.UIEvent<HTMLElement>) => {
    const scrollLeft = e.currentTarget.scrollLeft;
    const isStart = scrollLeft === 0;
    const scrollWidth = e.currentTarget.scrollWidth;
    const clientWidth = e.currentTarget.clientWidth;
    const isEnd = scrollLeft === e.currentTarget.scrollWidth - e.currentTarget.clientWidth;

    if (scrollWidth === clientWidth) {
      setShadow(null);
    } else {
      if (isStart) {
        setShadow("rewards--shadow-start");
      }

      if (isEnd) {
        setShadow("rewards--shadow-end");
      }

      if (!isEnd && !isStart && shadow !== null) {
        setShadow(null);
      }
    }
  };

  return (
    <div className={["rewards", shadow, className].join(" ").trim()}>
      <div className="rewards__wrapper" onScroll={onScrollHandler}>
        {children}
      </div>
    </div>
  );
};

export default RewardsScroll;
