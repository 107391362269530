import { Route, Routes } from "react-router-dom";
import MonthOverview from "./MonthOverview";

const CalendarRoutes = () => {
  return (
    <Routes>
      <Route index element={<MonthOverview />} />
    </Routes>
  );
};

export default CalendarRoutes;
