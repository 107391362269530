import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import { AnswerPractice, ABCDOddeleneOptionsText } from "components";

import type { IAssignmentResult } from "store/slices/topicPractice";

import { ABCDOddeleneOptionText } from "utils/formatTextForAnalysis";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* cYZzbh1FcF7C1Eq3H7ka */

interface Props {
  assignmentResult: IAssignmentResult;
  toggleModals: (index: number | null) => void;
}

const ABCDOddelene: FC<Props> = ({ assignmentResult, toggleModals }) => {
  const assignment = assignmentResult.assignment.assignment;
  if (assignment === undefined || assignment === null) return null;

  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);

  return (
    <>
      <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      {assignment.assignment?.questions.map((question, qIndex) => {
        return (
          <>
            <div key={qIndex} className={"mt-20px"}>
              <Latex>{question.heading ?? ""}</Latex>
              <ABCDOddeleneOptionsText qIndex={qIndex} options={question.options} />
            </div>

            <AnswerPractice
              key={`answer-${qIndex}`}
              index={qIndex}
              assignment={assignment}
              type={assignmentResult.answerVariants?.[qIndex]}
              toggleModals={toggleModals}
              showBackOnSolution={true}
              userAssignmentId={assignmentResult.assignment.id}
            >
              {ABCDOddeleneOptionText(assignmentResult.userAnswers[qIndex])}
            </AnswerPractice>
          </>
        );
      })}
    </>
  );
};

export default ABCDOddelene;
