import * as React from "react";

function IconTrophy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.47333 2.65198C1.9546 2.65198 1.51394 3.03132 1.51394 3.41068C1.51394 3.72599 1.6931 6.05044 3.9128 7.33471"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M15.527 2.65198C16.0457 2.65198 16.4864 3.03132 16.4864 3.41068C16.4864 3.72599 16.3072 6.05044 14.0875 7.33471"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M5.62637 14.4123L7.96064 12.389V9.35409C7.269 9.10117 5.67824 8.11481 4.84828 6.19268C4.01831 4.27054 4.07019 2.27253 4.19987 1.51379H13.7963C13.9259 2.39899 14.0038 4.57404 13.2775 6.19268C12.5513 7.81132 10.9865 8.97472 10.2949 9.35409V12.389L12.3698 14.4123"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M3.7854 16.4862V16.4862C3.7854 15.4525 4.62332 14.6146 5.65696 14.6146H12.4699C13.5036 14.6146 14.3415 15.4525 14.3415 16.4862V16.4862"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export default IconTrophy;
