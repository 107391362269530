import type { FC } from "react";

import type { AssignmentPracticeFragment, UserAssignmentForExerciseFragment } from "api/generated";

import type { TAssignmentStatus } from "store/slices/topicPractice";

interface Props {
  activeAssignmentId: string | undefined;
  assignments: AssignmentPracticeFragment[] | UserAssignmentForExerciseFragment[];
  results: Record<string, { status: TAssignmentStatus }>;
}

const DotNavigation: FC<Props> = ({ assignments, activeAssignmentId, results }) => {
  const getClassByStatus = (status: TAssignmentStatus | undefined) => {
    switch (status) {
      case "correct":
        return "success";
      case "partial":
        return "warning";
      case "incorrect":
        return "danger";
      default:
        return "";
    }
  };

  const getClasses = (assignmentId: string) => {
    const classes = ["dot"];
    if (assignmentId === activeAssignmentId) classes.push("current");

    classes.push(getClassByStatus(results[assignmentId]?.status));

    return classes.join(" ");
  };

  return (
    <div className="progress-dots" id="exerciseNavigation">
      {assignments.map(assignment => {
        return <div key={assignment.id} className={getClasses(assignment.id)} />;
      })}
    </div>
  );
};

export default DotNavigation;
