import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center my-60px">
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        className={"me-2"}
        variant={"primary"}
      />
      <h3 className={"mb-0"}>Loading...</h3>
    </div>
  );
};

export default Loading;
