import { useState } from "react";
import type { FC } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { BookmarkButton } from "components";
import { SolutionModal } from "features";

import type { TSolutionVariant, IAssignmentResult } from "store/slices/topicPractice";

import { getAssignmentModalPracticeByType } from "utils/getAssignmentByType";
import { getTextByAssignmentType } from "utils/formatTextForAnalysis";
import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";

interface Props {
  result: IAssignmentResult;
  onClose: () => void;
  isOpen: boolean;
}

const ResultModalPractice: FC<Props> = ({ result, onClose, isOpen }) => {
  const assignment = result.assignment.assignment;
  if (assignment === undefined || assignment === null) return null;

  const [questionAnalysisShown, setQuestionAnalysisShown] = useState<number | null>(null);

  const toggleModals = (questionAnalysisShown: number | null) => {
    setQuestionAnalysisShown(questionAnalysisShown);
  };

  const closeSolutionModal = () => {
    setQuestionAnalysisShown(null);
  };

  const getAnswerText = () => {
    if (questionAnalysisShown === null) return "";

    if (assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení") {
      return getTextForSelfEvaluationOption(result.answerVariants[questionAnalysisShown ?? 0]);
    }

    const type = assignment.assignment?.assignmentType.type;
    if (typeof type !== "string") return "";

    return getTextByAssignmentType(type, result.userAnswers[questionAnalysisShown]);
  };

  const getAnswerType = (): TSolutionVariant => {
    const type = result.answerVariants[questionAnalysisShown ?? 0];

    return type ?? "danger";
  };

  return (
    <>
      <Modal show={isOpen && questionAnalysisShown === null} onHide={onClose} className={"modal--exercise"} size={"lg"}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="exercise">
            <div className="exercise__body">
              {getAssignmentModalPracticeByType(assignment.assignment?.assignmentType.type, result, toggleModals)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={"modal-footer--yellow-bg"}>
          <div className="mw-sm">
            <div className="modal-footer-btn-stretch">
              <BookmarkButton userAssignmentId={result.assignment.id} />
              {/* TODO: translate */}
              <Button variant={"outline-secondary-darker"} onClick={onClose}>
                Zavřít
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <SolutionModal
        isShown={isOpen && questionAnalysisShown !== null}
        assignment={assignment}
        questionIndex={questionAnalysisShown ?? 0}
        answerType={getAnswerType()}
        toggleModal={closeSolutionModal}
        isGoBackShown={true}
        userAssignmentId={result.assignment.id}
      >
        {getAnswerText()}
      </SolutionModal>
    </>
  );
};

export default ResultModalPractice;
