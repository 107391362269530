import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import { AnswerPractice } from "components";

import type { IAssignmentResult } from "store/slices/topicPractice";

import { AnoNeOptionText } from "utils/formatTextForAnalysis";
import { getTextForSelfEvaluationOption } from "utils/getTextForSelfEvaluationOption";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* 4AZ0iichEnEayhhea5LI */

interface Props {
  assignmentResult: IAssignmentResult;
  toggleModals: (showQuestionAnalysisIndex: number | null) => void;
}

const AnoNe: FC<Props> = ({ assignmentResult, toggleModals }) => {
  const assignment = assignmentResult.assignment.assignment;
  if (assignment === undefined || assignment === null) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const { imageUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);

  return (
    <>
      <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      {assignment.assignment?.questions.map((question, index) => {
        return (
          <div key={question.heading} className="mt-20px question">
            <Latex>{question.heading ?? ""}</Latex>

            <AnswerPractice
              key={`answer-${index}`}
              index={index}
              assignment={assignment}
              type={assignmentResult.answerVariants?.[index]}
              toggleModals={toggleModals}
              showBackOnSolution={true}
              userAssignmentId={assignmentResult.assignment.id}
            >
              {isSelfEvaluation
                ? getTextForSelfEvaluationOption(assignmentResult.selfEvalAnswers[index]?.status ?? "danger")
                : AnoNeOptionText(assignmentResult.userAnswers[index])}
            </AnswerPractice>
          </div>
        );
      })}
    </>
  );
};

export default AnoNe;
