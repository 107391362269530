import type { IOnboardingTooltip } from "components/OnboardingTooltip/OnboardingTooltip";

export const tooltipTexts: IOnboardingTooltip[] = [
  {
    key: "after-practice",
    title: "Sebehodnocení po tréninku",
    description: (
      <p>
        {
          "Je potřeba vyplnit informace o tom, jak se cítíš po tréninku. Nemusíš se bát, nebude nikde zveřejněno, je to jen pro Tvou potřebu. Jakmile vše vyplníš, procvičování v ten daný den máš oficiálně za sebou :)"
        }
      </p>
    ),
    buttonText: "Super :)",
    elementId: "after-practice",
  },
];
