import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";

const AnimatedCapybaraResult = () => {
  const [className, setClassName] = useState("animation-success");

  useEffect(() => {
    setClassName("animation-success animate");
  }, []);

  return (
    <div className={className}>
      <Image src={"/images/capy-success-1.svg"} loading={"eager"} />
      <Image src={"/images/capy-success-2.svg"} loading={"eager"} />
    </div>
  );
};

export default AnimatedCapybaraResult;
