import { getDayNameFromIndex } from "utils/calendarDateFormatting";
import type { StudyDayGenericInput } from "api/generated";

export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const LOCAL_STORAGE_TIME_TO_LIVE = 1000 * 60 * 60 * 4; // 4 hrs, can/should change

export const TIMETABLE_REQUESTED_TIME = 120;
export const INTERVAL_TO_SHOW_ONBOARDING_TOOLTIP_BUTTON = 1000 * 2;

export const HANDSHAKE_QUERY_PARAM = "authorization-token";
export const ACCESS_TOKEN_PARAM = "access-token";
export const PARENT_TOKEN_PARAM = "parent-token";
export const TEACHER_EVALUATION_TOKEN_PARAM = "teacher-evaluation-token";
export const SEARCH_QUERY_PARAM = "q";
export const PAGE_QUERY_PARAM = "p";
export const SUBJECT_QUERY_PARAM = "s";
export const SESSION_STORAGE_KEY = "todas-training-data";

export const DATE_OF_FIRST_SCHOOL_DAY = "2023-09-04";
export const DATE_OF_ENTRANCE_EXAMINATION = "2025-04-14";

export const PHONE_VALIDATION_CODE_LENGTH = 6;
export const RESEND_CODE_TIMEOUT = 60;

export const DEFAULT_ASSIGNMENTS_COUNT = 3;
export const MAX_ASSIGNMENTS_PER_REQUEST = 30;

export const REWARDS_MONTH_TYPE = "month_plan";
export const REWARDS_POINTS_TYPE = "score";

export const INCORRECT_ANSWER_REPORT_GOOGLE_URL =
  "https://script.google.com/macros/s/AKfycby2upW6RWsbgU24KmApHIpH4y7Q09EUrhQxCgbdTaq3PWK2qoMX5Q6QEGDmMByBGkbzpw/exec";

export const ONBOARDING_STORE_ID = "onboarding";

export const DEFAULT_TIMETABLE_DURATION_MINUTES = 20;
export const DEFAULT_TIMETABLE_START = "17:00";
export const DEFAULT_GENERIC_TIMETABLE: StudyDayGenericInput[] = [
  {
    dayName: getDayNameFromIndex(1),
    duration: DEFAULT_TIMETABLE_DURATION_MINUTES,
    startAt: DEFAULT_TIMETABLE_START,
  },
  {
    dayName: getDayNameFromIndex(2),
    duration: DEFAULT_TIMETABLE_DURATION_MINUTES,
    startAt: DEFAULT_TIMETABLE_START,
  },
  {
    dayName: getDayNameFromIndex(3),
    duration: DEFAULT_TIMETABLE_DURATION_MINUTES,
    startAt: DEFAULT_TIMETABLE_START,
  },
  {
    dayName: getDayNameFromIndex(4),
    duration: DEFAULT_TIMETABLE_DURATION_MINUTES,
    startAt: DEFAULT_TIMETABLE_START,
  },
  {
    dayName: getDayNameFromIndex(5),
    duration: DEFAULT_TIMETABLE_DURATION_MINUTES,
    startAt: DEFAULT_TIMETABLE_START,
  },
  {
    dayName: getDayNameFromIndex(6),
    duration: DEFAULT_TIMETABLE_DURATION_MINUTES,
    startAt: DEFAULT_TIMETABLE_START,
  },
  {
    dayName: getDayNameFromIndex(7),
    duration: DEFAULT_TIMETABLE_DURATION_MINUTES,
    startAt: DEFAULT_TIMETABLE_START,
  },
];
