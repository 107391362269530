import type { FC, FunctionComponent } from "react";

import { AchievementIcon } from "components";

interface Props {
  icon: FunctionComponent;
  count: number | null;
  isEarned: boolean;
  title: string;
  type: string;
  isNewBadge?: boolean;
  onClick: null | ((type: string) => void);
}

const AchievementBadge: FC<Props> = ({ icon, count, isEarned, title, isNewBadge = false, type, onClick }) => {
  const handleClick = () => {
    if (onClick === null) return;

    onClick(type);
  };

  return (
    <div
      className={`${onClick === null ? "" : "achievement achievement--narrow"} ${
        isEarned ? "" : "achievement--inactive bg-white"
      }`}
      onClick={handleClick}
    >
      <AchievementIcon icon={icon} count={count} />
      <p>
        {isNewBadge ? "Tohle ocenění jsi obdržel/a za " : null}
        <strong>{isNewBadge ? title.toLocaleLowerCase() : title}</strong>
      </p>
    </div>
  );
};

export default AchievementBadge;
