import { useEffect, useState } from "react";
import type { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { BookmarkButton } from "components/Bookmark";

interface Props {
  userAssignmentId: string;
  onClose: () => void;
}

const Overtime: FC<Props> = ({ userAssignmentId, onClose }) => {
  const { t } = useTranslation(["common"]);

  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timeLeft => {
        timeLeft--;
        if (timeLeft <= 0) clearInterval(interval);
        return timeLeft;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Modal show={true} className={"modal--exercise"} size={"lg"}>
      <Modal.Header />
      <Modal.Body>
        <p className={"text-center"}>
          {/* TODO: translate */}
          Je potřeba pokračovat v dalším příkladu, ulož si otázku na později, ať se k ní pak můžeš vrátit.
        </p>
      </Modal.Body>
      <Modal.Footer style={{ margin: "auto" }}>
        <BookmarkButton userAssignmentId={userAssignmentId} />
        <Button
          variant={"outline-secondary-darker"}
          className={"flex-grow-0"}
          onClick={onClose}
          disabled={timeLeft > 0}
        >
          {timeLeft > 0 ? t("continueIn", { time: timeLeft }) : t("continue")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Overtime;
