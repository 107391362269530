import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IBookmark {
  assignmentId: string;
  bookmarkId: string;
}

interface IBookmarkState {
  bookmarks: IBookmark[];
}

const initialState: IBookmarkState = {
  bookmarks: [],
};

const bookmarksSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    saveBookmarks: (state, action: PayloadAction<IBookmark[]>) => {
      state.bookmarks = action.payload;
    },
    saveBookmark: (state, action: PayloadAction<IBookmark>) => {
      const bookmark = state.bookmarks.find(bookmark => bookmark.assignmentId === action.payload.assignmentId);

      if (bookmark === undefined) {
        state.bookmarks.push(action.payload);
        state.bookmarks = [...state.bookmarks];
      }
    },
    deleteBookmark: (state, action: PayloadAction<{ assignmentId: string }>) => {
      state.bookmarks = state.bookmarks.filter(bookmark => bookmark.assignmentId !== action.payload.assignmentId);
    },
  },
});

export default bookmarksSlice.reducer;
export const { saveBookmarks, saveBookmark, deleteBookmark } = bookmarksSlice.actions;

export const getBookmark = (assignmentId: string) => (state: RootState) =>
  state.bookmarks.bookmarks.find(bookmark => bookmark.assignmentId === assignmentId);
export const areBookmarksLoaded = (state: RootState) => state.bookmarks.bookmarks.length > 0;
