import { useTranslation } from "react-i18next";

export const useDateNames = () => {
  const { t } = useTranslation(["common"]);

  const dayNames = [t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday"), t("sunday")];

  const shortDayNames = [
    t("monday_short"),
    t("tuesday_short"),
    t("wednesday_short"),
    t("thursday_short"),
    t("friday_short"),
    t("saturday_short"),
    t("sunday_short"),
  ];

  const monthNames = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const monthNamesGenitive = [
    t("januaryGenitive"),
    t("februaryGenitive"),
    t("marchGenitive"),
    t("aprilGenitive"),
    t("mayGenitive"),
    t("juneGenitive"),
    t("julyGenitive"),
    t("augustGenitive"),
    t("septemberGenitive"),
    t("octoberGenitive"),
    t("novemberGenitive"),
    t("decemberGenitive"),
  ];

  const getDayNameFromDayIndex = (dayIndex: number) => dayNames[dayIndex - 1];
  const getShortDayNameFromDayIndex = (dayIndex: number) => {
    return shortDayNames[(dayIndex + 6) % 7];
  };

  return { monthNames, monthNamesGenitive, getDayNameFromDayIndex, getShortDayNameFromDayIndex };
};
