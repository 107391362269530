import type { FC } from "react";
import { Container } from "react-bootstrap";

import { TopicPractice } from "features";

import { Loading } from "components";

import type { UserAssignmentPracticeFragment } from "api/generated";

interface Props {
  isLoading: boolean;
  assignments: UserAssignmentPracticeFragment[] | null;
  title: string;
  topicId: string;
}

const PracticePageContent: FC<Props> = ({ isLoading, assignments, title, topicId }) => {
  if (isLoading) {
    return (
      <Container className={"container-mw-sm layout--narrow"}>
        <div className={"fullpage bg-primary-subtle"}>
          <Loading />
        </div>
      </Container>
    );
  }

  if (assignments === null || assignments.length === 0) {
    return (
      <Container className={"container-mw-sm layout--narrow"}>
        <div className={"fullpage bg-primary-subtle"}>
          <p>Nepodařilo se načíst úlohy</p>
        </div>
      </Container>
    );
  }

  return <TopicPractice assignmentsList={assignments} title={title} topicId={topicId} />;
};

export default PracticePageContent;
