import type { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import { AnoNeOptionText } from "utils/formatTextForAnalysis";

interface Props {
  qIndex: number;
  selectedValue: string;
  onChange: (qIndex: number, value: string) => void;
}

const Option: FC<Props> = ({ qIndex, selectedValue, onChange }) => {
  return (
    <ButtonGroup className={"btn-group-select btn-shadow"}>
      {[
        { name: "Ano", value: "A" },
        { name: "Ne", value: "N" },
      ].map((radio, rIndex) => (
        <ToggleButton
          key={rIndex}
          id={`radio-${qIndex}-${rIndex}`}
          type="radio"
          variant="select"
          value={radio.value}
          checked={selectedValue === radio.value}
          onChange={() => {
            onChange(qIndex, radio.value);
          }}
        >
          {AnoNeOptionText(radio.value)}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default Option;
