import type { FC, ReactNode } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { AbilityScore, GainedScore } from "components";

import { ReactComponent as PrinterIcon } from "images/icons/printer-blue.svg";

import { isSingleAbilityScore } from "utils/valueTranslators";
import { getAbilityScoreOptions } from "utils/changeAbilityScore";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setAbilityScore, getAssignmentDetail, getAssignmentResult } from "store/slices/exercise";
import type { IOption } from "store/slices/topicPractice";
import { measurePracticeTime } from "store/slices/timer";

interface Props {
  children: ReactNode;
  onSubmit?: () => void;
  goNext: () => void;
  isSubmitDisabled?: boolean;
  isSelfEvaluation?: boolean;
  printImage?: string;
  isAnalysis: boolean;
  userAssignmentId: string;
  showAbilityScore: boolean;
  points: number | null;
  maxPoints: number | null;
}

const DetailLayout: FC<Props> = ({
  children,
  onSubmit,
  isSubmitDisabled,
  isSelfEvaluation,
  printImage,
  isAnalysis,
  userAssignmentId,
  showAbilityScore,
  points,
  maxPoints,
  goNext,
}) => {
  const { t } = useTranslation(["assignments", "common", "exercise"]);
  const dispatch = useAppDispatch();
  const assignmentResults = useAppSelector(getAssignmentResult<IOption>(userAssignmentId));
  const assignmentDetail = useAppSelector(getAssignmentDetail(userAssignmentId));
  const isAbilityScoreShown = assignmentDetail !== null && isSingleAbilityScore(assignmentDetail) && showAbilityScore;

  const handleAbilityScoreSelect = (score: number) => {
    dispatch(setAbilityScore({ assignmentId: userAssignmentId, score }));
  };

  const handleSubmit = () => {
    dispatch(measurePracticeTime());
    if (onSubmit !== undefined) {
      onSubmit();
    }
  };

  const handleNext = () => {
    goNext();
  };

  const isCorrect = assignmentResults?.status === "correct" || assignmentResults?.status === "partial";
  const selfEvalOptions = getAbilityScoreOptions(isSelfEvaluation === true, isCorrect ? "success" : null);

  return (
    <>
      <div className="exercise">
        <div className="exercise__body">{children}</div>

        {points !== null ? (
          <div className="mt-32px">
            <GainedScore points={points} maxPoints={maxPoints} />
          </div>
        ) : null}

        <div className={`exercise__footer ${isAnalysis && isAbilityScoreShown ? "exercise__footer--emphasized" : ""}`}>
          <div className="mw-sm px-3">
            {isAnalysis ? (
              <>
                {isAbilityScoreShown && selfEvalOptions !== null ? (
                  <AbilityScore
                    isSelfEvaluated={isSelfEvaluation === true}
                    options={selfEvalOptions}
                    selectedValue={
                      typeof assignmentResults?.abilityScore === "number" ? assignmentResults?.abilityScore : 0
                    }
                    onValueSelect={handleAbilityScoreSelect}
                    index={0}
                  />
                ) : null}

                <Button
                  className={"w-100 text-uppercase"}
                  onClick={handleNext}
                  disabled={
                    showAbilityScore && assignmentResults?.abilityScore === undefined && selfEvalOptions !== null
                  }
                >
                  {t("continue", { ns: "common" })}
                </Button>
              </>
            ) : (
              <>
                {typeof printImage === "string" && printImage.length > 0 ? (
                  <Alert variant="info">
                    <div>
                      <strong>{t("info.paperwork", { ns: "exercise" })}</strong>
                    </div>
                    <a href={printImage} download>
                      <PrinterIcon /> {t("btn.printPaper", { ns: "exercise" })}
                    </a>
                  </Alert>
                ) : null}

                <Button className={"w-100 text-uppercase"} onClick={handleSubmit} disabled={isSubmitDisabled}>
                  {isSelfEvaluation === true
                    ? t("detail.submitSelfEvaluation", { ns: "assignments" })
                    : t("detail.submit", { ns: "assignments" })}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailLayout;
