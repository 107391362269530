import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { Loading } from "components";

import { useAppSelector } from "store/hooks";
import { getFirstLogin } from "store/slices/user";

import { useGetSessionResultsQuery } from "api/generated";

import { getPrevMonday } from "utils/calendarDateFormatting";
import { getNearestSunday } from "utils/validateWeekRange";
import { processApiError } from "utils/processApiError";

interface IStateData {
  from: number;
  to: number;
  total: number;
  real: number;
}

function getBarGraphFillColor(percentage: number) {
  if (percentage >= 0.76) {
    return "#27AE60";
  } else if (percentage >= 0.51) {
    return "#FDC32F";
  } else if (percentage >= 0.26) {
    return "#F2994A";
  } else {
    return "#EB5757";
  }
}
const generateChartData = (data: IStateData[]) => {
  return data
    .sort((a, b) => b.from - a.from)
    .map(item => {
      const dateFrom = new Date(item.from);
      const dateTo = new Date(item.to);
      const isSameMonth = dateFrom.getMonth() === dateTo.getMonth();

      const label = `${dateFrom.getDate()}.${isSameMonth ? "" : `${dateFrom.getMonth() + 1}.`} - ${dateTo.getDate()}.${
        dateTo.getMonth() + 1
      }.`;
      const ratio = item.total === 0 ? 1 : Math.round((item.real / item.total) * 100) / 100;
      return {
        name: label,
        value: ratio,
        label: `${ratio * 100} %`,
      };
    });
};

interface IChartItem {
  name: string;
  value: number;
}

const ExercisePercentageGraph = () => {
  const { t } = useTranslation();
  const firstLogin = useAppSelector(getFirstLogin);

  const [chartData, setChartData] = useState<IChartItem[]>([]);
  const dateFrom = typeof firstLogin === "string" ? firstLogin : "2023-09-01";
  const { data, isLoading, error: apiError } = useGetSessionResultsQuery({ dateFrom });

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const parsedData = data.userSessionReview.reduce<IStateData[]>((accumulator, session) => {
      const sessionDate = new Date(session.dateTested).getTime();
      const week = accumulator.find(data => {
        const result = data.from <= sessionDate && data.to >= sessionDate;
        return result;
      });

      if (week === undefined) {
        const from = getPrevMonday(new Date(sessionDate)).getTime();
        const to = getNearestSunday(new Date(sessionDate)).getTime();
        const total = session.scoreMaxTotal ?? 0;
        const real = session.scoreRealTotal ?? 0;
        accumulator.push({ from, to, total, real });
      } else {
        const total = session.scoreMaxTotal;
        const real = session.scoreRealTotal;
        if (typeof total === "number") {
          week.total += total;
        }

        if (typeof real === "number") {
          week.real += real;
        }
      }

      return accumulator;
    }, []);

    setChartData(generateChartData(parsedData));
  }, [data]);

  if (isLoading) return <Loading />;

  const getBarHeight = (numberOfBars: number) => {
    const barHeight = 17;
    const barGutter = 18;

    // The gutter is only inbetween bars, so there's one less gutter than there are bars

    return numberOfBars * barHeight + (numberOfBars - 1) * barGutter;
  };

  return (
    <>
      <h4 className={"mb-3 mt-3"}>{t("sectionTitle.weeklySuccessRate", { ns: "statistics" })}</h4>

      <div className="graph bar-graph">
        <ResponsiveContainer width="100%" height={getBarHeight(chartData.length)}>
          <BarChart layout="vertical" data={chartData} margin={{ right: 50 }}>
            <XAxis type="number" hide />
            <YAxis type="category" width={80} dataKey="name" stroke={"#B1AEA3"} interval={0} />

            <Bar
              dataKey="value"
              fill={"#0123456"}
              barSize={11}
              radius={[0, 11, 11, 0]}
              label={{
                fontSize: 14,
                fontWeight: 700,
                fill: "#3D3B32",
                position: "right",
                formatter: function (value: number) {
                  return `${Math.ceil(value * 100)} %`;
                },
              }}
            >
              {chartData.map(function (entry, index) {
                return <Cell key={index} fill={getBarGraphFillColor(entry.value)} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="d-flex justify-content-end mb-40px">% úspěšnost</div>
    </>
  );
};

export default ExercisePercentageGraph;
