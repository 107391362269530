// noinspection InvisibleCharacter

import { Alert, ButtonGroup, Col, Form, Row, ToggleButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ExerciseBar } from "../../../../features";
import { ReactComponent as IconCheck } from "images/icons/check.svg";
import { ReactComponent as IconCross } from "images/icons/close.svg";

import { useState } from "react";
import { useTranslation } from "react-i18next";

const Exercise06 = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Nechápu to", value: "1" },
    { name: "Nic moc, ale tuším", value: "2" },
    { name: "Tohle mi jde", value: "3" },
    { name: "Je to má superschopnost", value: "4" },
  ];

  return (
    <div className="exercise">
      <div className="exercise__header">
        <ExerciseBar
          totalTime={600000}
          isTimerStopped={true}
          userAssignmentId={"test"}
          isProgressBarVisible={true}
          onQuit={() => {}}
        />

        <div className="progress-dots">
          <div className="dot current" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>

      <div className="exercise__body">
        <p>
          <strong>Přiřaďte k jednotlivým významovým poměrům (1-3) větu (A-E), v níž se daný poměr uplatňuje.</strong>
        </p>
        <p>(Žádnou možnost z nabídky A-E nelze přiřadit vícekrát než jednou.)</p>

        <ul className={"list-options"}>
          <li>A) Představení se nelíbilo ani mně, ani spolužákům.</li>
          <li>B) Není to řešení jednodušší, a dokonce i levnější?</li>
          <li>C) Náš počítač je docela starý, a tudíž málo výkonný.</li>
          <li>D) Dáte si ten steak středně, nebo hodně propečený?</li>
          <li>E) Získala jsem v testu padesát, a nikoli čtyřicet bodů.</li>
        </ul>

        <div>
          <div className={"question"}>
            <Form.Group>
              <Form.Label>Poměr slučovací je ve větě:</Form.Label>
              <Alert variant={"success"}>
                <Row>
                  <Col xs={"auto"} className={"my-auto"}>
                    B
                    <IconCheck className={"text-success ms-4px"} />
                  </Col>
                  <Col xs={"auto"} className={"ms-auto my-auto"}>
                    <ToggleButton
                      value={1}
                      variant={"select"}
                      className={"btn-shadow btn-emoji"}
                      id="toggle-check"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        setChecked(e.currentTarget.checked);
                      }}
                    >
                      {t("btn.guessed", { ns: "exercise" })}
                    </ToggleButton>
                    <Button variant={"select"} className={"btn-shadow btn-smaller ms-4px"}>
                      Rozbor
                    </Button>
                  </Col>
                </Row>
              </Alert>
            </Form.Group>
          </div>

          <div className={"question"}>
            <Form.Group>
              <Form.Label>Poměr stupňovací je ve větě:</Form.Label>
              <Alert variant={"success"}>
                <Row>
                  <Col xs={"auto"} className={"my-auto"}>
                    A
                    <IconCheck className={"text-success ms-4px"} />
                  </Col>
                  <Col xs={"auto"} className={"ms-auto my-auto"}>
                    <ToggleButton
                      value={1}
                      variant={"select"}
                      className={"btn-shadow btn-emoji"}
                      id="toggle-check"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        setChecked(e.currentTarget.checked);
                      }}
                    >
                      {t("btn.guessed", { ns: "exercise" })}
                    </ToggleButton>
                    <Button variant={"select"} className={"btn-shadow btn-smaller ms-4px"}>
                      Rozbor
                    </Button>
                  </Col>
                </Row>
              </Alert>
            </Form.Group>
          </div>

          <div className={"question"}>
            <Form.Group>
              <Form.Label>Poměr důsledkový je ve větě:</Form.Label>
              <Alert variant={"danger"}>
                <Row>
                  <Col xs={"auto"} className={"my-auto"}>
                    F
                    <IconCross className={"text-danger ms-4px"} />
                  </Col>
                  <Col xs={"auto"} className={"ms-auto my-auto"}>
                    <ToggleButton
                      value={1}
                      variant={"select"}
                      className={"btn-shadow btn-emoji"}
                      id="toggle-check"
                      type="checkbox"
                      checked={checked}
                      onChange={e => {
                        setChecked(e.currentTarget.checked);
                      }}
                    >
                      {t("btn.guessed", { ns: "exercise" })}
                    </ToggleButton>
                    <Button variant={"select"} className={"btn-shadow btn-smaller ms-4px"}>
                      Rozbor
                    </Button>
                  </Col>
                </Row>{" "}
              </Alert>
            </Form.Group>
          </div>
        </div>
      </div>

      <div className="exercise__footer exercise__footer--emphasized">
        <div className="mw-sm">
          <p className="text-center">
            <strong>Jak ti tento příklad šel?</strong>
          </p>
          <ButtonGroup className={"btn-group-select btn-shadow mb-12px"}>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="select"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={e => {
                  setRadioValue(e.currentTarget.value);
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <Button className={"w-100 text-uppercase"} disabled>
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Exercise06;
