import type { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import EnvelopeStackIcon from "components/EnvelopeStackIcon/EnvelopeStackIcon";

import type { IEnvelopeCounts } from "features/DashboardEnvelopesSummary/DashboardEnvelopesSummary";

import { useAppDispatch } from "store/hooks";
import { setFrom } from "store/slices/envelopeNavigation";

interface Props {
  data: IEnvelopeCounts;
}

const EnvelopesSummary: FC<Props> = ({ data }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setFrom("/"));
  };

  return (
    <Row className={"g-2"}>
      <Col xs={4}>
        <Link to="/ulohy/obalka/cervena" onClick={handleClick} className="envelope">
          <div className="envelope__label envelope__label--red">
            {t("assignmentsWithCount", { ns: "common", count: data.red })}
          </div>
          <EnvelopeStackIcon type={"red"} percentage={data.red === 0 ? 0 : data.red / data.sum} />
          <p className="envelope__description">Zatím ti nejdou 🤷‍♀️</p>
        </Link>
      </Col>
      <Col xs={4}>
        <Link to="/ulohy/obalka/oranzova" onClick={handleClick} className="envelope">
          <div className="envelope__label envelope__label--orange">
            {t("assignmentsWithCount", { ns: "common", count: data.yellow })}
          </div>
          <EnvelopeStackIcon type={"orange"} percentage={data.yellow === 0 ? 0 : data.yellow / data.sum} />
          <p className="envelope__description">Ještě procvičuj☝️</p>
        </Link>
      </Col>
      <Col xs={4}>
        <Link to="/ulohy/obalka/zelena" onClick={handleClick} className="envelope">
          <div className="envelope__label envelope__label--green">
            {t("assignmentsWithCount", { ns: "common", count: data.green })}
          </div>
          <EnvelopeStackIcon type={"green"} percentage={data.green === 0 ? 0 : data.green / data.sum} />
          <p className="envelope__description">Umíš levou zadní 🙂</p>
        </Link>
      </Col>
    </Row>
  );
};

export default EnvelopesSummary;
