import type { ISaveSelfEvaluationAnswerParams } from "features/SelfEvaluation/Practice/_SelfEvaluation";

import { useAppDispatch } from "store/hooks";
import { setSelfEvalAnswers, setAssignmentEvaluation } from "store/slices/topicPractice";

export const useSavePracticeSelfEvaluation = () => {
  const dispatch = useAppDispatch();

  const savePracticeSelfEvaluationAnswer = ({
    userAssignmentId,
    selfEvalAnswer,
    answerVariants,
    status,
  }: ISaveSelfEvaluationAnswerParams) => {
    dispatch(setSelfEvalAnswers({ assignmentId: userAssignmentId, selfEvalAnswer }));
    dispatch(
      setAssignmentEvaluation({
        assignmentId: userAssignmentId,
        status,
        answerVariants,
      }),
    );
  };

  return savePracticeSelfEvaluationAnswer;
};
