import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";

import Layout from "./_Layout";

import { ViceMoznostiNahoreOption } from "components";
import SelfEvaluationTask from "features/SelfEvaluation/Practice/Task";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectSimpleAnswer, setSimpleAnswer } from "store/slices/answer";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

/* jchWUrJ67yoyztmi6ad7 */

interface Props {
  data: UserAssignmentDetailFragment;
  onSubmit: () => void;
}

const ViceMoznostiNahore: FC<Props> = ({ data, onSubmit }) => {
  const questionsCount = data.assignment?.assignment?.questions.length;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const selected = useAppSelector(selectSimpleAnswer);
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const handleChange = (index: number, value: string) => {
    const newAnswers = selected.length === 0 ? new Array(questionsCount).fill("") : [...selected];
    newAnswers[index] = value;

    dispatch(setSimpleAnswer(newAnswers));
  };

  const areAnswersFilled = () => {
    return selected.length > 0 && selected.every(answer => answer.length > 0);
  };

  if (isSelfEvaluation) {
    return <SelfEvaluationTask userAssignment={data} onSubmit={onSubmit} />;
  }

  return (
    <Layout onSubmit={onSubmit} isSubmitDisabled={!areAnswersFilled()}>
      <>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        {data.assignment?.assignment?.questions.map((question, index) => {
          return (
            <div key={index} className={"mt-20px"}>
              <Latex>{question.heading ?? ""}</Latex>

              <ViceMoznostiNahoreOption
                index={index}
                placeholder={question.placeholder ?? ""}
                options={question.options}
                onChange={handleChange}
              />
            </div>
          );
        })}

        <div className="question mt-20px">
          <p
            dangerouslySetInnerHTML={{
              __html: data.assignment?.assignment?.questionsText ?? "",
            }}
          ></p>
        </div>
      </>
    </Layout>
  );
};

export default ViceMoznostiNahore;
