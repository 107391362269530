import "katex/dist/katex.min.css";

import type { FC } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import Latex from "react-latex-next";
import { Nav } from "react-bootstrap";

import { EnvelopeIcon } from "components";
import IconArrowForward from "components/Icons/IconArrowForward/IconArrowForward";

import { removeHTML } from "utils/removeHTML";

export interface IAssignment {
  id: string;
  href: string;
  text: string;
  testId: string | null;
  testOrder: number | null;
  envelopeType: string | undefined | null;
}

interface Props {
  assignment: IAssignment;
}

const AssignmentBox: FC<Props> = ({ assignment }) => {
  const { href, text, testId, testOrder, envelopeType } = assignment;
  const { t } = useTranslation(["assignments"]);

  return (
    <div className="assignment-box">
      <LinkContainer to={href}>
        <Nav.Link>
          <div className="assignment-box__content">
            <div>
              <p className="assignment-box__text-clamp">
                <Latex>{removeHTML(text)}</Latex>
              </p>
              <div className="assignment-box__content__inclusion">
                <span>{t("topics.testIdentification", { ns: "assignments", testId, testOrder })}</span>
                <>
                  {typeof envelopeType === "string" ? (
                    <div>
                      <EnvelopeIcon type={envelopeType} />
                    </div>
                  ) : null}
                </>
              </div>
            </div>
            <IconArrowForward />
          </div>
        </Nav.Link>
      </LinkContainer>
    </div>
  );
};

export default AssignmentBox;
