import { useEffect, useState } from "react";

import { ExerciseBadgeModal } from "components";

import type { IBadgeResult } from "pages/Statistics/Tabs/Badges";

import { useCheckAchievementsQuery } from "api/generated";

import { newBadgeDataFormatter } from "utils/newBadgeDataFormatter";

const CheckExerciseBadges = () => {
  const { data } = useCheckAchievementsQuery();

  const [shownBadgeIndex, setShownBadge] = useState(0);
  const [gainedBadges, setGainedBadges] = useState<IBadgeResult[]>([]);

  useEffect(() => {
    if (data === undefined) return;

    const badges = newBadgeDataFormatter(data.checkAchievements);
    setGainedBadges(badges);
  }, [data]);

  const goNext = () => {
    setShownBadge(index => {
      return shownBadgeIndex + 1 >= gainedBadges.length ? 0 : index + 1;
    });
  };

  const goPrev = () => {
    setShownBadge(index => {
      return shownBadgeIndex <= 0 ? gainedBadges.length - 1 : index - 1;
    });
  };

  const onClose = () => {
    setGainedBadges([]);
  };

  if (data === undefined || gainedBadges.length === 0) return null;

  return (
    <ExerciseBadgeModal
      badge={gainedBadges[shownBadgeIndex]}
      isNavShown={gainedBadges.length > 1}
      isShown={true}
      goNext={goNext}
      goPrev={goPrev}
      onClose={onClose}
    />
  );
};

export default CheckExerciseBadges;
