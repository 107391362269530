import type { FC, FormEvent } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { Loading } from "components/Loading";
import type { IMonth, IPoints, TModalTypes } from "pages/Rewards/Rewards";

import { useDateNames } from "utils/hooks/useDateNames";

import { REWARDS_MONTH_TYPE, REWARDS_POINTS_TYPE } from "const";

interface IData {
  monthRewards: IMonth[];
  pointsRewards: IPoints[];
}

interface Props {
  defaultValue: number | null;
  isShown: boolean;
  modalType: TModalTypes;
  monthRewards: IMonth[];
  pointsRewards: IPoints[];
  closeModal: () => void;
  submitModal: (data: IData) => void;
}

const ParentRewardModal: FC<Props> = ({
  defaultValue,
  isShown,
  modalType,
  monthRewards,
  pointsRewards,
  closeModal,
  submitModal,
}) => {
  const [data, setData] = useState<IData>({ monthRewards, pointsRewards });
  const [isLoading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(modalType);
  const [selectedValue, setSelectedValue] = useState<number | null>(defaultValue);
  const { t } = useTranslation(["parentRewards"]);

  const handleTypeChange = (type: TModalTypes) => {
    setSelectedValue(type === REWARDS_MONTH_TYPE ? defaultValue : null);
    setSelectedType(type);
  };

  const handleMonthTextChange = (monthId: number, text: string) => {
    const changedMonth = data.monthRewards.find(month => month.month === monthId);

    if (changedMonth === undefined) return;

    changedMonth.text = text;

    setData({ ...data });
  };

  const handlePointsTextChange = (pointNumber: number, text: string) => {
    const changedPoints = data.pointsRewards.find(point => point.targetScore === pointNumber);

    if (changedPoints === undefined) {
      setData(current => {
        const points = [...current.pointsRewards];
        points.push({ targetScore: pointNumber, text });
        points.sort((a, b) => (a.targetScore ?? -1) - (b.targetScore ?? -1));

        return {
          monthRewards: current.monthRewards,
          pointsRewards: points,
        };
      });
      return;
    }

    changedPoints.text = text;

    setData({ ...data });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const trimmed = {
      monthRewards: data.monthRewards.map(reward => {
        return {
          ...reward,
          text: reward.text.trim(),
        };
      }),
      pointsRewards: data.pointsRewards.map(reward => {
        return {
          ...reward,
          text: reward.text.trim(),
        };
      }),
    };

    submitModal(trimmed);
  };

  const handlePointsDelete = () => {
    const removed = {
      monthRewards: [...data.monthRewards],
      pointsRewards: data.pointsRewards.filter(reward => reward.targetScore !== selectedValue),
    };

    submitModal(removed);
  };

  return (
    <Modal show={isShown} onHide={closeModal} size={"lg"}>
      <Modal.Header closeButton />

      {isLoading ? (
        <Loading />
      ) : (
        <Modal.Body className={"pt-0 px-32px"}>
          <h4 className={"mb-12px"}>Zadat odměnu</h4>

          <Form onSubmit={handleSubmit}>
            <Form.Select
              defaultValue={selectedType}
              onChange={e => {
                handleTypeChange(e.target.value as TModalTypes);
              }}
              className="mb-12px"
            >
              <option value={REWARDS_MONTH_TYPE}>Splnění plánu</option>
              <option value={REWARDS_POINTS_TYPE}>Dosažení bodů v testech nanečisto</option>
            </Form.Select>

            {selectedType === REWARDS_MONTH_TYPE ? (
              <MonthContent
                defaultMonth={selectedValue}
                months={data.monthRewards}
                onTextChange={handleMonthTextChange}
              />
            ) : (
              <PointsContent defaultPoints={selectedValue} points={data} onTextChange={handlePointsTextChange} />
            )}

            <Button type={"submit"} className={"w-100 text-uppercase"}>
              {t("Submit")}
            </Button>

            {selectedType === REWARDS_POINTS_TYPE ? (
              <Button variant={"link"} className={"w-100 text-uppercase"} onClick={handlePointsDelete}>
                {t("Delete")}
              </Button>
            ) : null}
          </Form>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ParentRewardModal;

interface IMonthProps {
  defaultMonth: number | null;
  months: IMonth[];
  onTextChange: (month: number, text: string) => void;
}

const MonthContent: FC<IMonthProps> = ({ defaultMonth, months, onTextChange }) => {
  const { monthNames } = useDateNames();
  const [selectedMonth, setSelectedMonth] = useState(months.find(month => month.month === defaultMonth));

  const handleMonthChange = (newMonth: string) => {
    const monthNumber = Number(newMonth);
    if (monthNumber.toString() !== newMonth) return;

    setSelectedMonth(months.find(month => month.month === monthNumber));
  };

  const handleTextChange = (text: string) => {
    if (selectedMonth === undefined) return;

    onTextChange(selectedMonth.month, text);
  };

  return (
    <>
      <Form.Select
        value={selectedMonth?.month ?? undefined}
        onChange={e => {
          handleMonthChange(e.target.value);
        }}
        className="mb-12px"
      >
        {months.map(month => (
          <option key={month.month} value={month.month}>
            {monthNames[month.month]}
          </option>
        ))}
      </Form.Select>
      <Form.Control
        placeholder="Napište odměnu"
        value={selectedMonth?.text ?? ""}
        onChange={e => {
          handleTextChange(e.target.value);
        }}
        className="mb-12px"
      />
    </>
  );
};

interface IPointsProps {
  defaultPoints: number | null;
  points: IData;
  onTextChange: (points: number, text: string) => void;
}

const PointsContent: FC<IPointsProps> = ({ defaultPoints, points, onTextChange }) => {
  const [selectedPoints, setSelectedPoints] = useState(
    points.pointsRewards.find(point => point.targetScore === defaultPoints),
  );

  const handlePointsChange = (newPoints: string) => {
    const pointsNumber = Number(newPoints);
    if (pointsNumber.toString() !== newPoints) return;

    const editing = points.pointsRewards.find(point => point.targetScore === pointsNumber);

    if (editing === undefined) {
      setSelectedPoints({
        targetScore: pointsNumber,
        text: "",
      });
    } else {
      setSelectedPoints(editing);
    }
  };

  const handleTextChange = (text: string) => {
    const targetScore = selectedPoints?.targetScore;
    if (typeof targetScore !== "number") return;

    setSelectedPoints({
      text,
      targetScore,
    });
    onTextChange(targetScore, text);
  };

  return (
    <>
      <Form.Control
        placeholder="Napište počet bodů"
        value={selectedPoints?.targetScore ?? ""}
        onChange={e => {
          handlePointsChange(e.target.value);
        }}
        className="mb-12px"
      />
      <Form.Control
        placeholder="Napište odměnu"
        value={selectedPoints?.text ?? ""}
        onChange={e => {
          handleTextChange(e.target.value);
        }}
        className="mb-12px"
      />
    </>
  );
};
