import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";

import AnalysisLayout from "./_AnalysisLayout";
import SelfEvaluation from "./_SelfEvaluation";

import { AnswerPractice } from "components";

import { useLazyGetAssignmentAnswersQuery } from "api/generated";
import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { selectSimpleAnswer } from "store/slices/answer";

import { getSolutionVariant } from "utils/getSolutionVariant";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { processApiError } from "utils/processApiError";

/* rPp7XgOY9DxQXaSbZsBQ */

interface Props {
  data: UserAssignmentForExerciseFragment;
}

const Razeni: FC<Props> = ({ data }) => {
  const assignment = data.assignment;
  if (assignment === null || assignment === undefined) return null;

  const isSelfEvaluation = assignment.scoring?.scoringMethod.scoringMethod === "sebeopravení";
  const selected = useAppSelector(selectSimpleAnswer);

  const [trigger, { data: answerData, isLoading, error: apiLazyError }] = useLazyGetAssignmentAnswersQuery();
  const [answers, setAnswers] = useState<Array<Array<string | null>> | undefined>(undefined);
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);

  const options =
    assignment.assignment?.questions.map(question => {
      return question.options.map(option => option.value);
    }) ?? [];

  useEffect(() => {
    trigger({ assignmentId: assignment.id }).catch(error => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);
  }, [apiLazyError]);

  useEffect(() => {
    if (answerData === undefined || answerData.assignments.length === 0) return;
    const responseAnswers =
      answerData.assignments[0].assignment?.questions.map(question =>
        question.correctAnswers
          .filter((answer): answer is string => answer !== null)[0]
          .split("")
          .map(value =>
            assignment.assignment?.isAnswerCaseSensitive === true ? [value] : [value.toLocaleLowerCase()],
          ),
      ) ?? [];

    setAnswers(responseAnswers.flat());
  }, [answerData]);

  if (isSelfEvaluation) {
    return <SelfEvaluation userAssignment={data} />;
  }

  return (
    <AnalysisLayout>
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className="mt-20px">
        {assignment.assignment?.questions.map((question, qIndex) => {
          return (
            <>
              <Latex>{question.heading ?? ""}</Latex>

              {question.options.map((option, oIndex) => {
                return (
                  <div
                    key={`text-${qIndex}-${oIndex}`}
                    className="assignment-option"
                    dangerouslySetInnerHTML={{ __html: option.additionalText ?? "" }}
                  ></div>
                );
              })}
              <div className="question question--list mt-20px">
                {options[qIndex].map((_values, vIndex) => {
                  const selectedString = qIndex in selected ? selected[qIndex] : "";

                  const type = getSolutionVariant(
                    selectedString[vIndex],
                    answers,
                    vIndex,
                    assignment.assignment?.isAnswerCaseSensitive ?? false,
                  );

                  return (
                    <div key={`question-${vIndex}`} className="button-group">
                      <span>{`${vIndex + 1}.`}</span>
                      {isLoading ? null : (
                        <AnswerPractice assignment={assignment} type={type} index={qIndex} userAssignmentId={data.id}>
                          {selectedString[vIndex]}
                        </AnswerPractice>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </AnalysisLayout>
  );
};

export default Razeni;
