export const sessionResultEnum = {
  NOT_PLANED: "Not planed",
  NOT_TAKEN: "Not taken",
  PARTIAL: "Partial",
  DONE: "Done",
  PLANNED: "Planned",
} as const;

export type TSessionResult = (typeof sessionResultEnum)[keyof typeof sessionResultEnum];

export const calculateSessionResult = (
  planedMinutes: number,
  spentMinutes: number,
  isFinished: boolean,
): TSessionResult => {
  if (planedMinutes === 0) return sessionResultEnum.NOT_PLANED;

  if (spentMinutes === 0) return sessionResultEnum.NOT_TAKEN;

  return isFinished ? sessionResultEnum.DONE : sessionResultEnum.PARTIAL;
};
