import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PracticePageContent from "../_PracticePageContent";

import { useGetEnvelopeSuperTopicPracticeAssignmentsQuery } from "api/generated";
import type { UserAssignmentPracticeFragment } from "api/generated";

import { translateEnvelopeId } from "utils/translateEnvelopeId";
import { processApiError } from "utils/processApiError";

const EnvelopeSuperTopicPractice = () => {
  const { envelopeId, superTopicId } = useParams();
  if (envelopeId === undefined || superTopicId === undefined) return null;

  const topicId = `${superTopicId}-${envelopeId}`;

  const {
    data,
    isLoading,
    error: apiError,
  } = useGetEnvelopeSuperTopicPracticeAssignmentsQuery({
    envelopeId: translateEnvelopeId(envelopeId),
    superTopicId,
  });

  const [allAssignments, setAllAssignments] = useState<UserAssignmentPracticeFragment[] | null>(null);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const assignments = data.userAssignments.items.filter(assignment => assignment.assignment?.isActive);
    setAllAssignments(assignments);
  }, [data]);

  return (
    <PracticePageContent
      isLoading={isLoading}
      assignments={allAssignments}
      title={data?.superTopics[0]?.name ?? ""}
      topicId={topicId}
    />
  );
};

export default EnvelopeSuperTopicPractice;
