import { useEffect, useState } from "react";
import type { FC } from "react";
import { Nav, NavItem } from "react-bootstrap";

import { ResultModalExercise } from "components";

import { useAppSelector } from "store/hooks";
import { getAssignment } from "store/slices/exercise";
import type { IAssignmentResult, IOption } from "store/slices/exercise";

import { ReactComponent as IconCheck } from "images/icons/check.svg";
import { ReactComponent as IconWarning } from "images/icons/check-partly.svg";
import { ReactComponent as IconError } from "images/icons/close.svg";
import { ReactComponent as IconArrow } from "images/icons/10-arrow2.svg";
import { ReactComponent as IconEnvelopeRed } from "images/icons/30-obalka-red.svg";
import { ReactComponent as IconEnvelopeOrange } from "images/icons/30-obalka-orange.svg";
import { ReactComponent as IconEnvelopeGreen } from "images/icons/30-obalka-green.svg";

import { removeHTML, containsLatex } from "utils/removeHTML";
import { gtmTaskEvent } from "utils/gtmEvents";

interface Props {
  result: IAssignmentResult<IOption | string>;
  assignmentId: string;
  type: "correct" | "partial" | "incorrect" | "not_taken";
  index: number;
  envelopes: { newEnvelope: string; oldEnvelope: string } | undefined;
  isSelfEvalDisabled?: boolean;
}

const ResultBox: FC<Props> = ({ assignmentId, result, type, index, envelopes, isSelfEvalDisabled }) => {
  const assignment = useAppSelector(getAssignment(assignmentId));
  if (assignment === null) return null;

  const [isResultModalOpen, setResultModalOpen] = useState(false);
  const getClassNameByType = () => {
    switch (type) {
      case "correct":
        return "text-success";
      case "partial":
        return "text-warning";
      default:
        return "text-danger";
    }
  };

  const getIconByType = () => {
    switch (type) {
      case "correct":
        return <IconCheck className={"me-4px"} />;
      case "partial":
        return <IconWarning className={"me-4px"} />;
      case "incorrect":
        return <IconError className={"me-4px"} />;
    }
  };

  const getTitle = () => {
    const topicName = assignment.topic?.name ?? "";

    if (typeof topicName === "string") {
      return `${index + 1}. ${topicName}`;
    }

    return `${index + 1}.`;
  };

  const getEnvelope = (envelope: string) => {
    switch (envelope) {
      case "red":
        return <IconEnvelopeRed />;
      case "yellow":
        return <IconEnvelopeOrange />;
      case "green":
        return <IconEnvelopeGreen />;
      default:
    }
  };

  const getEnvelopeChange = () => {
    if (
      envelopes === undefined ||
      typeof envelopes.newEnvelope !== "string" ||
      typeof envelopes?.oldEnvelope !== "string"
    ) {
      return null;
    }

    return (
      <div className="result-box__envelopes-wrapper">
        <div className="result-box__envelope">{getEnvelope(envelopes.oldEnvelope)}</div>
        <div>
          <IconArrow />
        </div>
        <div className="result-box__envelope">{getEnvelope(envelopes.newEnvelope)}</div>
      </div>
    );
  };

  const getContent = () => {
    const text =
      assignment.assignment?.assignment?.questions[0]?.heading ??
      assignment.assignment?.assignment?.assignmentText ??
      "";

    if (containsLatex(text)) return "";

    return removeHTML(text);
  };

  useEffect(() => {
    if (isResultModalOpen) {
      gtmTaskEvent("task-post-analysis", {
        id: assignment.id,
        subject: assignment.subject?.appName,
        result: result.status,
      });
    }
  }, [isResultModalOpen]);

  return (
    <>
      <NavItem>
        <Nav.Link
          className={"nav-link--with-arrow fw-normal flex-column"}
          onClick={() => {
            setResultModalOpen(true);
          }}
          id="assignment-result"
        >
          <p className={["result-box__title", getClassNameByType()].join(" ")}>
            {getIconByType()}
            <strong className="result-box__title__name">{getTitle()}</strong>
            {getEnvelopeChange()}
          </p>
          <p className={"line-clamp-2"}>{getContent()}</p>
        </Nav.Link>
      </NavItem>

      <ResultModalExercise
        isOpen={isResultModalOpen}
        onClose={() => {
          setResultModalOpen(false);
        }}
        result={result}
        assignment={assignment}
        isSelfEvalDisabled={isSelfEvalDisabled}
      />
    </>
  );
};

export default ResultBox;
