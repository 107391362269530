import { LOCAL_STORAGE_TIME_TO_LIVE } from "const";

interface ITimeLimitedStorageItem {
  value: string;
  expiry: number;
}

export const saveToTimedLocalStorage = (key: string, value?: string, expiry?: number) => {
  if (value === undefined) {
    window.localStorage.removeItem(key);
    return;
  }

  const now = new Date();
  const storageItem: ITimeLimitedStorageItem = {
    value,
    expiry: expiry ?? now.getTime() + LOCAL_STORAGE_TIME_TO_LIVE,
  };

  window.localStorage.setItem(key, JSON.stringify(storageItem));
};

export const getFromTimedLocalStorage = (key: string) => {
  const data = window.localStorage.getItem(key);

  if (data === null) {
    window.localStorage.removeItem(key);
    return null;
  }

  const parsedData: ITimeLimitedStorageItem = JSON.parse(data);
  const now = new Date();

  if (now.getTime() > parsedData.expiry) {
    window.localStorage.removeItem(key);
    return null;
  }

  return parsedData.value;
};

export const saveToLocalStorage = (key: string, value?: any) => {
  if (value === undefined) {
    window.localStorage.removeItem(key);
    return;
  }

  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = <T>(key: string) => {
  const value = window.localStorage.getItem(key);

  if (value === null) {
    window.localStorage.removeItem(key);
    return null;
  }

  return JSON.parse(value) as T;
};
