import { useEffect, useState } from "react";
import type { FC, FunctionComponent } from "react";
import { Container } from "react-bootstrap";

import PracticeBadges from "./BadgesCategories/Practice";
import PlanBadges from "./BadgesCategories/Plan";

import { badgesConfiguration } from "./badgesConfiguration";
import type { TBadgeCategory, IBadgeConfiguration } from "./badgesConfiguration";

import { BadgeModal } from "components";

import type { GetStatisticsDataQuery } from "api/generated";

const defaultBadges: IBadgeResult[] = badgesConfiguration.map(config => {
  return {
    category: config.category,
    icon: config.iconGray,
    iconGained: config.iconGained,
    iconGray: config.iconGray,
    isEarned: false,
    modalTextEarned: config.modalTextEarned,
    modalTextNotEarned: config.modalTextNotEarned,
    title: config.title,
    type: config.type,
    key: config.key,
    count: config.count,
    dateEarned: null,
  };
});

export interface IBadgeResult extends IBadgeConfiguration {
  category: TBadgeCategory;
  dateEarned: Date | null;
  isEarned: boolean;
  icon: FunctionComponent;
}

interface Props {
  data: GetStatisticsDataQuery["checkAchievements"] | undefined;
}

const Badges: FC<Props> = ({ data }) => {
  const [badgesData, setBadgesData] = useState<IBadgeResult[]>(defaultBadges);
  const [modalBadge, setModalBadge] = useState<IBadgeResult | null>(null);

  useEffect(() => {
    if (data === undefined) return;

    const realData: IBadgeResult[] = badgesData.map(badge => {
      const badgeData = data.achievements.find(achievement => {
        if (badge.key === "plan" && achievement.type === "plan") return true;
        if (badge.key === "sessions") {
          const combinedName = `sessions-${achievement.goal}`;
          if (badge.type === combinedName) return true;
        }

        return false;
      });

      if (badgeData === undefined) return badge;

      badge.icon = badge.iconGained;
      badge.isEarned = true;
      badge.dateEarned = new Date(badgeData.dateAchieved);

      return badge;
    });

    setBadgesData(realData);
  }, [data]);

  const handleBadgeClick = (type: string) => {
    const badgeToShow = badgesData.find(badge => badge.type === type);

    if (badgeToShow === undefined) return;

    setModalBadge(badgeToShow);
  };

  const handleModalClose = () => {
    setModalBadge(null);
  };

  const handleNextBadge = () => {
    if (modalBadge === null) return;

    const index = badgesData.findIndex(badge => badge.type === modalBadge.type);

    if (index === -1) return;
    if (index >= badgesData.length - 1) {
      setModalBadge(badgesData[0]);
      return;
    }

    setModalBadge(badgesData[index + 1]);
  };

  const handlePrevBadge = () => {
    if (modalBadge === null) return;

    const index = badgesData.findIndex(badge => badge.type === modalBadge.type);

    if (index === -1) return;
    if (index <= 0) {
      setModalBadge(badgesData[badgesData.length - 1]);
      return;
    }

    setModalBadge(badgesData[index - 1]);
  };

  return (
    <Container className={"container-mw-md"}>
      <PlanBadges data={badgesData.filter(badge => badge.category === "plan")} onClick={handleBadgeClick} />
      <PracticeBadges data={badgesData.filter(badge => badge.category === "practice")} onClick={handleBadgeClick} />

      <BadgeModal
        key={`${modalBadge?.type ?? "badge-modal"}`}
        badge={modalBadge}
        goNext={handleNextBadge}
        goPrev={handlePrevBadge}
        isShown={modalBadge !== null}
        onClose={handleModalClose}
      />
    </Container>
  );
};

export default Badges;
