import Logo from "images/logos/tonatrenujes.svg";

import { Alert, Col, Container, Image, Row } from "react-bootstrap";

const NotAuthenticated = () => {
  return (
    <Container className="container-mw-sm">
      <Row>
        <Col className={"py-5 text-center"}>
          <Image src={Logo} loading={"lazy"} className={"mb-40px"} />

          <Alert variant={"danger"} className={"text-start"}>
            <p>
              {/* TODO: translate */}
              <strong>Vypršela platnost přihlášení</strong> <br />
              Prosím, přihlašte se znovu v <a href="https://zona.to-das.cz/">zóně!</a>
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default NotAuthenticated;
