import { badgesConfiguration } from "pages/Statistics/Tabs/badgesConfiguration";
import type { IBadgeResult } from "pages/Statistics/Tabs/Badges";

import type { CheckAchievementsFragment } from "api/generated";

export const newBadgeDataFormatter = (data: CheckAchievementsFragment) => {
  const badges: IBadgeResult[] = [];

  for (const achievement of data.achievementsNew) {
    const type = achievement.type === "sessions" ? `sessions-${achievement.goal}` : achievement.type;
    const config = badgesConfiguration.find(config => config.type === type);
    if (config === undefined) continue;

    const result: IBadgeResult = {
      category: config.category,
      icon: config.iconGained,
      iconGained: config.iconGained,
      iconGray: config.iconGray,
      isEarned: true,
      modalTextEarned: config.modalTextEarned,
      modalTextNotEarned: config.modalTextNotEarned,
      title: config.title,
      type: config.type,
      dateEarned: new Date(achievement.dateAchieved),
      key: config.key,
      count: config.count,
    };

    badges.push(result);
  }

  return badges;
};

export const onboardingBadgeDataFormatter = () => {
  const onboardingBadgeData = badgesConfiguration.find(badge => badge.type === "sessions-onboarding");

  if (onboardingBadgeData === undefined) {
    console.error("No onboarding badge configuration found!");
    return null;
  }

  const result: IBadgeResult = {
    category: onboardingBadgeData.category,
    icon: onboardingBadgeData.iconGained,
    iconGained: onboardingBadgeData.iconGained,
    iconGray: onboardingBadgeData.iconGray,
    isEarned: true,
    modalTextEarned: onboardingBadgeData.modalTextEarned,
    modalTextNotEarned: onboardingBadgeData.modalTextNotEarned,
    title: onboardingBadgeData.title,
    type: onboardingBadgeData.type,
    dateEarned: new Date(),
    key: onboardingBadgeData.key,
    count: onboardingBadgeData.count,
  };

  return result;
};
