import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IOption {
  value?: string | null | undefined;
  additionalText?: string | null | undefined;
}

interface IAnswer {
  simple: string[];
  structured: Array<IOption | null>;
}

const initialState: IAnswer = {
  simple: [],
  structured: [],
};

const answerSlice = createSlice({
  name: "answer",
  initialState,
  reducers: {
    setSimpleAnswer: (state, action: PayloadAction<string[]>) => {
      state.simple = [...action.payload];
    },
    setStructuredAnswer: (state, action: PayloadAction<IOption[]>) => {
      state.structured = [...action.payload];
    },
  },
});

export default answerSlice.reducer;
export const { setSimpleAnswer, setStructuredAnswer } = answerSlice.actions;

export const selectSimpleAnswer = (state: RootState) => state.answer.simple;
export const selectStructuredAnswer = (state: RootState) => state.answer.structured;
