import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Image } from "react-bootstrap";

import { NotExerciseInfo } from "components";

import { ReactComponent as BgWaveTop } from "images/bg/bckg-branded-top.svg";

import { useAppSelector } from "store/hooks";
import { getAllResults, getPracticeTopicName } from "store/slices/topicPractice";

const Evaluation = () => {
  const { t } = useTranslation("exercise");

  const results = useAppSelector(getAllResults);
  const topicName = useAppSelector(getPracticeTopicName);

  return (
    <main className={"fullpage bg-cyan-200"}>
      <BgWaveTop className={"wave__top"} />

      <Container className="container-mw-sm text-center fullpage__content pb-5">
        <div className="page px-3">
          <div className="page__content my-auto ms-sm-0 flex-grow-0">
            <Image src={"/images/confetti.svg"} />

            <h1 className="h2 my-20px">
              Super, máš procvičeno!
              <br />
              <span className="text-highlight">
                {Object.keys(results).length}{" "}
                {topicName?.toLocaleLowerCase() ?? t("exercise", { count: Object.keys(results).length })} navíc!
              </span>
            </h1>
            <p>{t("message.doneForToday", { ns: "exercise" })}</p>
          </div>

          <NotExerciseInfo />
        </div>
      </Container>

      <div className="fullpage__footer bg-cyan-200">
        <Container className="container-mw-sm py-20px">
          <LinkContainer to="/">
            <Button className={"text-uppercase w-100"}>{t("btn.doneForToday", { ns: "exercise" })}</Button>
          </LinkContainer>
        </Container>
      </div>
    </main>
  );
};

export default Evaluation;
