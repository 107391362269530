import type { FC } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { AchievementIcon } from "components";
import type { IBadgeResult } from "pages/Statistics/Tabs/Badges";

import { ReactComponent as IconArrowLeft } from "images/icons/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "images/icons/arrow-right.svg";

interface Props {
  badge: IBadgeResult | null;
  isShown: boolean;
  isNavShown?: boolean;
  goNext: () => void;
  goPrev: () => void;
  onClose: () => void;
}

const ExerciseBadgeModal: FC<Props> = ({ badge, isShown, isNavShown = true, goNext, goPrev, onClose }) => {
  if (badge === null) return null;

  return (
    <Modal show={isShown} onHide={onClose}>
      <Modal.Header closeButton />
      <Modal.Body className={"pb-40px"}>
        <Row>
          <Col xs={"auto"} className="px-0 flex-column d-flex justify-content-center">
            {isNavShown ? (
              <Button className={"btn-circular btn-circular--sm"} variant={"light"} onClick={goPrev}>
                <IconArrowLeft className={"flex-shrink-0"} />
              </Button>
            ) : null}
          </Col>
          <Col className={"text-center"}>
            <h4 className={"mb-12px"}>Získala jsi odznak {badge.title}!</h4>
            <p className={"mb-12px"}>{badge.modalTextEarned}</p>
            <div className="achievement--inactive bg-white">
              <AchievementIcon icon={badge.icon} count={badge.count} />
            </div>
            <Button variant="success" className={"w-100 text-uppercase"} onClick={onClose}>
              Přejít na výsledky
            </Button>
          </Col>
          <Col xs={"auto"} className="px-0 flex-column d-flex justify-content-center">
            {isNavShown ? (
              <Button className={"btn-circular btn-circular--sm"} variant={"light"} onClick={goNext}>
                <IconArrowRight className={"flex-shrink-0"} />
              </Button>
            ) : null}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ExerciseBadgeModal;
