import type { FC } from "react";
import { LinkContainer } from "react-router-bootstrap";

import { useAppDispatch } from "store/hooks";
import { setFrom } from "store/slices/envelopeNavigation";

interface Props {
  type: string;
}

const EnvelopeIcon: FC<Props> = ({ type }) => {
  const dispatch = useAppDispatch();

  let envelopeColor;
  let typeParam;

  switch (type) {
    case "green":
      envelopeColor = "#27AE60";
      typeParam = "zelena";
      break;
    case "yellow":
      envelopeColor = "#F2994A";
      typeParam = "oranzova";
      break;
    case "red":
      envelopeColor = "#EB5757";
      typeParam = "cervena";
      break;
    default:
      envelopeColor = "#2296B9";
  }

  const linkToEnvelope = typeParam === undefined ? "" : `/ulohy/obalka/${typeParam}`;

  const handleClick = () => {
    dispatch(setFrom("/ulohy"));
  };

  return (
    <LinkContainer to={linkToEnvelope} onClick={handleClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" fill="none">
        <path
          fill={envelopeColor}
          d="m1.284 9.81.193 1.96 2.01 1.26 5.278 1.477 5.133-1.235 4.89-5.569 3.39-4.745-.412-.363-1.961-.533-2.276-.363-4.503-.678H11.96l-.872.46-.775.775-.992.702-1.55 1.114L1.284 9.81Z"
        />
        <path
          fill="#34322E"
          d="m1.284 9.81.193 1.96 2.01 1.26 5.278 1.477 5.133-1.235 4.89-5.569 3.39-4.745-.412-.363-1.961-.533-2.276-.363-4.503-.678H11.96l-.872.46-.775.775-.992.702-1.55 1.114L1.284 9.81Z"
          opacity=".3"
        />
        <path
          stroke="#34322E"
          d="m1.2 9.843 5.254-4.65c.676-.619 2.236-1.965 3.074-2.397 1.047-.54 1.351-1.892 3.04-1.79 1.689.1 6.62.945 7.16 1.046.433.082 1.51.372 1.993.507"
        />
        <path stroke="#34322E" d="M12.83 1.46c.057.253.136.951 0 1.72" />
        <g stroke="#78746D">
          <path
            fill="#fff"
            d="M15.902 11.883c.323-.351 2.848-3.339 3.156-3.83.242-.384 1.766-2.355 2.55-3.387.017-.023.04-.086-.053-.105-.794-.164-2.35-.52-2.722-.52-.465 0-.62-.31-1.705-.31h-6.817C9.02 4.97 6.035 7.728 4.423 8.844c-1.611 1.115-2.369 1.893-2.574 2.175-.025.048-.007.077.02.084.694.182 2.272.563 3.613.78 1.739.28 5.526 1.01 5.988 1.01.463 0 1.066.289 1.475.289h1.55c.336 0 1.085-.949 1.408-1.3Z"
          />
          <path d="M2.08 11.009c1.222-.48 3.885-1.521 4.774-1.858 1.11-.42 4.004-2.05 4.216-2.26.211-.21 0-.824 0-1.472 0-.483-.062-1.168-.736-1.647" />
        </g>
        <path
          fill={envelopeColor}
          d="m5.435 10.858-4.183-.93.465 4.699c-.052.207.03.62.774.62.93 0 4.339 1.084 5.578 1.394 1.24.31 2.634.155 4.338.31 1.705.155 1.937 0 2.325-.31.774-.62.93-1.24 1.859-2.324.93-1.085 2.944-3.719 3.254-4.338.31-.62 2.324-2.324 2.634-2.634.31-.31.155-3.15.31-3.77.123-.495-.775-.93-1.24-1.084.207.103.62.34.62.465 0 .155-.465.31-.775.62s-1.24 1.549-1.704 2.169c-.465.62-.93 1.084-1.705 1.859-.62.62-2.944 3.46-4.028 4.803-.775-.155-2.417-.496-2.79-.62-.371-.124-1.6-.206-2.478-.31 0 .465.03 1.209-1.085 1.085-1.115-.124-2.014-1.343-2.169-1.704Z"
        />
        <path
          stroke="#34322E"
          d="m5.435 10.858-4.183-.93.465 4.699c-.052.207.03.62.774.62.93 0 4.339 1.084 5.578 1.394 1.24.31 2.634.155 4.338.31 1.705.155 1.937 0 2.325-.31.774-.62.93-1.24 1.859-2.324.93-1.085 2.944-3.719 3.254-4.338.31-.62 2.324-2.324 2.634-2.634.31-.31.155-3.15.31-3.77.123-.495-.775-.93-1.24-1.084.207.103.62.34.62.465 0 .155-.465.31-.775.62s-1.24 1.549-1.704 2.169c-.465.62-.93 1.084-1.705 1.859-.62.62-2.944 3.46-4.028 4.803-.775-.155-2.417-.496-2.79-.62-.371-.124-1.6-.206-2.478-.31 0 .465.03 1.209-1.085 1.085-1.115-.124-2.014-1.343-2.169-1.704Z"
        />
        <path stroke="#34322E" d="M13.992 13.033c.057.253.137.95 0 1.719" />
      </svg>
    </LinkContainer>
  );
};

export default EnvelopeIcon;
