import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

import type { UserAssignmentForExerciseFragment } from "api/generated";

export interface IOption {
  value?: string | null | undefined;
  additionalText?: string | null | undefined;
}

export type TAssignmentStatus = "not_taken" | "correct" | "partial" | "incorrect";
export type TSolutionVariant = "success" | "warning" | "danger";

export interface IAssignmentResult<T extends IOption | string> {
  status: TAssignmentStatus;
  answersVariants?: TSolutionVariant[];
  answers?: T[];
  correctAnswers?: Array<Array<string | null>>;
  selfEvalAnswers: Array<{ points: number | null; status: TSolutionVariant | null }>;
  timeLearn?: number;
  abilityScore?: number | Array<number | undefined>;
  tips: boolean[];
  points?: number;
}

export interface IExercise {
  sessionId: string | null;
  assignmentsData: UserAssignmentForExerciseFragment[];
  assignmentsResults: Record<string, IAssignmentResult<IOption | string>>;
  newEnvelopes: Record<string, string>;
  selectedTime: number | undefined;
}

const initialState: IExercise = {
  sessionId: null,
  assignmentsData: [],
  assignmentsResults: {},
  newEnvelopes: {},
  selectedTime: undefined,
};

const exerciseSlice = createSlice({
  name: "exercisePractice",
  initialState,
  reducers: {
    setExerciseAssignments: (
      state,
      action: PayloadAction<{ sessionId: string; assignments: UserAssignmentForExerciseFragment[] }>,
    ) => {
      const { sessionId, assignments } = action.payload;

      state.sessionId = sessionId;
      state.assignmentsData = assignments;

      state.assignmentsResults = createEmptyResults(assignments);
    },
    setSavedExerciseAssignments: (state, action: PayloadAction<IExercise>) => {
      const { sessionId, assignmentsData, assignmentsResults, selectedTime } = action.payload;

      state.assignmentsData = assignmentsData;
      state.assignmentsResults = assignmentsResults;
      state.sessionId = sessionId;
      state.selectedTime = selectedTime;
    },
    finishSession: state => {
      state = initialState;
    },
    setStatus: (state, action: PayloadAction<{ userAssignmentId: string; status: TAssignmentStatus }>) => {
      const { userAssignmentId, status } = action.payload;
      const assignment = { ...state.assignmentsResults[userAssignmentId] };
      assignment.status = status;

      state.assignmentsResults = { ...state.assignmentsResults, [userAssignmentId]: assignment };
    },
    setAnswer: (state, action: PayloadAction<{ assignmentId: string; answer: Array<string | IOption> }>) => {
      const { assignmentId, answer } = action.payload;
      const assignment = { ...state.assignmentsResults[assignmentId] };
      assignment.answers = answer;

      state.assignmentsResults = { ...state.assignmentsResults, [assignmentId]: assignment };
    },
    setSelfEvalAnswers: (
      state,
      action: PayloadAction<{
        assignmentId: string;
        selfEvalAnswer: Array<{ points: number | null; status: TSolutionVariant | null }>;
      }>,
    ) => {
      const { assignmentId, selfEvalAnswer } = action.payload;
      const assignment = { ...state.assignmentsResults[assignmentId] };
      assignment.selfEvalAnswers = selfEvalAnswer;

      state.assignmentsResults = { ...state.assignmentsResults, [assignmentId]: assignment };
    },
    setAnswerVariants: (state, action: PayloadAction<{ assignmentId: string; answerVariants: TSolutionVariant[] }>) => {
      const { assignmentId, answerVariants } = action.payload;
      const assignment = { ...state.assignmentsResults[assignmentId] };
      assignment.answersVariants = answerVariants;

      state.assignmentsResults = { ...state.assignmentsResults, [assignmentId]: assignment };
    },
    setAssignmentEvaluation: (
      state,
      action: PayloadAction<{
        assignmentId: string;
        status: TAssignmentStatus;
        answerVariants: TSolutionVariant[];
        correctAnswers: Array<Array<string | null>>;
      }>,
    ) => {
      const { assignmentId, status, answerVariants, correctAnswers } = action.payload;
      const assignment = { ...state.assignmentsResults[assignmentId] };
      assignment.status = status;
      assignment.answersVariants = answerVariants;
      assignment.correctAnswers = correctAnswers;

      state.assignmentsResults = { ...state.assignmentsResults, [assignmentId]: assignment };
    },
    setTimeLearn: (state, action: PayloadAction<{ assignmentId: string; timeLearn: number }>) => {
      const { assignmentId, timeLearn } = action.payload;
      const assignment = { ...state.assignmentsResults[assignmentId] };
      assignment.timeLearn = timeLearn;

      state.assignmentsResults = { ...state.assignmentsResults, [assignmentId]: assignment };
    },
    setAbilityScore: (
      state,
      action: PayloadAction<{ assignmentId: string; score: number | Array<number | undefined> }>,
    ) => {
      const { assignmentId, score } = action.payload;
      const assignment = { ...state.assignmentsResults[assignmentId] };
      assignment.abilityScore = score;

      state.assignmentsResults = { ...state.assignmentsResults, [assignmentId]: assignment };
    },
    resetExercise: state => {
      state = {
        sessionId: null,
        assignmentsData: [],
        assignmentsResults: {},
        newEnvelopes: {},
        selectedTime: undefined,
      };
    },
    clearSessionId: state => {
      state.sessionId = null;
    },
    setTips: (state, action: PayloadAction<{ userAssignmentId: string; tips: boolean[] }>) => {
      const { userAssignmentId, tips } = action.payload;

      state.assignmentsResults[userAssignmentId].tips = tips;
    },
    setTip: (state, action: PayloadAction<{ userAssignmentId: string; tip: boolean; index: number }>) => {
      const { userAssignmentId, tip, index } = action.payload;

      const copy = [...state.assignmentsResults[userAssignmentId].tips];
      copy[index] = tip;
      state.assignmentsResults[userAssignmentId].tips = copy;
    },
    setPoints: (state, action: PayloadAction<{ userAssignmentId: string; points: number }>) => {
      const { userAssignmentId, points } = action.payload;

      state.assignmentsResults[userAssignmentId].points = points;
    },
    setNewEnvelopes: (state, action: PayloadAction<Record<string, string>>) => {
      state.newEnvelopes = action.payload;
    },
    setSelectedTime: (state, action: PayloadAction<number | undefined>) => {
      state.selectedTime = action.payload;
    },
    changeAnswerVariant: (
      state,
      action: PayloadAction<{ userAssignmentId: string; index: number; type: TSolutionVariant }>,
    ) => {
      const { userAssignmentId, index, type } = action.payload;

      const savedVariants = state.assignmentsResults[userAssignmentId].answersVariants;
      if (savedVariants === undefined) return;

      savedVariants[index] = type;
      state.assignmentsResults[userAssignmentId].answersVariants = [...savedVariants];
    },
  },
});

export default exerciseSlice.reducer;
export const {
  setExerciseAssignments,
  setSavedExerciseAssignments,
  finishSession,
  setStatus,
  setAnswer,
  setSelfEvalAnswers,
  setAnswerVariants,
  setAssignmentEvaluation,
  setTimeLearn,
  setAbilityScore,
  resetExercise,
  clearSessionId,
  setTips,
  setTip,
  setPoints,
  setNewEnvelopes,
  setSelectedTime,
  changeAnswerVariant,
} = exerciseSlice.actions;

export const getAssignments = (state: RootState) => state.exercise.assignmentsData;
export const getResults = (state: RootState) => state.exercise.assignmentsResults;

export const getSessionId = (state: RootState) => state.exercise.sessionId;
export const getAssignmentResult =
  <T extends IOption | string>(assignmentId: string) =>
  (state: RootState) =>
    state.exercise.assignmentsResults[assignmentId] as IAssignmentResult<T> | undefined;

export const getAnswersStatus =
  (userAssignmentId: string) =>
  (state: RootState): boolean[] => {
    const userAssignment = state.exercise.assignmentsData.find(assignment => assignment.id === userAssignmentId);
    const questions = userAssignment?.assignment?.assignment?.questions;

    if (questions === undefined) return [];

    const answersVariants = state.exercise.assignmentsResults[userAssignmentId]?.answersVariants;

    const result: boolean[] = [];
    for (let i = 0; i < questions.length; i++) {
      const userAnswer = answersVariants?.[i];

      result.push(userAnswer === "success");
    }

    return result;
  };

export const getAssignmentDetail = (userAssignmentId: string) => (state: RootState) => {
  const userAssignment = state.exercise.assignmentsData.find(assignment => assignment.id === userAssignmentId);

  if (userAssignment === undefined || userAssignment.assignment === undefined) return null;
  return userAssignment.assignment;
};

export const getAssignment = (userAssignmentId: string) => (state: RootState) => {
  const userAssignment = state.exercise.assignmentsData.find(assignment => assignment.id === userAssignmentId);

  return userAssignment ?? null;
};
export const getAssignmentMaxPoints = (userAssignmentId: string) => (state: RootState) => {
  const userAssignment = state.exercise.assignmentsData.find(assignment => assignment.id === userAssignmentId);

  return userAssignment?.scoreMax ?? 0;
};

export const getAbilityScore = (userAssignmentId: string) => (state: RootState) => {
  return state.exercise.assignmentsResults[userAssignmentId].abilityScore;
};
export const getTips = (userAssignmentId: string) => (state: RootState) => {
  return state.exercise.assignmentsResults[userAssignmentId]?.tips;
};
export const getTip = (userAssignmentId: string, index: number) => (state: RootState) => {
  return state.exercise.assignmentsResults[userAssignmentId]?.tips[index];
};
export const getAssignmentGainedPoints = (userAssignmentId: string) => (state: RootState) => {
  return state.exercise.assignmentsResults[userAssignmentId]?.points ?? null;
};

export const createEmptyResults = (assignments: UserAssignmentForExerciseFragment[]) => {
  const assignmentsResults: Record<string, IAssignmentResult<IOption | string>> = {};

  for (const assignment of assignments) {
    assignmentsResults[assignment.id] = {
      selfEvalAnswers: [],
      status: "not_taken",
      abilityScore: undefined,
      timeLearn: 0,
      answers: [],
      answersVariants: [],
      tips: [],
    };
  }

  return assignmentsResults;
};

export const getNewEnvelopes = (state: RootState) => state.exercise.newEnvelopes;
export const getSelectedTime = (state: RootState) => state.exercise.selectedTime;
