import { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { PHONE_VALIDATION_CODE_LENGTH } from "const";

const isAllowedKey = (isNumericOnly: boolean, key: string) =>
  isNumericOnly ? /^\d*$/.test(key) : /^[a-zA-Z0-9]$/.test(key);

interface Props {
  // Callback function when all fields are filled. Otherwise null is passed
  onCodeComplete: (code: string | null) => void;
  // If isNumericOnly is true, only allow numeric keys otherwise allow alphanumeric keys
  isNumericOnly?: boolean;
  disabled?: boolean;
}

/**
 * Component to input validation code
 * @author Lukas Werner
 * @contact sherpa.psx@gmail.com
 */
export const ValidationCodeInput = ({ onCodeComplete, isNumericOnly = false, disabled }: Props) => {
  const inputs = useRef<HTMLInputElement[]>([]);
  const [value, setValue] = useState<string[]>(Array(PHONE_VALIDATION_CODE_LENGTH).fill(""));

  // Changes focus on previous input if backspace is pressed
  const handleChange = useCallback(
    (id: string | undefined, event: React.KeyboardEvent) => {
      if (id === undefined || event.key !== "Backspace") return;

      const index = Number(id);
      if (isNaN(index)) return;

      if (index > 0 && value[index] === "") inputs.current[index - 1].focus();
    },
    [value, isNumericOnly],
  );

  // Sets new input value if valid character is inserted
  const handleInput = useCallback(
    (id: string | undefined, newVal: string) => {
      if (id === undefined || !isAllowedKey(isNumericOnly, newVal)) return;

      const index = Number(id);
      if (isNaN(index)) return;

      const newValue = [...value];
      newValue[index] = newVal;

      if (newVal !== "") {
        if (index < PHONE_VALIDATION_CODE_LENGTH - 1) inputs.current[index + 1].focus();
        if (index === PHONE_VALIDATION_CODE_LENGTH - 1) {
          inputs.current[index].blur();
        }
      }

      setValue(newValue);
    },
    [value, isNumericOnly],
  );

  /**
   * Handle paste event
   * Will parse paste data and paste only first n characters
   * Does not matter if the paste data is longer than PHONE_VALIDATION_CODE_LENGTH or pasting input index.
   * It will paste characters from the first to the last input and slice the rest
   * @param event
   */
  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text").slice(0, PHONE_VALIDATION_CODE_LENGTH);
    const newValue = [...value];

    // Fill only allowed characters based on isNumericOnly
    paste.split("").forEach((char, i) => {
      if (i < PHONE_VALIDATION_CODE_LENGTH && (isNumericOnly ? /^[0-9]$/ : /^[a-zA-Z0-9]$/).test(char)) {
        newValue[i] = char; // Update state with valid pasted characters
      }
    });

    setValue(newValue);
    // Focus on the last input after pasting
    inputs.current[PHONE_VALIDATION_CODE_LENGTH - 1].focus();
  };

  // Call onCodeComplete when all fields are filled
  useEffect(() => {
    const isFilled = value.every(char => char !== "") && value.join("").trim().length === PHONE_VALIDATION_CODE_LENGTH;
    onCodeComplete(isFilled ? value.join("").trim() : null);
  }, [value, onCodeComplete]);

  return (
    <Row className="gx-4px">
      {Array.from({ length: PHONE_VALIDATION_CODE_LENGTH }).map((_, index) => (
        <Col key={`code_validator_${index}`} xs={2}>
          <Form.Control
            ref={(ref: HTMLInputElement) => {
              inputs.current[index] = ref;
            }}
            autoFocus={index === 0}
            value={value[index]}
            type={isNumericOnly ? "tel" : "text"}
            maxLength={1}
            onFocus={event => {
              event.target.select();
            }}
            // onKeyDown does not work on mobiles
            onKeyDown={event => {
              handleChange(event.currentTarget.dataset.id, event);
            }}
            // onInput works everywhere, but does not tracks pressed key
            onInput={event => {
              handleInput(event.currentTarget.dataset.id, event.currentTarget.value);
            }}
            onPaste={handlePaste}
            size="lg"
            data-id={index}
            className="text-center form-control--focus-azure"
            // This will allow paste code directly from SMS on mobile phone
            autoComplete="one-time-code"
            disabled={disabled}
          />
        </Col>
      ))}
    </Row>
  );
};
