import { useTranslation } from "react-i18next";
import { Breadcrumb, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { PhoneValidator } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

const PhoneValidation = () => {
  const { t } = useTranslation(["common", "onboarding"]);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-sm"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/nastaveni/zmena-telefonu"}>
                <IconArrowBack />
                {t("phoneChange.heading", { ns: "settings" })}
              </Link>
            </li>
          </Breadcrumb>
          <h1>{t("phoneValidation.heading", { ns: "settings" })}</h1>
        </Container>
      </div>

      <Container className={"container-mw-sm page-container pt-25px pt-sm-45px"}>
        <div className="page px-3">
          <PhoneValidator redirectBackUrl="/nastaveni/zmena-telefonu" redirectNextUrl="/nastaveni/zmena-telefonu" />
        </div>
      </Container>
    </>
  );
};

export default PhoneValidation;
