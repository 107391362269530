import type { FC } from "react";

interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
}

const Half: FC<Props> = ({ foregroundColor, backgroundColor }) => {
  return (
    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      {backgroundColor !== null ? (
        <path
          d="M1.44098 55.4289L2.06598 61.7574L8.55111 65.8202L25.5834 70.5861L42.1468 66.6015L57.929 48.6317L68.8672 33.3182L67.539 32.1463L61.2105 30.4275L53.8663 29.2555L39.3342 27.0679H35.8965L33.0838 28.5523L30.5837 31.0525L27.3803 33.3182L22.3801 36.9122L1.44098 55.4289Z"
          fill={backgroundColor}
        />
      ) : (
        ""
      )}

      <path
        opacity="0.3"
        d="M1.44098 55.4289L2.06598 61.7574L8.55111 65.8202L25.5834 70.5861L42.1468 66.6015L57.929 48.6317L68.8672 33.3182L67.539 32.1463L61.2105 30.4275L53.8663 29.2555L39.3342 27.0679H35.8965L33.0838 28.5523L30.5837 31.0525L27.3803 33.3182L22.3801 36.9122L1.44098 55.4289Z"
        fill="#34322E"
      />
      <path
        d="M1.17145 55.5357L18.1276 40.5333C20.3075 38.535 25.3433 34.1896 28.0465 32.7944C31.4254 31.0504 32.4064 26.6904 37.8564 27.0174C43.3063 27.3444 59.2201 30.0694 60.9641 30.3964C62.3593 30.658 65.8327 31.5954 67.395 32.0314"
        stroke="#34322E"
      />
      <path d="M38.7017 28.4841C38.8851 29.3004 39.1418 31.5526 38.7017 34.0314" stroke="#34322E" />
      <path
        d="M48.6177 62.1188C49.6587 60.9872 57.8057 51.3467 58.8015 49.7626C59.5809 48.5227 64.499 42.1625 67.0281 38.8309C67.084 38.7573 67.1611 38.5542 66.8584 38.4917C64.2945 37.9617 59.2735 36.8123 58.0735 36.8123C56.5735 36.8123 56.0735 35.8123 52.5735 35.8123H30.5735C26.4068 39.8123 16.7735 48.7123 11.5735 52.3123C6.37352 55.9123 3.92783 58.4229 3.26514 59.3318C3.18701 59.488 3.24361 59.5808 3.32899 59.6032C5.57141 60.1915 10.6638 61.4212 14.989 62.1188C20.6013 63.024 32.8217 65.3775 34.3153 65.3775C35.809 65.3775 37.754 66.3123 39.0735 66.3123H44.0735C45.1598 66.3123 47.5767 63.2503 48.6177 62.1188Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M4.01373 59.2975C7.95334 57.7519 16.5494 54.3893 19.4166 53.3036C23.0006 51.9465 32.3394 46.6877 33.0221 46.0091C33.7047 45.3306 33.0221 43.3514 33.0221 41.2592C33.0221 39.6997 32.8231 37.4895 30.6471 35.9438"
        stroke="#78746D"
      />
      <path
        d="M48.6177 59.1188C49.6587 57.9872 57.8057 48.3467 58.8015 46.7626C59.5809 45.5227 64.499 39.1625 67.0281 35.8309C67.084 35.7573 67.1611 35.5542 66.8584 35.4917C64.2945 34.9617 59.2735 33.8123 58.0735 33.8123C56.5735 33.8123 56.0735 32.8123 52.5735 32.8123H30.5735C26.4068 36.8123 16.7735 45.7123 11.5735 49.3123C6.37352 52.9123 3.92783 55.4229 3.26514 56.3318C3.18701 56.488 3.24361 56.5808 3.32899 56.6032C5.57141 57.1915 10.6638 58.4212 14.989 59.1188C20.6013 60.024 32.8217 62.3775 34.3153 62.3775C35.809 62.3775 37.754 63.3123 39.0735 63.3123H44.0735C45.1598 63.3123 47.5767 60.2503 48.6177 59.1188Z"
        fill="white"
        stroke="#78746D"
      />

      {foregroundColor !== null ? (
        <path
          d="M14.8384 58.8123L1.33844 55.8123L2.83844 65.8123C2.67177 66.4789 2.93844 67.8123 5.33844 67.8123C8.33844 67.8123 19.3384 71.3123 23.3384 72.3123C27.3384 73.3123 31.8384 72.8123 37.3384 73.3123C42.8384 73.8123 43.5884 73.3123 44.8384 72.3123C47.3384 70.3123 47.8384 68.3123 50.8384 64.8123C53.8384 61.3123 60.3384 52.8123 61.3384 50.8123C62.3384 48.8123 68.8384 43.3123 69.8384 42.3123C70.8384 41.3123 70.3384 37.3123 70.8384 35.3123C71.2384 33.7123 68.3384 32.3123 66.8384 31.8123C67.5051 32.1456 68.8384 32.9123 68.8384 33.3123C68.8384 33.8123 67.3384 34.3123 66.3384 35.3123C65.3384 36.3123 62.3384 40.3123 60.8384 42.3123C59.3384 44.3123 57.8384 45.8123 55.3384 48.3123C53.3384 50.3123 45.8384 59.4789 42.3384 63.8123C39.8384 63.3123 34.5384 62.2123 33.3384 61.8123C32.1384 61.4123 28.1718 61.1456 25.3384 60.8123C25.3384 62.3123 25.4384 64.7123 21.8384 64.3123C18.2384 63.9123 15.3384 59.9789 14.8384 58.8123Z"
          fill={foregroundColor}
        />
      ) : (
        ""
      )}

      <path
        d="M14.8384 58.8123L1.33844 55.8123L2.83844 65.8123C2.67177 66.4789 2.93844 67.8123 5.33844 67.8123C8.33844 67.8123 19.3384 71.3123 23.3384 72.3123C27.3384 73.3123 31.8384 72.8123 37.3384 73.3123C42.8384 73.8123 43.5884 73.3123 44.8384 72.3123C47.3384 70.3123 47.8384 68.3123 50.8384 64.8123C53.8384 61.3123 60.3384 52.8123 61.3384 50.8123C62.3384 48.8123 68.8384 43.3123 69.8384 42.3123C70.8384 41.3123 70.3384 37.3123 70.8384 35.3123C71.2384 33.7123 68.3384 32.3123 66.8384 31.8123C67.5051 32.1456 68.8384 32.9123 68.8384 33.3123C68.8384 33.8123 67.3384 34.3123 66.3384 35.3123C65.3384 36.3123 62.3384 40.3123 60.8384 42.3123C59.3384 44.3123 57.8384 45.8123 55.3384 48.3123C53.3384 50.3123 45.8384 59.4789 42.3384 63.8123C39.8384 63.3123 34.5384 62.2123 33.3384 61.8123C32.1384 61.4123 28.1718 61.1456 25.3384 60.8123C25.3384 62.3123 25.4384 64.7123 21.8384 64.3123C18.2384 63.9123 15.3384 59.9789 14.8384 58.8123Z"
        stroke="#34322E"
      />
      <path d="M42.4517 65.8301C42.6351 66.6463 42.8918 68.8986 42.4517 71.3773" stroke="#34322E" />
      <path
        d="M48.6177 56.1188C49.6587 54.9872 57.8057 45.3467 58.8015 43.7626C59.5809 42.5227 64.499 36.1625 67.0281 32.8309C67.084 32.7573 67.1611 32.5542 66.8584 32.4917C64.2945 31.9617 59.2735 30.8123 58.0735 30.8123C56.5735 30.8123 56.0735 29.8123 52.5735 29.8123H30.5735C26.4068 33.8123 16.7735 42.7123 11.5735 46.3123C6.37352 49.9123 3.92783 52.4229 3.26514 53.3318C3.18701 53.488 3.24361 53.5808 3.32899 53.6032C5.57141 54.1915 10.6638 55.4212 14.989 56.1188C20.6013 57.024 32.8217 59.3775 34.3153 59.3775C35.809 59.3775 37.754 60.3123 39.0735 60.3123H44.0735C45.1598 60.3123 47.5767 57.2503 48.6177 56.1188Z"
        fill="white"
        stroke="#78746D"
      />
      <path
        d="M4.01373 53.2975C7.95334 51.7519 16.5494 48.3893 19.4166 47.3036C23.0006 45.9465 34.3394 41.6877 35.0221 41.0091C35.7047 40.3306 34.5248 37.1976 34.5248 35.1054C34.5248 33.546 32.8231 31.4895 30.6471 29.9438"
        stroke="#78746D"
      />
      <path
        d="M32.1946 38.7672C31.8987 38.9336 31.3957 39.3552 31.7508 39.7103"
        stroke="#78746D"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Half;
