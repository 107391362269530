import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

interface IEnvelopeNavigationState {
  from: string | null;
}

const initialState: IEnvelopeNavigationState = {
  from: null,
};

const envelopeNavigationSlice = createSlice({
  name: "envelopeNavigation",
  initialState,
  reducers: {
    setFrom: (state, action: PayloadAction<string>) => {
      state.from = action.payload;
    },
  },
});

export default envelopeNavigationSlice.reducer;
export const { setFrom } = envelopeNavigationSlice.actions;

export const getFrom = (state: RootState) => state.envelopeNavigation.from;
