import type { FC } from "react";

interface Props {
  foregroundColor?: string;
  backgroundColor?: string;
}

const Empty: FC<Props> = ({ foregroundColor, backgroundColor }) => {
  return (
    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      {backgroundColor !== null ? (
        <path
          d="M1.26953 55.4289L1.89453 61.7574L8.37966 65.8202L25.4119 70.5861L41.9754 66.6015L57.7576 48.6317L68.6957 33.3182L67.3675 32.1463L61.039 30.4275L53.6948 29.2555L39.1627 27.0679H35.725L32.9124 28.5523L30.4122 31.0525L27.2089 33.3182L22.2086 36.9122L1.26953 55.4289Z"
          fill={backgroundColor}
        />
      ) : (
        ""
      )}

      <path
        opacity="0.3"
        d="M1.26953 55.4289L1.89453 61.7574L8.37966 65.8202L25.4119 70.5861L41.9754 66.6015L57.7576 48.6317L68.6957 33.3182L67.3675 32.1463L61.039 30.4275L53.6948 29.2555L39.1627 27.0679H35.725L32.9124 28.5523L30.4122 31.0525L27.2089 33.3182L22.2086 36.9122L1.26953 55.4289Z"
        fill="#34322E"
      />

      {foregroundColor !== null ? (
        <path
          d="M14.667 58.8123L1.16699 55.8123L2.66699 65.8123C2.50033 66.4789 2.76699 67.8123 5.16699 67.8123C8.16699 67.8123 19.167 71.3123 23.167 72.3123C27.167 73.3123 31.667 72.8123 37.167 73.3123C42.667 73.8123 43.417 73.3123 44.667 72.3123C47.167 70.3123 47.667 68.3123 50.667 64.8123C53.667 61.3123 60.167 52.8123 61.167 50.8123C62.167 48.8123 68.667 43.3123 69.667 42.3123C70.667 41.3123 70.167 37.3123 70.667 35.3123C71.067 33.7123 68.167 32.3123 66.667 31.8123C67.3337 32.1456 68.667 32.9123 68.667 33.3123C68.667 33.8123 67.167 34.3123 66.167 35.3123C65.167 36.3123 62.167 40.3123 60.667 42.3123C59.167 44.3123 57.667 45.8123 55.167 48.3123C53.167 50.3123 45.667 59.4789 42.167 63.8123C39.667 63.3123 34.367 62.2123 33.167 61.8123C31.967 61.4123 28.0003 61.1456 25.167 60.8123C25.167 62.3123 25.267 64.7123 21.667 64.3123C18.067 63.9123 15.167 59.9789 14.667 58.8123Z"
          fill={foregroundColor}
        />
      ) : (
        ""
      )}

      <path
        d="M14.667 58.8123L1.16699 55.8123L2.66699 65.8123C2.50033 66.4789 2.76699 67.8123 5.16699 67.8123C8.16699 67.8123 19.167 71.3123 23.167 72.3123C27.167 73.3123 31.667 72.8123 37.167 73.3123C42.667 73.8123 43.417 73.3123 44.667 72.3123C47.167 70.3123 47.667 68.3123 50.667 64.8123C53.667 61.3123 60.167 52.8123 61.167 50.8123C62.167 48.8123 68.667 43.3123 69.667 42.3123C70.667 41.3123 70.167 37.3123 70.667 35.3123C71.067 33.7123 68.167 32.3123 66.667 31.8123C67.3337 32.1456 68.667 32.9123 68.667 33.3123C68.667 33.8123 67.167 34.3123 66.167 35.3123C65.167 36.3123 62.167 40.3123 60.667 42.3123C59.167 44.3123 57.667 45.8123 55.167 48.3123C53.167 50.3123 45.667 59.4789 42.167 63.8123C39.667 63.3123 34.367 62.2123 33.167 61.8123C31.967 61.4123 28.0003 61.1456 25.167 60.8123C25.167 62.3123 25.267 64.7123 21.667 64.3123C18.067 63.9123 15.167 59.9789 14.667 58.8123Z"
        stroke="#34322E"
      />
      <path
        d="M1 55.5357L17.9561 40.5333C20.1361 38.535 25.1718 34.1896 27.875 32.7944C31.254 31.0504 32.235 26.6904 37.6849 27.0174C43.1349 27.3444 59.0487 30.0694 60.7927 30.3964C62.1878 30.658 65.6613 31.5954 67.2236 32.0314"
        stroke="#34322E"
      />
      <path d="M42.2803 65.8301C42.4636 66.6463 42.7204 68.8986 42.2803 71.3773" stroke="#34322E" />
      <path d="M38.5303 28.4841C38.7136 29.3004 38.9704 31.5526 38.5303 34.0314" stroke="#34322E" />
      <path
        d="M12.208 58.0853C15.9583 54.96 24.1464 48.1783 26.8965 46.0533C30.3341 43.3969 35.4906 38.8654 36.8188 37.6153C38.147 36.3652 38.8502 34.8808 40.8035 35.4277C42.7567 35.9746 45.5694 36.8339 51.8197 37.6153C56.82 38.2404 61.4036 38.8134 63.0704 39.0217"
        stroke="#34322E"
      />
    </svg>
  );
};

export default Empty;
