import { useEffect, useState } from "react";
import type { FC } from "react";

import { SelfEvaluationOption } from "components";

import type { SelfEvaluationFragment } from "api/generated";

import { getFirebaseImageURL } from "utils/getFirebaseImageURL";

interface Props {
  selectIndex: number;
  selfEvaluationData: SelfEvaluationFragment | null | undefined;
  score: number | null;
  handleEvaluationSelect: (index: number, points: number) => void;
}

const SelfEvaluationOptionSelection: FC<Props> = ({
  selectIndex,
  selfEvaluationData,
  score,
  handleEvaluationSelect,
}) => {
  if (selfEvaluationData === null || selfEvaluationData === undefined) return null;

  const [imageUrls, setImageUrls] = useState<string[]>(new Array(selfEvaluationData.options.length).fill(""));

  useEffect(() => {
    selfEvaluationData.options.forEach((option, index) => {
      if (typeof option.image === "string") {
        getFirebaseImageURL(option.image)
          .then(url => {
            if (url === undefined) return;
            setImageUrls(urls => {
              const copy = [...urls];
              copy[index] = url;
              return copy;
            });
          })
          .catch(console.error);
      }
    });
  }, []);

  return (
    <>
      {selfEvaluationData.options.map((option, optionIndex) => {
        const scoreReal = option.scoreReal;
        if (typeof scoreReal !== "number") return null;

        return (
          <SelfEvaluationOption
            key={`${selectIndex}-${optionIndex}`}
            score={score}
            scoreReal={scoreReal}
            optionText={option.text}
            selectIndex={selectIndex}
            optionIndex={optionIndex}
            imagePath={Array.isArray(imageUrls) ? imageUrls?.[optionIndex] : undefined}
            handleEvaluationSelect={handleEvaluationSelect}
          />
        );
      })}
    </>
  );
};

export default SelfEvaluationOptionSelection;
