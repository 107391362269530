import type { FC, FunctionComponent } from "react";

interface Props {
  icon: FunctionComponent;
  count: number | null;
}

const AchievementIcon: FC<Props> = ({ icon: Icon, count }) => {
  return (
    <div>
      <Icon />
      {count !== null && count > 0 ? <div className="achievement__badge">{count}x</div> : null}
    </div>
  );
};

export default AchievementIcon;
