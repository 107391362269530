import { Navigate, Route, Routes } from "react-router-dom";

import BackgroundSound from "./BackgroundSound";
import PhoneChange from "./PhoneChange";
import PhoneValidation from "./PhoneValidation";
import Settings from "./Settings";

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Settings />} />
      <Route path="/zvuk" element={<BackgroundSound />} />
      <Route path="/overeni-telefonu" element={<PhoneValidation />} />
      <Route path="/zmena-telefonu" element={<PhoneChange />} />

      <Route path="*" element={<Navigate to="/nastaveni" />} />
    </Routes>
  );
};

export default SettingsRoutes;
