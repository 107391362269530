import { useTranslation } from "react-i18next";
import { Badge, Breadcrumb, Container, Form, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconArrowBack from "../../../../components/Icons/IconArrowBack/IconArrowBack";
import Button from "react-bootstrap/Button";
import AssignmentBox from "../../../../components/AssignmentBox/AssignmentBox";
import { Pagination } from "../../../../components";

const Envelope03 = () => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <div className={"page-header page-header--has-tabs"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/"}>
                <IconArrowBack />
                {t("goHome")}
              </Link>
            </li>
          </Breadcrumb>

          <h1>{t("pageTitle.savedExercises", { ns: "envelope" })}</h1>

          <Button className={"text-uppercase"}>Procvičit tento typ úloh</Button>

          <hr className={"my-25px"} />

          <Form>
            <Form.Control className={"form-search"} placeholder={"Hledej v textu úlohy..."} />
          </Form>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Tab.Container defaultActiveKey={"first"}>
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="first">
                Čeština <Badge bg={""}>18</Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                Matematika <Badge bg={""}>18</Badge>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {[
                {
                  id: "1",
                  href: "/",
                  text: "Lorem ipsum dolor sit amet",
                  testId: "cj_test_t02",
                  testOrder: 1,
                  envelopeType: "green",
                },
                {
                  id: "2",
                  href: "/",
                  text: "Lorem ipsum dolor sit amet",
                  testId: "cj_test_t02",
                  testOrder: 2,
                  envelopeType: "orange",
                },
                {
                  id: "3",
                  href: "/",
                  text: "Lorem ipsum dolor sit amet",
                  testId: "cj_test_t02",
                  testOrder: 3,
                  envelopeType: "red",
                },
                {
                  id: "4",
                  href: "/",
                  text: "Lorem ipsum dolor sit amet",
                  testId: "cj_test_t02",
                  testOrder: 4,
                  envelopeType: "green",
                },
                {
                  id: "5",
                  href: "/",
                  text: "Lorem ipsum dolor sit amet",
                  testId: "cj_test_t02",
                  testOrder: 5,
                  envelopeType: "orange",
                },
                {
                  id: "6",
                  href: "/",
                  text: "Lorem ipsum dolor sit amet",
                  testId: "cj_test_t02",
                  testOrder: 6,
                  envelopeType: "red",
                },
              ].map(function (assignment, index) {
                return <AssignmentBox assignment={assignment} key={index} />;
              })}

              <Pagination className={"mt-16px"} current={1} total={11} onNavigation={() => {}} />
            </Tab.Pane>
            <Tab.Pane eventKey="second">This is for the second nav tab</Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
};

export default Envelope03;
