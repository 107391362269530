import { Navigate, Route, Routes } from "react-router-dom";

import AssignmentDetail from "./AssignmentDetail";
import CombinedTopics from "./CombinedTopics";
import Practices from "../Practice";
import Subjects from "./Subjects";
import SuperTopics from "./SuperTopics";
import EnvelopeAssignmentDetail from "./EnvelopeAssignmentDetail";
import EnvelopeCombinedTopics from "./EnvelopeCombinedTopics";
import EnvelopeSuperTopics from "./EnvelopeSuperTopics";
import EnvelopeSubjects from "./EnvelopeSubjects";

const EnvelopeRoutes = () => {
  return (
    <Routes>
      <Route index element={<Subjects />} />

      <Route path="/cviceni/*" element={<Practices />} />

      <Route path="/:superId" element={<SuperTopics />} />
      <Route path="/:superId/:combinedId" element={<CombinedTopics />} />
      <Route
        path="/:superId/:combinedId/:assignmentId"
        element={<AssignmentDetail key={Math.random()} />}
        key={Math.random()}
      />
      <Route path="/detail/:assignmentId" element={<AssignmentDetail />} />

      <Route path="obalka/:envelopeId" element={<EnvelopeSubjects />} />
      <Route path="obalka/:envelopeId/:superId" element={<EnvelopeSuperTopics />} />
      <Route path="obalka/:envelopeId/:superId/:combinedId" element={<EnvelopeCombinedTopics />} />
      <Route path="obalka/:envelopeId/:superId/:combinedId/:assignmentId" element={<EnvelopeAssignmentDetail />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default EnvelopeRoutes;
