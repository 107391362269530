import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useState } from "react";
import type { FC } from "react";

import SelfEvaluationSolution from "features/SelfEvaluation/Practice/SelfEvaluationSolution";

import type { UserAssignmentDetailFragment } from "api/generated";

import type { TAssignmentStatus, TSolutionVariant } from "store/slices/exercise";

import { getEvaluationVariantFromOption, getSelfEvaluationStatus } from "utils/getSolutionVariant";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { useSavePracticeSelfEvaluation } from "utils/hooks/useSavePracticeSelfEvaluation";

export interface ISaveSelfEvaluationAnswerParams {
  userAssignmentId: string;
  selfEvalAnswer: number[];
  status: TAssignmentStatus;
  answerVariants: TSolutionVariant[];
}

interface Props {
  userAssignment: UserAssignmentDetailFragment;
}

const SelfEvaluationAnalysis: FC<Props> = ({ userAssignment }) => {
  if (userAssignment.assignment === undefined || userAssignment.assignment === null) return null;

  const isShared = userAssignment.assignment?.selfEvaluation?.hasSharedSelfEvaluation === true;
  const questionsCount = userAssignment.assignment?.assignment?.questions.length;
  const selfEvaluation = userAssignment.assignment?.selfEvaluation;

  const [shownStep, setShownStep] = useState<"options" | "summary" | "goNext">("options");
  const [selectedOptions, setSelectedOptions] = useState<
    Array<{ points: number | null; status: TSolutionVariant | null }>
  >(new Array(isShared ? 1 : questionsCount).fill({ points: null, status: null }));

  const { imageUrl: solutionImageURL } = useImageUrlResolver(selfEvaluation?.sharedSelfEvaluation?.general?.image);
  const saveToStore = useSavePracticeSelfEvaluation();

  const handleEvaluationSelect = (index: number, option: number) => {
    let maxPoints = 0;

    if (isShared) {
      const points = userAssignment.assignment?.selfEvaluation?.sharedSelfEvaluation?.options
        .map(option => option.scoreReal)
        .filter((point): point is number => typeof point === "number");
      maxPoints = points === undefined ? 0 : Math.max(...points);
    } else {
      const points = userAssignment.assignment?.assignment?.questions[index].selfEvaluation?.options
        .map(option => option.scoreReal)
        .filter((point): point is number => typeof point === "number");
      maxPoints = points === undefined ? 0 : Math.max(...points);
    }

    setSelectedOptions(options => {
      const copy = [...options];
      copy[index] = {
        points: option,
        status: getEvaluationVariantFromOption(option, maxPoints),
      };

      return copy;
    });
  };

  const handleOptionsSubmit = () => {
    const scoreMax = userAssignment.assignment?.scoring?.scoreMax ?? 0;

    saveToStore({
      answerVariants: [],
      selfEvalAnswer: selectedOptions,
      status: getSelfEvaluationStatus(selectedOptions, scoreMax),
      userAssignmentId: userAssignment.id,
    });
    setShownStep("summary");

    return false;
  };

  return (
    <div>
      {isShared ? <Latex>{selfEvaluation?.sharedSelfEvaluation?.general?.text ?? ""}</Latex> : null}
      {solutionImageURL !== null ? <img src={solutionImageURL} /> : null}

      <SelfEvaluationSolution
        sendDataToBe={false}
        selectedOptions={selectedOptions}
        userAssignment={userAssignment}
        shownStep={shownStep}
        handleEvaluationSelect={handleEvaluationSelect}
        handleOptionsSubmit={handleOptionsSubmit}
        handleNextClick={undefined}
      />
    </div>
  );
};

export default SelfEvaluationAnalysis;
