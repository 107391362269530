import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PracticePageContent from "../_PracticePageContent";

import { useGetPracticeEnvelopeQuery } from "api/generated";
import type { UserAssignmentPracticeFragment } from "api/generated";

import { translateEnvelopeId } from "utils/translateEnvelopeId";
import { processApiError } from "utils/processApiError";

const EnvelopePractice = () => {
  const { envelopeId } = useParams();
  if (envelopeId === undefined) return null;

  const { t } = useTranslation();

  const {
    data,
    isLoading,
    error: apiError,
  } = useGetPracticeEnvelopeQuery({ envelopeId: translateEnvelopeId(envelopeId) });
  const [allAssignments, setAllAssignments] = useState<UserAssignmentPracticeFragment[] | null>(null);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    setAllAssignments(data.trainingEnvelope);
  }, [data]);

  const getTitle = () => {
    switch (envelopeId) {
      case "cervena":
        return t("pageTitle.redEnvelope", { ns: "envelope" });
      case "oranzova":
        return t("pageTitle.orangeEnvelope", { ns: "envelope" });
      case "zelena":
        return t("pageTitle.greenEnvelope", { ns: "envelope" });
      default:
        return "";
    }
  };

  return (
    <PracticePageContent isLoading={isLoading} assignments={allAssignments} title={getTitle()} topicId={envelopeId} />
  );
};

export default EnvelopePractice;
