import TagManager from "react-gtm-module";
import type { TAssignmentStatus } from "../store/slices/exercise";
import isEqual from "lodash/isEqual";

export const gtmTaskEvent = (
  event: "task-shown" | "task-result" | "task-analysis" | "task-post-analysis",
  eventData: {
    id: string | undefined;
    subject: string | null | undefined;
    questionIndex?: number;
    score?: number | string;
    result?: TAssignmentStatus;
  },
) => {
  const data = {
    event,
    ...eventData,
  };

  const itemAlreadyPresent =
    typeof (window as any)?.dataLayer !== "undefined"
      ? (window as any)?.dataLayer.some((item: unknown) => isEqual(item, data))
      : false;

  if (itemAlreadyPresent === false) {
    TagManager.dataLayer({
      dataLayer: data,
    });
  }
};

export const gtmExercisePaused = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "exercise-paused",
    },
  });
};

export const gtmExerciseQuit = (index: number, count: number) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "exercise-quit",
      questions: `${index}/${count}`,
    },
  });
};

export const gtmExerciseFinished = (total: number, countCompleted: number, countCorrect: number, time: number) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "exercise-finished",
      questions: `${countCompleted}/${total}`,
      correctAnswers: `${countCorrect}/${countCompleted}`,
      time,
    },
  });
};

export const gtmExerciseSelfEvaluation = (evaluation: number) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "exercise-self-evaluation",
      evaluation: reverseEvaluation(evaluation),
    },
  });
};

const reverseEvaluation = (evaluation: number) => {
  switch (evaluation) {
    case 1:
      return 5;
    case 2:
      return 4;
    case 3:
      return 3;
    case 4:
      return 2;
    case 5:
      return 1;
  }
};
