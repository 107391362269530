import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { tooltipTexts, doneOnboardingTooltip } from "./TooltipTexts";

import { ExerciseBadgeModal, OnboardingTooltip } from "components";
import { ExerciseResults } from "features";

import {
  useSetOnboardingFinishedMutation,
  useLazyGetOnboardingStatusQuery,
  useSetTimetableGenericMutation,
} from "api/generated";

import { useAppSelector } from "store/hooks";
import { getResults } from "store/slices/exercise";
import { selectAdmissionsDeadline } from "store/slices/user";

import { formatDate } from "utils/calendarDateFormatting";
import { processApiError } from "utils/processApiError";
import { onboardingBadgeDataFormatter } from "utils/newBadgeDataFormatter";

import { DEFAULT_GENERIC_TIMETABLE } from "const";

const setHighlightClass = (element: HTMLElement) => {
  element.classList.add("highlight-tooltip");
};

const resetHighlightClass = (element: HTMLElement) => {
  element.classList.remove("highlight-tooltip");
};

const ExerciseResultsPage = () => {
  const [finishOnboarding, { error: apiOnboardingMutationError }] = useSetOnboardingFinishedMutation();
  const [getOnboardingStatus, { error: apiOnboardingError }] = useLazyGetOnboardingStatusQuery();
  const [setTimetableGenericMutation, { error: apiTimetableMutationError }] = useSetTimetableGenericMutation();

  const navigate = useNavigate();

  const results = useAppSelector(getResults);
  const admissionDate = useAppSelector(selectAdmissionsDeadline);

  const [showOnboardingBadge, setShowOnboardingBadge] = useState(false);
  const [shownTooltipIndex, setShownTooltipIndex] = useState<number | null>(null);
  const [onboardingFinished, setOnboardingFinished] = useState(false);
  const [doneTooltipVisible, setDoneTooltipVisible] = useState(false);

  const highlightedElement = useRef<HTMLElement | null>(null);
  const shownTooltips = useRef<number[]>([]);
  const processingTooltip = useRef<boolean>(false);
  const firstLoadDone = useRef<boolean>(false);

  useEffect(() => {
    if (firstLoadDone.current) return;

    firstLoadDone.current = true;

    getOnboardingStatus()
      .then(data => {
        if (data.data?.onboarding.finished === true) return;

        setShowOnboardingBadge(true);
        finishOnboarding().catch(console.error);
      })
      .catch(console.error);

    showNextTooltip();
  }, []);

  useEffect(() => {
    if (apiOnboardingMutationError === undefined) return;

    processApiError(apiOnboardingMutationError);
  }, [apiOnboardingMutationError]);

  useEffect(() => {
    if (apiTimetableMutationError === undefined) return;

    processApiError(apiTimetableMutationError);
  }, [apiTimetableMutationError]);

  useEffect(() => {
    if (apiOnboardingError === undefined) return;

    processApiError(apiOnboardingError);
  }, [apiOnboardingError]);

  const showNextTooltip = () => {
    if (processingTooltip.current || highlightedElement.current !== null) {
      return;
    }

    processingTooltip.current = true;

    for (let tooltipIndex = 0; tooltipIndex < tooltipTexts.length; tooltipIndex++) {
      if (shownTooltips.current.includes(tooltipIndex)) continue;

      const elementId = tooltipTexts[tooltipIndex]?.elementId;
      highlightedElement.current =
        elementId !== null && typeof elementId === "string" ? document.getElementById(elementId) : null;

      if (elementId !== undefined && highlightedElement.current !== null) {
        shownTooltips.current.push(tooltipIndex);
      }

      if (
        (highlightedElement.current !== null && highlightedElement.current !== undefined) ||
        elementId === undefined
      ) {
        shownTooltips.current.push(tooltipIndex);
        highlightedElement.current !== null && setHighlightClass(highlightedElement.current);
        setShownTooltipIndex(tooltipIndex);

        processingTooltip.current = false;

        return;
      }
    }

    if (shownTooltips.current.length >= tooltipTexts.length) {
      setTimetableGenericMutation({
        from: formatDate(new Date()),
        to: admissionDate,
        timeTable: DEFAULT_GENERIC_TIMETABLE,
      }).catch(console.error);

      setOnboardingFinished(true);

      return;
    }

    processingTooltip.current = false;
  };

  const onTooltipClose = () => {
    if (highlightedElement.current !== null && highlightedElement.current !== undefined) {
      resetHighlightClass(highlightedElement.current);
    }

    highlightedElement.current = null;
    setShownTooltipIndex(null);
    showNextTooltip();
  };

  const onClose = () => {
    setShowOnboardingBadge(false);
  };

  const onContinue = () => {
    setDoneTooltipVisible(true);
  };

  const onDoneTooltipClose = () => {
    setDoneTooltipVisible(false);
    navigate("/");
  };

  return (
    <>
      <ExerciseResults results={results} envelopes={{}} onContinue={onContinue} />
      <OnboardingTooltip
        key={shownTooltipIndex}
        data={shownTooltipIndex === null ? null : tooltipTexts[shownTooltipIndex]}
        onConfirm={onTooltipClose}
        highlightedElement={highlightedElement.current ?? null}
      />
      {onboardingFinished && showOnboardingBadge ? (
        <ExerciseBadgeModal
          badge={onboardingBadgeDataFormatter()}
          isNavShown={false}
          isShown={true}
          goNext={() => {}}
          goPrev={() => {}}
          onClose={onClose}
        />
      ) : null}
      {doneTooltipVisible ? (
        <OnboardingTooltip
          key={doneOnboardingTooltip.key}
          data={doneOnboardingTooltip}
          onConfirm={onDoneTooltipClose}
          highlightedElement={null}
        />
      ) : null}
    </>
  );
};

export default ExerciseResultsPage;
