import { Navigate, Route, Routes } from "react-router-dom";

import Bookmarked from "../Bookmarks/Overview";
import BookmarkedAssignmentDetail from "../Bookmarks/Detail";

const EnvelopeRoutes = () => {
  return (
    <Routes>
      <Route index element={<Bookmarked />} />
      <Route path="/:userAssignmentId" element={<BookmarkedAssignmentDetail />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default EnvelopeRoutes;
