import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PracticePageContent from "../_PracticePageContent";

import { useGetEnvelopeCombinedTopicPracticeAssignmentsQuery } from "api/generated";
import type { UserAssignmentPracticeFragment } from "api/generated";

import { translateEnvelopeId } from "utils/translateEnvelopeId";
import { processApiError } from "utils/processApiError";

const EnvelopeCombinedTopicPractice = () => {
  const { envelopeId, combinedTopicId } = useParams();
  if (envelopeId === undefined || combinedTopicId === undefined) return null;

  const TOPIC_ID = `${combinedTopicId}-${envelopeId}`;

  const {
    data,
    isLoading,
    error: apiError,
  } = useGetEnvelopeCombinedTopicPracticeAssignmentsQuery({
    envelopeId: translateEnvelopeId(envelopeId),
    combinedTopicId,
  });

  const [allAssignments, setAllAssignments] = useState<UserAssignmentPracticeFragment[] | null>(null);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const assignments = data.userAssignments.items.filter(assignment => assignment.assignment?.isActive);
    setAllAssignments(assignments);
  }, [data]);

  return (
    <PracticePageContent
      isLoading={isLoading}
      assignments={allAssignments}
      title={data?.combinedTopics[0]?.name ?? ""}
      topicId={TOPIC_ID}
    />
  );
};

export default EnvelopeCombinedTopicPractice;
