import type { FunctionComponent } from "react";

import { ReactComponent as SampionColor } from "images/achievements/80-sampion-tydne.svg";
import { ReactComponent as SampionGray } from "images/achievements/80-sampion-tydne-gray.svg";
import { ReactComponent as SuperstarColor } from "images/achievements/80-superstart.svg";
import { ReactComponent as SuperstarGray } from "images/achievements/80-superstart-gray.svg";
import { ReactComponent as Session1Color } from "images/achievements/80-sessions-1.svg";
import { ReactComponent as Session1Gray } from "images/achievements/80-sessions-1-gray.svg";
import { ReactComponent as Session3Color } from "images/achievements/80-sessions-3.svg";
import { ReactComponent as Session3Gray } from "images/achievements/80-sessions-3-gray.svg";
import { ReactComponent as Session7Color } from "images/achievements/80-sessions-7.svg";
import { ReactComponent as Session7Gray } from "images/achievements/80-sessions-7-gray.svg";
import { ReactComponent as Session12Color } from "images/achievements/80-sessions-12.svg";
import { ReactComponent as Session12Gray } from "images/achievements/80-sessions-12-gray.svg";
import { ReactComponent as Session20Color } from "images/achievements/80-sessions-20.svg";
import { ReactComponent as Session20Gray } from "images/achievements/80-sessions-20-gray.svg";
import { ReactComponent as Session30Color } from "images/achievements/80-sessions-30.svg";
import { ReactComponent as Session30Gray } from "images/achievements/80-sessions-30-gray.svg";
import { ReactComponent as Session40Color } from "images/achievements/80-sessions-40.svg";
import { ReactComponent as Session40Gray } from "images/achievements/80-sessions-40-gray.svg";
import { ReactComponent as Session50Color } from "images/achievements/80-sessions-50.svg";
import { ReactComponent as Session50Gray } from "images/achievements/80-sessions-50-gray.svg";
import { ReactComponent as Session70Color } from "images/achievements/80-sessions-70.svg";
import { ReactComponent as Session70Gray } from "images/achievements/80-sessions-70-gray.svg";
import { ReactComponent as Session90Color } from "images/achievements/80-sessions-90.svg";
import { ReactComponent as Session90Gray } from "images/achievements/80-sessions-90-gray.svg";
import { ReactComponent as Session100Color } from "images/achievements/80-sessions-100.svg";
import { ReactComponent as Session100Gray } from "images/achievements/80-sessions-100-gray.svg";
import { ReactComponent as Session125Color } from "images/achievements/80-sessions-125 v3.svg";
import { ReactComponent as Session125Gray } from "images/achievements/80-sessions-125 v3-gray.svg";

export type TBadgeCategory = "plan" | "practice";

export interface IBadgeConfiguration {
  category: TBadgeCategory;
  key: string;
  type: string;
  title: string;
  iconGained: FunctionComponent;
  iconGray: FunctionComponent;
  count: number | null;
  modalTextEarned: string;
  modalTextNotEarned: string;
}

export const badgesConfiguration: IBadgeConfiguration[] = [
  {
    category: "plan",
    key: "sessions",
    type: "sessions-onboarding",
    title: "Super start",
    iconGained: SuperstarColor,
    iconGray: SuperstarGray,
    count: null,
    modalTextEarned: "Vítej na palubě! Úvod máš za sebou a teď směle do procvičování. :-)",
    modalTextNotEarned: "",
  },
  {
    category: "plan",
    key: "plan",
    type: "plan",
    title: "Šampion týdne",
    iconGained: SampionColor,
    iconGray: SampionGray,
    count: 0,
    modalTextEarned:
      "Jsi šampion! Týdenní plán máš splněný a nám nezbývá než smeknout. Jen tak dál! Získáš odznak i v příštím týdnu?",
    modalTextNotEarned:
      "Ještě trocha cviku a odznak máš na triku! Šampion týdne budeš, až dokončíš poslední trénink z plánu pro tento týden",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-1",
    title: "Dokončen 1 trénink",
    iconGained: Session1Color,
    iconGray: Session1Gray,
    count: null,
    modalTextEarned: "Super! První trénink máš za sebou. Odznak za něj ti už nikdo neodpáře, je jen a jen tvůj.",
    modalTextNotEarned: "Odznak za první trénink na tebe čeká! Tak jdeme na to?",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-3",
    title: "Dokončeny 3 tréninky",
    iconGained: Session3Color,
    iconGray: Session3Gray,
    count: null,
    modalTextEarned: "Do třetice všeho dobrého! Máš za sebou tři úspěšné tréninky. Super, jen tak dál.",
    modalTextNotEarned: "Tenhle odznak může být tvůj za 3, 2, 1… Stačí, když 3× dokončíš trénink.",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-7",
    title: "Dokončeno 7 tréninků",
    iconGained: Session7Color,
    iconGray: Session7Gray,
    count: null,
    modalTextEarned: "Z tvého pokroku jsme v sedmém nebi! Gratulujeme k dokončení sedmého tréninku. Jsi skoro profík!",
    modalTextNotEarned:
      "Za sedmero horami, sedmero řekami a hlavně za sedmero tréninky čeká odznak. Pusť se do procvičování a brzy bude tvůj.",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-12",
    title: "Dokončeno 12 tréninků",
    iconGained: Session12Color,
    iconGray: Session12Gray,
    count: null,
    modalTextEarned: "Dobrá práce! Už máš tucet tréninků za sebou. Jde ti to skvěle, jen tak dál.",
    modalTextNotEarned: "Za dvanáctý trénink získáš tenhle odznak. Tak co, pustíme se rovnou do toho?",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-20",
    title: "Dokončeno 20 tréninků",
    iconGained: Session20Color,
    iconGray: Session20Gray,
    count: null,
    modalTextEarned: "No pane jo, dvacet tréninků v řadě, to už je něco! Máme radost, jak ti to jde.",
    modalTextNotEarned: "Dej si dvacet a tenhle odznak máš v kapse. Stačí dokončit dvě desítky tréninků.",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-30",
    title: "Dokončeno 30 tréninků",
    iconGained: Session30Color,
    iconGray: Session30Gray,
    count: null,
    modalTextEarned: "Máš za sebou třicátý trénink, paráda! Takhle vypadá poctivá příprava na přijímačky.",
    modalTextNotEarned: "Vedeš si skvěle! Co takhle ještě trochu potrénovat? Odznak za 30 tréninků už tu na tebe čeká.",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-40",
    title: "Dokončeno 40 tréninků",
    iconGained: Session40Color,
    iconGray: Session40Gray,
    count: null,
    modalTextEarned: "Tomu říkáme parádní pokrok! Už máš za sebou 40 tréninků. Jde ti to skvěle!",
    modalTextNotEarned: "Za 40 tréninků získáš tenhle odznak. Tak s vervou do toho a za chvilku máš hotovo!",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-50",
    title: "Dokončeno 50 tréninků",
    iconGained: Session50Color,
    iconGray: Session50Gray,
    count: null,
    modalTextEarned:
      "Gratulujeme k bezva výkonu! Už máš za sebou 50 tréninků. Máme takové tušení, že u přijímaček pěkně zaválíš.",
    modalTextNotEarned: "Vytáhneš to na 50 tréninků? Stačí ještě trocha procvičování a tenhle odznak bude tvůj.",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-70",
    title: "Dokončeno 70 tréninků",
    iconGained: Session70Color,
    iconGray: Session70Gray,
    count: null,
    modalTextEarned:
      "Wow, ty seš úplný trénink master! Už jich máš za sebou 70 a my doufáme, že to není ještě finální číslo.",
    modalTextNotEarned: "Ještě trocha procvičování a odznak za 70 tréninků bude tvůj. Tak co, jdeme rovnou na to?",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-90",
    title: "Dokončeno 90 tréninků",
    iconGained: Session90Color,
    iconGray: Session90Gray,
    count: null,
    modalTextEarned:
      "Gratulujeme! Získáváš odznak za 90 tréninků a naše obří uznání za to, jak poctivě se přípravě věnuješ. Takhle by to mělo vypadat!",
    modalTextNotEarned: "Ještě kousek a odznak za 90 tréninků bude tvůj. Pustíme se rovnou do procvičování?",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-100",
    title: "Dokončeno 100 tréninků",
    iconGained: Session100Color,
    iconGray: Session100Gray,
    count: null,
    modalTextEarned:
      "Juhůůů, tak to je jízda! Málokdo to dokáže vytáhnout z 0 na 100. Gratulujeme ke stovce dokončených tréninků.",
    modalTextNotEarned: "Zakulatíme společně počet tréninků? Za 100 dokončených získáš tenhle odznak.",
  },
  {
    category: "practice",
    key: "sessions",
    type: "sessions-125",
    title: "Dokončeno 125 tréninků",
    iconGained: Session125Color,
    iconGray: Session125Gray,
    count: null,
    modalTextEarned:
      "Jsi mistr tréninků! Na tuhle úroveň se dopracuje málokdo. Odznak za 125 dokončených tréninků má v naší aplikaci jen minimum studujících a ty jsi teď jedním z nich.",
    modalTextNotEarned:
      "Ještě trocha procvičování a získáš náš odznak za největší počet tréninků. Zařadíš se mezi elitu? Stačí dokončit 125 tréninků.",
  },
];
