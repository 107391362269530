import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useState } from "react";
import type { FC, ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { AnswerSimple, BookmarkButton } from "components";
import IconArrowBackBig from "components/Icons/IconArrowBackBig/IconArrowBackBig";

import type { AssignmentDetailFragment } from "api/generated";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

interface Props {
  assignment: AssignmentDetailFragment;
  answerType: "success" | "warning" | "danger";
  isShown: boolean;
  questionIndex: number;
  toggleModal: () => void;
  isGoBackShown?: boolean;
  children: ReactNode;
  userAssignmentId: string;
}

const SolutionModal: FC<Props> = ({
  assignment,
  questionIndex,
  answerType,
  children,
  isShown,
  toggleModal,
  isGoBackShown,
  userAssignmentId,
}) => {
  const { t } = useTranslation(["common"]);
  const [isAssignmentVisible, setAssignmentVisible] = useState(false);

  const analysis =
    assignment.analysis?.hasSharedAnalysis === true
      ? assignment.analysis.sharedAnalysis
      : assignment.assignment?.questions[questionIndex].solutionAnalysis;

  const { imageUrl: imageAnalysisUrl } = useImageUrlResolver(analysis?.image);
  const { imageUrl: imageAssignmentUrl } = useImageUrlResolver(assignment?.assignment?.assignmentImage);

  const getTitle = () => {
    const heading = assignment.assignment?.questions[questionIndex]?.heading;
    if (typeof heading === "string" && heading.length > 0) return heading;

    return "";
  };

  const toggleAssignment = () => {
    setAssignmentVisible(val => !val);
  };

  return (
    <Modal show={isShown} onHide={toggleModal} className={"modal--exercise"} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Button size={"sm"} variant={"navigation"} onClick={toggleModal}>
            <IconArrowBackBig />
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="exercise">
          <div className="exercise__body">
            <div>
              <Button variant="link" onClick={toggleAssignment} className="p-0 mb-20px">
                {isAssignmentVisible ? "Skrýt zadání" : "Zobrazit zadání"}
              </Button>

              {isAssignmentVisible ? (
                <div>
                  <Latex>{assignment?.assignment?.assignmentText ?? ""}</Latex>
                  {imageAssignmentUrl !== null ? <img src={imageAssignmentUrl} /> : null}
                </div>
              ) : null}
            </div>

            <Latex>{getTitle()}</Latex>
            <AnswerSimple type={answerType}>{children}</AnswerSimple>
            <hr className={"in-modal thin gray-200"} />
            <p>
              {/* TODO: Translate */}
              <strong>Postup řešení</strong>
            </p>
            {typeof analysis?.explainerVideo === "string" && analysis.explainerVideo.length > 0 ? (
              <>
                <div className={"video-wrapper mt-3"}>
                  <iframe
                    src={`https://player.vimeo.com/video/${analysis?.explainerVideo}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </>
            ) : null}
            {typeof analysis?.writtenVideo === "string" && analysis.writtenVideo.length > 0 ? (
              <>
                <div className={"video-wrapper mt-3"}>
                  <iframe
                    src={`https://player.vimeo.com/video/${analysis?.writtenVideo}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </>
            ) : null}
            {imageAnalysisUrl !== null ? (
              <div className="mt-3">
                <img src={imageAnalysisUrl} />
              </div>
            ) : null}
            <div className="mt-3">
              <Latex>{analysis?.text ?? ""}</Latex>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer-btn-wrapper">
          <div className="modal-footer-btn-stretch">
            <BookmarkButton userAssignmentId={userAssignmentId} />
            <Button variant={"outline-secondary-darker"} onClick={toggleModal} className={"flex-grow-0"}>
              {t("close")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SolutionModal;
