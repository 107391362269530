import { useState, useEffect } from "react";
import type { FC } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CalendarWeek, ExerciseButtons } from "features";

import type { GetDashboardDataQuery, StudyDayFragment, WorstTopicsFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { getUserEmail, selectAdmissionsDeadline, selectDateOfRegistration } from "store/slices/user";

import { formatDate } from "utils/calendarDateFormatting";
import { getSessionFromStorage } from "utils/sessionStorageHandler";

import tipImage from "images/icons/tip.svg";
import TagManager from "react-gtm-module";
import { capitalize } from "lodash";

const calculateInterruptedExerciseTime = (email: string | null | undefined) => {
  const sessionData = getSessionFromStorage(email);

  if (sessionData === null) return 0;

  return sessionData.spentTime;
};

interface Props {
  data: GetDashboardDataQuery | undefined;
}

const DashboardCalendarOverview: FC<Props> = ({ data }) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const today = formatDate(new Date());
  const userEmail = useAppSelector(getUserEmail);
  const admissionsDeadline = useAppSelector(selectAdmissionsDeadline);
  const dateOfRegistration = useAppSelector(selectDateOfRegistration);

  const [trainTopics, setTrainTopics] = useState<WorstTopicsFragment[]>([]);
  const [weekData, setWeekData] = useState<{
    studyDays: StudyDayFragment[];
    tests: string[];
    admissionsDeadline: string;
    dateOfRegistration: string | null;
  }>({
    studyDays: [],
    tests: [],
    admissionsDeadline: "",
    dateOfRegistration: null,
  });

  const interruptedExerciseTime = calculateInterruptedExerciseTime(userEmail);

  useEffect(() => {
    if (data === undefined || data.timetable === undefined || admissionsDeadline === "undefined") return;

    const studyDays: StudyDayFragment[] = [];
    for (const timetable of data.timetable.timetable) {
      for (const plan of timetable.plan) {
        studyDays.push(plan);
      }
    }

    const tests = data.testPresence.map(presence => presence.dateTime.split(" ")[0]);
    setWeekData({ studyDays, tests, admissionsDeadline, dateOfRegistration });

    const worstTopics = [...data.userTopicsWorst];
    if (Array.isArray(worstTopics)) {
      setTrainTopics(worstTopics.sort((a, b) => (a.abilityScoreAll ?? 0) - (b.abilityScoreAll ?? 0)));
    }
  }, [data, admissionsDeadline, dateOfRegistration]);

  const todayResult = weekData.studyDays.find(day => day.date === today);

  const redirectExercise = () => {
    TagManager.dataLayer({ dataLayer: { event: "start-exercise" } });

    navigate("/cviceni");
  };

  const notPlanned =
    todayResult === undefined || typeof todayResult.duration !== "number" || todayResult.duration === 0;

  const shouldTrain =
    todayResult !== undefined &&
    typeof todayResult.duration === "number" &&
    todayResult.duration > 0 &&
    (typeof todayResult?.durationReal !== "number" || todayResult.durationReal === 0) &&
    interruptedExerciseTime === 0;

  const isTrainingFinished = todayResult?.sessionFinished === true;

  const isTrainingPartiallyDone =
    todayResult !== undefined &&
    typeof todayResult.duration === "number" &&
    todayResult.duration > 0 &&
    todayResult.sessionFinished !== true &&
    ((typeof todayResult.durationReal === "number" && todayResult.durationReal > 0) || interruptedExerciseTime > 0);

  return (
    <div className="well">
      <div className="pt-6px">
        <div className="px-3 pt-8px">
          <CalendarWeek data={weekData} />
        </div>
      </div>
      <hr />
      <div className="pt-12px px-16px px-sm-28px pb-32px">
        <Row className="g-12px">
          <Col xs={"auto"} style={{ width: 50 }} className={"text-center"}>
            <Image src={tipImage} />
          </Col>
          <Col>
            {/* Text: Ceka ho procvicovani */}
            {shouldTrain ? (
              <>
                <p className={"fw-bold mb-8px"}>Tvoje cesta začíná!</p>
                <p className={"mb-12px"}>
                  Pojď rovou splnit první procvičování! Máš v plánu {todayResult.duration} minut, tak si připrav věci a
                  jdeme na to!
                </p>
              </>
            ) : null}

            {/* Text: Splneno */}
            {isTrainingFinished ? (
              <>
                <p className={"fw-bold mb-8px"}>Dnes už máš procvičování za sebou, ale...</p>
                {trainTopics.length > 0 ? (
                  <p className={"mb-12px"}>
                    Máš-li trochu času, projdi si pár příkladů z oblastí, které Ti moc nejdou:
                  </p>
                ) : null}
              </>
            ) : null}

            {/* Text: Castecne splneno */}
            {isTrainingPartiallyDone ? (
              <>
                <p className={"fw-bold mb-8px"}>Chybí ti ještě pár příkladů</p>
                <p className={"mb-12px"}>Když splníš plán za celý týden, dostaneš odznak a možná i něco dalšího. :-)</p>
              </>
            ) : null}

            {/* Text: Nema naplanovano */}
            {notPlanned && !isTrainingFinished ? (
              <>
                <p className={"fw-bold mb-8px"}>Dnes nemáš v plánu procvičování, ale...</p>
                {trainTopics.length > 0 ? (
                  <p className={"mb-12px"}>
                    Kdybys měla ale trochu času, mrkni třeba na 3{" "}
                    {trainTopics[0].topic?.name?.toLocaleUpperCase() ?? "úlohy"}, chtělo by je trochu procvičit!
                  </p>
                ) : null}
              </>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={"auto"} style={{ width: 50 }} className={"d-none d-sm-block"}></Col>
          <Col className={"well__btn"}>
            {/* Button: Ceka ho procvicovani */}
            {shouldTrain ? (
              <Button onClick={redirectExercise}>{capitalize(t("btn.startExercise", { ns: "exercise" }))}</Button>
            ) : null}
            {/* Button: Splneno */}
            {isTrainingFinished ? <ExerciseButtons topics={trainTopics} /> : null}
            {/* Button: Castecne splneno */}
            {isTrainingPartiallyDone ? (
              <Button onClick={redirectExercise}>{capitalize(t("btn.finishTraining", { ns: "exercise" }))}</Button>
            ) : null}
            {/* Button: Nema naplanovano */}
            {notPlanned && !isTrainingFinished ? <ExerciseButtons topics={trainTopics} /> : null}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardCalendarOverview;
