import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";
import { Form } from "react-bootstrap";

import Layout from "./_Layout";

import { AbilityScore, AnswerExercise } from "components";
import { ExerciseSelfEvaluation } from "features";

import { useLazyGetAssignmentAnswersQuery, useCheckAnswersMutation } from "api/generated";
import type { UserAssignmentForExerciseFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getAssignmentGainedPoints,
  getAssignmentMaxPoints,
  getAssignmentResult,
  setAbilityScore,
  setAnswer,
  setAssignmentEvaluation,
  setPoints,
  setTimeLearn,
  setTips,
} from "store/slices/exercise";
import {
  getAssignmentTime,
  startAssignmentTimer,
  resetAssignmentTimer,
  stopAssignmentTimer,
  setIsTimerShown,
} from "store/slices/timer";

import { evaluateGenericType, getSolutionVariant } from "utils/getSolutionVariant";
import { useSaveExerciseSelfEvaluation } from "utils/hooks/useSaveExerciseSelfEvaluation";
import { getTimeToLearn } from "utils/getTimeToLearn";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { isSelfEvaluationForEveryAssignment } from "utils/valueTranslators";
import { processAnswerResponse } from "utils/processAnswerResponse";
import { isAbilityScoreShown } from "utils/isAbilityScoreShown";
import { changeDistinctAbilityScore, getAbilityScoreOptions } from "utils/changeAbilityScore";
import { processApiError, processExerciseError, logGeneralExerciseError } from "utils/processApiError";
import { useEmptyAssignmentAnswer } from "utils/hooks/useEmptyAssignmentAnswer";

/* cgRyAE4Kk1Ol2MGnphOA */

interface Props {
  data: UserAssignmentForExerciseFragment;
  userAssignmentId: string;
  evaluateAnswer: boolean;
  onNextClick: () => void;
}

const JednoslovneOdpovedi: FC<Props> = ({ data, userAssignmentId, evaluateAnswer, onNextClick }) => {
  const questionsCount = data.assignment?.assignment?.questions.length ?? 0;
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const saveExerciseSelfEvaluation = useSaveExerciseSelfEvaluation();
  const dispatch = useAppDispatch();
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);
  const { setEmptyExerciseAnswer } = useEmptyAssignmentAnswer();

  const assignmentResults = useAppSelector(getAssignmentResult<string>(userAssignmentId));
  const time = useAppSelector(getAssignmentTime);
  const assignmentMaxPoints = useAppSelector(getAssignmentMaxPoints(userAssignmentId));
  const assignmentGainedPoints = useAppSelector(getAssignmentGainedPoints(userAssignmentId));

  const [sendAnswers, { error: apiMutationError, isLoading: isSendingAnswers }] = useCheckAnswersMutation();
  const [trigger, { data: answerData, isLoading: isGettingAnswers, error: apiLazyError }] =
    useLazyGetAssignmentAnswersQuery();

  const [submit, setSubmit] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const [isRequestsLoading, setRequestsLoading] = useState(false);

  useEffect(() => {
    dispatch(resetAssignmentTimer());

    if (assignmentResults?.answers === undefined || assignmentResults.answers.length === 0) {
      dispatch(setAnswer({ assignmentId: userAssignmentId, answer: new Array(questionsCount).fill("") }));
    }

    if (assignmentResults?.tips === undefined || assignmentResults.tips.length === 0) {
      dispatch(setTips({ userAssignmentId, tips: new Array(questionsCount).fill(false) }));
    }

    dispatch(startAssignmentTimer());

    return () => {
      dispatch(resetAssignmentTimer());
    };
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);

    setEmptyExerciseAnswer(userAssignmentId, evaluateAnswers);
  }, [apiLazyError]);

  useEffect(() => {
    processExerciseError(apiMutationError, {
      assignmentId: userAssignmentId,
      selfEvaluation: null,
      userAnswers: assignmentResults?.answers ?? [],
      timeToSolve: time,
    });
  }, [apiMutationError]);

  useEffect(() => {
    setRequestsLoading(isGettingAnswers && isSendingAnswers);
  }, [isGettingAnswers, isSendingAnswers]);

  useEffect(() => {
    let pendingRequestTimeout: NodeJS.Timeout | null = null;

    if (!isGettingAnswers) return;

    pendingRequestTimeout = setTimeout(() => {
      setEmptyExerciseAnswer(userAssignmentId, evaluateAnswers);
      setRequestsLoading(false);
    }, 5000);

    return () => {
      if (pendingRequestTimeout !== null) {
        clearTimeout(pendingRequestTimeout);
      }
    };
  }, [isGettingAnswers]);

  useEffect(() => {
    const responseAnswers = answerData?.assignments.map(
      assignment =>
        assignment.assignment?.questions.map(question => {
          if (data.assignment?.assignment?.isAnswerCaseSensitive === true) return question.correctAnswers;
          return question.correctAnswers
            .filter((answer): answer is string => answer !== null)
            .map(answer => answer.toLocaleLowerCase());
        }),
    );

    if (
      responseAnswers === undefined ||
      responseAnswers[0] === undefined ||
      responseAnswers[0].length === 0 ||
      assignmentResults === undefined ||
      assignmentResults.answers === undefined
    ) {
      return;
    }

    dispatch(
      setAssignmentEvaluation({
        assignmentId: userAssignmentId,
        status: evaluateGenericType(
          assignmentResults.answers,
          responseAnswers[0],
          data.assignment?.assignment?.isAnswerCaseSensitive ?? false,
        ),
        answerVariants: evaluateAnswers(responseAnswers[0]),
        correctAnswers: responseAnswers[0],
      }),
    );
  }, [answerData]);

  useEffect(() => {
    if (!submit) return;

    if (typeof data.assignment?.id === "string") {
      trigger({ assignmentId: data.assignment.id }).catch(error => {
        console.error(error);
      });
    }

    if (assignmentResults === undefined || assignmentResults.answers === undefined || !evaluateAnswer) return;

    sendAnswers({
      assignmentId: userAssignmentId,
      selfEvaluation: null,
      userAnswers: assignmentResults.answers.map(answer => {
        return {
          answers: [answer ?? ""],
        };
      }),
      timeToSolve: time,
    })
      .then(response => {
        processAnswerResponse(response, data);

        if ("data" in response) {
          dispatch(setPoints({ userAssignmentId, points: response.data.answer.scoreReal }));
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        dispatch(resetAssignmentTimer());
        dispatch(startAssignmentTimer());
        setSubmit(false);
      });
  }, [submit]);

  useEffect(() => {
    if (goNext) {
      dispatch(setTimeLearn({ assignmentId: userAssignmentId, timeLearn: time }));
      onNextClick();
      setGoNext(false);
    }
  }, [goNext]);

  const evaluateAnswers = (answers: Array<Array<string | null>>) => {
    if (
      data.assignment === undefined ||
      data.assignment === null ||
      data.assignment?.assignment === undefined ||
      data.assignment?.assignment === null
    )
      return [];
    const caseSensitive = data.assignment.assignment.isAnswerCaseSensitive;

    return data.assignment.assignment.questions.map((_question, qIndex) => {
      return getSolutionVariant(assignmentResults?.answers?.[qIndex], answers, qIndex, caseSensitive ?? false);
    });
  };

  const handleChange = (index: number, value: string) => {
    if (assignmentResults === undefined || assignmentResults.answers === undefined) return;

    const copy = [...assignmentResults.answers];
    copy[index] = value;
    dispatch(setAnswer({ assignmentId: userAssignmentId, answer: copy }));
  };

  const onSubmit = () => {
    setSubmit(true);
    dispatch(setIsTimerShown(false));
  };

  const handleNextClick = () => {
    dispatch(stopAssignmentTimer());
    setGoNext(true);
  };

  const areAnswersFilled = () => {
    try {
      const answers = assignmentResults?.answers;
      const areFilled = answers !== undefined && answers.length > 0 && answers.every(answer => answer.length > 0);
      return areFilled;
    } catch (error) {
      logGeneralExerciseError(error, data?.assignment?.id, assignmentResults?.answers);
      return false;
    }
  };

  const handleAbilityScoreSelect = (value: number, index: number) => {
    if (
      data.assignment === undefined ||
      data.assignment === null ||
      !isSelfEvaluationForEveryAssignment(data.assignment)
    )
      return;

    const newAbilityScore = changeDistinctAbilityScore(value, index, assignmentResults?.abilityScore, questionsCount);
    dispatch(setAbilityScore({ assignmentId: userAssignmentId, score: newAbilityScore }));
  };

  const getAbilityScoreOnIndex = (index: number) => {
    const abilityScore = assignmentResults?.abilityScore;

    if (abilityScore === undefined || typeof abilityScore === "number") return 0;

    return abilityScore[index];
  };

  if (isSelfEvaluation) {
    if (data.assignment === undefined || data.assignment === null) return null;

    return (
      <ExerciseSelfEvaluation
        userAssignment={data}
        userAssignmentId={userAssignmentId}
        results={assignmentResults}
        onNextClick={handleNextClick}
        saveAnswer={saveExerciseSelfEvaluation}
        evaluateAnswer={evaluateAnswer}
      />
    );
  }

  return (
    <Layout
      onSubmit={onSubmit}
      isAnalysis={assignmentResults?.correctAnswers !== undefined}
      goNext={handleNextClick}
      isSubmitDisabled={!areAnswersFilled() || isRequestsLoading}
      userAssignmentId={userAssignmentId}
      showAbilityScore={isAbilityScoreShown(data.assignment?.scoring?.selfEvaluation.selfEvaluation)}
      points={assignmentGainedPoints}
      maxPoints={assignmentMaxPoints}
    >
      <div>
        <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
        {imageUrl !== null ? <img src={imageUrl} /> : null}

        {data.assignment?.assignment?.questions.map((question, qIndex) => {
          const selfEvalOptions = getAbilityScoreOptions(
            isSelfEvaluation,
            assignmentResults?.answersVariants?.[qIndex],
          );

          return (
            <div key={question.heading} className="mt-20px question">
              <Latex>{question.heading ?? ""}</Latex>

              {assignmentResults?.correctAnswers === undefined ? (
                <Form.Control
                  onChange={event => {
                    handleChange(qIndex, event.target.value);
                  }}
                  value={assignmentResults?.answers?.[qIndex] ?? ""}
                ></Form.Control>
              ) : (
                <>
                  <AnswerExercise
                    subject={data.subject?.appName}
                    key={`answer-${qIndex}`}
                    index={qIndex}
                    assignment={data.assignment}
                    userAssignmentId={data.id}
                    answerValidation={{
                      correctAnswer: assignmentResults.correctAnswers?.[qIndex]?.[0] ?? "",
                      userAnswer: assignmentResults?.answers?.[qIndex] ?? "",
                      scoreMax: assignmentMaxPoints,
                    }}
                    type={assignmentResults?.answersVariants?.[qIndex] ?? "danger"}
                    timeToLearn={getTimeToLearn(data.timeToLearn)}
                    onNext={handleNextClick}
                  >
                    {assignmentResults?.answers?.[qIndex]}
                  </AnswerExercise>

                  {data.assignment !== undefined &&
                  data.assignment !== null &&
                  isSelfEvaluationForEveryAssignment(data.assignment) &&
                  question.showAbilityScore === true &&
                  selfEvalOptions !== null ? (
                    <>
                      <hr className={"my-25px"} />
                      <div className="mw-sm">
                        <AbilityScore
                          isSelfEvaluated={isSelfEvaluation}
                          options={selfEvalOptions}
                          selectedValue={getAbilityScoreOnIndex(qIndex)}
                          onValueSelect={(value: number) => {
                            handleAbilityScoreSelect(value, qIndex);
                          }}
                          index={qIndex}
                          key={qIndex}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default JednoslovneOdpovedi;
