import type { MouseEvent } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

import arrowLeft from "images/icons/arrow-right.svg";

interface IPageData {
  heading: string;
  text: string;
  iconName: string;
}

const Introduction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { screenId } = useParams();

  const pagesData = t("introduction", { ns: "onboarding" }) as unknown as IPageData[];

  const redirectOnFirst = () => {
    navigate("/onboarding/uvod/1");
  };

  if (screenId !== Number(screenId).toString()) {
    redirectOnFirst();
    return null;
  }
  // pages are 1, 2, 3,... but index starts at 0
  const screenIndex = Number(screenId) - 1;

  const next = () => {
    if (screenIndex < pagesData.length - 1) {
      // Because index is screen - 1, we have to add 2 to get to the next screen
      navigate(`/onboarding/uvod/${screenIndex + 2}`);
    }
  };

  const setOnboardingStatus = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate("/onboarding/preference");
  };

  return (
    <main className={"text-center minh-100vh d-flex flex-column justify-content-between justify-content-sm-center"}>
      <div className="page page--h-460p px-3">
        <div className="page__content justify-content-center">
          <div className={"mb-3"}>
            <img src={`/images/${pagesData[screenIndex].iconName}`} />
          </div>
          <h1 className={"mb-3 h2"}>{pagesData[screenIndex].heading}</h1>
          <p>{pagesData[screenIndex].text}</p>
        </div>
      </div>

      {screenIndex < pagesData.length - 1 ? (
        <div className={"page__footer page__footer--has-btn"}>
          <div className="steps-indicator">
            {screenIndex !== pagesData.length - 1 &&
              pagesData.map((_el, i) => {
                return <div className={`dot ${i === screenIndex ? "active" : ""}`} key={i} />;
              })}
          </div>
          <Button variant={"primary"} className={"btn-circular"} onClick={next}>
            <img src={arrowLeft} alt="" />
          </Button>
        </div>
      ) : (
        <div className={"page__footer"}>
          <Link
            className={"btn btn-primary w-100 text-uppercase"}
            to="/onboarding/preference"
            onClick={setOnboardingStatus}
          >
            {t("continue", { ns: "common" })}
          </Link>
        </div>
      )}
    </main>
  );
};

export default Introduction;
