import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

const Layout = () => {
  return (
    <Container className={"layout--fluid"} fluid>
      <Outlet />
    </Container>
  );
};

export default Layout;
