import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";

import { ButtonGroup, Col, Container, Form, Image, Row, ToggleButton } from "react-bootstrap";
import { ReactComponent as BgWaveTop } from "images/bg/bckg-branded-top.svg";
import { useState } from "react";

const Exercise10 = () => {
  const { t } = useTranslation(["common", "exercise"]);
  const [selfEvalValue, setSelfEvalValue] = useState(1);

  return (
    <main className={"fullpage fullpage--split"}>
      <BgWaveTop className={"wave__top wave__top--pink"} />

      <div className="fullpage__content bg-cyan-200">
        <Container className="container-mw-sm text-center">
          <div className="page px-3 minh-0">
            <div className="page__content my-auto ms-sm-0 pt-50px">
              <h1 className="h2 mb-20px">
                Super! Tady je Tvé
                <br />
                <span className="text-highlight">vyhodnocení!</span>
              </h1>

              <Row className={"d-flex gx-3 justify-content-center"}>
                <Col className={"flex-grow-0"}>
                  <Image src={"/images/thumbup-color.svg"} loading={"lazy"} />
                </Col>
                <Col className={"flex-grow-0"}>
                  <Image src={"/images/thumbup-color.svg"} loading={"lazy"} />
                </Col>
                <Col className={"flex-grow-0"}>
                  <Image src={"/images/thumbup-outline.svg"} loading={"lazy"} />
                </Col>
              </Row>

              <Row className={"text-center mt-20px row--border-inside gx-2"}>
                <Col>
                  <p className={"d-flex flex-column mb-0"}>
                    <strong className={"mb-8px"}>Otázky</strong>
                    <span>3/3</span>
                  </p>
                </Col>
                <Col>
                  <p className={"d-flex flex-column mb-0"}>
                    <strong className={"mb-8px"}>Čas</strong>
                    <span>7 min</span>
                  </p>
                </Col>
                <Col>
                  <p className={"d-flex flex-column mb-0"}>
                    <strong className={"mb-8px"}>Správně</strong>
                    <span>2/3</span>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>

      <div className="fullpage__footer">
        <Container className="container-mw-sm">
          <Row className={"mb-20px"}>
            <Col className={"text-center"}>
              <h3 className={"mb-20px"}>Jak se cítíš po dokončení procvičování?</h3>
              <ButtonGroup className={"btn-group-emoji mb-20px"}>
                {[
                  { name: "🥺", value: 1 },
                  { name: "😞", value: 2 },
                  { name: "😐", value: 3 },
                  { name: "😊", value: 4 },
                  { name: "😁", value: 5 },
                ].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-1-${idx}`}
                    type="radio"
                    variant="select"
                    name="radio1"
                    value={radio.value}
                    checked={selfEvalValue === radio.value}
                    onChange={e => {
                      setSelfEvalValue(Number.parseInt(e.currentTarget.value));
                    }}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              <p>
                Super, vypadá to, že je tvoje nálada skvělá. Je to proto, že máš všechny otázky správně? Nebo jaký je k
                tomu důvod?
              </p>
              <Form.Control
                as={"textarea"}
                placeholder={"Tady se můžeš rozepsat. Neboj, je to jen pro tebe, nikde nebude zveřejněno"}
              />
            </Col>
          </Row>

          <LinkContainer to="/dev/cviceni">
            <Button className={"text-uppercase w-100"}>{t("btn.showResults", { ns: "exercise" })}</Button>
          </LinkContainer>
        </Container>
      </div>
    </main>
  );
};

export default Exercise10;
