import * as React from "react";

function IconEnvelope(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1" y="1.5" width="14" height="11" rx="3" stroke="currentColor" strokeWidth="2" />
      <path d="M0.941406 4.5L8.47082 7.5L15.0591 4.5" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}

export default IconEnvelope;
