import { BrowserRouter } from "react-router-dom";

import ParentRouter from "./pages/parentRouter";
import TeacherEvaluationRouter from "./pages/teacherEvaluationRouter";
import UserRouter from "./pages/userRouter";

import { Authenticator, AuthenticatedArea } from "components";
import { NotAuthenticated } from "features/NotAuthenticated";

import { useAppSelector } from "store/hooks";
import { selectToken, selectParentToken, selectTeacherEvaluationToken } from "store/slices/token";

import "./App.scss";
import "./Print.scss";

function App() {
  const token = useAppSelector(selectToken);
  const parentToken = useAppSelector(selectParentToken);
  const teacherEvaluationToken = useAppSelector(selectTeacherEvaluationToken);

  return (
    <BrowserRouter>
      <Authenticator>
        {typeof token === "string" && token.length > 0 ? (
          <AuthenticatedArea>
            <UserRouter />
          </AuthenticatedArea>
        ) : typeof parentToken === "string" && parentToken.length > 0 ? (
          <ParentRouter />
        ) : typeof teacherEvaluationToken === "string" && teacherEvaluationToken.length > 0 ? (
          <TeacherEvaluationRouter />
        ) : (
          <NotAuthenticated />
        )}
      </Authenticator>
    </BrowserRouter>
  );
}

export default App;
