import { useEffect } from "react";
import type { FC } from "react";

import { PracticeSelfEvaluation } from "features";
import type { ISaveSelfEvaluationAnswerParams } from "features/SelfEvaluation/Practice/_SelfEvaluation";

import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch } from "store/hooks";
import { setSelfEvalAnswers, setAssignmentEvaluation } from "store/slices/topicPractice";

import { eventEmitter, Events } from "utils/events/EventEmitter";

/* rL4n9VXYXv2RntyoGQlj */

interface Props {
  data: UserAssignmentDetailFragment;
  onNextClick: () => void;
}

const Rysovani: FC<Props> = ({ data, onNextClick }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    eventEmitter.dispatch(Events.ONBOARDING_UPDATE);
  }, []);

  const saveSelfEvaluationAnswer = ({
    userAssignmentId,
    selfEvalAnswer,
    answerVariants,
    status,
  }: ISaveSelfEvaluationAnswerParams) => {
    dispatch(setSelfEvalAnswers({ assignmentId: userAssignmentId, selfEvalAnswer }));
    dispatch(
      setAssignmentEvaluation({
        assignmentId: userAssignmentId,
        status,
        answerVariants,
      }),
    );
  };

  return (
    <PracticeSelfEvaluation
      userAssignment={data}
      userAssignmentId={data.id}
      onNextClick={onNextClick}
      saveAnswer={saveSelfEvaluationAnswer}
    />
  );
};

export default Rysovani;
