import { useTranslation } from "react-i18next";
import { Accordion, Breadcrumb, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconArrowBack from "../../../components/Icons/IconArrowBack/IconArrowBack";

const Help = () => {
  const { t } = useTranslation(["common", "help"]);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/dev"}>
                <IconArrowBack />
                {t("goHome")}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-8px"}>{t("pageNameHelp")}</h1>

          <Form>
            <Form.Control className={"form-search"} placeholder={t("search.placeholder", { ns: "help" })} />
          </Form>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px flex-grow-1"}>
        <Accordion defaultActiveKey="0" className="my-sm-20px">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Co znamenají &quot;obálky&quot;?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Jak funguje procvičování?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Jak změním plán?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Co když nemůžu nějaký den procvičovat?</Accordion.Header>
            <Accordion.Body>
              Každá z otázek se nachází v určité obálce podle toho, jaké máš výsledky. Pokud jsi otázku spočítal dvakrát
              špatně, najdeš ji v červené obálce. Cílem je mít co nejvíce otázek v zelené obálce.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Stále se nelepším, co s tím?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Jak fungují záložky?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default Help;
