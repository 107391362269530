import { useEffect, useState } from "react";

import { CheckExerciseBadges, ExerciseResults } from "features";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { getAssignments, getResults, getNewEnvelopes } from "store/slices/exercise";
import type { IAssignmentResult, IOption } from "store/slices/exercise";
import { updateSessionsCount } from "store/slices/user";

import { processApiError } from "utils/processApiError";

import { useGetCurrentSessionCountQuery } from "api/generated";

interface IEnvelopesChange {
  newEnvelope: string;
  oldEnvelope: string;
}

interface IData {
  data: Record<string, IAssignmentResult<IOption | string>>;
  envelopes: Record<string, IEnvelopesChange>;
}

const Results = () => {
  const results = useAppSelector(getResults);
  const assignments = useAppSelector(getAssignments);
  const newEnvelopes = useAppSelector(getNewEnvelopes);

  const dispatch = useAppDispatch();

  const { data, error: apiError } = useGetCurrentSessionCountQuery();

  const [transformedData, setTransformedData] = useState<IData>({ data: {}, envelopes: {} });

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    const data: Record<string, IAssignmentResult<IOption | string>> = {};
    const envelopes: Record<string, { oldEnvelope: string; newEnvelope: string }> = {};

    for (const assignment of assignments) {
      const assignmentId = assignment.id;
      const result = results[assignmentId];

      data[assignmentId] = result;

      envelopes[assignmentId] = { newEnvelope: newEnvelopes[assignmentId], oldEnvelope: assignment.envelope ?? "" };
    }

    setTransformedData({ data, envelopes });
  }, [results, assignments]);

  useEffect(() => {
    if (data === undefined) return;

    dispatch(updateSessionsCount(data.currentUser.sessionsAchieved ?? 0));
  }, [data]);

  return (
    <>
      <ExerciseResults results={transformedData.data} envelopes={transformedData.envelopes} />
      <CheckExerciseBadges />
    </>
  );
};

export default Results;
