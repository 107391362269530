import { useEffect, useState } from "react";
import { Button, Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Loading, TopicBox } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useAppDispatch } from "store/hooks";
import { removeResults } from "store/slices/topicPractice";

import { useGetEnvelopeSuperTopicTopicsQuery } from "api/generated";

import {
  parseCombinedTopicRedEnvelope,
  parseCombinedTopicOrangeEnvelope,
  parseCombinedTopicGreenEnvelope,
  sortTopicsByName,
} from "utils/sortTopics";
import type { IParsedTopic } from "utils/sortTopics";
import { translateEnvelopeId } from "utils/translateEnvelopeId";
import { processApiError } from "utils/processApiError";

const EnvelopeSuperTopics = () => {
  const { t } = useTranslation(["common"]);
  const { envelopeId, superId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (envelopeId === undefined || typeof superId !== "string" || superId?.length === 0) {
    navigate("/");
    return null;
  }

  const translatedEnvelopeName = translateEnvelopeId(envelopeId);
  if (translatedEnvelopeName === "") return null;

  const {
    data: topicData,
    isLoading,
    error: apiError,
  } = useGetEnvelopeSuperTopicTopicsQuery({
    superTopicId: superId,
    envelopeId: translatedEnvelopeName,
    isGreen: envelopeId === "zelena",
    isOrange: envelopeId === "oranzova",
    isRed: envelopeId === "cervena",
  });

  const [topics, setTopics] = useState<IParsedTopic[]>([]);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  const getTopicHref = (topicId: string) => {
    return `/ulohy/obalka/${envelopeId}/${superId}/${topicId}`;
  };

  useEffect(() => {
    if (topicData === undefined) return;

    const parsedData: IParsedTopic[] = [];
    const topics = topicData.superTopics[0].combinedTopics;

    for (const topic of topics) {
      const parsed = getTopic(topic);
      if (parsed !== null) parsedData.push(parsed);
    }

    setTopics(sortTopicsByName(parsedData));
  }, [topicData]);

  if ((topicData?.superTopics === undefined || topicData.superTopics.length !== 1) && !isLoading) return null;

  const title = topicData === undefined ? "" : topicData.superTopics[0].name;

  const getTopic = (topic: { id: string; name?: string | null | undefined }) => {
    if (topicData === undefined) return null;

    switch (envelopeId) {
      case "cervena":
        return parseCombinedTopicRedEnvelope(topic, topicData.userCombinedTopics, getTopicHref);
      case "oranzova":
        return parseCombinedTopicOrangeEnvelope(topic, topicData.userCombinedTopics, getTopicHref);
      case "zelena":
        return parseCombinedTopicGreenEnvelope(topic, topicData.userCombinedTopics, getTopicHref);
      default:
        return null;
    }
  };

  const handlePracticeStart = () => {
    dispatch(removeResults());
    navigate(`/procvicovani/obalka/${envelopeId}/superokruh/${superId}`);
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={`/ulohy/obalka/${envelopeId}`}>
                <IconArrowBack />
                {t("back", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-3"}>{title !== "" ? title : "\u00A0"}</h1>

          <Button
            className={"text-uppercase"}
            disabled={topicData?.userAssignments.totalCount === undefined || topicData.userAssignments.totalCount === 0}
            onClick={() => {
              handlePracticeStart();
            }}
          >
            {t("trainThisTypeOfAssignments", { ns: "assignments" })}
          </Button>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            {topics.length === 0 ? (
              <div>Kde nic, tu nic</div>
            ) : (
              topics.map(topic => (
                <TopicBox key={topic.topicName} topic={topic} showStars={false} envelopeCounts={topic.envelopes} />
              ))
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default EnvelopeSuperTopics;
