import type { FC } from "react";
import { Nav, Tab } from "react-bootstrap";

import { StarRating, TopicBox } from "components";

import type { ISubjectData } from "utils/sortTopics";

interface Props {
  data: ISubjectData[];
  fallbackText: string;
  showStars: boolean;
}

const SubjectsTab: FC<Props> = ({ data, fallbackText, showStars }) => {
  if (data.length === 0) return null;

  return (
    <Tab.Container defaultActiveKey={data[0].subjectName}>
      <Nav>
        {data.map(subject => {
          return (
            <Nav.Item key={subject.subjectName}>
              {subject.assignmentsCount === undefined ? (
                <Nav.Link eventKey={subject.subjectName} className={"flex-column text-center"}>
                  <div>{subject.subjectName}</div>
                  <StarRating rating={Math.min(subject.subjectPercentage ?? 0, 100) / 100} />
                </Nav.Link>
              ) : (
                <Nav.Link eventKey={subject.subjectName} className={"text-center envelope-counts"}>
                  <div>{subject.subjectName}</div>
                  <div className={`envelope-count envelope-count--default`}>{subject.assignmentsCount}</div>
                </Nav.Link>
              )}
            </Nav.Item>
          );
        })}
      </Nav>
      <Tab.Content className={"subjects"}>
        {data.map(subject => {
          return (
            <Tab.Pane key={subject.subjectName} eventKey={subject.subjectName}>
              {subject.topics.length === 0 ? (
                <p className={"mx-5 my-auto mt-sm-3 mb-sm-0 text-center"}>{fallbackText}</p>
              ) : (
                subject.topics?.map(topic => {
                  return (
                    <TopicBox
                      key={topic.topicName}
                      topic={topic}
                      envelopeCounts={topic.envelopes}
                      showStars={showStars}
                    />
                  );
                })
              )}
            </Tab.Pane>
          );
        })}
      </Tab.Content>
    </Tab.Container>
  );
};

export default SubjectsTab;
