import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { startGlobalTimer, stopAllTimers, setGlobalTimer } from "store/slices/timer";
import { setSavedExerciseAssignments, getAssignments } from "store/slices/exercise";
import { getUserEmail } from "store/slices/user";

import { getSessionFromStorage } from "utils/sessionStorageHandler";

import Test from "./02_Test/Test";
import Summary from "./03_Summary/Summary";
import Results from "./04_Results/Results";

type TExerciseStep = "test" | "summary" | "results";

const Exercise = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const dispatch = useAppDispatch();
  const assignments = useAppSelector(getAssignments);
  const userEmail = useAppSelector(getUserEmail);

  const [step, setStep] = useState<TExerciseStep | undefined>(undefined);

  useEffect(() => {
    if (step !== undefined) return;

    const sessionData = getSessionFromStorage(userEmail);
    const savedSessionId = sessionData?.sessionId;

    if (
      sessionData === null ||
      typeof savedSessionId !== "string" ||
      savedSessionId.length === 0 ||
      sessionId !== savedSessionId
    ) {
      navigate("/cviceni");
      return;
    }

    dispatch(setGlobalTimer(sessionData.spentTime));
    dispatch(setSavedExerciseAssignments(sessionData));
    dispatch(startGlobalTimer());

    return () => {
      dispatch(stopAllTimers());
    };
  }, []);

  useEffect(() => {
    if (assignments.length === 0 || step !== undefined) return;

    setStep("test");
  }, [assignments]);

  const onQuit = () => {
    navigate("/");
  };

  const onFinishingLastAssignment = () => {
    setStep("summary");
  };

  const onSummaryClose = () => {
    setStep("results");
  };

  if (step === "test") return <Test onQuit={onQuit} onFinishingLastAssignment={onFinishingLastAssignment} />;
  if (step === "summary") return <Summary onSubmit={onSummaryClose} />;
  if (step === "results") return <Results />;

  return <h1>Something went wrong</h1>;
};

export default Exercise;
